import React from 'react'
import { Dropdown } from 'react-bootstrap';

export default function FilterVefiedDropDown({ctx=null}) {
  if(!ctx?.state.access?.verificationAccess){
     return ''
  }
  return (
    <>
     <div className="mb-1">
              {/* <label htmlFor="rollName">Role Type <span className=" text-danger">*</span></label><br /> */}
              <Dropdown>
                <Dropdown.Toggle className="input-box role-type-dropdown " >
                  {ctx.state.verified || "Select verify"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom-drop-down">
                  {
                    ['All','Verified', 'Unverified',].map((item, i) => {
                      return (
                        <Dropdown.Item key={i} onClick={() => {ctx.setState({verified:item,verifyUrl:item==='All'?'':`isVerified=${item==='Verified'?1:0}` });ctx.props.history.push({search:item==='All'?'p=1':`p=1&isVerified=${item==='Verified'?1:0}`})}} >{item}</Dropdown.Item>
                      )
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
     </div>
    </>
  )
}
