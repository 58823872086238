import React, { Component } from 'react';
import { connect } from "react-redux";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { ComponentHeader, InformationModal, VerifiedModal } from '../common';
import { deleteRolsApi, getAllRolesApi } from './Api';
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { Col, Image, Row } from "react-bootstrap";
import { START_PAGE, isModuleExist } from "../../utils/Constant";
import editPencil from "../../assets/images/edit-icon.png";
import { USER_ROLE_VERIFY } from '../../utils/urls';
import FilterVefiedDropDown from '../common/FilterVefiedDropDown';

class Roles extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist("Role", 'User Role');

    this.state = {
      access: AccesData,
      delete: false,
      deleteData: {},

      isVerify: false,
      show: false,
      verified: '',
      verifyUrl: ''
    }
  }

  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllRolesApi(this);
  }
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";



    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllRolesApi(this);

      });
    }
  }
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = async (id) => {

    deleteRolsApi(id, this, this.openCloseDeleteModal)

  }
  render() {
    const { access } = this.state;
    const { roleList, totalPages, currentPage } = this.props.rolesState;

    return (
      <>

        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.role.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.role?.roleName} ?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        <VerifiedModal

          name={`'${this.state.editData?.roleName}'`}
          show={this.state.show}
          url={USER_ROLE_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getAllRolesApi(this) }}
        />
        <section className="request-section">
          <ComponentHeader
            title="Roles"

            primaryBtn={access.createAccess ? "Add Role" : ""}
            handlePrimaryBtn={() => {
              this.props.history.push({ pathname: "/add-role", state: { roleList } });
            }}
          />
          <Row>
            <Col sm={2}>
              <FilterVefiedDropDown ctx={this} />
            </Col>
          </Row>
          <div className="commom-table-wrapper">
            <CustomTable
              tableData={roleList?.list || []}
              columnList={[
                {
                  coumnWidth: 250,
                  labelName: "Role",
                  dataKey: "role",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "role") {
                      return rowData?.role?.roleName;
                    }
                  },
                },
                {
                  coumnWidth: access.verificationAccess ? 110 : 0,
                  labelName: "Verified",
                  dataKey: "verify",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "verify") {
                      return <div className=" d-flex align-items-center inter-medium">
                        {(rowData?.role?.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData?.role?.isVerified, editData: rowData?.role })} className="edit-pencil-icon" alt="icon" />
                      </div>;
                    }
                  },
                },
                {
                  coumnWidth: 250,
                  labelName: "Role Type",
                  dataKey: "roleType",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "roleType") {
                      return rowData?.role?.roleType || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Updated By",
                  dataKey: "updatedBy",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "updatedBy") {
                      return rowData.role?.updatedBy?.name || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Created By",
                  dataKey: "createdBy",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "createdBy") {
                      return rowData.role?.createdBy?.name || 'Na';
                    }
                  },
                },
                {
                  // coumnWidth: 170,
                  coumnWidth: access.updateAccess || access.deleteAccess ? 170 : 0,
                  labelName: "Action",
                  dataKey: "",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "") {
                      return (
                        <>{true &&
                          // <>{rowData?.role?.roleName !== 'SuperAdmin' &&
                          <>
                            {access.updateAccess && (
                              <Image src={editIcon} className="edit-icon" onClick={() => { this.props.history.push({ pathname: '/edit-role', state: { roleList, editData: rowData } }) }} />
                            )}
                            {access.deleteAccess && (
                              <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                            )}
                          </>
                        }
                        </>
                      );
                    }
                  },
                },
              ]}
              message="No data found" // For Pagination
              history={this.props.history}
              location={this.props.location}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </div>
        </section>
      </>
    )
  }
}

const mapStateToProps = state => ({
  rolesState: state.RolesState
});
const mapDispatchToProps = {
  getAllRolesApi,
}
Roles.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);