import React from "react";

import {
    Form,
    FormElement,
    FormValidator,
    CustomTextControl,
    BaseReactComponent,
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import { ComponentHeader, ImageUploader } from "../common";
import { getCommonApi } from "./Api";
import { postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import { toast } from "react-toastify";

class TargetedContentForm extends BaseReactComponent {
    constructor(props) {
        super(props);

        const data = props?.location.state?.data || null;


        this.state = {
            editData: data || "",
            allDropDownData: '',
            targetedContentName: data?.targetedContentName || "",
            description: data?.description || "",
            thumbnailPath: "",
            thumbnailFile: null,
            bannerPath: "",
            bannerfile: null,
            circleImagePath: "",
            circleImageFile: null,
            uploadImage: data?.thumbnailPath || null,
            uploadImage2: data?.bannerPath || null,
            uploadImage3: data?.circleImagePath || null,

            attachments: [],
            // *
            ageGroup: data?.ageGroup || "",
            gender: data?.gender || "",
            employmentType: data?.employmentType || '',
            incomeGroup: data?.incomeGroup || '',
            maritalStatus: data?.maritalStatus || '',
            goal: data?.goal || '',
            riskAppetite: data?.riskAppetite || '',
            financialUnderstanding: data?.financialUnderstanding || '',


            resLoading: false,

        };
    }
    convertToCamelCase = sentence => sentence.toLowerCase().split(' ').map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))).join('');
    async componentDidMount() {
        const res = await getCommonApi('targetedContentFilters')

        this.setState({
            allDropDownData: res.data.data.list
        })
    }
    imageUploaser = (e, name) => {

        const imageUrl = URL.createObjectURL(e.target.files[0]);
        if (name === "banner") {
            this.setState({ bannerPath: e.target.files[0].name, bannerfile: e.target.files[0], uploadImage2: imageUrl })
        } else if (name === "circleImage") {
            this.setState({ circleImagePath: e.target.files[0].name, circleImageFile: e.target.files[0], uploadImage3: imageUrl })
        } else {
            this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
        }
    }

    onValidSubmit = async () => {
        this.setState({ resLoading: true })
        const { thumbnailPath, bannerPath, editData, circleImagePath, bannerfile, thumbnailFile, circleImageFile } = this.state;

        if (!this.state.targetedContentName) {
            toast.error("Fields can not be empty.");
        } else {
            let eventData = {
                "data": {
                    "targetedContentName": this.state.targetedContentName,
                    "description": this.state.description,
                    ...(thumbnailPath ? { "thumbnailPath": thumbnailPath } : {}),
                    ...(bannerPath ? { "bannerPath": bannerPath } : {}),

                }
            }
            const data = new FormData();
            data.append("key", JSON.stringify(eventData));
            thumbnailFile !== null && data.append("file", thumbnailFile);
            bannerfile !== null && data.append("file", bannerfile);

            if (this.state.editData) {
                const response = await putAuthCommonApi(`targetedContent/${this.state.editData?.uuid}`, data);
                if (response.status === 200) {

                    this.setState({ resLoading: false })
                    this.props.history.push({ pathname: `/targeted-content`, search: 'p=1' });
                } else {
                    this.setState({ resLoading: false })
                }
            } else {
                const response = await postAuthCommonApi('/targetedContent', data);
                if (response.status === 200) {
                    // toast.success("Event Added Successfully")
                    this.setState({ resLoading: false })
                    this.props.history.push({ pathname: `/targeted-content`, search: 'p=1' });
                } else {
                    this.setState({ resLoading: false })
                }

            }
        }

    };

    render() {
        const { uploadImage, thumbnailFile, resLoading, bannerfile, uploadImage2, circleImageFile, uploadImage3, allDropDownData, ageGroup, gender, employmentType, incomeGroup, maritalStatus, goal, riskAppetite, financialUnderstanding } = this.state;
        return (
            <section className="add-section">
                <ComponentHeader
                    title={this.state.editData ? "Edit Targeted Content" : "Add Targeted Content"}
                    primaryBtn="Save"
                    resLoading={resLoading}
                    isBackArrow={true}
                    isReturn="/targeted-content"
                    handlePrimaryBtn={this.onValidSubmit}
                />
                <Form onValidSubmit={this.onValidSubmit}>
                    <Row>
                        <Col sm={3}>
                            <FormElement
                                valueLink={this.linkState(this, "targetedContentName")}
                                label="Name"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Field cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        //   type: "",
                                        placeholder: "Enter Name",
                                    },
                                }}
                            />
                        </Col>

                        <Col sm={3}>
                            <ImageUploader required={false} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} />
                        </Col>
                        <Col sm={3}>
                            <ImageUploader required={false} id="Banner" label="Upload Banner" name={typeof (bannerfile) == 'object' ? uploadImage2 : bannerfile} onChange={(e) => this.imageUploaser(e, "banner")} onDelete={() => this.setState({ bannerfile: "" })} />
                        </Col>
                        <Col sm={4}>
                            <FormElement
                                valueLink={this.linkState(this, "description")}
                                label="Description"
                                helpText="Description can't be more than 4000 character"

                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        //   type: "",
                                        as: "textarea",
                                        placeholder: "Enter Description",
                                    },
                                }}
                            />
                        </Col>




                    </Row>

                </Form>

            </section>
        );
    }
}

export default TargetedContentForm;
