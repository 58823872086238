import React from "react";
import { connect } from "react-redux";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  SelectControl,

} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { getKeywordNameApi, getAllAmcApi } from '../MasterCategory/Api';
import "react-datepicker/dist/react-datepicker.css";
import { getEventCategoryApi, getEventSpeakerApi, getAllCitesApi } from "./Api";
import { ImageUploader, SelectKeywords, SelectLocation } from "../common";
import { getAllContentCreatorApi, postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import ReactDOM from "react-dom";
import { EventType, IN_PERSON, MUTUAL_FUND_COMPANY, ONLINE, SUPER_ADMIN, Thefinpedia, YesNoOptions } from "../../utils/Constant";
import moment from "moment";
import { userAmcUuids, userCreatorUuids, userRoleType } from "../../utils/ReusableFunctions";
import { toast } from "react-toastify";
import DatePickerControl from "../dashboard/_utils/DatePickerControl";
const htmlToEditerConvert = (data) => {
  const contentBlock = htmlToDraft(data || '');
  const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
}
class AddEditEvent extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props?.location.state?.data || null;
    const roleType = userRoleType() || SUPER_ADMIN;
    this.state = {
      editData: data,
      roleType,
      eventUuid: data?.uuid || "",
      isUpdated: (props?.location.state ?? false) || false,
      name: data?.name || "",
      isPremium: data?.isPremium.toString() || "",
      description: data ? htmlToEditerConvert(data.description) : EditorState.createEmpty() || "",
      date: data?.startDate ? new Date(data?.startDate) : "",
      endDate: data?.endDate ? new Date(data?.endDate) : "",
      // date: data?.date || "",
      venueName: data?.venueName || "",
      venueMapLink: data?.venueMapLink || "",
      onlineLink: data?.onlineLink || "",
      eventType: data?.eventType || "",
      amount: data?.amount || "",
      organiser: data?.organiser || "",
      eventFormat: data?.eventFormat || "",
      // eventFormat: data?.eventFormat.toUpperCase()==='ONLINE'?"ONLINE":"IN PERSON" || "",
      speaker: data?.speaker || "",
      category: data?.category || "",
      totalSeat: data?.totalSeats || "",
      thumbnailPath: "",
      thumbnailFile: null,
      bannerPath: "",
      bannerfile: null,
      uploadImage: data?.thumbnailPath || null,
      uploadImage2: data?.bannerPath || null,
      location: data?.city ? [data?.city] : [],
      allCities: [],
      keyword: [],
      keywordOption: [],
      selectedKeywords: data?.keywords?.split(',') || [],
      isAddContent: true,

      eventOrganiserOptions: [],
      eventOrganiserUuid: data?.eventOrganiser?.uuid || '',

      eventCategoryOptions: [],
      eventCategoryUuid: data?.eventCategory?.uuid || '',

      eventSpeakerOptions: [],
      eventSpeakerUuid: data?.eventSpeakers?.map((item) => { return item?.uuid }) || '',

      isAmcShow: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
      creatorList: [],
      amc: data?.amc?.uuid || (userAmcUuids().length > 0 ? userAmcUuids() : ''),
      creator: data?.contentCreator?.uuid || (userCreatorUuids() || ''),
      amcOption: [],

      address: data?.address || '',

      search: '',
      locationSearch: '',

      registrationLink: data?.registrationLink || '',
      isRegistration: data?.registrationLink ? true : false,

      resLoading: false,
      isEvent: true,
      htmlRichDescription: "",
      editorState: EditorState.createEmpty(),

    };
  }

  imageUploaser = (e, name) => {

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (name === "banner") {
      this.setState({ bannerPath: e.target.files[0].name, bannerfile: e.target.files[0], uploadImage2: imageUrl })
    } else {
      this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
    }
  }

  componentDidMount() {
    this.props.getKeywordNameApi(this);
    this.props.getAllAmcApi(this);
    // getEventOrganiserApi(this);
    getEventCategoryApi(this);
    getEventSpeakerApi(this);
    getAllCitesApi(this);
    getAllContentCreatorApi(this);



  }
  componentDidUpdate(prevProps, prevState) {
    const editData = this.props?.location.state?.data;
    if (this.state.creatorList !== prevState.creatorList) {
      if (userCreatorUuids()) {
        const isExest = this.state.creatorList?.find(item => userCreatorUuids().split(',')?.includes(item.value))
        console.log(userCreatorUuids().split(','), ' is selected thate this', this.state.creatorList, isExest)
        this.setState({ isAmcShow: isExest?.label?.includes(MUTUAL_FUND_COMPANY) })
      }
    }
    if (this.state.search !== prevState.search) {
      this.props.getKeywordNameApi(this);
    }
    if (this.state.creatorList !== prevState.creatorList) {
      if (userCreatorUuids() || editData?.contentCreator?.uuid) {
        const isExest = this.state.creatorList?.find(item => editData?.contentCreator?.uuid === item.value)
        const isShowAmc = isExest?.label?.includes(MUTUAL_FUND_COMPANY);
        const isShowRegister = isExest?.label?.includes(Thefinpedia);
        this.setState({ isAmcShow: isShowAmc, isRegistration: !isShowRegister })
      }
    }
    if (this.state.locationSearch !== prevState.locationSearch) {
      getAllCitesApi(this);
    }
  }
  handlePageSize = (pageSize) => {

  };
  handleSave = () => {
    const requiredFields = [
      this.state.name,
      this.state.date,
      this.state.endDate,
      this.state.eventFormat,
      this.state.creator,
      this.state.eventSpeakerUuid,
      this.state.eventCategoryUuid,
      this.state.isPremium,
      this.state.eventType,
      this.state.eventCategoryUuid,
      this.state.totalSeat,
      ...(this.state.eventFormat === "In Person"
        ? [this.state.address, this.state.venueName]
        : []
      )
    ];

    const emptyField = requiredFields.some(field =>
      field === "" || field == null || (Array.isArray(field) && field.length === 0)
    );

    if (emptyField) {
      toast.error("Please fill out all required fields.");
    }



    ReactDOM.findDOMNode(this.form).dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };
  onValidSubmit = async () => {
    const { registrationLink } = this.state;
    let eventSpeaker = [];
    this.state.eventSpeakerUuid?.map((item) => {
      eventSpeaker?.push({ uuid: item })
    })
    this.setState({ resLoading: true });




    let eventData = {
      "data": {
        "name": this.state.name,
        "description": this.state.htmlRichDescription,
        "startDate": this.state.date,
        "endDate": this.state.endDate,
        "onlineLink": this.state.onlineLink,
        "eventFormat": this.state.eventFormat,
        "eventType": this.state.eventType,
        "isPremium": this.state.isPremium === "true" ? true : false,
        // "amount":parseInt(this.state.amount),
        ...(this.state.eventType === EventType.PAID && { "amount": this.state.amount }),
        "totalSeats": parseInt(this.state.totalSeat),
        "keywords": this.state.selectedKeywords?.join(','),
        "thumbnailPath": this.state.thumbnailPath,
        "bannerPath": this.state.bannerPath,
        // "eventOrganiser": { "uuid": this.state.eventOrganiserUuid },
        "eventCategory": { "uuid": this.state.eventCategoryUuid },
        // "eventSpeakers": [{ "uuid": this.state.eventSpeakerUuid }]
        "registrationLink": this.state.isRegistration === true ? registrationLink : "",
        ...(eventSpeaker?.length > 0 && { "eventSpeakers": eventSpeaker }),


        "amc": {
          "uuid": this?.state?.isAmcShow === true ? this.state.amc : ""
        },

        "contentCreator": {
          "uuid": this.state.creator
        },

        "venueName": this.state.eventFormat !== ONLINE ? this.state.venueName : "",
        "city": this.state.eventFormat !== ONLINE ? this.state.location.join(',') : "",
        "venueMapLink": this.state.eventFormat !== ONLINE ? this.state.venueMapLink : "",
        ...(this.state.address && { "address": this.state.eventFormat !== ONLINE ? this.state.address : "" }),


      }
    }
    const data = new FormData();
    data.append("key", JSON.stringify(eventData));
    data.append("file", this.state?.thumbnailFile);
    data.append("file", this.state?.bannerfile);


    if (this.state.eventUuid) {
      const response = await putAuthCommonApi(`events/${this.state?.eventUuid}`, data);
      if (response.status === 200) {

        this.setState({ resLoading: false })

        this.props.history.push({ pathname: `/events`, search: 'pageSize=10&pageNumber=1' });
      } else {
        this.setState({ resLoading: false })
      }
    } else {
      const response = await postAuthCommonApi('/events', data);
      if (response.status === 200) {
        // toast.success("Event Added Successfully")
        this.setState({ resLoading: false })
        this.props.history.push({ pathname: `/events`, search: 'pageSize=10&pageNumber=1' });
      } else {
        this.setState({ resLoading: false })
      }

    }
  };

  render() {
    const {
      eventFormat,
      uploadImage,
      thumbnailFile,
      bannerfile,
      uploadImage2,
      isPremium,
      roleType,
      amcOption
    } = this.state;
    return (
      <section className="add-section">
        <ComponentHeader
          title={this.state.eventUuid ? "Update Event" : "Add Event"}
          primaryBtn="Save"
          isBackArrow={true}
          resLoading={this.state.resLoading}
          isReturn="/events?pageSize=10&pageNumber=1"
          handlePrimaryBtn={this.handleSave}
        />
        <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "name")}
                label="Event Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Event name cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter event name",
                  },
                }}
              />
            </Col>

            <Col md={4}>
              <div className="form-group custom-date-range-picker">

                <FormElement
                  valueLink={this.linkState(this, "date")}
                  label="Event Start Date"
                  required
                  showTimeSelect
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Start Date cannot be empty",
                    }
                  ]}
                  control={{
                    type: DatePickerControl,

                    settings: {
                      selected: this.state.date,
                      onChange: (date) => this.setState({ date: date }),
                      // maxDate: new Date(),
                      maxDate: this.state.endDate,
                      minDate: moment().toDate(),
                      dateFormat: "MMMM d, yyyy h:mm aa",

                      placeholder: "Event start date",
                      className: "form-control",
                      showYearDropdown: true,
                      yearDropdownItemNumber: 50,
                      scrollableYearDropdown: true,
                      timeIntervals: 15


                    }
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group custom-date-range-picker">

                <FormElement
                  valueLink={this.linkState(this, "endDate")}
                  label="Event End Date"
                  required
                  showTimeSelect
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "End Date cannot be empty",
                    }
                  ]}
                  control={{
                    type: DatePickerControl,

                    settings: {
                      selected: this.state.endDate,
                      onChange: (date) => this.setState({ endDate: date }),
                      // maxDate: new Date(),
                      minDate: this.state.date || moment().subtract(1, 'days').toDate(),
                      dateFormat: "MMMM d, yyyy h:mm aa",
                      placeholder: "Event end Date",
                      className: "form-control",
                      showYearDropdown: true,
                      yearDropdownItemNumber: 50,
                      scrollableYearDropdown: true,
                      timeIntervals: 15


                    }
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "eventFormat")}
                label="Event Format"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Event format cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select event format",
                    options: [
                      { label: "Online", value: ONLINE },
                      { label: "Offline", value: IN_PERSON },
                    ],
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.eventFormat);
                    },
                  },
                }}
              />
            </Col>
            {eventFormat == ONLINE ? (
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "onlineLink")}
                  label="Online Link"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Link cannot be empty",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter link",
                    },
                  }}
                />
              </Col>

            ) : (
              <>
                <Col md={4}>
                  <FormElement
                    valueLink={this.linkState(this, "venueName")}
                    label="Venue Name"
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Venue name cannot be empty",
                      },
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter venue name",
                      },
                    }}
                  />
                </Col>
                <Col md={4}>
                  <FormElement
                    valueLink={this.linkState(this, "address")}
                    label="Venue Address"
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Adress cannot be empty",
                      },
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter address",
                      },
                    }}
                  />
                </Col>

                <Col md={4}>
                  <SelectLocation
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Please enter Location",
                      },
                    ]}
                    label="Location" placeholder={'Enter Location'} onchangeHandler={(data) => this.setState({ locationSearch: data })} selectedKeyWords={this.state.location} options={this.state.allCities} getKeys={(data) => this.setState({ location: data })} />
                </Col>
                <Col md={4}>
                  <FormElement
                    valueLink={this.linkState(this, "venueMapLink")}
                    label="Venue Map Link"

                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter venue map link",
                      },
                    }}
                  />
                </Col>
              </>
            )}


          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "creator")}
                label="Organiser"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Creator cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Creator",
                    options: this.state.creatorList,
                    multiple: false,
                    searchable: true,
                    disabled: (userAmcUuids()?.length > 0),
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.creator);
                      const isExest = this.state.creatorList?.find(item => this.state.creator?.includes(item.value))
                      const isShowAmc = isExest?.label?.includes(MUTUAL_FUND_COMPANY);
                      const isShowRegister = isExest?.label?.includes(Thefinpedia);


                      this.setState({ isAmcShow: isShowAmc, isRegistration: !isShowRegister })
                    },
                  },
                }}
              />
            </Col>
            {this.state.isRegistration &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "registrationLink")}
                  label="Registration Link"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Please enter venueMapLink",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter registration link",
                    },
                  }}
                />
              </Col>
            }
            {(this.state.isAmcShow || (userAmcUuids()?.length > 0)) &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "amc")}
                  label="AMC"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "AMC cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Enter AMC Name",

                      options: (userAmcUuids()?.length > 0) ? amcOption?.filter((ele) => userAmcUuids()?.some(item => item === ele.value)) : amcOption,//if adil give data then use this
                      multiple: false,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.amc);
                      },
                    },
                  }}
                />
              </Col>
            }
          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "eventSpeakerUuid")}
                label="Event Speaker"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Event speaker cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select speaker",
                    options: this.state.eventSpeakerOptions,
                    multiple: true,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.eventSpeakerUuid);
                    },
                  },
                }}
              />
            </Col>


            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "eventCategoryUuid")}
                label="Event Category"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Event Category cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select category",
                    options: this.state.eventCategoryOptions,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.eventCategoryOptions);
                    },
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "eventType")}
                label="Event Type"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Event type cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select type",
                    options: [
                      {
                        label: "Free",
                        value: EventType.FREE
                      },
                      {
                        label: "Paid",
                        value: EventType.PAID
                      },
                      {
                        label: "Sponsored",
                        value: EventType.SPONSORED
                      }
                    ],
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.eventType);
                    },
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "isPremium")}
                label="Is Premium"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Premium Field cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select value",
                    options: YesNoOptions,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.isPremium);
                    },
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <SelectKeywords label="Keywords" placeholder={'Enter Keywords'} onchangeHandler={(info) => { this.setState({ search: info }) }} selectedKeyWords={this.state.selectedKeywords} options={this.state.keywordOption} getKeys={(data) => this.setState({ selectedKeywords: data })} />
            </Col>


            {
              this.state.eventType === EventType.PAID &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "amount")}
                  label="Amount"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Please enter amount",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter amount",
                    },
                  }}
                />
              </Col>}
            <Col md={8}>

              <h3 className=" inter-bold f-s-16 black-757 mb-10">Description</h3>

              <Editor
                editorStyle={{ height: '200px' }}
                editorState={this.state.description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName bg-white inter-regular f-s-14 lh-15 grey-757"
                onEditorStateChange={(editorState) => {
                  const htmlContent = draftToHtml(convertToRaw(editorState?.getCurrentContent()));
                  this.setState({
                    description: editorState,
                    htmlRichDescription: htmlContent
                  });
                }}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'textAlign', 'emoji', 'colorPicker'],
                  inline: {
                    inDropdown: false,
                    options: ['bold', 'italic', 'underline', 'strikethrough']
                  },
                  fontSize: {
                    inDropdown: false,
                    className: 'd-none',
                    options: []
                  },
                  fontFamily: {
                    inDropdown: false,
                    options: [],
                    className: 'd-none',
                    component: undefined,
                    dropdownClassName: undefined,
                  },

                  blockType: {
                    inDropdown: false,
                    options: [],
                    className: 'd-none',
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  list: {
                    inDropdown: false,
                    options: []
                  },
                  link: {
                    inDropdown: true,
                    options: []
                  },
                  emoji: {
                    inDropdown: true,
                    popupClassName: 'popupEmojiClassName',
                    emojis: ['✔️', '❌']
                  },
                  embedded: {
                    inDropdown: true,
                    options: []
                  },
                  image: {
                    inDropdown: true,
                    options: []
                  },
                  history: {
                    inDropdown: true,
                    options: []
                  },
                  remove: {
                    visible: false
                  }
                }}
              />
            </Col>

            <Col sm={6} className="mt-4 mb-4">
              <ImageUploader required={true} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} />
            </Col>
            <Col sm={6} className="mt-4 mb-4">
              <ImageUploader required={true} id="Banner" label="Upload Banner" name={typeof (bannerfile) == 'object' ? uploadImage2 : bannerfile} onChange={(e) => this.imageUploaser(e, "banner")} onDelete={() => this.setState({ bannerfile: "" })} />
            </Col>

            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "totalSeat")}
                label="Total Seat"
                required
                validations={[
                  {
                    validate: FormValidator.isNum,
                    message: "Please enter numerical value",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter total seat",
                  },
                }}
              />
            </Col>
          </Row>
        </Form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  usersState: state.UsersState,
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
  getKeywordNameApi,
  getAllAmcApi,

};
AddEditEvent.propTypes = {
  // getPosts: PropTypes.func

};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEvent);