import React from "react";
import { connect } from "react-redux";

import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,

} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import "react-datepicker/dist/react-datepicker.css";
import { getAllPlatformDataApi } from "./Api";
import { ImageUploader } from "../common";
import { postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import ReactDOM from "react-dom";
import { SUPER_ADMIN } from "../../utils/Constant";
import { userRoleType } from "../../utils/ReusableFunctions";
class AddEditPlatform extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props?.location.state?.data || null;
    const roleType = userRoleType() || SUPER_ADMIN;


    this.state = {
      editData: data,
      roleType,
      platformUuid: data?.uuid || "",
      isUpdated: (props?.location.state ?? false) || false,
      platformName: data?.platformName || "",
      code: data?.code || "",
      iconImagePath: "",
      thumbnailFile: null,
      uploadImage: data?.iconImagePath || null,
      resLoading: false
    };
  }

  imageUploaser = (e, name) => {
    // console.log(typeof (e.target.files[0]))
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({ iconImagePath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })

  }


  handlePageSize = (pageSize) => {
    // console.log("Heyyy pageSize", pageSize);
  };
  handleSave = () => {
    ReactDOM.findDOMNode(this.form).dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };
  onValidSubmit = async () => {
    this.setState({ resLoading: true });
    const { platformName, code } = this.state;

    const platformData = {
      "data": {
        "platformName": platformName,
        "code": code.toUpperCase()
      }
    };

    const data = new FormData();
    data.append("key", JSON.stringify(platformData));
    data.append("file", this.state?.thumbnailFile);

    if (this.state.platformUuid) {
      const response = await putAuthCommonApi(`platform/${this.state.platformUuid}`, data);
      if (response.status === 200) {
        this.setState({ resLoading: false });
        this.props.history.push({ pathname: `/platform`, search: 'pageSize=10&pageNumber=1' });
      } else {
        this.setState({ resLoading: false });
      }
    } else {
      const response = await postAuthCommonApi('/platform', data);
      if (response.status === 200) {
        this.setState({ resLoading: false });
        this.props.history.push({ pathname: `/platform`, search: 'pageSize=10&pageNumber=1' });
      } else {
        this.setState({ resLoading: false });
      }
    }
  };


  render() {
    const {
      uploadImage,
      thumbnailFile,

    } = this.state;
    // const {platformData}=this.props.autoShareState
    return (
      <section className="add-section">
        <ComponentHeader
          title={this.state.platformUuid ? "Update Platform" : "Add Platform"}
          primaryBtn="Save"
          isBackArrow={true}
          resLoading={this.state.resLoading}
          isReturn="/platform?pageSize=10&pageNumber=1"
          handlePrimaryBtn={this.handleSave}
        />

        <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "platformName")}
                label="Platform Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Name cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter Name",
                  },
                }}
              />
            </Col>

            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "code")}
                label="Code"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Code cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter Code",
                  },
                }}

              />
            </Col>
            <Col sm={6}>
              <ImageUploader required={true} id="Thumbnail" label="Upload Image" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} />
            </Col>

          </Row>


        </Form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  platformData: state.AutoShareState.platformData,
});
const mapDispatchToProps = {


  getAllPlatformDataApi

};
AddEditPlatform.propTypes = {
  // getPosts: PropTypes.func

};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPlatform);