import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import { CustomModal, Spinner } from "../common";
import { addColorApi, updateColorApi } from "./Api";

class ColorForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;
    this.state = {
      isAddContent: true,
      colorName: data?.colorName || "",
      colorCode: data?.colorCode || "",

      resLoading: false,
    };
  }

  componentDidMount() { }
  onValidSubmit = () => {
    this.setState({ resLoading: true })
    let data = {
      "data": {
        "colorName": this.state.colorName,
        "colorCode": this.state.colorCode.includes('#') ? this.state.colorCode : `#${this.state.colorCode}`,
      },
    };
    if (this.props.editData?.uuid) {
      updateColorApi(JSON.stringify(data), this.props.this, this.props.onHide, this.props.editData.uuid, this)
    } else {
      addColorApi(JSON.stringify(data), this.props.this, this.props.onHide, this);
    }

  };
  render() {
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props.editData?.uuid ? "Edit Color" : "Add Color"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "colorName")}
                label="Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Color Name",
                  },
                }}
              />
            </Col>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "colorCode")}
                label="Code"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Color Code",
                  },
                }}
              />
            </Col>
          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              {this.state.resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

export default ColorForm;
