import React from "react";
import { connect } from "react-redux";
import cancel from '../../assets/images/greay-cancel-icon.svg';

import {
    Form,
    FormElement,
    FormValidator,
    CustomTextControl,
    BaseReactComponent,

} from "../../utils/form";
import { Row, Col, Image, Button } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import "react-datepicker/dist/react-datepicker.css";
import { getAllOccassionDataApi } from "./Api";
import { postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import ReactDOM from "react-dom";
import { SUPER_ADMIN } from "../../utils/Constant";
import { userRoleType } from "../../utils/ReusableFunctions";
import { SearchContent } from "../content";
import DatePicker from "react-datepicker";

class AddEditOccassion extends BaseReactComponent {
    constructor(props) {
        super(props);
        const data = props?.location.state?.data || null;
        const roleType = userRoleType() || SUPER_ADMIN;


        this.state = {
            editData: data,
            roleType,
            occassionUuid: data?.uuid || "",
            isUpdated: (props?.location.state ?? false) || false,
            occassionName: data?.occasionName || "",
            resLoading: false,
            date: data?.date ? new Date(data?.date) : "",
            showSearchContent: false,
            selectedContent: data?.content ? [data?.content] : [],
        };





    }

    imageUploaser = (e, name) => {
        // console.log(typeof (e.target.files[0]))
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        this.setState({ iconImagePath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })

    }


    handlePageSize = (pageSize) => {
        // console.log("Heyyy pageSize", pageSize);
    };
    handleSave = () => {
        ReactDOM.findDOMNode(this.form).dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    };
    onValidSubmit = async () => {
        this.setState({ resLoading: true });
        const { occassionName, date } = this.state;



        const occassionData = {
            "data": {
                "occasionName": occassionName,
                "contentUuid": this.state.selectedContent[0].uuid,
                "date": date
            }
        };


        if (this.state.occassionUuid) {
            const response = await putAuthCommonApi(`playlist/content/${this.state.occassionUuid}`, occassionData);
            if (response.status === 200) {
                this.setState({ resLoading: false });
                this.props.history.push({ pathname: `/occassion`, search: 'pageSize=10&pageNumber=1' });
            } else {
                this.setState({ resLoading: false });
            }
        } else {
            const response = await postAuthCommonApi('/playlist/content', occassionData);
            if (response.status === 200) {

                this.setState({ resLoading: false });
                this.props.history.push({ pathname: `/occassion`, search: 'pageSize=10&pageNumber=1' });
            } else {
                this.setState({ resLoading: false });
            }
        }
    };


    render() {
        const {
            uploadImage,
            thumbnailFile,

        } = this.state;
        // const {platformData}=this.props.autoShareState
        return (
            <section className="add-section">
                {
                    this.state.showSearchContent &&
                    <SearchContent
                        showModal={this.state.showSearchContent}
                        onHide={(selectedContent) => this.setState({ showSearchContent: false, selectedContent })}
                        selectedContent={this.state.selectedContent}
                        isMultiselect={false}
                    />
                }
                <ComponentHeader
                    title={this.state.occassionUuid ? "Update Occassion" : "Add Occassion"}
                    primaryBtn="Save"
                    isBackArrow={true}
                    resLoading={this.state.resLoading}
                    isReturn="/occassion"
                    handlePrimaryBtn={this.handleSave}
                />

                <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
                    <Row>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "occassionName")}
                                label="Occassion Name"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Name cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Name",
                                    },
                                }}
                            />
                        </Col>

                        <Col md={4}>
                            <div className="form-group custom-date-range-picker">
                                <label htmlFor="" className="form-label"> Date <span className=" text-danger">*</span></label>
                                <DatePicker
                                    selected={this.state.date}
                                    onChange={(date) => this.setState({ date: date })}
                                    // maxDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select Date"
                                    className="form-control "
                                    // showMonthDropdown
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={50}
                                    scrollableYearDropdown
                                />
                            </div>
                        </Col>

                        <Col md={12}>
                            <div className="form-group">
                                <label htmlFor="" className="form-label">Content</label>

                                <Button onClick={() => this.setState({ showSearchContent: true })} className="primary-btn mb-5 d-block">Select Content</Button>
                            </div>
                        </Col>
                        {this.state.selectedContent?.length > 0 &&

                            <div className=" mb-3 d-flex align-items-center gap-20 w-100" ><span className='inter-regular f-s-14 ellipsis lh-22'><Image width={60} height={60} src={this.state?.selectedContent[0]?.amcCircleImagePath || this.state.selectedContent[0]?.amc?.circleImagePath} className='' alt='img' /> {this.state.selectedContent[0]?.title || this.state.selectedContent[0]?.contentVariation?.title} </span> <Image src={cancel} onClick={() => { this.setState({ selectedContent: {} }) }} className='search-icons pointer' alt='canle imge0' /> </div>
                        }

                    </Row>


                </Form>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    occassionData: state.AutoShareState.occassionData,
});
const mapDispatchToProps = {


    getAllOccassionDataApi

};
AddEditOccassion.propTypes = {
    // getPosts: PropTypes.func

};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditOccassion);