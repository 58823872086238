import React from "react";
import PropTypes from "prop-types";
import {
  BaseReactComponent,
  CustomTextControl,
  Form,
  FormElement,
  FormSubmitButton,
  FormValidator,
} from "../../../utils/form";
import { CustomModal } from "../../common";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { changePasswordApi } from "../Api";

class ChangePasswordModal extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      currentPassword: "",
      newPassword: "",
      verifyPassword: "",
      inProgress: false,
    };
  }
  componentDidMount() {}

  onSubmit = async() => {
    const {currentPassword,newPassword,verifyPassword}=this.state;
    if(newPassword===verifyPassword){
        const data={
            "data":{
                "currentPassword":currentPassword,
                "newPassword": newPassword
            }
        }
        changePasswordApi(data,this.props.handleClose);

    }else{
        toast.error('Password not matched.')
    }
  };
  render() {
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.handleClose}
        title={"Change Password"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onSubmit}>
          <FormElement
            valueLink={this.linkState(this, "currentPassword")}
            label="Current Password"
            required
            validations={[
              {
                validate: FormValidator.isRequired,
                message: "Password cannot be empty",
              },
            ]}
            control={{
              type: CustomTextControl,
              settings: {
                type: "password",
                placeholder: "Enter Current Password",
              },
            }}
          />
          <FormElement
            valueLink={this.linkState(this, "newPassword")}
            label="New Password"
            required
            validations={[
              {
                validate: FormValidator.isRequired,
                message: "Password cannot be empty",
              },
            ]}
            control={{
              type: CustomTextControl,
              settings: {
                type: "password",
                placeholder: "Enter New Password",
              },
            }}
          />
          <FormElement
            valueLink={this.linkState(this, "verifyPassword")}
            label="Verify Password"
            required
            validations={[
              {
                validate: FormValidator.isRequired,
                message: "Password cannot be empty",
              },
            ]}
            control={{
              type: CustomTextControl,
              settings: {
                type: "password",
                placeholder: "Verify the Password",
              },
            }}
          />

          {this.state.errorMessage && (
            <p className="error">{this.state.errorMessage}</p>
          )}
          <div className="submit-wrapper  gap-20">
            <Button onClick={this.props.handleClose} className="secondary-btn">Close</Button>
            <FormSubmitButton customClass="primary-btn">
              Submit
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

ChangePasswordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ChangePasswordModal;
