import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import logo from "../../assets/images/finpedia-logo.svg";
import PasswordHide from "../../assets/images/password-hide.svg";
import PasswordShow from "../../assets/images/password-show.svg";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import ForgotPassword from "../common/ForgotPassword";
import InformationModal from "../common/InformationModal";
import { loginApi } from "./Api";
import { deleteToken } from "../../utils/ManageToken";
import ResetPassword from "./ResetPassword";
class Login extends BaseReactComponent {
  constructor(props) {
    super(props);
    const token = props.match.params.token || "";

    console.log(token)
    this.state = {
      email: "",
      password: "",
      lastName: "",
      showPassowrd: false,
      forgotPassword: false,
      information: false,

      showForgotPasswordModal: false,
      token: token ? token : "",
    };
  }

  componentDidMount() {
    deleteToken();
  }

  openCloseForgotPasswordModal = () => {
    this.setState({
      forgotPassword: !this.state.forgotPassword,
    });
  };
  openCloseInformationModal = () => {
    this.setState({
      information: !this.state.information,
    });
  };

  onValidSubmit = (e) => {

    const data = {
      data: {
        "email": this.state.email,
        "password": this.state.password
      }
    }

    this.props.loginApi(this, data);
  };

  render() {
    return (
      <div className="login-section">
        {
          this.state.token &&
          <ResetPassword
            show={this.state.token}
            handleClose={() => {
              this.setState({ token: false })
              this.props.history.push("/");
            }}
            {...this.prosp}
          />
        }
        {this.state.forgotPassword && (
          <ForgotPassword
            show={this.state.forgotPassword}
            handleClose={this.openCloseForgotPasswordModal}
            email={this.state.email || ''}
          />
        )}
        {this.state.information && (
          <InformationModal
            show={this.state.information}
            handleClose={this.openCloseInformationModal}
          />
        )}
        <div className="login-wrapper d-flex">
          <div className="left">
            <Image src={logo} className="logo" />
          </div>
          <div className="right">
            <div className="login-form">
              <h3 className="inter-bold f-s-18 lh-24">Hey! Welcome Back</h3>
              <Form onValidSubmit={this.onValidSubmit}>
                <FormElement
                  valueLink={this.linkState(this, "email")}
                  label="Email"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Please enter email id",
                    },
                    {
                      validate: FormValidator.isEmail,
                      message: "Please enter valid email",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter email",
                    },
                  }}
                />
                <div className="password-wrapper">
                  <FormElement
                    valueLink={this.linkState(this, "password")}
                    label="Password"
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Password cannot be empty",
                      },
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        type: this.state.showPassowrd ? "text" : "password",
                        placeholder: "Enter Password",
                      },
                    }}
                  />
                  <Image
                    src={this.state.showPassowrd ? PasswordShow : PasswordHide}
                    className='password-hide pos-abs pointer'
                    onClick={() => this.setState({ showPassowrd: !this.state.showPassowrd })}
                  />
                </div>
                <FormSubmitButton customClass="primary-btn">Login</FormSubmitButton>
              </Form>

              <p
                onClick={this.openCloseForgotPasswordModal}
                className="inter-regular grey-757 f-s-16 lh-24 forgot-password"
              >
                Forgot Password?
              </p>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginState: state.LoginState,
});
const mapDispatchToProps = (dispatch) => ({
  loginApi: (ctx, data) => dispatch(loginApi(ctx, data)),
});

Login.propTypes = {
  loginApi: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
