import React, { useEffect, useState } from "react";
import CanvasCobrand from "./CobrandCanvas";
import { Button, Image } from "react-bootstrap";
import ComponentHeader from "../ComponentHeader";
import MinusIconCanvas from "../../../assets/images/canvas/minus-icon-canvas.svg"
const CobrandContent = ({ onClose, onSave, personalizationTemplateVariationImages }) => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [canvasWidth, setCanvasWidth] = useState(500); // Track width for all canvases
  const [canvasHeight, setCanvasHeight] = useState(300);
  const [data, setData] = useState([
  ]);



  useEffect(() => {
    if (personalizationTemplateVariationImages && personalizationTemplateVariationImages.length > 0) {
      const initialData = personalizationTemplateVariationImages.map((item, index) => {

        return {
          canvasWidth: item?.canvasWidth || 500,
          canvasHeight: item?.canvasHeight || 300,
          backgroundColor: item.backgroundColor || "#ffffff",
          ref: null,
          pageNumber: item?.pageNumber,
          editable: true,
          layers: item?.layers || [],
        };
      });

      setData(initialData);
    } else {
      setData([{
        canvasWidth: 500,
        canvasHeight: 300,
        backgroundColor: "#ffffff",
        ref: null,
        pageNumber: 1,
        thumbnail: null,
        editable: true,
        layers: [],
      }])
    }
  }, [personalizationTemplateVariationImages]);
  console.log("main data", data)
  const handleSave = () => {
    const allJsonData = data.map((canvasItem) => {
      if (canvasItem.ref) {
        const canvasJson = canvasItem.ref.toJSON();

        return {
          ...canvasItem,
          layers: canvasJson.objects,
          version: canvasJson?.version,
          backgroundColor: canvasJson.background,
          ref: null,
        };

      } else {
        const jsonData = {
          version: "5.2.1",
          objects: canvasItem.layers,
          background: canvasItem.backgroundColor || "#ffffff",
        };
        return {
          ...canvasItem,
          layers: jsonData.objects,
          version: jsonData.version,
          backgroundColor: jsonData.background,
          ref: null
        };
      }
    }).filter(Boolean); // Remove any null values

    onSave(allJsonData);
  };

  const updateCanvasDimensions = (newWidth, newHeight) => {
    setCanvasWidth(newWidth);
    setCanvasHeight(newHeight);
    const updatedData = data.map((item) => ({
      ...item,
      canvasWidth: newWidth,
      canvasHeight: newHeight,

    }));
    setData(updatedData);
  };

  return (
    <>
      <section>
        <ComponentHeader
          title={"Back"}
          isBackArrow={true}
          onClose={onClose}
          primaryBtn="Save"
          handlePrimaryBtn={handleSave}
          className="mt-4"
        />
        <div className="video-cobrand-container">
          <CobrandContent.Sidebar
            active={currentScreen}
            setActive={setCurrentScreen}
            data={data}
            setData={setData}
            height={canvasHeight}
            width={canvasWidth}
            currentScreen={currentScreen}
            setCurrentScreen={setCurrentScreen}
          />
          <div className="right-container">
            {data.map((item, index) => {
              if (item.pageNumber === currentScreen) {
                if (item.editable) {
                  return (
                    <CanvasCobrand
                      key={item.pageNumber} // This will force the component to re-render when the page changes
                      screen={item.pageNumber}
                      canvas={item}
                      index={index}
                      height={canvasHeight}
                      width={canvasWidth}
                      setHeight={setCanvasHeight}
                      setWidth={setCanvasWidth}
                      updateCanvasDimensions={updateCanvasDimensions}
                      setCanvas={(updatedCanvas) => {
                        const newData = [...data];
                        newData[index] = updatedCanvas;
                        setData(newData);
                      }}
                    />
                  );
                } else {
                  return item.component;
                }
              }
              return null;
            })}

          </div>
        </div>
      </section>
    </>
  );
};

export default CobrandContent;

const Sidebar = ({ active, setActive, data, setData, height, width }) => {
  // Function to add a new page
  const handleAddPage = () => {

    const newPageNumber = data.length + 1;
    const newPage = {
      canvasWidth: width,
      canvasHeight: height,
      backgroundColor: "#ffffff",
      ref: null,
      pageNumber: newPageNumber,
      thumbnail: null,
      editable: true,
      isDefault: true
    };

    setData([...data, newPage]);
  };

  const handleDeletePage = (pageNumber) => {
    const updatedData = data
      .filter((item) => item.pageNumber !== pageNumber)
      .map((item, index) => ({
        ...item,
        pageNumber: index + 1,
      }));

    setData(updatedData);

    if (active > pageNumber) {

      setActive(active - 1);
    } else if (active < pageNumber) {

      setActive(active);
    } else {

      setActive(Math.max(1, pageNumber - 1));
    }

  };

  return (
    <section>
      <div className="sidebar">
        <p className="fx-14 roboto-medium black-242 text-center">Pages</p>
        <div className="slides">
          {data.map((item, index) => (
            <div key={index}>
              <div
                className={`sidebar-item ${active === item.pageNumber ? "active" : ""}`}
              // onClick={() => setActive(item.pageNumber)}
              >
                <div className="d-flex flex-row justify-content-between">
                  <p className="f-s-14">Page{item.pageNumber}</p>
                  {data.length > 1 &&
                    <Image
                      className="delete-page-btn mr-3"
                      onClick={() => handleDeletePage(item.pageNumber)}
                      src={MinusIconCanvas}
                      width={10}
                      style={{ cursor: "pointer" }}

                    />
                  }
                </div>
                {item?.component ? (
                  <div
                    className={`thumbnail ${active === item.pageNumber ? "active" : ""}`}
                    onClick={() => { setActive(item.pageNumber) }}
                  >
                    {item?.component}
                  </div>
                ) : (
                  <div
                    className={`thumbnail ${active === item.pageNumber ? "active" : ""}`}
                    onClick={() => setActive(item.pageNumber)}
                  >
                    <img src={item?.ref?.toDataURL()} alt="" />
                  </div>

                )}
              </div>



              {index < data?.length - 1 && <hr className="show-desktop" />}
            </div>
          ))}
        </div>

        {/* Add More button */}
        <Button className="primary-btn" onClick={handleAddPage}>
          + Add More
        </Button>
      </div>
    </section>
  );
};

CobrandContent.Sidebar = Sidebar;