import React, { Component } from 'react';
import { connect } from "react-redux";


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastName: "",
    }
  }

  componentDidMount() { }

  handleSubmit = () => {

  }

  handleChange = (event) => {
    this.setState({ lastName: event.target.value })
  }

  render() {
    return (
      <div>
        {/* <Form noValidate onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group md="4" controlId="validationFormik102">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={this.state.lastName}
                onChange={(event) => this.handleChange(event)}
                isValid={this.state.lastName ? true : false}
              />

              <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  homeState: state.HomeState
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
}
Home.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);