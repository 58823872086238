import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import { Col, Row, Table } from "react-bootstrap";
import { Image } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { getCalenderDetailsApi } from "./Api";

class ViewCalenderFilterDetails extends BaseReactComponent {
    constructor(props) {
        super(props);
        const { params } = props.match;
        const calenderFilterDetails = this.props.location.state.data;

        this.state = {
            params,
            show: false,


        };
    }

    componentDidMount() {
        getCalenderDetailsApi("/calender-filter", this);

    }
    render() {
        const { data } = this.state;
        console.log(data)
        // console.log('thi is get Regisster detila',registerUser);
        return (
            <section className="user-section">
                {/* // ---------------------------------------View details -------------------------------- */}

                <div className="view-details-wrapper">

                    <ComponentHeader title="View Calender Filter Details" isBackArrow={true} isReturn="/calender-filter" />
                    <div className="view-details d-flex">
                        <div className="view-details-content">
                            <div className="user-details d-flex">
                                <div className="eclipse">
                                    <Image src={data?.icon} className="eclipse" />
                                </div>
                                <div className="name-details">
                                    <h3 className="inter-medium f-s-20">{data?.filterName || ""}</h3>

                                </div>
                                <div className="line"></div>
                                <div className="name-description">
                                    <h3 className="grey-2 ">About</h3>

                                </div>
                            </div>
                            <h3 className="inter-regular f-s-14 grey-2">
                                Calender Filter Details
                            </h3>
                            <div className="line2"></div>
                            <Table className="custom-table">
                                <Row>


                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Filter Name</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.filterName} </h3>
                                        </div>
                                    </Col>


                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Code</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.code || 'NA'}</h3>
                                        </div>
                                    </Col>



                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Is Verified</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.isVerified ? "Yes" : "No" || ''}</h3>
                                        </div>
                                    </Col>





                                </Row>




                            </Table>


                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({ usersState: state.UsersState });
const mapDispatchToProps = {
    // getPosts: fetchPosts
};
ViewCalenderFilterDetails.propTypes = {
    // getPosts: PropTypes.func
    location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCalenderFilterDetails);
