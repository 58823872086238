import React from "react";
import PropTypes from "prop-types";
import {
  BaseReactComponent,
  CustomTextControl,
  Form,
  FormElement,
  FormSubmitButton,
  FormValidator,
} from "../../utils/form";
import CustomModal from "./CustomModal";
import { forgotPasswordApi } from "./api";
import { toast } from "react-toastify";
// import { forgotPasswordApi } from './Api';

class ForgotPassword extends BaseReactComponent {
  constructor(props) {
    const {email}=props
    super(props);
    this.state = {
      'errorMessage': "",
      "email": email || "",
      'inProgress': false,
    };
  }
  componentDidMount() { }

  onSubmit = async() => {
    const userInfo={
      "data": {
          "emailAddress": this.state.email
      }
  }
  const data = new URLSearchParams();
  data.append("email", this.state.email);
  const response=await forgotPasswordApi('admin/user/forgot-password', userInfo );
  if (response.status < 250 && response.status > 199) {
    toast.success(response?.data?.data?.message ||  "Link has been send to email.")
    this.props.handleClose()
  }
  };
  render() {
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.handleClose}
        title={"Forgot Password"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onSubmit}>
          <FormElement
            valueLink={this.linkState(this, "email")}
            label="Email"
            required
            validations={[
              {
                validate: FormValidator.isRequired,
                message: "Please enter email id",
              },
              {
                validate: FormValidator.isEmail,
                message: "Please enter valid email",
              },
            ]}
            control={{
              type: CustomTextControl,
              settings: {
                placeholder: "Enter email",
              },
            }}
          />
          {this.state.errorMessage && (
            <p className="error">{this.state.errorMessage}</p>
          )}
          <div className="submit-wrapper">
            <FormSubmitButton customClass="primary-btn">
              Submit
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

ForgotPassword.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ForgotPassword;
