import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import { Col, Row, Table } from "react-bootstrap";

import { Image } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { getDetaisContentApi } from "../content/Api";
import { IN_PERSON } from "../../utils/Constant";
class ViewPlaylistDetails extends BaseReactComponent {
  constructor(props) {
    super(props);
    const { params } = props.match;
    const playlistDetails = this.props.location.state.detail;

    this.state = {
      params,
      show: false,
      firstname: "",
      lastname: "",
      pleaseselect: "",
      detailsData: [],
      registerUser: [],
      details: playlistDetails
    };
  }

  componentDidMount() {
    getDetaisContentApi("/playlist", this);

  }
  render() {
    const { data, detailsData, params, registerUser, details } = this.state;
    console.log(data)
    // console.log('thi is get Regisster detila',registerUser);
    return (
      <section className="user-section">
        {/* // ---------------------------------------View details -------------------------------- */}

        <div className="view-details-wrapper">

          <ComponentHeader title="View Playlist" isBackArrow={true} isReturn="/playlist" />
          <div className="view-details d-flex">
            <div className="view-details-content">
              <div className="user-details d-flex">
                <div className="eclipse">
                  <Image src={data?.thumbnailPath} className="eclipse" />
                </div>
                <div className="name-details">
                  <h3 className="inter-medium f-s-20">{data?.name || ""}</h3>
                  {/* <h4 className="inter-medium grey-3">Coach-Gynecologist</h4>
                  <h5 className="inter-medium f-s-10 grey-3">Mumbai</h5> */}
                </div>
                <div className="line"></div>
                <div className="name-description">
                  <h3 className="grey-2 ">About</h3>
                  <p className="inter-medium f-s-14">
                    {data?.description}
                  </p>
                </div>
              </div>
              <h3 className="inter-regular f-s-14 grey-2">
                Playlist Details
              </h3>
              <div className="line2"></div>
              <Table className="custom-table">
                <Row>


                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Title</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.title} </h3>
                    </div>
                  </Col>


                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">AMC</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.amc?.amcName || 'NA'}</h3>
                    </div>
                  </Col>

                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Content Creator</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.contentCreator?.contentCreatorName || ''}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Is Customized</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.isCustomized ? "Yes" : "No" || ''}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Is Verified</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.isVerified ? "Yes" : "No" || ''}</h3>
                    </div>
                  </Col>

                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Platforms</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.platforms.map((item) => item?.platform?.platformName + ",")}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Upload Frequency</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.uploadFrequency}</h3>
                    </div>
                  </Col>


                </Row>
                <br /> <br />
                {details?.user &&
                  <h3 className="inter-regular f-s-14 grey-2">
                    User Details
                  </h3>
                }
                <div className="line2"></div>
                {details?.user &&
                  <Row>
                    <Col sm={3} xs={12}>
                      <h4 className="inter-semibold grey-2 ">First Name</h4>
                      <h3 className="inter-semibold f-s-14 ">{details?.user?.firstName}</h3>
                    </Col>
                    <Col sm={3} xs={12}>
                      <h4 className="inter-semibold grey-2 ">Last Name</h4>
                      <h3 className="inter-semibold f-s-14 ">{details?.user?.lastName}</h3>
                    </Col>
                    <Col sm={3} xs={12}>
                      <h4 className="inter-semibold grey-2 ">Mobile Number</h4>
                      <h3 className="inter-semibold f-s-14 ">{details?.user?.mobileNumber}</h3>
                    </Col>
                    <Col sm={3} xs={12}>
                      <h4 className="inter-semibold grey-2 ">Email Address</h4>
                      <h3 className="inter-semibold f-s-14 ">{details?.user?.emailAddress}</h3>
                    </Col>


                  </Row>
                }

              </Table>


            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ usersState: state.UsersState });
const mapDispatchToProps = {
  // getPosts: fetchPosts
};
ViewPlaylistDetails.propTypes = {
  // getPosts: PropTypes.func
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPlaylistDetails);
