import React, { Component } from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Col, Image, Row } from "react-bootstrap";
import { deletePostofthedayApi, getPostOfDayApi } from "./Api";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import editPencil from "../../assets/images/edit-icon.png";

import { PostofthedayForm } from ".";
import { ComponentHeader, InformationModal, VerifiedModal } from "../common";
import { connect } from "react-redux";
import { START_PAGE, contentMaster, isModuleExist } from "../../utils/Constant";
import { POSTOFTHEDAY_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";
export class Postoftheday extends Component {
  constructor(props) {
    super(props);

    const AccesData = isModuleExist(contentMaster, 'Post of the Day');
    this.state = {
      access: AccesData,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,

      isVerify: false,
      show: false,
      dummyData: [
        {
          id: 1,
          name: "Nirav",
        },
        {
          id: 2,
          name: "Mahesh",
        },
        {
          id: 3,
          name: "Dharmik",
        },
        {
          id: 4,
          name: "Uday",
        },
      ],
      verified: '',
      verifyUrl: ''
    };
  }
  // todo---------------------------------------
  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getPostOfDayApi(this);
  }
  // todo---------------------------------------

  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {

    deletePostofthedayApi(id, this, this.openCloseDeleteModal)
  }
  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";

    // const search = params.get("search") || "";

    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getPostOfDayApi(this);

      });
    }
  }
  // todo:-----------------------------------------

  render() {
    const { showEdit, access } = this.state;
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;
    return (
      <section className="request-section">

        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.link} this post?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        <VerifiedModal
          // name={`this '${this.state.editData?.campaignName}' campaign.`}
          name={`this post of day`}
          show={this.state.show}
          url={POSTOFTHEDAY_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getPostOfDayApi(this) }}
        />
        {
          showEdit &&
          <PostofthedayForm
            show={showEdit}
            onHide={this.showEditModal}
            editData={this.state.editData}
            this={this}
          />
        }
        <ComponentHeader
          title="Post of the day"
          // isSearch={false}
          // placeholder={"Search speaker"}
          // onChangeMethod={this.onChangeMethod}
          // clearSearch={() => this.clearSearch()}
          primaryBtn={access.createAccess ? "Add Post" : ''}
          handlePrimaryBtn={() => {
            // this.props.history.push('/ads/banner-ads/add');
            this.setState({ showEdit: true })
          }}
        />
        <Row>
          <Col sm={2}>
            <FilterVefiedDropDown ctx={this} />
          </Col>
        </Row>
        <div className="commom-table-wrapper">
          <CustomTable
            tableData={listData?.list || []}
            columnList={[
              {
                coumnWidth: 250,
                labelName: "Link",
                dataKey: "link",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "link") {
                    return rowData.link;
                  }
                },
              },
              {
                coumnWidth: access.verificationAccess ? 100 : 0,
                labelName: "Verified",
                dataKey: "verify",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "verify") {
                    return <div className=" d-flex align-items-center inter-medium">
                      {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                    </div>;
                  }
                },
              },
              {
                coumnWidth: 200,
                labelName: "Thumbnail Image",
                dataKey: "thumbnailPath",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "thumbnailPath") {
                    return (rowData.thumbnailPath ? <Image src={rowData.thumbnailPath} className="table-img" alt="Photo" /> : "NA");
                  }
                },
              },
              {
                coumnWidth: 80,
                labelName: "Position",
                dataKey: "position",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "position") {
                    return rowData.position
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Updated By",
                dataKey: "updatedBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "updatedBy") {
                    return rowData?.updatedBy?.name || 'Na';;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Created By",
                dataKey: "createdBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "createdBy") {
                    return rowData?.createdBy?.name || 'Na';
                  }
                },
              },
              {
                coumnWidth: 250,
                labelName: "Action",
                dataKey: "",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <>
                        {access.updateAccess && <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />}
                        {access.deleteAccess && <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />}
                      </>
                    );
                  }
                },
              },
            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}


const mapStateToProps = state => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getPostOfDayApi
}

export default connect(mapStateToProps, mapDispatchToProps)(Postoftheday);
