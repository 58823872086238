import { DEFAULT_LIST, START_PAGE } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { GET_ALL_EVENT_DATA } from "./ActionTypes";
const INITIAL_STATE = {
  totalPages: null,
  currentPage: START_PAGE,
  eventData: DEFAULT_LIST
};
const EventsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_EVENT_DATA:
      return {
        eventData: action.payload,
        totalPages: calculateTotalPageCount(action.payload.length),
        currentPage: action.currentPage
      };
    default:
      return state
  }
};
export default EventsReducer