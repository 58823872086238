import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const DatePickerControl = (props) => {
    const {
        valueLink,
        placeholder,
        disabled = false,
        minDate,
        maxDate,
        failedValidation,
        showYearDropdown,
        yearDropdownItemNumber,
        scrollableYearDropdown,
        onBlur,
        showTimeSelect
    } = props;

    const handleSelect = (date) => {
        valueLink.requestChange(date);
    };

    return (
        <div className="date-picker-wrapper">
            <DatePicker
                selected={valueLink.value}
                onChange={handleSelect}
                placeholderText={placeholder}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                showYearDropdown={showYearDropdown}
                yearDropdownItemNumber={yearDropdownItemNumber}
                scrollableYearDropdown={scrollableYearDropdown}
                dateFormat="MMMM d, yyyy h:mm aa"
                onBlur={() => onBlur(valueLink.value)}
                showTimeSelect={showTimeSelect}
                // Only show time picker if `showTimeSelect` is true
                timeIntervals={15} // Customize the time interval here
                timeCaption="Time" // Label for time selection

                customInput={
                    <input
                        type="text"
                        readOnly
                        className={`form-control date-picker-control ${failedValidation && failedValidation.message ? "is-invalid" : ""}`}
                        value={valueLink.value ? format(valueLink.value, showTimeSelect ? 'HH:mm' : 'dd-MM-yyyy') : ""}
                    />
                }
            />
        </div>
    );
};

export default DatePickerControl;
