import React from "react";

import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import { CustomModal, ImageUploader, Spinner } from "../common";
import { addAppNotificationApi, updateAppNotificationApi } from "./Api";
import { API_LIMIT, IMAGEFILETYPE, START_PAGE } from "../../utils/Constant";

class InAppNotificationForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;

    this.state = {
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      title: data?.title || "",
      description: data?.discription || "",
      thumbnailPath: "",
      thumbnailFile: null,
      uploadImage: data?.thumbnailPath || null,
      link: data?.link || "",
      type: data?.type || '',
      ctaLabel: data?.ctaLabel || "",

      attachments: [],
      resLoading: false,
    };
  }

  componentDidMount() { }
  imageUploaser = (e, name) => {

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
  }

  onValidSubmit = () => {
    this.setState({ resLoading: true })
    let eventData = {
      "data": {
        "title": this.state.title,
        "discription": this.state.description,
        "thumbnailPath": this.state.thumbnailPath,
        "ctaLabel": this.state.ctaLabel,
        "link": this.state.link,
        "type": this.state.type
      }
    }
    const data = new FormData();
    data.append("key", JSON.stringify(eventData));
    data.append("file", this.state.thumbnailFile);

    if (this.props?.editData?.uuid) {
      updateAppNotificationApi(data, this.props.this, this.props.onHide, this.props.editData.uuid, this)
    } else {
      addAppNotificationApi(data, this.props.this, this.props.onHide, this);
    }
  };

  render() {
    const { uploadImage, resLoading, thumbnailFile, bannerfile, uploadImage2, circleImageFile, uploadImage3 } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={(this.props.editData.uuid) ? "Edit App Notification" : "Add App Notification"}
        modalClass={"change-password event-ads-modal"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "title")}
                label="Title"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Title",
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "ctaLabel")}
                label="Enter Cta Label"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Cta Label",
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "link")}
                label="Link"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Link",
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "type")}
                label="Type"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Type",
                  },
                }}
              />
            </Col>

            <Col sm={4}>
              <ImageUploader required={true}
                id="Thumbnail"
                label="Upload Thumbnail"
                name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile}
                onChange={(e) => this.imageUploaser(e, "thumbnail")}
                onDelete={() => this.setState({ thumbnailFile: "" })}
                acceptedFileType={IMAGEFILETYPE}
              />
            </Col>

            <Col sm={6}>
              <FormElement
                valueLink={this.linkState(this, "description")}
                label="Description"
                helpText="Description can't be more than 4000 character"

                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    as: "textarea",
                    placeholder: "Enter Description",
                  },
                }}
              />
            </Col>
          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              {resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

export default InAppNotificationForm;
