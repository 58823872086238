import React from "react";
import ReactDOM from "react-dom";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  SelectControl,
} from "../../utils/form";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { getKeywordNameApi } from '../MasterCategory/Api';
import { Row, Col, Button } from "react-bootstrap";
import { ComponentHeader, SelectKeywords, Spinner } from "../common";
import { addEventAdsApi, updateEventAdsApi } from "./Api";
import { getAllEventsApi } from "../events/Api";
import moment from "moment";
import { toast } from "react-toastify";

class EventsAdsForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    // const data = props.editData || null;
    const data = props?.location?.state?.editData || null;
    this.state = {
      editData: data,
      eventAdName: data?.eventAdName || "",
      adType: data?.adType || "",
      keywords: data?.keywords || [],
      event: data?.event?.uuid || '',
      position: data?.position || "",
      startDate: data?.startDate ? new Date(data?.startDate) : "",
      endDate: data?.endDate ? new Date(data?.endDate) : "",

      isAddContent: true,
      eventOptions: [],
      keywordOption: [],
      resLoading: false,
    };
  }

  componentDidMount() {
    this.props.getKeywordNameApi(this);
    getAllEventsApi(this)
  }

  onSubmit = () => {
    ReactDOM.findDOMNode(this.form).dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  }

  onValidSubmit = () => {
    this.setState({ resLoading: true })
    if (this.state.startDate && this.state.endDate) {
      let eventAdData = {
        "data": {
          "eventAdName": this.state.eventAdName,
          "keywords": this.state.keywords,
          "position": parseInt(this.state.position),
          "startDate": this.state.startDate,
          "event": { "uuid": this.state.event },
          "adType": this.state.adType,
          "endDate": this.state.endDate
        }
      };

      if (this.state.editData?.uuid) {
        updateEventAdsApi(eventAdData, this.props.this, this.props.onHide, this.state.editData.uuid, this)
      } else {
        addEventAdsApi(eventAdData, this.props.this, this.props.onHide, this)
      }
    } else {
      toast.error('Select start and end date.')
    }

  }
  onHide = () => {
    this.props.getAllCampaignApi();
    this.props.onHide();
  }
  render() {
    const { eventOptions, resLoading, editData } = this.state;
    return (
      <section className="add-section">

        <ComponentHeader
          title={editData?.uuid ? "Edit Event Ads" : "Add Event Ads"}
          // primaryBtn={false}
          isBackArrow={true}
          isReturn={'/event-ads'}

        />
        <Form ref={el => this.form = el} onValidSubmit={() => this.onValidSubmit()}>
          <Row>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "eventAdName")}
                label="Event Ad Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Event Ads Name",
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "position")}
                label="Event Position"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Position",
                  },
                }}
              />
            </Col>


            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "adType")}
                label="Event Ad Type"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Event type cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Type",
                    options: [
                      {
                        value: 'FEATURED',
                        label: "Featured",
                      },
                      {
                        value: 'AD',
                        label: "Ad",
                      },

                    ],
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.adType);
                    },
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "event")}
                label="Select Event"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Event type cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Event",
                    options: eventOptions,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.event);
                    },
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <SelectKeywords label="Keywords" required={false} selectedKeyWords={this.state.keywords} options={this.state.keywordOption} getKeys={(data) => this.setState({ keywords: data })} />
            </Col>
            <Col md={4}>
              <div className="form-group custom-date-range-picker">
                <label htmlFor="" className="form-label">Ad Start Date</label>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) => this.setState({ startDate: date })}
                  maxDate={this.state.endDate}
                  minDate={moment().toDate()}
                  showTimeSelect
                  // filterTime={filterPassedTime}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  // dateFormat="dd/MM/yyyy"
                  placeholderText="Ad Start Date"
                  className="form-control "
                  // showMonthDropdown
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group custom-date-range-picker">
                <label htmlFor="" className="form-label">Ad End Date</label>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) => this.setState({ endDate: date })}
                  // minDate={this.state.startDate}
                  showTimeSelect
                  minDate={this.state.startDate || moment().subtract(1, 'days').toDate()}
                  // filterTime={filterPassedTime}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  // dateFormat="dd/MM/yyyy"
                  placeholderText="Ad End Date"
                  className="form-control "
                  // showMonthDropdown
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                />
              </div>
            </Col>
          </Row>

          <div className="submit-wrapper" style={{ justifyContent: "center" }}>

            <Button className="primary-btn" onClick={() => this.onSubmit()}>
              {resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </Button>
          </div>
        </Form>
        {/* </CustomModal> */}
      </section>
    );
  }
}

const mapStateToProps = state => ({
  // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {
  getKeywordNameApi,
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsAdsForm);
