import React from "react";

import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import { CustomModal, Spinner } from "../common";
import { addEventcategoryApi, updateEvenCategoryApi } from "./Api";

class EventCategoryForm extends BaseReactComponent {
  constructor(props) {
    const data = props.editData || null;
    super(props);
    this.state = {
      eventCategoryName: data?.eventCategoryName || "",
      resLoading: false,
    };
  }

  componentDidMount() { }
  onValidSubmit = () => {
    this.setState({ resLoading: true })
    let data = {
      "data": {
        "eventCategoryName": this.state.eventCategoryName,
      },
    };
    if (this.props?.editData?.uuid) {
      updateEvenCategoryApi(data, this.props.this, this.props.onHide, this.props.editData.uuid, this)
    } else {
      addEventcategoryApi(JSON.stringify(data), this.props.this, this.props.onHide, this);
    }
  };
  render() {
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={(this.props?.editData?.uuid) ? "Edit Event Category" : "Add Event Category"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "eventCategoryName")}
                label="Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Name",
                  },
                }}
              />
            </Col>
          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              {this.state.resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

export default EventCategoryForm;
