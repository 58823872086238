
import { toast } from "react-toastify";
import { postLoginInstance, preLoginInstance } from "../../utils";

export const changePasswordApi = (data, onHide) => {
  return postLoginInstance.post("admin/user/changePassword", data)
    .then((res) => {

      onHide();
      toast.success(res.data.data.message);
    })
    .catch((error) => {

      toast.error(error.message);
    });
};
