import React, { Component } from "react";
import { connect } from "react-redux";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Col, Image, Row } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { ComponentHeader, InformationModal, VerifiedModal } from "../common";
import { API_LIMIT, Ads, START_PAGE, contentBannerAds, isModuleExist, subAds } from "../../utils/Constant";
import { deleteBannerApi, getAllBannerApi } from "./Api";
import moment from 'moment';
import editPencil from "../../assets/images/edit-icon.png";
import { BANNER_AD_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";

export class AddContent extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist(Ads, props?.location?.pathname === contentBannerAds ? subAds.contentBannerAd : subAds.eventBannerAd);
    // console.log(props)
    this.state = {
      access: AccesData,
      type: props?.location?.pathname,
      dummyData: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      languageList: [],
      totalPages: null,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,

      isVerify: false,
      show: false,
    };
  }

  // todo---------------------------------------
  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllBannerApi(this);
  }
  // todo---------------------------------------

  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };

  deleteHandle = (id) => {
    //  console.log("yes delete  this id",)
    deleteBannerApi(id, this, this.openCloseDeleteModal)
  }

  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";
    // const search = params.get("search") || "";

    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllBannerApi(this);

      });
    }
  }
  // todo:-----------------------------------------

  render() {
    const { showEdit, access, type } = this.state;
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;
    return (
      <section className="request-section">
        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete  '${this.state.deleteData?.bannerAdName}' this Ads ?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}

        <VerifiedModal
          name={`'${this.state.editData?.bannerAdName}'`}
          // name={`this '${this.state.editData?.bannerAdName}' banner.`}
          show={this.state.show}
          url={BANNER_AD_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getAllBannerApi(this) }}
        />

        <ComponentHeader
          title={(type === contentBannerAds ? "Content Banner Ads" : 'Event Banner Ad')}
          isSearch={false}
          placeholder={"Search ad's"}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          primaryBtn={access.createAccess ? (type === contentBannerAds ? "Add Content Banner Ad" : 'Add Event Banner Ad') : ""}
          handlePrimaryBtn={() => {
            this.props.history.push(type === contentBannerAds ? '/ads/content-banner-ads/add' : '/ads/event-banner-ads/add');
          }}
        />
        <Row>
          <Col sm={2}>
            <FilterVefiedDropDown ctx={this} />
          </Col>
        </Row>
        <div className="commom-table-wrapper">
          <CustomTable
            tableData={listData?.list || []}
            columnList={[
              // {
              //   coumnWidth: 50,
              //   labelName: "#",
              //   dataKey: "id",
              //   className: "",
              //   isCell: true,
              //   cell: (rowData, dataKey) => {
              //     if (dataKey === "id") {
              //       return "5";
              //     }
              //   },
              // },
              {
                coumnWidth: 200,
                labelName: "Banner Name",
                dataKey: "bannerAdName",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "bannerAdName") {
                    return rowData.bannerAdName;
                  }
                },
              },
              {
                coumnWidth: access.verificationAccess ? 100 : 0,
                labelName: "Verified",
                dataKey: "verify",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "verify") {
                    return <div className=" d-flex align-items-center inter-medium">
                      {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                    </div>;
                  }
                },
              },
              {
                coumnWidth: 200,
                labelName: "Redirection Link",
                dataKey: "redirectionLink",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "redirectionLink") {
                    return rowData.redirectionLink
                  }
                },
              },
              {
                coumnWidth: 140,
                labelName: "Keywords",
                dataKey: "keywords",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "keywords") {
                    return rowData?.keywords?.join(', ');
                  }
                },
              },
              {
                coumnWidth: 100,
                labelName: "Start Date",
                dataKey: "startDate",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "startDate") {
                    return moment(rowData.startDate).format('lll');
                  }
                },
              },
              {
                coumnWidth: 100,
                labelName: "End Date",
                dataKey: "endDate",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "endDate") {
                    return moment(rowData.endDate).format('lll');
                  }
                },
              },
              // {
              //     coumnWidth: 250,
              //     labelName: "Thumbnail Picture",
              //     dataKey: "thumbnailPath",
              //     className: "",
              //     isCell: true,
              //     cell: (rowData, dataKey) => {
              //       if (dataKey === "thumbnailPath") {
              //         return <Image className="table-img" src={rowData.thumbnailPath} alt="AMC Logo" />;
              //       }
              //     },
              //   },
              {
                coumnWidth: 150,
                labelName: "Banner Image",
                dataKey: "bannerImagePath",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "bannerImagePath") {
                    return <Image src={rowData.bannerImagePath} className="table-img" alt="Photo" />;
                  }
                },
              },
              // {
              //   coumnWidth: 170,
              //   labelName: "Action",
              //   dataKey: "",
              //   className: "",
              //   isCell: true,
              //   cell: (rowData, dataKey) => {
              //     if (dataKey === "") {
              //       return (
              //         <Button onClick={() => this.props.history.push({
              //           // pathname: `/campaign-detail/${rowData.uuid}`,
              //           pathname: `/add-detail/All`,
              //           search: `&key=add&value=${rowData.bannerAdName}&pageSize=${API_LIMIT}&pageNumber=${currentPage || START_PAGE}`,
              //           state: {
              //             name: rowData.bannerAdName,
              //             id: rowData.uuid
              //           }
              //         })} className=" view-details-btn inter-medium f-s-14 lh-18">
              //           View Detail
              //         </Button>
              //       );
              //     }
              //   },
              // },
              {
                coumnWidth: 150,
                labelName: "Updated By",
                dataKey: "updatedBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "updatedBy") {
                    return rowData?.updatedBy?.name || 'Na';;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Created By",
                dataKey: "createdBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "createdBy") {
                    return rowData?.createdBy?.name || 'Na';
                  }
                },
              },
              {
                coumnWidth: access.updateAccess || access.deleteAccess ? 150 : 0,
                labelName: "Action",
                dataKey: "",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <>
                        {access.updateAccess &&
                          <Image src={editIcon} className="edit-icon" onClick={() => { this.props.history.push({ pathname: type === contentBannerAds ? '/ads/content-banner-ads/add' : '/ads/event-banner-ads/add', state: { editData: rowData } }) }} />
                        }
                        {/* <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} /> */}
                        {access.deleteAccess && (
                          <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                        )}
                      </>
                    );
                  }
                },
              },
            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getAllBannerApi
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContent);
