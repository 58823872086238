import { postLoginInstance } from "../../utils";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
import { getAllCustomerData } from "./CustomersAction";


export const getCustomerNameApi = (ctx = null) => {

  return function (dispatch, getState) {
    return postLoginInstance.get(`customer?pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}${ctx.state.search && `&searchValue=${ctx.state.search}`}`)
      .then((res) => {
        if (ctx.state.isDropdown) {
          const customerList = res.data.data.list.map((item) => ({
            ...item,
            label: item.firstName + " " + item.lastName,
            value: item.uuid
          }))
          return ctx.setState({ customerList });
        } else {
          dispatch(getAllCustomerData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }
      });
  }

};





export const getDetaisCustomerApi = (path, ctx) => {

  return postLoginInstance.get(`${path}/${ctx.props.match.params.adsID}`)
    .then((res) => {
      return ctx.setState({ data: res.data.data });
    })
    .catch((err) => {

    })
}


