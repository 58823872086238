import React from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import { API_LIMIT, isModuleExist, START_PAGE, SUPER_ADMIN } from "../../utils/Constant";
import { ComponentHeader } from "../common";
import { getSsoUserApi } from "./Api";
import { replaceHistory, userRoleType } from "../../utils/ReusableFunctions";
class SsoUser extends BaseReactComponent {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist("Sso", 'Web User');
    // const roleType = userRoleType() || SUPER_ADMIN;
    this.state = {
      access: AccesData,
      search: "",
      dummyData: [],
      searchResult: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,
      userLoginDetails: {}
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getSsoUserApi(this);

    const getUserLoginDetails = () => {
      const userDetails = localStorage.getItem('finAdmin');
      if (userDetails) {
        this.setState({ userLoginDetails: JSON.parse(userDetails) })
      }
    };

    // Fetch user login details
    getUserLoginDetails();


  }

  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevSearch = prevParams.get("search") || "";

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const search = params.get("search") || "";

    if (prevPage !== page || prevSearch !== search) {
      this.setState({ currentPage: page, search }, () => {
        this.props.getSsoUserApi(this);

      });
    }
  }


  clearSearch = () => {
    this.onChangeMethod({ search: "" })
  }

  onChangeMethod = (value) => {
    this.setState({ searchText: value.search, currentPage: START_PAGE }, () => {
      replaceHistory(this.props.history, START_PAGE, value.search)
    })
  }

  getDetailsUser = (rowData) => {
    // sessionStorage.setItem("detail", JSON.stringify(rowData))
    this.props.history.push({ pathname: `/sso/web-user/details/0/${rowData?.id}`, state: { "id": rowData?.id } });
  }





  render() {

    const { userList, totalPages, currentPage } = this.props.usersState;
    // console.log(this.props)
    return (
      <>

        <section className="request-section">
          <ComponentHeader
            title="Customers"
            isSearch={true}
            placeholder={"Search Customer"}
            onChangeMethod={this.onChangeMethod}
            clearSearch={() => this.clearSearch()}
          />
          {/* <CustomTab data={nav_obj} /> */}
          <div className="commom-table-wrapper">
            <CustomTable
              tableData={userList?.list || []}
              columnList={[

                {
                  coumnWidth: 200,
                  labelName: "Full Name",
                  dataKey: "firstName",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "firstName") {
                      const firstName = rowData.firstName ?? "";
                      const lastName = rowData.lastName ?? "";
                      return `${firstName} ${lastName}`.trim();
                    }
                  },
                },
                {
                  coumnWidth: 200,
                  labelName: "Email Id",
                  dataKey: "emailAddress",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "emailAddress") {
                      return rowData.emailAddress || 'Na';
                    }
                  },
                },

                {
                  coumnWidth: 250,
                  labelName: "Mobile Number",
                  dataKey: "mobileNumber",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "mobileNumber") {
                      return rowData.mobileNumber || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 250,
                  labelName: "Company Name",
                  dataKey: "companyName",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "companyName") {
                      return rowData.companyName || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 200,
                  labelName: "No of Events",
                  dataKey: "noOfEvent",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "noOfEvent") {
                      return rowData.noOfEvent;
                    }
                  },
                },
                {
                  coumnWidth: 350,
                  labelName: "Subscription Plan Name",
                  dataKey: "premiumPlanName",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "premiumPlanName") {
                      return (
                        <>
                          {rowData?.premiumPlanName || 'NA'}
                          <br />
                          {rowData?.subPremiumPlanName || 'NA'}
                          <br />
                          {rowData?.subPremiumPlanVariation || 'NA'}
                        </>
                      );
                    }
                  },
                },
                {
                  coumnWidth: 100,
                  labelName: "Liked",
                  dataKey: "liked",
                  className: "liked",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "liked") {
                      return rowData?.liked;

                    }

                  },
                },
                {
                  coumnWidth: 100,
                  labelName: "Shared",
                  dataKey: "shared",
                  className: "shared",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "shared") {

                      return rowData?.shared;
                    }
                    return '0';
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Downloaded",
                  dataKey: "downloaded",
                  className: "downloaded",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "downloaded") {

                      return rowData?.downloaded;
                    }

                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Cobrand",
                  dataKey: "cobrand",
                  className: "cobrand",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "cobrand") {
                      return rowData?.cobrand;

                    }

                  },
                },
                {
                  coumnWidth: 100,
                  labelName: "Saved",
                  dataKey: "saved",
                  className: "saved",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "saved") {
                      return rowData?.saved;

                    }

                  },
                },


                {
                  coumnWidth: this.state.userLoginDetails?.user?.ssoPartner?.allowPartnerAdminUserDetails ? 300 : 0,
                  labelName: "Action",
                  dataKey: "",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "") {
                      return (
                        <>
                          <Button onClick={() => this.getDetailsUser(rowData)} className="primary-btn inter-medium f-s-14 lh-18">
                            View Detail
                          </Button>

                        </>

                      );
                    }
                  },
                },


              ]}
              message="No data found" // For Pagination
              history={this.props.history}
              location={this.props.location}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </div>
        </section>
      </>
    );
  }
}


const mapStateToProps = state => ({
  usersState: state.SsoContentEventState,
});
const mapDispatchToProps = {
  getSsoUserApi

};
export default connect(mapStateToProps, mapDispatchToProps)(SsoUser);
