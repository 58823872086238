import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import { Col, Row, Table } from "react-bootstrap";

import { Image } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { durationObj } from "../../utils/Constant";
import { getSubdomainDetailsApi } from "./Api";
import { userAmcUuids, userCreatorUuids } from "../../utils/ReusableFunctions";

class ViewSubDomainUser extends BaseReactComponent {
    constructor(props) {
        super(props);
        const data = props?.location.state?.data || null;

        const { params } = props.match;
        this.state = {
            params,

            detailsData: data,
            ssoUuid: data?.uuid || "",
            isUpdated: (props?.location.state ?? false) || false,
            companyName: data?.companyName || "",
            isAmcShowContent: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
            isAddContent: true,
            isAmcShow: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
            creatorList: [],

            thumbnailPath: "",
            thumbnailFile: null,
            uploadImage: data?.companyLogo || null,
            amcContent: data?.amc?.map(item => item?.uuid) || (userAmcUuids().length > 0 ? userAmcUuids() : ''),
            creatorContent: data?.contentCreator?.map(item => item.uuid) || (userCreatorUuids() || []),
            amcEvent: data?.amc?.map(item => item?.uuid) || (userAmcUuids().length > 0 ? userAmcUuids() : ''),
            creatorEvent: data?.contentCreator?.map(item => item.uuid) || (userCreatorUuids() || []),
            amcOption: [],
            showSearchContent: false,
            search: '',
            locationSearch: '',
            resLoading: false,
            loading: false,
            link: data?.websiteLink || "",
            contact: data?.mobileNumber || "",
            email: data?.emailAddress || "",
            type: "",
            subscriptionOptions: [],
            typesOptions: [],
            subscription: "",
            premium: "",
            subPremium: "",
            variation: "",
            plansData: [],
            subPremiumPlan: [],
            subPremiumPlanVariation: [],


        };
    }

    componentDidMount() {
        getSubdomainDetailsApi("/sub-domain", this);


    }
    render() {
        const { data, detailsData, params, registerUser } = this.state;
        console.log(data)

        // console.log('thi is get Regisster detila',registerUser);
        return (
            <section className="user-section">
                {/* // ---------------------------------------View details -------------------------------- */}

                <div className="view-details-wrapper">

                    <ComponentHeader title="View Sub Domain " isBackArrow={true} isReturn="/subdomain" />
                    <div className="view-details d-flex">
                        <div className="view-details-content">
                            <div className="user-details d-flex">
                                <div className="eclipse">
                                    <Image src={data?.companyLogo} className="eclipse" />
                                </div>
                                <div className="name-details">
                                    <h3 className="inter-medium f-s-20">{data?.companyName || ""}</h3>

                                </div>
                                <div className="line"></div>
                                <div className="name-description">
                                    <h3 className="grey-2 ">About</h3>
                                    <p className="inter-medium f-s-14">
                                        {data?.description}
                                    </p>
                                </div>
                            </div>
                            <h3 className="inter-regular f-s-14 grey-2">
                                Subdomain Details
                            </h3>
                            <div className="line2"></div>
                            <Table className="custom-table">
                                <Row>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Company Name</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.companyName}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Email Address</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.emailAddress}</h3>
                                        </div>
                                    </Col>

                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Mobile Number</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.mobileNumber || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Website Link</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.websiteLink || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Is Verified</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.isVerified ? "Yes" : "No" || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Membership Name</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.premiumPlan?.name || ''}</h3>
                                        </div>
                                    </Col>

                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Plan Name</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.subPremiumPlan?.name || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Variation</h4>
                                            <h3 className="inter-semibold f-s-14 ">{durationObj[data?.subPremiumPlanVariation?.resetInDays] || ''}</h3>
                                        </div>
                                    </Col>


                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Logo Position</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.logoPosition


                                            }</h3>
                                        </div>
                                    </Col>

                                    <Col sm={3} md={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Api Key </h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.apiKey
                                            }</h3>
                                        </div>
                                    </Col>
                                </Row>

                            </Table>

                            {data?.modules?.length > 0 &&
                                <div className="variation-wrapper">
                                    <h3 className="inter-regular f-s-14 grey-2">Modules</h3>
                                    <hr />
                                    {
                                        data?.modules?.map((item, i) => {
                                            return (
                                                <div key={i}>
                                                    <Table>
                                                        <Row className="">
                                                            <Col sm={3} xs={12}>
                                                                <div className="detail">
                                                                    <h4 className="inter-semibold grey-2 ">Module Name</h4>
                                                                    <h3 className="inter-semibold f-s-14 ">{item?.moduleName || "Na"}</h3>
                                                                </div>
                                                            </Col>
                                                            {(item?.moduleName === "CONTENT" || item?.moduleName === "CAMPAIGN" || item?.moduleName === "EVENT") && (
                                                                <>
                                                                    <Col sm={3} xs={12}>
                                                                        <div className="detail">
                                                                            <h4 className="inter-semibold grey-2 ">Content Creator</h4>
                                                                            <h3 className="inter-semibold f-s-14 ">
                                                                                {item?.contentCreators?.length > 0
                                                                                    ? item.contentCreators.map(creator => creator?.contentCreatorName).join(", ")
                                                                                    : "Na"}
                                                                            </h3>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={3} xs={12}>
                                                                        <div className="detail">
                                                                            <h4 className="inter-semibold grey-2 ">AMC</h4>
                                                                            <h3 className="inter-semibold f-s-14 ">
                                                                                {item?.amcs?.length > 0
                                                                                    ? item.amcs.map(amc => amc?.amcName).join(", ")
                                                                                    : "Na"}
                                                                            </h3>
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            )}

                                                            <Col sm={3} xs={12}>
                                                                <div className="detail">
                                                                    {/* Conditional Rendering for Module Type */}
                                                                    {item?.moduleName === "CAMPAIGN" ? (
                                                                        <>
                                                                            <h4 className="inter-semibold grey-2 ">CAMPAIGN</h4>
                                                                            <h3 className="inter-semibold f-s-14 ">
                                                                                {item?.uuids?.map(uuid => uuid?.campaignName).join(", ") || "Na"}
                                                                            </h3>
                                                                        </>
                                                                    ) : item?.moduleName === "CONTENT" ? (
                                                                        <>
                                                                            <h4 className="inter-semibold grey-2 ">CONTENT</h4>
                                                                            <h3 className="inter-semibold f-s-14 ">
                                                                                {item?.uuids?.map(uuid => uuid?.title).join(", ") || "Na"}
                                                                            </h3>
                                                                        </>
                                                                    ) : item?.moduleName === "CATEGORY" ? (
                                                                        <>
                                                                            <h4 className="inter-semibold grey-2 ">CATEGORY</h4>
                                                                            <h3 className="inter-semibold f-s-14 ">
                                                                                {item?.uuids?.map(uuid => uuid?.categoryName).join(", ") || "Na"}
                                                                            </h3>
                                                                        </>
                                                                    ) : item?.moduleName === "EVENT" ? (
                                                                        <>
                                                                            <h4 className="inter-semibold grey-2 ">EVENTS</h4>
                                                                            <h3 className="inter-semibold f-s-14 ">
                                                                                {item?.uuids?.map(uuid => uuid?.eventName).join(", ") || "Na"}
                                                                            </h3>
                                                                        </>
                                                                    ) : item?.moduleName === "TOOLS" ? (
                                                                        <>
                                                                            <h4 className="inter-semibold grey-2 ">TOOLS</h4>
                                                                            <h3 className="inter-semibold f-s-14 ">
                                                                                {item?.uuids?.map(uuid => uuid?.toolName).join(", ") || "Na"}
                                                                            </h3>
                                                                        </>
                                                                    ) : item?.moduleName === "LANGUAGE" ? (
                                                                        <>
                                                                            <h4 className="inter-semibold grey-2 ">LANGUAGE</h4>
                                                                            <h3 className="inter-semibold f-s-14 ">
                                                                                {item?.uuids?.map(uuid => uuid?.languageName).join(", ") || "Na"}
                                                                            </h3>
                                                                        </>
                                                                    ) : item?.moduleName === "TARGETED CONTENT" ? (
                                                                        <>
                                                                            <h4 className="inter-semibold grey-2 ">TARGETED CONTENT</h4>
                                                                            <h3 className="inter-semibold f-s-14 ">
                                                                                {item?.uuids?.map(uuid => uuid?.targetedContentName).join(", ") || "Na"}
                                                                            </h3>
                                                                        </>
                                                                    )
                                                                        : item?.moduleName === "PERSONALIZE" ? (
                                                                            <>
                                                                                <h4 className="inter-semibold grey-2 ">PERSONALIZE</h4>
                                                                                <h3 className="inter-semibold f-s-14 ">
                                                                                    {item?.uuids?.map(uuid => uuid?.calenderName).join(", ") || "Na"}
                                                                                </h3>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <h4 className="inter-semibold grey-2 "></h4>
                                                                                <h3 className="inter-semibold f-s-14 "></h3>
                                                                            </>
                                                                        )}
                                                                </div>
                                                            </Col>


                                                        </Row>
                                                    </Table>
                                                    {i < data.modules.length - 1 && <hr />}
                                                </div>
                                            )
                                        })
                                    }


                                </div>
                            }











                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({ subdomainState: state.SubdomainState });
const mapDispatchToProps = {
    // getPosts: fetchPosts
};
ViewSubDomainUser.propTypes = {
    // getPosts: PropTypes.func
    location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSubDomainUser);
