import { START_PAGE } from "../../utils/Constant";
import { GET_ALL_OCCASSION_DATA, GET_ALL_PLATFORM_DATA, GET_ALL_PLAYLIST_DATA } from "./ActionTypes";

export const getAllPlaylistData = (payload, currentPage = START_PAGE) => ({
  type: GET_ALL_PLAYLIST_DATA,
  payload,
  currentPage,
});

export const getAllPlatformData = (payload, currentPage = START_PAGE) => ({
  type: GET_ALL_PLATFORM_DATA,
  payload,
  currentPage,
});

export const getAllOccassionData = (payload, currentPage = START_PAGE) => ({
  type: GET_ALL_OCCASSION_DATA,
  payload,
  currentPage,
});