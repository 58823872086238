import React from "react";
import ReactDOM from "react-dom";
import cancel from '../../assets/images/greay-cancel-icon.svg';
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  SelectControl,
} from "../../utils/form";
import { connect } from "react-redux";
import { Row, Col, Button, Image } from "react-bootstrap";
import { ComponentHeader, ImageUploader, SelectKeywords, Spinner } from "../common";
import DatePicker from "react-datepicker";
import { addContentAdApi, addBannerAdApi, updateContentAdApi, updateBannerAdApi } from "./Api";
import { getKeywordNameApi } from "../MasterCategory/Api"
import { GENERIC, KEYWORD } from "../../utils/Constant";
import SearchContent from "../content/SearchContent";

class AddEditAds extends BaseReactComponent {
  constructor(props) {
    // console.log(props)
    super(props);
    const data = props.location.state?.editData || null;
    // console.log(props.location.state)
    this.state = {
      editData: (props.location.state?.editData ?? false),
      type: props?.match?.params.type,
      contentAdName: data?.contentAdName || "",
      adType: data?.adType || "",
      keywords: data?.keywords || [],
      keywordOption: [],
      isAddContent: true,
      startDate: data?.startDate ? new Date(data?.startDate) : "",
      endDate: data?.endDate ? new Date(data?.endDate) : "",
      pageSize: 1000,
      //todo-Bannder data
      bannerAdName: data?.bannerAdName || "",
      redirectionLink: data?.redirectionLink || "",
      thumbnailPath: "",
      thumbnailFile: null,
      bannerPath: "",
      bannerfile: null,
      uploadImage: data?.thumbnailPath || null,
      uploadImage2: data?.bannerImagePath || null,
      showSearchContent: false,
      selectedContent: data?.content ? [data?.content] : [],


      genericKey: data?.adCategory || '',
      search: "",
      resLoading: false,

    };

  }

  componentDidMount() {
    this.props.getKeywordNameApi(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.search !== prevState.search) {
      this.props.getKeywordNameApi(this);
    }
  }
  imageUploaser = (e, name) => {
    // console.log(typeof (e.target.files[0]))
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (name === "banner") {
      this.setState({ bannerPath: e.target.files[0].name, bannerfile: e.target.files[0], uploadImage2: imageUrl })

    } else {
      this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
    }
  }
  onSubmit = () => {
    ReactDOM.findDOMNode(this.form).dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  }
  onValidSubmit = () => {
    this.setState({ resLoading: true })
    let type = this?.props?.location?.pathname.includes("event") ? "event" : "content";
    let AdsData = {
      "data": {
        "contentAdName": this.state.contentAdName,
        "keywords": this.state.keywords,
        // "position": 1,
        "startDate": this.state.startDate,
        "content": { "uuid": this.state.selectedContent[0]?.uuid },
        ...(this.state.genericKey ? { "adCategory": this.state.genericKey } : {}),
        "adType": this.state.genericKey === GENERIC ? 'AD' : this.state.adType,
        "endDate": this.state.endDate
      }
    };
    let BannerData = {
      "data": {
        "bannerAdName": this.state.bannerAdName,
        "redirectionLink": this.state.redirectionLink,
        "keywords": this.state.keywords,
        // "position": 2,
        ...(this.state.genericKey ? { "adCategory": this.state.genericKey } : {}),
        "startDate": this.state.startDate,
        "endDate": this.state.endDate,
        "type": type,
        // "bannerPath" : this.state.bannerPath
      }
    };
    const data = new FormData();
    data.append("key", JSON.stringify(BannerData));
    data.append("file", this.state.bannerfile);
    if (this.props.location.state?.editData) {
      ['content-banner-ads', 'event-banner-ads'].includes(this.state.type) ?
        updateBannerAdApi(data, this, this.props.onHide, this.state.editData.uuid, this)
        :
        updateContentAdApi(JSON.stringify(AdsData), this, this.props.onHide, this.state.editData.uuid, this)
    } else {
      ['content-banner-ads', 'event-banner-ads'].includes(this.state.type) ?
        addBannerAdApi(data, this, this.props.onHide, this)
        :
        addContentAdApi(AdsData, this, this.props.onHide, this)
    }

  }
  onHide = () => {
    this.props.getKeywordNameApi();
    this.props.onHide();
  }
  keywordsChangeHandler = (info) => {
    this.setState({ search: info })
  }
  render() {
    const { bannerfile, resLoading, thumbnailFile, editData, uploadImage2, type } = this.state;
    return (

      <section className="add-section">
        {
          this.state.showSearchContent &&
          <SearchContent
            showModal={this.state.showSearchContent}
            onHide={(selectedContent) => this.setState({ showSearchContent: false, selectedContent })}
            selectedContent={this.state.selectedContent}
            isMultiselect={false}
          />
        }
        <ComponentHeader
          title={['content-banner-ads'].includes(type) ? ` ${editData ? "Edit" : "Add"} Content Banner` : (['event-banner-ads'].includes(type)) ? ` ${editData ? "Edit" : "Add"} Event Banner` : `${editData ? "Edit" : "Add"}  Ads`}
          // primaryBtn={false}
          isBackArrow={true}
          isReturn={type === "content-ads" ? "/content-ads" : (type === 'event-banner-ads') ? '/event-banner-ads' : '/content-banner-ads'}
        // handlePrimaryBtn={() => console.log("click in adds Form")}
        />
        <Form ref={el => this.form = el} onValidSubmit={this.onValidSubmit}>
          <Row>
            {!(['content-banner-ads', 'event-banner-ads'].includes(type)) ? <>
              <Col sm={4}>
                <FormElement
                  valueLink={this.linkState(this, "contentAdName")}
                  label="Content Ad Name"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      //   type: "",
                      placeholder: "Enter Content Ad Name",
                    },
                  }}
                />
              </Col>
              <Col>
                <FormElement
                  valueLink={this.linkState(this, "genericKey")}
                  label="Generic/Keyword"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      options: [
                        {
                          value: GENERIC,
                          label: "Generic",
                        },
                        {
                          value: KEYWORD,
                          label: "Keyword",
                        },
                      ],
                      multiple: false,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.genericKey);
                      },
                    },
                  }}
                />
              </Col>
              {this.state.genericKey !== GENERIC &&
                <Col sm={4}>
                  {/* {this.state.subCategoryName===""?null: */}
                  <FormElement
                    valueLink={this.linkState(this, "adType")}
                    label="Select Ad Type"
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Field cannot be empty",
                      },
                    ]}
                    control={{
                      type: SelectControl,
                      settings: {
                        options: [
                          {
                            value: 'FEATURED',
                            label: "Featured",
                          },
                          {
                            value: 'AD',
                            label: "Ad",
                          },
                        ],
                        multiple: false,
                        searchable: true,
                        onChangeCallback: (onBlur) => {
                          onBlur(this.state.adType);
                        },
                      },
                    }}
                  />
                  {/* } */}
                </Col>
              }

            </> : <>
              <Col sm={4}>
                <FormElement
                  valueLink={this.linkState(this, "bannerAdName")}
                  label="Banner Ad Name"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      //   type: "",
                      placeholder: "Enter Name",
                    },
                  }}
                />
              </Col>
              <Col sm={4}>
                <FormElement
                  valueLink={this.linkState(this, "redirectionLink")}
                  label="Add Redirection Link"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      //   type: "",
                      placeholder: "Enter Redirection Link",
                    },
                  }}
                />
              </Col>
              <Col sm={4}>
                <ImageUploader
                  id="Banner"
                  label="Upload Banner"
                  name={typeof (bannerfile) == 'object' ? uploadImage2 : bannerfile}
                  onChange={(e) => this.imageUploaser(e, "banner")}
                  onDelete={() => this.setState({ bannerPath: "", uploadImage2: "" })}
                  required={true}
                />
              </Col>
            </>}
            {this.state.genericKey !== GENERIC &&
              <Col md={4}>
                <SelectKeywords label="Keywords" placeholder={'Enter Keywords'} onchangeHandler={(data) => this.keywordsChangeHandler(data)} options={this.state.keywordOption} selectedKeyWords={this.state.keywords} getKeys={(data) => this.setState({ keywords: data })} />
              </Col>
            }

            <Col md={4}>
              <div className="form-group custom-date-range-picker">
                <label htmlFor="" className="form-label">Start Date <span className=" text-danger">*</span></label>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) => this.setState({ startDate: date, endDate: "" })}
                  minDate={new Date()}

                  // maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Start Date"
                  className="form-control "
                  // showMonthDropdown
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group custom-date-range-picker">
                <label htmlFor="" className="form-label">End Date <span className=" text-danger">*</span></label>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) => this.setState({ endDate: date })}
                  minDate={this.state.startDate || new Date()}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="End Date"
                  className="form-control "
                  // showMonthDropdown
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                />
              </div>
            </Col>
            {
              !(['content-banner-ads', 'event-banner-ads'].includes(this.state.type)) &&
              <Col md={12}>
                <div className="form-group">
                  <label htmlFor="" className="form-label">Content</label>
                  {this.state.selectedContent.length > 0 &&

                    <div className=" mb-3 d-flex align-items-center gap-20 w-100" ><span className='inter-regular f-s-14 ellipsis lh-22'><Image width={60} height={60} src={this.state.selectedContent[0]?.amcCircleImagePath} className='' alt='img' /> {this.state.selectedContent[0]?.title} </span> <Image src={cancel} onClick={() => { this.setState({ selectedContent: {} }) }} className='search-icons pointer' alt='canle imge0' /> </div>
                  }

                  {/* <p>{this.state.selectedContent[0]?.title}</p> */}
                  <Button onClick={() => this.setState({ showSearchContent: true })} className="primary-btn mb-5 d-block">Select Content</Button>
                </div>
              </Col>}


          </Row>

          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            {/* <FormSubmitButton customClass={"primary-btn mt-5"}>
              Save
            </FormSubmitButton> */}
            <Button className="primary-btn mt-5" onClick={() => this.onSubmit()}>
              {resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </Button>

          </div>
        </Form>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {
  getKeywordNameApi
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAds);
