import { postLoginInstance, preLoginInstance } from "../../utils";
import { API_LIMIT, SOCIALMEDIA, START_PAGE } from "../../utils/Constant";
import { getAllContent } from "./ContentAction";
import { toast } from "react-toastify";

// export const getCustomerNameApi = (ctx = null) => {

//   return function (dispatch, getState) {
//   return postLoginInstance.get(`admin/customer?pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
//     .then((res) => {
//       // return ctx.setState({ dummyData: res.data.data ,});
//       dispatch(getAllCustomerData(res.data.data, ctx?.state.currentPage || START_PAGE));

//     });
//   }

// };

export const getAllContentApi = (ctx = null) => {
  // const params = new URLSearchParams(ctx.props.location.search);
  // const verify = params.get("isVerified") || "";
  ctx.setState({ isLoading: true })
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    // return postLoginInstance.get(`admin/content?${verify ?'isVerified=' + verify + '&' : ''} pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}${ctx.state.search && `&searchValue=${ctx.state.search}`}`)
    return postLoginInstance.get(`admin/content?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}${ctx.state.search && `&searchValue=${ctx.state.search}`}`)
      .then((res) => {
        dispatch(getAllContent(res.data.data, ctx?.state.currentPage || START_PAGE));
        ctx.setState({ isLoading: false })
      });
  };
};

export const getAllDisplayPositionApi = (ctx) => {
  return postLoginInstance.get(`position`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.position,
          value: item.uuid,
          code: item.code,
        }))
      // console.log(option?.splice(2))
      ctx.setState({
        displayPositionOptions: option
      })
    })
}

export const getAllContentCreatorApi = (ctx) => {
  const newUrl = ctx?.state?.isUserPage ? 'contentCreator' : `contentCreator?type=${ctx?.state?.isEvent ? 'event' : 'content'}`
  return postLoginInstance.get(newUrl)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.contentCreatorName,
          value: item.uuid,
          // code: item.code
        }))
      // console.log('this is option of creatorr', res.data.data.list)
      // let makerCreator=option?.find((item)=>item.code)
      ctx.setState({
        creatorList: option
      })
    })
}

export const getAllBrandingTypeApi = (ctx) => {
  return postLoginInstance.get(`branding`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.brandingName,
          value: item.uuid
        }))
      ctx.setState({
        brandingTypeOption: option
      })
    })
}
export const getAllSocialMediaApi = (ctx) => {
  return postLoginInstance.get(`socialMedia`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.socialMediaName,
          value: item.uuid
        }))
      ctx.setState({
        socialMediaOption: option
      })
    })
}

export const getAllUploadSectionApi = (ctx) => {
  return postLoginInstance.get(`uploadSection`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          code: item.code,
          label: item.uploadSection,
          value: item.uuid
        }))
      ctx.setState({
        uploadSectionOptions: option
      })
    })
}


export const getCommonApi = async (url) => {
  try {
    const response = await postLoginInstance.get(url);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  }
}
export const getSearchApi = async (url) => {
  try {
    const response = await preLoginInstance.get(url);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  }
}
export const postAuthCommonApi = async (url, data) => {
  try {
    const response = await postLoginInstance.post(url, data);
    if (response.status < 250 && response.status > 199) {
      toast.success("Successfully Save.")
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
    return false;
  }
}

export const putAuthCommonApi = async (url, data) => {
  try {
    const response = await postLoginInstance.put(url, data);
    if (response.status < 250 && response.status > 199) {
      toast.success("Successfully Updated.")
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
    return false;
  }
}
export const patchAuthCommonApi = async (url, data, ctx) => {
  try {
    const res = await postLoginInstance.patch(url, data);
    if (res.status < 250 && res.status > 199) {
      ctx.setState({ show: false });
      toast.success("Successfully Updated.");
      ctx.props.history.push('/content');
    }
    return res;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  }
}
export const deleteAuthCommonApi = async (url) => {
  try {
    const response = await postLoginInstance.delete(url);
    if (response.status < 250 && response.status > 199) {
      toast.success("Successfully Deleted.")
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  }
}

export const getDetaisContentApi = (path, ctx, isEdit = false, state) => {
  console.log(ctx)

  return postLoginInstance.get(`${path}/${ctx.props?.location?.state?.id}`)
    .then((res) => {

      const isExest = res?.data?.data?.uploadSection && res?.data?.data?.uploadSection.some(item => item.code === SOCIALMEDIA);
      ctx.setState({ isSocialMedia: isExest || false })

      return isEdit ? (ctx.setContentData(res?.data?.data)) : ctx.setState({ data: res?.data?.data });
    })
    .catch((err) => {
      console.log('Eroorr', err)
      toast.error(err?.response?.data?.message || 'Something went wrong.');
    })
}
export const gerEventregisterUser = (ctx) => {
  // console.log(ctx)
  return postLoginInstance.get(`/eventRegistration/registrationByEvent/${ctx.props.location.state.id}`)
    .then((res) => {
      return ctx.setState({ registerUser: res.data.data });
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message || 'Something went wrong.');
    })
}


export const patchAuthVerifyApi = async (url, data) => {
  try {
    const res = await postLoginInstance.patch(url, data);
    if (res.status < 250 && res.status > 199) {
      toast.success("Successfully Updated.");
      return res;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
    return false;
  }
}


export const getDetailsApi = (path, ctx, isEdit = false) => {
  console.log(ctx)


  return postLoginInstance.get(`${path}/${ctx.props?.location?.state?.id}`)
    .then((res) => {
      const r = res.data.data;

      return isEdit ? ctx.setContentData(res?.data.data) : ctx.setState({ editData: r, playlistUuid: r.uuid, title: r.title, uploadFrequency: r.uploadFrequency, isAmcShow: r?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false, uploadImage: r.thumbnailPath, amc: r?.amc?.uuid, platformUuid: r?.platforms?.map((item) => item?.platform?.uuid), creator: r?.contentCreator?.uuid, selectedContent: r?.contents, selectedItems: r?.contentTypes });
    })
    .catch((err) => {
      console.log('Eroorr', err)
      toast.error(err?.response?.data?.message || 'Something went wrong 55555555.');
    })
}