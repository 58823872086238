import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
  SelectControl,
} from "../../utils/form";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CustomModal, ImageUploader, Spinner } from "../common";
import { addCampaignApi, updateCampaignApi } from "./Api";
import { getAmcApi } from "../events/Api";
import { getAllContentCreatorApi } from "../content/Api";
import { userAmcUuids, userCreatorUuids, userRoleType } from "../../utils/ReusableFunctions";
import { SUPER_ADMIN } from "../../utils/Constant";

class CampaignForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;
    const roleType = userRoleType() || SUPER_ADMIN;
    this.state = {
      editData: data,
      roleType,
      campaignName: data?.campaignName || "",
      description: data?.description || "",
      thumbnailPath: "",
      thumbnailFile: null,
      bannerPath: "",

      bannerfile: null,
      circleImagePath: "",
      circleImageFile: null,
      uploadImage: data?.thumbnailPath || null,
      uploadImage2: data?.bannerPath || null,
      uploadImage3: data?.circleImagePath || null,
      startDate: "",
      endDate: "",

      isAmcShow: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
      creator: data?.contentCreator?.uuid || '',
      creatorList: [],

      amc: data?.amc?.uuid || (userAmcUuids().length > 0 ? userAmcUuids() : ''),
      amcOption: [],

      resLoading: false,
    };
  }

  componentDidMount() {
    getAmcApi(this);
    getAllContentCreatorApi(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.creatorList !== prevState.creatorList) {
      if (userCreatorUuids()) {
        const isExest = this.state?.creatorList?.find(item => userCreatorUuids()?.split(',')?.includes(item.value))

        this.setState({ isAmcShow: isExest?.label?.includes('Mutual Fund Company') })
      }
    }
  }
  imageUploaser = (e, name) => {

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (name === "banner") {
      this.setState({ bannerPath: e.target.files[0].name, bannerfile: e.target.files[0], uploadImage2: imageUrl })
    } else if (name === "circleImage") {
      this.setState({ circleImagePath: e.target.files[0].name, circleImageFile: e.target.files[0], uploadImage3: imageUrl })
    } else {
      this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
    }
  }
  onHide = () => {
    this.props.onHide();
  }
  onValidSubmit = async () => {
    this.setState({ resLoading: true })
    const { thumbnailPath, bannerPath, editData, circleImagePath, campaignName, description, amc, bannerfile, thumbnailFile, circleImageFile } = this.state;

    let CampaignData = {
      "data": {
        "campaignName": this.state.campaignName,
        "description": this.state.description,

        ...(thumbnailPath ? { "thumbnailPath": thumbnailPath } : {}),
        ...(bannerPath ? { "bannerPath": bannerPath } : {}),
        ...(circleImagePath ? { "circleImagePath": circleImagePath } : {}),

        ...((this.state.amc && amc !== editData) && {
          "amc": {
            "uuid": this.state.amc
          }
        }),
        "contentCreator": {
          "uuid": this.state.creator
        },
      }
    };
    const data = new FormData();
    data.append("key", JSON.stringify(CampaignData));
    thumbnailFile !== null && data.append("file", thumbnailFile);
    bannerfile !== null && data.append("file", bannerfile);
    circleImageFile !== null && data.append("file", circleImageFile);
    if (this.props?.editData?.uuid) {
      const response = await updateCampaignApi(`campaign/${this.props.editData.uuid}`, data, this.props.this)
      if (response.status < 250 && response.status > 199) {
        this.onHide()
        this.setState({ resLoading: false })
      } else {
        this.setState({ resLoading: false })
      }

    } else {
      const response = await addCampaignApi(data, this.props.this)
      if (response.status < 250 && response.status > 199) {
        this.setState({ resLoading: false })
        this.onHide()
      } else {
        this.setState({ resLoading: false })
      }

    }

  }

  render() {
    const { uploadImage, amcOption, resLoading, roleType, thumbnailFile, bannerfile, uploadImage2, circleImageFile, uploadImage3 } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props?.editData?.uuid ? "Edit Campaign" : "Add Campaign"}
        modalClass={"change-password event-ads-modal"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={6}>
              <FormElement
                valueLink={this.linkState(this, "campaignName")}
                label="Campaign Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Campaign Name",
                  },
                }}
              />
            </Col>

            <Col md={6}>
              <FormElement
                valueLink={this.linkState(this, "creator")}
                label="Creator"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Creator cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Creator",
                    options: this.state.creatorList,
                    multiple: false,
                    searchable: true,
                    disabled: (userAmcUuids()?.length > 0),
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.creator);
                      const isExest = this.state.creatorList?.find(item => this.state.creator?.includes(item.value))
                      this.setState({ isAmcShow: isExest?.label?.includes('Mutual Fund Company'), amc: '' })
                    },
                  },
                }}
              />
            </Col>
            {(this.state.isAmcShow || (userAmcUuids()?.length > 0)) &&
              <Col md={6}>
                <FormElement
                  valueLink={this.linkState(this, "amc")}
                  label="AMC"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "AMC cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Enter AMC Name",
                      options: (userAmcUuids()?.length > 0) ? amcOption?.filter((ele) => userAmcUuids()?.some(item => item === ele.value)) : amcOption,//if adil give data then use this
                      multiple: false,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.amc);
                      },
                    },
                  }}
                />
              </Col>
            }

            <Col sm={6}>
              <ImageUploader required={false} id="circleImage" label="Upload logo" name={typeof (circleImageFile) == 'object' ? uploadImage3 : circleImageFile} onChange={(e) => this.imageUploaser(e, "circleImage")} onDelete={() => this.setState({ circleImageFile: "" })} />
            </Col>
            <Col sm={6}>
              <ImageUploader required={false}
                id="Thumbnail"
                label="Upload Thumbnail"
                name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile}
                onChange={(e) => this.imageUploaser(e, "thumbnail")}
                onDelete={() => this.setState({ thumbnailFile: "", thumbnailPath: "" })}
              />
            </Col>
            <Col sm={6}>
              <ImageUploader required={false}
                id="Banner"
                label="Upload Banner"
                name={typeof (bannerfile) == 'object' ? uploadImage2 : bannerfile}
                onChange={(e) => this.imageUploaser(e, "banner")}
                onDelete={() => this.setState({ bannerPath: "", bannerfile: "" })}
              />
            </Col>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "description")}
                label="Description"
                helpText="Description can't be more than 4000 character"

                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    as: "textarea",
                    placeholder: "Enter Description",
                  },
                }}
              />
            </Col>
          </Row>

          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              {resLoading ? <> <Spinner /> Saving...</> : "Save"}
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
  // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {
  // getAllAmcApi
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignForm);
