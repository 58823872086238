import { START_PAGE } from "../../utils/Constant";
import { GET_ALL_TEMPLATES_DATA, GET_PERSONALIZATION_CATEGORY_DATA } from "./ActionTypes";

export const getAllTemplatesData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_TEMPLATES_DATA,
    payload,
    currentPage,
});
export const getCategoryData = (payload, currentPage = START_PAGE) => ({
    type: GET_PERSONALIZATION_CATEGORY_DATA,
    payload,
    currentPage,
});
