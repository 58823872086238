import React, { Component } from 'react'
import { Button, Col, Image, Row, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { ComponentHeader } from '../common'
import SearchContent from '../content/SearchContent'
import { getCollectionDetailsApi } from './Api'

export class LanguageDetails extends Component {
  constructor(props) {
    super(props)
    const id = props.match.params.id;
    this.state = {
      url: 'languages/details',
      type: 'languages',
      id,
      data: "",
      showSearchContent: false,
    }
  }
  componentDidMount() {
    getCollectionDetailsApi(this)
  }
  render() {
    const { data } = this.state;
    // console.log(data);
    return (
      <section className='page-padding'>
        {
          this.state.showSearchContent &&
          <SearchContent
            showModal={this.state.showSearchContent}
            onHide={() => this.setState({ showSearchContent: false })}
          />
        }
        <div className="view-details-wrapper">

          <ComponentHeader
            title="Language Details"
            isBackArrow={true}
            isReturn="/language" />
          <div className="view-details d-flex">
            {
              data &&
              <div className="view-details-content">
                <div className="user-details d-flex">
                  <div className="eclipse">
                    <Image src={data?.circleImagePath} alt='' />
                  </div>
                  <div className="name-details">
                    <h3 className="inter-medium f-s-20">{data?.languageName}</h3>
                    <h4 className="inter-medium grey-3">{data?.uuid}</h4>
                    {/* <h5 className="inter-medium f-s-10 grey-3">Mumbai</h5> */}
                  </div>
                  {data?.description && <>
                    <div className="line"></div>
                    <div className="name-description">
                      <h3 className="grey-2 ">Description</h3>
                      <p className="inter-medium f-s-14">
                        {data?.description}
                      </p>
                    </div>
                  </>
                  }
                </div>

                <Table className="custom-table">
                  <Row>

                    {Object.keys(data?.contentTypeStats || {}).map((item) => {
                      return (
                        <Col sm={3} xs={12}>
                          <div className="detail">
                            <h4 className="inter-semibold grey-2 ">{item} </h4>
                            <h3 className="inter-semibold f-s-14 ">{data?.contentTypeStats[item] || 'Na'}</h3>
                          </div>
                        </Col>
                      )
                    })}
                    <Col sm={3} xs={12}>
                      <div className="detail">
                        <h4 className="inter-semibold grey-2 ">Thumbnail Image</h4>
                        <Image src={data?.thumbnailPath} alt="icon" className="details-image-box" />
                      </div>
                    </Col>
                    <Col sm={3} xs={12}>
                      <div className="detail">
                        <h4 className="inter-semibold grey-2 ">Banner Image</h4>
                        <Image src={data?.bannerPath} alt="icon" className="details-image-box" />
                      </div>
                    </Col>
                  </Row>
                </Table>
                <div className="d-flex justify-content-end">
                  <Button onClick={() => this.setState({ showSearchContent: true })} className='primary-btn'>Add Content</Button>
                </div>
                <div className="line2"></div>
                <div className="content-type-tab">

                </div>

              </div>
            }
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageDetails)