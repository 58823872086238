import React from "react";
import { Image } from "react-bootstrap";

const ContentIfame = ({ extUrlLink, contentFilePath = '', uploadFileName="", height = "150", classes="" }) => {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg']; // Add more extensions if needed 
  const videoExtensions = ['.mp4', '.jpeg', '.png', '.gif', '.bmp', '.svg']; // Add more extensions if needed 
//  console.log(extUrlLink)
  const isImage = (filePath) => {
    // console.log("is Image :",filePath)
    const extension = filePath ? filePath.toLowerCase().slice(filePath.lastIndexOf('.')) : '';
    return imageExtensions.includes(extension);
  };
  const isVideo = (filePath) => {
    // console.log(filePath?.type)
    // console.log(filePath)
    const extension = filePath ? filePath?.toLowerCase().slice(filePath.lastIndexOf('.')) : '';
    // console.log(extension)
    if (filePath.type?.startsWith('video/') || videoExtensions.includes(extension?.toLowerCase())) {
      return (true);
    } else {
      return (false);
    }
  };

  if (extUrlLink.includes("youtube.com")) {
    const YoutubeURL = `https://www.youtube.com/embed/${extUrlLink?.split("v=")[1]}?&rel=0`;
    // console.log(extUrlLink, "condition true--");
    return (
      <div className="video-play-area">
        <iframe
          width="100%"
          height={height}
          src={YoutubeURL}
          allowFullScreen
          title="Embedded YouTube"
          controls
          muted
        />
      </div>
    )
  } else if (contentFilePath || extUrlLink) {
    let file = contentFilePath ? contentFilePath : extUrlLink
    // console.log(uploadFileName, "conditon Else path--");
    return (
      <div className={"infographic-detail-wrapper " + classes }>
        {
          isImage(file)
            ?
            <div className="detail-wrapper">
              <Image
                className="presentation"
                height={height}
                src={file}
                alt="presentations"
              />
            </div>
            : (isVideo(uploadFileName)) ?

                <video src={file} width="100%" height={`${height == 60 ? 60 : "100%"}`} controls> Video tag is Not supported</video>
               
                  :<iframe
                    src={`${file}&embedded=true#toolbar=0&view=fit&navpanes=0`}
                    // src={`${file}?autoplay=0&mute=0"`}
                    // src={file}
                    width="100%"
                    height={height}
                    style={{ width: "100%", height: `${height == 60 ? 60 : "100%"}`, border: "none" }}
                    title="This is title"
                    allowFullScreen
                    frameborder="0"
                    controls
                    muted
                  ></iframe>
        }
                </div>
                )

  } else {
    return null
  }

};
                export default ContentIfame;

