import { DEFAULT_LIST, START_PAGE } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { GET_ALL_LIST_DATA, GET_ALL_COLOR, GET_ALL_SPEAKER,GET_ALL_LANGUAGE } from "./ActionTypes";
const INITIAL_STATE = {
    speakerList: DEFAULT_LIST,
    languageList: DEFAULT_LIST,
    totalPages: null,
    currentPage: START_PAGE,
    colorList: DEFAULT_LIST,
    listData: DEFAULT_LIST,
};
const MasterCategoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_LIST_DATA:
              return {
                listData: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
              };
        case GET_ALL_SPEAKER:
              return {
                speakerList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
              };
        case GET_ALL_LANGUAGE:
            return {
              languageList: action.payload,
              totalPages: calculateTotalPageCount(action.payload.length),
              currentPage: action.currentPage
            };
        case GET_ALL_COLOR:
            return {
              colorList: action.payload,
              totalPages: calculateTotalPageCount(action.payload.length),
              currentPage: action.currentPage
            };
        default:
            return state
    }
};
export default MasterCategoryReducer