import React from "react";
import {
    Form,
    FormElement,
    FormValidator,
    CustomTextControl,
    BaseReactComponent,
    FormSubmitButton,
    SelectControl,
} from "../../utils/form";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CustomModal } from "../common";
import { addTemmplateApi, updateTemplateApi, } from "./Api";

class TemplateForm extends BaseReactComponent {
    constructor(props) {
        super(props);
        const data = props?.editData || null;
        this.state = {
            type: data?.type || "",
            dltNumber: data?.dltNumber || "",
            template: data?.template || "",
            title: data?.title || "",
            message: data?.message || "",

        };
    }


    componentDidUpdate() {

    }



    onValidSubmit = () => {
        let groupData = {
            "data": {
                "type": this.state.type,
                "dltNumber": this.state.dltNumber,
                "template": this.state.template,
                "title": this.state.title,
                "message": this.state.message
            }
        };

        if (this.props?.editData?.uuid) {
            updateTemplateApi(groupData, this.props.this, this.props.onHide, this.props.editData.uuid)
        } else {
            addTemmplateApi(groupData, this.props.this, this.props.onHide)
        }
    }
    onHide = () => {

        this.props.onHide();
    }
    render() {
        const { usersOptions } = this.state;
        return (
            <CustomModal
                show={this.props.show}
                onHide={this.props.onHide}
                title={this.props?.editData?.uuid ? "Edit Template" : "Add Template"}
                modalClass={"change-password"}
            >
                <Form onValidSubmit={this.onValidSubmit}>
                    <Row>
                        <Col sm={12}>
                            <FormElement
                                valueLink={this.linkState(this, "title")}
                                label="Title"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Field cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        //   type: "",
                                        placeholder: "Enter title",
                                    },
                                }}
                            />
                        </Col>
                        <Col sm={12}>
                            <FormElement
                                valueLink={this.linkState(this, "message")}
                                label="Message "
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Field cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        //   type: "",
                                        placeholder: "Enter message",
                                    },
                                }}
                            />
                        </Col>
                        <Col sm={12}>
                            <FormElement
                                valueLink={this.linkState(this, "template")}
                                label="Template"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Field cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        //   type: "",
                                        placeholder: "Enter template",
                                    },
                                }}
                            />
                        </Col>
                        <Col sm={12}>
                            <FormElement
                                valueLink={this.linkState(this, "dltNumber")}
                                label="DLT Number"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Field cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        //   type: "",
                                        placeholder: "Enter DLT number",
                                    },
                                }}
                            />
                        </Col>


                        <Col md={12}>
                            <FormElement
                                valueLink={this.linkState(this, "type")}
                                label="Select Type"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Content type cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: SelectControl,
                                    settings: {
                                        placeholder: "Select type",
                                        options: ["sms", 'email', 'internal', 'whatsapp']?.map(item => ({
                                            label: item.toUpperCase(),
                                            value: item
                                        })),
                                        multiple: false,
                                        searchable: true,
                                        onChangeCallback: (onBlur) => {
                                            onBlur(this.state.type);
                                        },
                                    },
                                }}
                            />
                        </Col>

                    </Row>

                    <div className="submit-wrapper" style={{ justifyContent: "center" }}>
                        <FormSubmitButton customClass={"primary-btn"}>
                            Save
                        </FormSubmitButton>
                    </div>
                </Form>
            </CustomModal>
        );
    }
}

const mapStateToProps = state => ({
    // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {
    // getAllUsersApi
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateForm);
