import React from "react";
import { connect } from "react-redux";

import {
    Form,
    FormElement,
    FormValidator,
    CustomTextControl,
    BaseReactComponent,
    SelectControl,

} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import "react-datepicker/dist/react-datepicker.css";
import { getAllCalenderApi, getAllCalenderFilterApi } from "./Api";
import { ClearButton, ImageUploader } from "../common";
import { postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import ReactDOM from "react-dom";

class AddEditCalenderFilter extends BaseReactComponent {
    constructor(props) {
        super(props);
        const data = props?.location.state?.data || null;


        this.state = {
            editData: data,
            calenderFilterUuid: data?.uuid || "",
            isUpdated: (props?.location.state ?? false) || false,
            filterName: data?.filterName || "",
            thumbnailFile: null,
            uploadImage: data?.icon || null,
            resLoading: false,

        };

    };

    imageUploaser = (e, name) => {
        // console.log(typeof (e.target.files[0]))
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        this.setState({ iconImagePath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })

    }


    handlePageSize = (pageSize) => {
        // console.log("Heyyy pageSize", pageSize);
    };
    handleSave = () => {
        ReactDOM.findDOMNode(this.form).dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    };
    onValidSubmit = async () => {
        this.setState({ resLoading: true });
        const { filterName } = this.state;
        const calenderFilterData = {
            "data": {
                "filterName": filterName,

            }
        };

        const data = new FormData();
        data.append("key", JSON.stringify(calenderFilterData));
        data.append("file", this.state?.thumbnailFile);

        if (this.state.calenderFilterUuid) {
            const response = await putAuthCommonApi(`calender-filter/${this.state.calenderFilterUuid}`, data);
            if (response.status === 200) {
                this.setState({ resLoading: false });
                this.props.history.push({ pathname: `/calender-filter`, search: 'pageSize=10&pageNumber=1' });
            } else {
                this.setState({ resLoading: false });
            }
        } else {
            const response = await postAuthCommonApi('/calender-filter', data);
            if (response.status === 200) {
                this.setState({ resLoading: false });
                this.props.history.push({ pathname: `/calender-filter`, search: 'pageSize=10&pageNumber=1' });
            } else {
                this.setState({ resLoading: false });
            }
        }
    };


    render() {
        const {
            uploadImage,
            thumbnailFile,

        } = this.state;

        return (
            <section className="add-section">
                <ComponentHeader
                    title={this.state.calenderUuid ? "Update Calender Filter" : "Add Calender Filter"}
                    primaryBtn="Save"
                    isBackArrow={true}
                    resLoading={this.state.resLoading}
                    isReturn="/calender-filter?pageSize=10&pageNumber=1"
                    handlePrimaryBtn={this.handleSave}
                />

                <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
                    <Row>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "filterName")}
                                label="Filter Name"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Name cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Name",
                                    },
                                }}
                            />
                        </Col>
                        <Col sm={4}>
                            <ImageUploader required={true} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} />
                        </Col>


                    </Row>



                </Form>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {



    getAllCalenderFilterApi

};
AddEditCalenderFilter.propTypes = {
    // getPosts: PropTypes.func

};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCalenderFilter);