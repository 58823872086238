import React from 'react'
import { Button, Image, Modal } from 'react-bootstrap';
import closeIcon from '../../assets/images/greay-cancel-icon.svg';
import { patchAuthVerifyApi } from '../content/Api';
export default function VerifiedModal({ show = false,name='', url = '', isVerified, handleCancel = () => { } ,successRes=()=>{}}) {
    // const history = useHistory();
    const updatedVerifiedHandler = async () => {
        console.log(!isVerified)
        const info = {
            "data": {
                "isVerified": !isVerified
            }
        }
        const newData = new FormData();
        newData.append("key", JSON.stringify(info));
        const res = await patchAuthVerifyApi(url, info)
        console.log(res)
        if (res) {
            successRes();
            handleCancel()
        }
    }
    return (
        <Modal show={show}  onHide={handleCancel} dialogClassName={`custom-modal verify-modal vh-100  `}>
            {

                <Modal.Header className="p-0 border-0">
                    {/* <Modal.Title className='red-hat-display-bold f-s-20'>  Do you wish to {!data?.isVerified ?'verify':'un verify'} content ?</Modal.Title> */}
                    <Image src={closeIcon} className='close-icon model-icon-close' alt='icon' onClick={handleCancel} />
                </Modal.Header>
            }
            <Modal.Body className="p-0">
                <h3 className='red-hat-display-bold f-s-20 p-0'>  Do you wish to {!isVerified ? 'verify' : 'unverify'} {name} ?</h3>

                <div className=" d-flex align-items-center justify-content-end pt-5">
                    <Button className="primary-btn py-2 mr-3" onClick={updatedVerifiedHandler}>Yes</Button>
                    <Button className=" secondary-btn py-2" onClick={handleCancel}>No</Button>
                </div>
            </Modal.Body>
        </Modal>
    )


}
