import { toast } from "react-toastify";
import { preLoginInstance } from "../../utils";
import { getAllLoginData } from "./LoginAction";

// LOGIN
export const loginApi = (ctx, data) => {

  return function (dispatch) {
    preLoginInstance.post('admin/user/login', data)
      .then(res => {

        if (res?.data?.data?.token) {
          localStorage.setItem('finToken', res?.data?.data?.token);
          localStorage.setItem('finAdmin', JSON.stringify(res?.data?.data));
          dispatch(getAllLoginData(res?.data?.data));

          ctx.props.history.push({
            pathname: '/dashboard',
          });

        } else {
          toast.error(res?.data.message || "Something went wrong");
          ctx.setState({
            errorMessage: res?.data?.message || "Invalid Credentials"
          });
        }
      })
      .catch(err => {
        toast.error(err?.response?.data?.message || "Something went wrong");
        ctx.setState({
          errorMessage: "Something went wrong"
        });
      });
  };
};



export const resetPasswordApi = (ctx, data) => {
  preLoginInstance.put(`admin/user/reset-password/${ctx?.state?.token}`, data)
    .then((res) => {
      toast.success(res?.data?.data?.message || "Password set successfully");
      ctx.props.handleClose()
      // ctx.props.history.push("/onboarding");
    })
    .catch((error) => {
      toast.error(error.response.data.message || "Something went wrong")
    });
};