import React, { Component } from "react";
import { connect } from "react-redux";
import ComponentHeader from "../common/ComponentHeader";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Button, Col, Image, Row } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import editPencil from "../../assets/images/edit-icon.png";
import { getAllPlaylistDataApi } from "./Api";
import { API_LIMIT, START_PAGE, isModuleExist, uploadFrequency } from "../../utils/Constant";
import { InformationModal, VerifiedModal } from "../common";
import { toast } from "react-toastify";
import { EVENT_VERIFY, PLAYLIST_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";
import { deleteAuthCommonApi } from "../content/Api";


class Playlist extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist("Auto Share", 'Playlist');

    this.state = {
      access: AccesData,
      search: "",
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,


      isVerify: false,
      show: false,

      verified: '',
      verifyUrl: ''

    };
  }


  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllPlaylistDataApi(this);

  }

  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";


    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";

    // const search = params.get("search") || "";

    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllPlaylistDataApi(this);

      });
    }
  }

  getDetailsUser = (data) => {
    this.props.history.push({ pathname: `/playlist/details/${data?.uuid}`, state: { "id": data?.uuid, "detail": data } });
  }

  showEditModal = (rowData = null) => {
    // getDetailsApi("/playlist", this, rowData.uuid);
    this.props.history.push({ pathname: `/playlist/add-playlist`, state: { "id": rowData?.uuid } })
    this.setState({
      showEdit: !this.state.showEdit,

    });
  };

  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };

  deleteHandle = async (id) => {
    const res = await deleteAuthCommonApi(`/playlist/${id}`);
    // console.log(res)
    if (res.status === 200) {
      this.props.getAllPlaylistDataApi(this);
      this.setState({
        delete: !this.state.delete
      });

    } else {
      toast.error("Somthis wrong...")
    }
  }

  render() {
    const { access } = this.state;

    const { playlistData, totalPages, currentPage } = this.props.autoShareState;
    // console.log(eventData)
    return (
      <>
        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.title}?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        <VerifiedModal
          name={`'${this.state.editData?.title}'`}
          show={this.state.show}
          url={PLAYLIST_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getAllPlaylistDataApi(this) }}
        />
        <section>
          <ComponentHeader
            title="Playlist"
            primaryBtn={access.createAccess ? "Add Playlist" : ""}
            // isSearch={true}
            // isBackArrow={true}
            // isReturn="/contentCollections"
            handlePrimaryBtn={() => { this.props.history.push('/playlist/add-playlist') }}
          />
          <Row>
            <Col sm={2}>
              <FilterVefiedDropDown ctx={this} />
            </Col>
          </Row>
          <div className="commom-table-wrapper">
            <CustomTable
              tableData={playlistData?.list}
              columnList={[
                {
                  coumnWidth: 150,
                  labelName: "Title",
                  dataKey: "title",
                  className: "title",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "title") {
                      return rowData.title;
                    }
                  },
                },
                {
                  coumnWidth: access.verificationAccess ? 120 : 0,
                  labelName: "Verified",
                  dataKey: "isVerified",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "isVerified") {
                      return <div className=" d-flex align-items-center inter-medium">
                        {(rowData?.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                      </div>;
                    }
                  },
                },
                {
                  coumnWidth: 200,
                  labelName: "Content Creator",
                  dataKey: "contentCreator",
                  className: "contentCreator",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "contentCreator") {
                      return rowData?.contentCreator?.contentCreatorName || 'Na';;
                    }
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "AMC",
                  dataKey: "amc",
                  className: "amc",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "amc") {
                      return rowData?.amc?.amcName || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "User",
                  dataKey: "user",
                  className: "user",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "user") {
                      return `${rowData?.user?.firstName || ''} ${rowData?.user?.lastName || ''}`.trim() || 'Na';

                    }
                  },
                },

                {
                  coumnWidth: 150,
                  labelName: "Upload Frequency",
                  dataKey: "uploadFrequency",
                  className: "uploadFrequency",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "uploadFrequency") {
                      // const searchValue = rowData.uploadFrequency == 0.00 ? 10 : rowData.uploadFrequency;
                      const frequency = uploadFrequency.find(item => item.value == rowData.uploadFrequency);
                      return frequency ? frequency.label : "";
                    }
                  },
                },

                {
                  coumnWidth: 150,
                  labelName: "Thumbnail Image",
                  dataKey: "thumbnailPath",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "thumbnailPath") {
                      return <Image src={rowData.thumbnailPath} className="table-img" alt="Photo" />;
                    }
                  },
                },


                {

                  coumnWidth: access.updateAccess || access.deleteAccess || playlistData?.list?.isCustomized === false ? 300 : 0,
                  labelName: "Action",
                  dataKey: "",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "") {
                      return (
                        <>
                          <Button onClick={() => this.getDetailsUser(rowData)} className=" primary-btn mr-4">
                            View Detail
                          </Button>
                          {access.updateAccess && rowData?.isCustomized === false && (
                            <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                          )}
                          {access.deleteAccess && rowData?.isCustomized === false && (
                            <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                          )}
                        </>
                      );
                    }
                  },

                }

              ]}
              message="No data found" // For Pagination
              history={this.props.history}
              location={this.props.location}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  autoShareState: state.AutoShareState,
});
const mapDispatchToProps = {
  getAllPlaylistDataApi,
  deleteAuthCommonApi

};
Playlist.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);
