import React from "react";
import PropTypes from "prop-types";
// import { Button, Glyphicon, Image } from "react-bootstrap";
import leftArrow from "../../assets/images/ellipse-arrow-up.svg";
import rightArrow from "../../assets/images/ellipse-up-arrow.svg";
import CustomButton from "../form/CustomButton";

const onLeftClick = (props) => {
  // console.log('props', props);
  if (props.noUrl) {
    props.loadData(props.page - 1);
  } else {
    // console.log('Heyaa Prev');
    if (props.page > 1) {
      const params = new URLSearchParams(props.history.location.search);
      params.set("p", props.page - 1);
      props.history.push(`${props.history.location.pathname}?${params}`);
      // props.loadData(props.page - 1);
    }
  }
};

const onNextClick = (props) => {
  if (props.noUrl) {
    props.loadData(props.page + 1);
  } else {
    // console.log('Heyaa Next');
    if (props.page < props.pageCount) {
      const params = new URLSearchParams(props.history.location.search);
      params.set("p", props.page + 1);
      props.history.push(`${props.history.location.pathname}?${params}`);
      // props.loadData(props.page + 1);
    }
  }
};

const Pagination = (props) => {
  // console.log('props', props);
  return (
    <div className="pagination-wrapper">
      <CustomButton
        handleClick={() => onLeftClick(props)}
        buttonImage={leftArrow}
        className="left-arrow"
      />
      <h5>
        {props.page} of {props.pageCount}
      </h5>
      <CustomButton
        handleClick={() => onNextClick(props)}
        buttonImage={rightArrow}
        className="right-arrow"
      />
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  loadData: PropTypes.func,
};
export default Pagination;
