import { toast } from "react-toastify";
import { API_LIMIT, MAX_LIMIT, START_PAGE } from "../../utils/Constant";
import { getAllTemplatesData, getCategoryData } from "./PersonalizationAction";
import { postLoginInstance } from "../../utils";

export const getAllTemplatesApi = (ctx = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {

    return postLoginInstance.get(`personalization/template?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}${ctx.state.search && `&searchValue=${ctx.state.search}`}`)
      .then((res) => {


        dispatch(getAllTemplatesData(res.data.data, ctx?.state.currentPage || START_PAGE));

      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Something went wrong")
      })
  }

};

export const getDetailsTemplateApi = (path, ctx) => {
  console.log(ctx, "subdomain")
  return postLoginInstance.get(`${path}/${ctx.props?.location?.state?.id || ctx.props.match.params.id}`)
    .then((res) => {
      const d = res.data.data
      const processedVariations = d.personalizationTemplateVariations?.map(module => {
        const processedVariations = {
          languageUuid: module?.language?.uuid,
          personalizationTemplateVariationImages:
            module?.personalizationTemplateVariationImages

        };
        return processedVariations;
      });
      return ctx.setState({
        editData: d, templateUuid: d?.uuid || "", templateName: d?.templateName || "", variations: processedVariations, categoryUuid: d?.personalizationCategory?.uuid, uploadImage: d?.thumbnailImage
      });
    })
    .catch((err) => {
      console.log('Eroorr', err)
      toast.error(err?.response?.data?.message || 'Something went wrong.');
    })
};

export const getTemplateDetailsApi = (path, ctx) => {

  return postLoginInstance.get(`${path}/${ctx.props?.location?.state?.id || ctx.props.match.params.id}`)
    .then((res) => {
      return ctx.setState({ data: res?.data?.data });

    })
    .catch((err) => {
      console.log('Eroorr', err)
      toast.error(err?.response?.data?.message || 'Something went wrong.');
    })
};
export const getPersonalizationCategoryApi = (ctx = null, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `personalization/category?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || MAX_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.categoryName,
              value: item.uuid
            }))
          ctx.setState({
            categoryOptions: option
          })
        } else {
          dispatch(
            getCategoryData(res.data.data, ctx?.state.currentPage || START_PAGE)
          );
        }
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addPersonalizationCategoryApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance.post("personalization/category", data).then((res) => {
    ctx.props.getPersonalizationCategoryApi(null, onHide);
    toast.success("Added Successfully.")
    ctx2?.setState({ resLoading: false })
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deletePersonalizationCategoryApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`personalization/category/${id}`).then((res) => {
    ctx.props.getPersonalizationCategoryApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updatePersonalizationCategoryApi = (data, ctx, onHide, id) => {
  return postLoginInstance.put(`personalization/category/${id}`, data).then((res) => {
    ctx.props.getPersonalizationCategoryApi(null, onHide);
    toast.success("Successfully Updated.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};