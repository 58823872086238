import React, { Component } from "react";
import { connect } from "react-redux";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Button, Image } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { ComponentHeader, InformationModal } from "../common";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
import { deleteTargetedApi, getAllTargetedListApi } from "./Api";
import TargatedForm from "./TargatedForm";

export class TargetedTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummyData: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      languageList: [],
      totalPages: null,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,
    };
  }

  // todo---------------------------------------
  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllTargetedListApi(this);
  }
  // todo---------------------------------------
  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;


    if (prevPage !== page) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllTargetedListApi(this);

      });
    }
  }
  // todo:-----------------------------------------

  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {

    deleteTargetedApi(id, this, this.openCloseDeleteModal)
  }
  render() {
    const { showEdit } = this.state;
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;
    return (
      <section className="request-section">

        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete  ${this.state.deleteData.targetedContentName} ?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        {
          showEdit &&
          <TargatedForm
            show={showEdit}
            onHide={this.showEditModal}
            editData={this.state.editData}
            this={this}
          />
        }
        <ComponentHeader
          title="Targeted Content"
          isSearch={false}
          placeholder={"Search Targeted Content"}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          primaryBtn="New Targeted Content"
          handlePrimaryBtn={() => {

            this.setState({ showEdit: true })
          }}
        />
        <div className="commom-table-wrapper">

          <CustomTable
            tableData={listData?.list || []}
            columnList={[
              {
                coumnWidth: 50,
                labelName: "#",
                dataKey: "id",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "id") {
                    return "#";
                  }
                },
              },
              {
                coumnWidth: 250,
                labelName: "Targeted Content Name",
                dataKey: "targetedContentName",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "targetedContentName") {
                    return rowData.targetedContentName;
                  }
                },
              },
              {
                coumnWidth: 350,
                labelName: "Description",
                dataKey: "description",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "description") {
                    return rowData.description;
                  }
                },
              },
              {
                coumnWidth: 200,
                labelName: "Thumbnail Picture",
                dataKey: "thumbnailPath",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "thumbnailPath") {
                    return <Image className="table-img" src={rowData.thumbnailPath} alt="AMC Logo" />;
                  }
                },
              },
              {
                coumnWidth: 200,
                labelName: "Banner Path Image",
                dataKey: "bannerPath",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "bannerPath") {
                    return <Image src={rowData.bannerPath} className="table-img" alt="Photo" />;
                  }
                },
              },
              {
                coumnWidth: 170,
                labelName: "Action",
                dataKey: "",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <Button onClick={() => this.props.history.push({
                        pathname: `/targeted-details/${rowData.uuid}`

                      })} className=" view-details-btn inter-medium f-s-14 lh-18">
                        View Detail
                      </Button>
                    );
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "",
                dataKey: "",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <>
                        <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                        <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                      </>
                    );
                  }
                },
              },
            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getAllTargetedListApi
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetedTab);
