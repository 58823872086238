import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import editPencil from "../../assets/images/edit-icon.png";
import { Table, Image, Row, Col, Button, Modal, Tabs, Tab } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import closeIcon from '../../assets/images/greay-cancel-icon.svg';
import { getDetaisContentApi, getDetaisContentEventApi, patchAuthCommonApi } from "./Api";
// import ContentIfame from "./_utils/ContentIframe";
import { API_LIMIT, isModuleExist, START_PAGE } from "../../utils/Constant";
import ContentIfame from "../content/_utils/ContentIframe";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { getCommonApi } from "../content/Api";
import Pagination from "../../utils/commonComponent/Pagination";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

class ViewSsoContent extends BaseReactComponent {
    constructor(props) {
        // console.log(props)
        super(props);
        const { params } = props.match;

        const AccesData = isModuleExist("Sso", 'Content');
        this.state = {
            access: AccesData,
            params,
            show: false,
            firstname: "",
            lastname: "",
            pleaseselect: "",
            detailsData: [],
            activeTab: '',

        };


    }

    componentDidMount = () => {
        const { id } = this.props.match.params;

        getDetaisContentEventApi("/sso/content/allDetails", this);
        this.props.history.push({ search: 'tab=details' })


    }

    contentDetailsTab = (data) => {
        return (
            <div className="view-details-content">
                <div className="user-details d-flex">
                    <div className="eclipse">
                        <Image src={data?.amc?.circleImagePath} className="eclipse" />
                    </div>
                    <div className="name-details">
                        <h3 className="inter-medium overflow-eclipse mw-200 f-s-20">{data?.defaultVariation?.title || ""} </h3>
                        <h4 className="inter-medium grey-3 f-s-14">{data?.amc?.amcName}</h4>
                        {
                            data?.uploadSection?.map((item) => {
                                return (
                                    <h4 className="inter-medium grey-3 f-s-14 pills">{item.uploadSection}</h4>
                                )
                            })
                        }
                        {/*<h5 className="inter-medium grey-3 f-s-14">End Date: {moment(data?.endDate).format('lll')}</h5> */}
                    </div>
                    <div className="line "></div>
                    <div className="name-description">
                        <h3 className="grey-2 ">Description</h3>
                        <p className="inter-medium f-s-14">{data?.defaultVariation?.description || ""}</p>
                    </div>
                </div>
                <h3 className="inter-regular f-s-14 grey-2">
                    Content Details
                </h3>
                <div className="line2"></div>
                <Table className="custom-table">
                    <Row>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Content Type</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.contentType?.contentTypeName || "na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Content Creator</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.contentCreator?.contentCreatorName || "na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">AMC</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.amc?.amcName || "Na"}</h3>
                            </div>
                        </Col>

                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Social Media</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.socialMedia.map(item => item?.socialMediaName) || "na"}</h3>
                            </div>
                        </Col>

                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Category</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.category?.categoryName || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">
                                    Sub Category
                                </h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.subCategory?.subCategoryName || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">
                                    Default Language
                                </h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.defaultLanguage?.languageName || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Display Position</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.displayPosition.map(item => item?.position + ' ') || "xyz@gmail.com"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Source</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.source}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Duration (minutes)</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.duration}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 d-flex w-100 gap-12 ">Verified
                                    {this.state.access.verificationAccess &&
                                        <Image onClick={() => { this.setState({ show: true }) }} src={editPencil} className="edit-pencil mt-1" />}
                                </h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.isVerified ? "Yes" : "No"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Premium</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.isPremium ? "Yes" : 'No'}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Keywords</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.keywords}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">
                                    Published At
                                </h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.publishedAt || "Na"}</h3>
                            </div>
                        </Col>

                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">
                                    Download Count
                                </h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.downloadCount || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">
                                    Like Count
                                </h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.likeCount || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">
                                    Saved Count
                                </h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.savedCount || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">
                                    Share Count
                                </h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.shareCount || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">
                                    Download Count
                                </h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.downloadCount || "Na"}</h3>
                            </div>
                        </Col>


                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Targeted Content</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.targetedContent?.map((item) => item?.targetedContentName + " ") || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Campaign</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.campaign?.map((item) => item?.campaignName + " ") || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Branding</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.branding?.brandingName || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Thumbnail Image</h4>
                                <a href={data?.thumbnail} target="_blank" rel="noreferrer">
                                    <Image src={data?.thumbnail} alt="icon" className="details-image-box" />
                                </a>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Age Group</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.ageGroup || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Employment Type</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.employmentType || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Financial Understanding</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.financialUnderstanding || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Gender</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.gender || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Goal</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.goal || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Marital Status</h4>
                                <h3 className="inter-semibold f-s-14 ">{(data?.maritalStatus) || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Risk Appetide</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.riskAppetide || "Na"}</h3>
                            </div>
                        </Col>
                        <Col sm={3} xs={12}>
                            <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Income Group</h4>
                                <h3 className="inter-semibold f-s-14 ">{data?.incomeGroup || "Na"}</h3>
                            </div>
                        </Col>
                    </Row>
                </Table>

                <div className="variation-wrapper">
                    <h3 className="inter-regular f-s-14 grey-2">Default Variation</h3>
                    <div className="line2"></div>
                    <Table>
                        <Row>
                            <Col sm={4} xs={12}>
                                <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">Title</h4>
                                    <h3 className="inter-semibold f-s-14 ">{data?.defaultVariation?.title || "Na"}</h3>
                                </div>
                            </Col>
                            <Col sm={4} xs={12}>
                                <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">Description</h4>
                                    <h3 className="inter-semibold f-s-14 ">{data?.defaultVariation?.description || "Na"}</h3>
                                </div>
                            </Col>
                            <Col sm={4} xs={12}>
                                <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">Color</h4>
                                    <h3 className="inter-semibold f-s-14 ">{data?.defaultVariation?.color?.colorName || "Na"}</h3>
                                </div>
                            </Col>
                            <Col sm={4} xs={12}>
                                <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">Language</h4>
                                    <h3 className="inter-semibold f-s-14 ">{data?.defaultVariation?.language?.languageName || "Na"}</h3>
                                </div>
                            </Col>
                            <Col sm={4} xs={12}>
                                <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">External Url</h4>
                                    <h3 className="inter-semibold f-s-14 ">
                                        <a href={data?.defaultVariation?.extUrlLink || ""} target="_blank" rel="noopener noreferrer">
                                            {data?.defaultVariation?.extUrlLink || "Na"}
                                        </a>
                                    </h3>
                                </div>
                            </Col>
                            {/* <Col sm={4} xs={12}>
                      <div className="detail">
                        <h4 className="inter-semibold grey-2 ">Content Image</h4>
                        <ContentIfame extUrlLink={data?.defaultVariation?.extUrlLink || ""} contentFilePath={data?.defaultVariation?.contentFilePath || ''} />
                      </div>
                    </Col> */}

                        </Row>
                    </Table>

                </div>
                {data?.contentVariation?.length > 0 &&
                    <div className="variation-wrapper">
                        <h3 className="inter-regular f-s-14 grey-2">Content Variation</h3>
                        <div className="line2"></div>
                        {
                            data?.contentVariation?.map((item, i) => {
                                return (
                                    <Table>
                                        <Row>
                                            <Col sm={4} xs={12}>
                                                <div className="detail">
                                                    <h4 className="inter-semibold grey-2 ">Title</h4>
                                                    <h3 className="inter-semibold f-s-14 ">{item?.title || "Na"}</h3>
                                                </div>
                                            </Col>
                                            <Col sm={4} xs={12}>
                                                <div className="detail">
                                                    <h4 className="inter-semibold grey-2 ">Description</h4>
                                                    <h3 className="inter-semibold f-s-14 ">{item?.description || "Na"}</h3>
                                                </div>
                                            </Col>
                                            <Col sm={4} xs={12}>
                                                <div className="detail">
                                                    <h4 className="inter-semibold grey-2 ">Color</h4>
                                                    <h3 className="inter-semibold f-s-14 ">{item?.color?.colorName || "Na"}</h3>
                                                </div>
                                            </Col>
                                            <Col sm={4} xs={12}>
                                                <div className="detail">
                                                    <h4 className="inter-semibold grey-2 ">Language</h4>
                                                    <h3 className="inter-semibold f-s-14 ">{item?.language?.languageName || "Na"}</h3>
                                                </div>
                                            </Col>
                                            <Col sm={4} xs={12}>
                                                <div className="detail">
                                                    <h4 className="inter-semibold grey-2 ">External Url</h4>
                                                    <h3 className="inter-semibold f-s-14 ">
                                                        <a href={item?.extUrlLink || ""} target="_blank" rel="noopener noreferrer">
                                                            {item?.extUrlLink || "Na"}
                                                        </a>
                                                    </h3>
                                                </div>
                                            </Col>
                                            <Col sm={4} xs={12}>
                                                <div className="detail">
                                                    <h4 className="inter-semibold grey-2 ">Content Image</h4>
                                                    <ContentIfame extUrlLink={item?.extUrlLink || ""} contentFilePath={item?.contentFilePath} height="100" classes={'custom-images-height'} />

                                                    {/* <Image src={item?.thumbnailPath} alt="icon" className="details-image-box" /> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Table>
                                )
                            })
                        }


                    </div>
                }

            </div>
        )
    }



    // componentDidMount() { }
    returnDate(timestamp) {
        const date = new Date(timestamp);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    }

    render() {
        const { data, detailsData, params } = this.state;
        console.log(data)
        return (
            <section className="user-section">
                {/* // ---------------------------------------View details -------------------------------- */}

                <div className="view-details-wrapper ">
                    <ComponentHeader
                        title={`${data?.defaultVariation?.title} Details`}
                        isBackArrow={true}
                        isReturn={"/sso/content"}
                    />
                    <div className="view-details">
                        <Tabs
                            defaultActiveKey={'Details'}
                            onSelect={(d) => { this.setState({ activeTab: d }); this.props.history.push({ search: 'tab=' + d }) }}
                            className="inter-regular f-s-16 lh-22 user-details-tabs"
                            id=""
                        >
                            <Tab eventKey={'Details'} title={'Details'}>
                                {this.contentDetailsTab(data)}
                            </Tab>
                            <Tab eventKey={'Activity'} title={'Activity'}>
                                <MyActivity data={data?.id} />

                            </Tab>
                        </Tabs>

                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({ usersState: state.UsersState });
const mapDispatchToProps = {
    // getPosts: fetchPosts
    // getDetaisContentApi
};
ViewSsoContent.propTypes = {
    // getPosts: PropTypes.func
    location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSsoContent);

function MyActivity({ data }) {
    const navigate = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [activityType, setActivityType] = useState('Liked');
    const [activityData, setActivityData] = useState([])
    const uniqueTypes = ['Cobrand', 'Downloaded', 'Liked', 'Shared']; // List of all unique types

    const TabsNames = [{ name: 'Downloads', type: "Downloaded" }, { name: "Co-Brands", type: "Cobrand" }, { name: "Likes", type: "Liked" }, { name: "Shares", type: "Shared" }, { name: "Recent Search", type: "Search" },]
    useEffect(async () => {
        let url = "user-tracking?type=" + activityType


        const contentId = data;
        const Type = (TabsNames.find((ele => ele.name === activityType)))?.type || 'Downloaded';
        if (contentId) {

            const res = await getCommonApi(`sso/content/tracking?contentId=${contentId}&type=${activityType}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`);
            // console.log(res)

            setActivityData(res?.data?.data)

        }

    }, [activityType, data])
    return (
        <div className="activity-tabs-section">
            <Tabs
                defaultActiveKey={'Liked'}
                onSelect={(data) => { setActivityType(data); console.log(data) }}
                className="inter-regular f-s-16 lh-22 user-details-tabs bg-ECE"
                id=""
            >

                {uniqueTypes.map((type) => {
                    const filteredData = activityData?.filter(item => item.type === type);

                    return (
                        <Tab eventKey={type} title={type} key={type}>
                            {filteredData?.length > 0 ? (
                                <div className="commom-table-wrapper">
                                    <CustomTable
                                        tableData={filteredData || []}
                                        columnList={[
                                            {
                                                coumnWidth: 300,
                                                labelName: "Name",
                                                dataKey: "name",
                                                className: "name",
                                                isCell: true,
                                                cell: (rowData, dataKey) => {
                                                    if (dataKey === "name") {
                                                        return rowData?.name;
                                                    }
                                                },
                                            },

                                            {
                                                coumnWidth: 300,
                                                labelName: "Email Address",
                                                dataKey: "emailAddress",
                                                className: "email",
                                                isCell: true,
                                                cell: (rowData, dataKey) => {
                                                    if (dataKey === "emailAddress") {
                                                        return rowData?.emailAddress;
                                                    }
                                                },
                                            },
                                            {
                                                coumnWidth: 300,
                                                labelName: "Mobile Number",
                                                dataKey: "mobileNumber",
                                                className: "number",
                                                isCell: true,
                                                cell: (rowData, dataKey) => {
                                                    if (dataKey === "mobileNumber") {
                                                        return rowData?.mobileNumber;
                                                    }
                                                },
                                            },
                                            {
                                                coumnWidth: 300,
                                                labelName: "ARN Number",
                                                dataKey: "arnNumber",
                                                className: "arn",
                                                isCell: true,
                                                cell: (rowData, dataKey) => {
                                                    if (dataKey === "arnNumber") {
                                                        return rowData?.arnNumber;
                                                    }
                                                },
                                            },


                                        ]}
                                        message="No data found" // For Pagination
                                        history={navigate}
                                        location={location}
                                        totalPages={Math.ceil(activityData?.length / API_LIMIT)}
                                        currentPage={parseInt(params.get('p')) || START_PAGE}

                                    />

                                </div>
                            ) : (
                                <h1 className="inter-regular f-s-24 lh-32 text-center py-4 w-100">
                                    No data...
                                </h1>
                            )}
                        </Tab>
                    );
                })}



            </Tabs>
        </div>
    )
}