import React from "react";
import { connect } from "react-redux";

import {
    Form,
    FormElement,
    FormValidator,
    CustomTextControl,
    BaseReactComponent,
    SelectControl,

} from "../../utils/form";
import { Row, Col, Button, Image } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import "react-datepicker/dist/react-datepicker.css";
import { getAllTemplatesApi, getDetailsTemplateApi, getPersonalizationCategoryApi } from "./Api";
import { ClearButton, CobrandContent, ImageUploader } from "../common";
import ReactDOM from "react-dom";
import closeIcon from '../../assets/images/close.png';
import { getLanguageNameApi } from "../MasterCategory/Api";
import { postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import { toast } from "react-toastify";
import { MAX_LIMIT } from "../../utils/Constant";



class AddEditTemplate extends BaseReactComponent {
    constructor(props) {
        super(props);
        const data = props?.location.state?.data || null;


        this.state = {
            editData: data,
            templateUuid: data?.uuid || "",
            isUpdated: (props?.location.state ?? false) || false,
            thumbnailFile: null,
            isAddContent: true,
            uploadImage: data?.calenderImage || null,
            resLoading: false,
            templateName: "",
            description: data?.description || "",
            categoryUuid: "",
            variations: [],
            languageUuid: "",
            isDefault: true,
            languageOption: [],
            categoryOptions: [],
            isCanvasOpen: false,
            pageNumber: "",
            canvasHeight: "",
            canvasWidth: "",
            backgroundColor: "",
            pageSize: MAX_LIMIT,
            isDefault: false,
            currentVariationIndex: "",
            personalizationTemplateVariationImages: [],
            personalizationTemplateVariationImage: [],

        };

    };


    async componentDidMount() {
        this.props.getLanguageNameApi(this);
        this.props.getPersonalizationCategoryApi(this);
        if (this.props?.location?.state?.data?.uuid) {
            this.setState({ loading: true })

            await getDetailsTemplateApi("/personalization/template", this).finally(() => {
                this.setState({ loading: false })
            });

        };


    };

    componentDidUpdate(prevProps, prevState) {

    };

    imageUploaser = (e, name) => {

        const imageUrl = URL.createObjectURL(e.target.files[0]);
        this.setState({ iconImagePath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })

    };

    openCanvas = (index) => {
        const variationData = this.state.variations[index]?.personalizationTemplateVariationImages || [];
        this.setState({ isCanvasOpen: true, currentVariationIndex: index, personalizationTemplateVariationImages: variationData })
    };
    addMore = () => {
        let variations = this.state.variations ? this.state.variations.map(a => ({ ...a })) : [];
        variations.push({
            languageUuid: "",
            isDefault: variations.length === 0,
            personalizationTemplateVariationImages: []
        });

        this.setState({ variations });
    };

    removeVariation = (i) => {
        let variations = this.state.variations.map(a => ({ ...a }));
        variations.splice(i, 1);
        this.setState({ variations });
    };

    updateCanvasData = (updatedCanvasData) => {
        const { currentVariationIndex, variations } = this.state;
        const updatedVariations = [...variations];
        updatedVariations[currentVariationIndex].personalizationTemplateVariationImages = updatedCanvasData;
        console.log(updatedCanvasData, "ucd")
        this.setState({
            variations: updatedVariations,
            isCanvasOpen: false,
            personalizationTemplateVariationImage: [],
        });
    };


    processedVariations = (variations) => {
        return variations.map((variation, index) => ({
            languageUuid: variation?.languageUuid,
            isDefault: index === 0,
            personalizationTemplateVariationImages: [
                {
                    pageNumber: this.state.pageNumber || index + 1,
                    canvasHeight: this.state.canvasHeight,
                    canvasWidth: this.state.canvasWidth,
                    layers: variation?.personalizationTemplateVariationImage || [], // Set
                },
            ],
        }));
    };



    handlePageSize = (pageSize) => {

    };
    handleSave = () => {
        ReactDOM.findDOMNode(this.form).dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    };
    onValidSubmit = async () => {
        this.setState({ resLoading: true });
        const { templateName, description, categoryUuid, variations, thumbnailFile, uploadImage } = this.state;
        if (!thumbnailFile && !uploadImage) {
            toast.error("Please fill all required fields");
            this.setState({ resLoading: false });
            return;
        }
        else if (variations?.length == 0) {
            toast.error("Atleast one variation is required");
            this.setState({ resLoading: false });
            return;
        }
        else {
            const templateData = {
                "data": {
                    "templateName": templateName,
                    "thumbnailImage": thumbnailFile?.name,
                    "description": description,
                    "personalizationCategoryUuid": categoryUuid,
                    "personalizationTemplateVariations": variations
                }
            }

            const data = new FormData();
            data.append("key", JSON.stringify(templateData));
            if (this.state.thumbnailFile) {
                data.append("file", this.state?.thumbnailFile);
            };

            if (this.state.templateUuid) {
                const response = await putAuthCommonApi(`/personalization/template/${this.state.templateUuid}`, data);
                if (response.status === 200) {
                    this.setState({ resLoading: false });
                    this.props.history.push({ pathname: `/templates`, search: 'pageSize=10&pageNumber=1' });
                } else {
                    this.setState({ resLoading: false });
                }
            } else {
                const response = await postAuthCommonApi('/personalization/template', data);
                if (response.status === 200) {
                    this.setState({ resLoading: false });
                    this.props.history.push({ pathname: `/templates`, search: 'pageSize=10&pageNumber=1' });
                } else {
                    this.setState({ resLoading: false });
                }
            }
        }

    };


    render() {
        const {
            uploadImage,
            thumbnailFile,
            categoryOptions,
            isCanvasOpen,
            languageOption

        } = this.state;



        return (
            <>

                {isCanvasOpen ?
                    <div className="canvas-section">

                        <CobrandContent
                            onClose={() => this.setState({ isCanvasOpen: false })}
                            onSave={(updatedCanvasData) => this.updateCanvasData(updatedCanvasData)}
                            personalizationTemplateVariationImages={this?.state?.personalizationTemplateVariationImages}
                        />

                    </div>

                    :
                    <section className="add-section">
                        <ComponentHeader
                            title={this.state.templateUuid ? "Update Template" : "Add Template"}
                            primaryBtn="Save"
                            isBackArrow={true}
                            resLoading={this.state.resLoading}
                            isReturn="/templates?pageSize=10&pageNumber=1"
                            handlePrimaryBtn={this.handleSave}
                        />

                        <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
                            <Row>
                                <Col md={4}>
                                    <FormElement
                                        valueLink={this.linkState(this, "templateName")}
                                        label="Template Name"
                                        required
                                        validations={[
                                            {
                                                validate: FormValidator.isRequired,
                                                message: "Name cannot be empty",
                                            },
                                        ]}
                                        control={{
                                            type: CustomTextControl,
                                            settings: {
                                                placeholder: "Enter Name",
                                            },
                                        }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FormElement
                                        valueLink={this.linkState(this, "categoryUuid")}
                                        label="Select Category"
                                        required
                                        validations={[
                                            {
                                                validate: FormValidator.isRequired,
                                                message: "Filter cannot be empty",
                                            },
                                        ]}
                                        control={{
                                            type: SelectControl,
                                            settings: {
                                                placeholder: "Select Category",
                                                options: categoryOptions,
                                                multiple: false,
                                                searchable: true,
                                            },
                                        }}
                                    />
                                    {this.state.filtersUuid?.length > 0 &&
                                        <ClearButton onClick={() => this.setState({ filtersUuid: [] })} />
                                    }
                                </Col>
                            </Row>
                            <Row>

                                <Col md={4}>
                                    <FormElement
                                        valueLink={this.linkState(this, "description")}
                                        label="Description"
                                        helpText="Description can't be more than 4000 character"

                                        control={{
                                            type: CustomTextControl,
                                            settings: {
                                                placeholder: "Please enter description",
                                                as: "textarea",
                                                rows: 5,
                                            },
                                        }}
                                        classes={{
                                            inputField: "custom-input",
                                            label: "custom-label",
                                        }}
                                    />
                                </Col>

                                <Col sm={4}>
                                    <ImageUploader required={true} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: null, uploadImage: null })} />
                                </Col>

                                <Col sm={12}>
                                    <h3 className="inter-semibold f-s-19 lh-24 section-title mt-5">Variations</h3>
                                    <br />
                                    {
                                        this.state.variations?.map((variation, index) => {
                                            return (
                                                <>
                                                    {
                                                        index > 0 &&
                                                        <><hr /><br /></>
                                                    }
                                                    <p className="variation-srno">Variation - {index + 1} </p>
                                                    <Row>


                                                        <Col md={4}>
                                                            <FormElement
                                                                valueLink={this.linkState(this, `variations.${index}.languageUuid`)}
                                                                label="Language"
                                                                required
                                                                validations={[
                                                                    {
                                                                        validate: FormValidator.isRequired,
                                                                        message: "Language cannot be empty",
                                                                    },
                                                                ]}
                                                                control={{
                                                                    type: SelectControl,
                                                                    settings: {
                                                                        placeholder: "Select Language",
                                                                        options: languageOption,
                                                                        multiple: false,
                                                                        searchable: true,
                                                                        onChangeCallback: (onBlur) => {
                                                                            onBlur(this.state.variations[index].languageUuid);
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </Col>


                                                        <Col sm={4}>

                                                            <Button onClick={() => this.openCanvas(index)} className=" primary-btn ml-4 mt-5">
                                                                Open Canvas
                                                            </Button>
                                                        </Col>
                                                        {index === 0 && (
                                                            <Col md={2}>
                                                                {index === 0 && (
                                                                    <div className="form-group mt-5">
                                                                        <label htmlFor={`isDefault-${index}`} className="f-s-16 pt-2 mr-2">Is Default: </label>
                                                                        <input
                                                                            id={`isDefault-${index}`}
                                                                            type="checkbox"
                                                                            className="mr-5 pointer"
                                                                            checked={index === 0 || variation.isDefault} // Always checked for index 0
                                                                            readOnly
                                                                            onChange={() => { }} // No-op as it's always true
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Col>



                                                        )}
                                                        <Col sm={1}>

                                                            <Image src={closeIcon} className="remove-icon" onClick={() => this.removeVariation(index)} />


                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        })
                                    }
                                    <Button onClick={this.addMore} className="primary-btn">Add More +</Button>
                                </Col>

                            </Row>

                        </Form>
                    </section>
                }


            </>
        );
    }
}

const mapStateToProps = (state) => ({
    calenderFilterData: state.SubdomainState.calenderFilterList,
});
const mapDispatchToProps = {
    getAllTemplatesApi,
    getLanguageNameApi,
    getPersonalizationCategoryApi
};
AddEditTemplate.propTypes = {


};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditTemplate);