import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

class OnBoarding extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() { }

    render() {
        return (
            <div>OnBoarding Component</div>
        )
    }
}

const mapStateToProps = state => ({
    onBoardingState: state.OnBoardingState
});
const mapDispatchToProps = {
    // getPosts: fetchPosts
}
OnBoarding.propTypes = {
    // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);