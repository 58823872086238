import React, { Component } from "react";
import { connect } from "react-redux";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Col, Image, Row } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { ComponentHeader, InformationModal, VerifiedModal } from "../common";
import { API_LIMIT, Notifications, START_PAGE, isModuleExist } from "../../utils/Constant";
import { deleteUserGroupApi, getAllNotificationSetupApi } from "./Api";
import NotificationSetupForm from "./NotificationSetupForm";
import editPencil from "../../assets/images/edit-icon.png";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";
import { toast } from "react-toastify";


export class NotificationSetup extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist(Notifications, 'Send');
    this.state = {
      access: AccesData,
      dummyData: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      languageList: [],
      totalPages: null,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,

      isVerify: false,
      show: false,

      verified: '',
      verifyUrl: ''
    };
  }

  // todo---------------------------------------
  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllNotificationSetupApi(this);
  }
  // todo---------------------------------------
  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";
    // const search = params.get("search") || "";

    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllNotificationSetupApi(this);

      });
    }
  }
  // todo:-----------------------------------------

  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state?.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {

    deleteUserGroupApi(id, this, this.openCloseDeleteModal)
  }
  render() {
    const { showEdit, access } = this.state;
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;

    return (
      <section className="request-section">

        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete this ?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        <VerifiedModal
          name={`'${this.state.editData?.firstName}'`}
          show={this.state.show}
          url={+`/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getAllCampaignApi(this) }}
        />
        {
          //   true &&
          showEdit &&
          <NotificationSetupForm
            show={showEdit}
            onHide={this.showEditModal}
            editData={this.state.editData}
            this={this}
          />
        }
        <ComponentHeader
          title="Notification Setup"
          isSearch={false}
          placeholder={"Search setup"}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          primaryBtn={access.createAccess ? "Add Notification Setup" : ''}
          handlePrimaryBtn={() => {

            this.setState({ showEdit: true })
          }}
        />
        <Row>
          <Col sm={2}>
            <FilterVefiedDropDown ctx={this} />
          </Col>
        </Row>
        <div className="commom-table-wrapper">

          <CustomTable
            tableData={listData?.list || []}
            columnList={[

              {
                coumnWidth: 200,
                labelName: "User Name",
                dataKey: "userName",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "userName") {
                    return rowData?.user?.firstName || "Na";
                  }
                },
              },
              {
                coumnWidth: access.verificationAccess ? 100 : 0,
                labelName: "Verified",
                dataKey: "verify",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "verify") {
                    return <div className=" d-flex align-items-center inter-medium">
                      {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                    </div>;
                  }
                },
              },
              {
                coumnWidth: 250,
                labelName: "Premium Plan",
                dataKey: "premiumPlan",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "premiumPlan") {
                    return rowData?.premiumPlan?.name || "Na";
                  }
                },
              },
              {
                coumnWidth: 220,
                labelName: "Notification Templete ",
                dataKey: "notificationTempleteId",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "notificationTempleteId") {
                    return rowData?.notificationTemplete?.title || "Na";
                  }
                },
              },
              {
                coumnWidth: 320,
                labelName: "Scheduled Date/Time ",
                dataKey: "dateTime",
                className: "time",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "dateTime") {
                    const date = new Date(rowData?.dateTime);
                    return date.toLocaleString('en-US', {
                      month: 'long', // Full month name
                      day: 'numeric', // Day of the month
                      year: 'numeric', // Full year
                      hour: 'numeric', // Hour
                      minute: 'numeric', // Minute
                      hour12: true, // Use 12-hour format
                    });
                  }
                },
              },
              {
                coumnWidth: 100,
                labelName: "Triggered",
                dataKey: "isTriggered",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "isTriggered") {
                    return rowData.isTriggered ? 'Yes' : 'No';
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Updated By",
                dataKey: "updatedBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "updatedBy") {
                    return rowData.updatedBy?.name || 'Na';
                  }
                },
              },

              {
                coumnWidth: 150,
                labelName: "Created By",
                dataKey: "createdBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "createdBy") {
                    return rowData.createdBy?.name || 'Na';
                  }
                },
              },

              {
                coumnWidth: 200,
                labelName: "Action",
                dataKey: "",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <div className="d-flex justify-content-end">
                        {access.updateAccess && !rowData.isTriggered && <Image src={editIcon} className="edit-icon" onClick={() => {
                          const rowDateTime = new Date(rowData?.dateTime);
                          const currentDate = new Date();

                          if (rowDateTime < currentDate) {
                            toast.error("Notification already sent.");
                          } else {
                            this.showEditModal(rowData);
                          }
                        }} />}
                        {access.deleteAccess && <Image src={deleteIcon} className="edit-icon"
                          onClick={() => {
                            const rowDateTime = new Date(rowData?.dateTime);
                            const currentDate = new Date();

                            if (rowDateTime < currentDate) {
                              toast.error("Notification already sent.");
                            } else {
                              this.openCloseDeleteModal(rowData);
                            }
                          }}
                        />}
                      </div>
                    );
                  }
                },
              },
            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getAllNotificationSetupApi
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSetup);
