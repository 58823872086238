import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";


function CustomTab({ data }) {
 
  const [activeTab, setActiveTab] = useState();
  const history = useHistory();
  const location = useLocation();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", tab);
    

    history.replace({
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    console.log("ue effect loaded",data[0].route)
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");  
    if (tab) {
      setActiveTab(tab);
    }
    else{
      handleTabChange(data[0].route);
    }
  }, [location]);

  return (
    <Tabs
      defaultActiveKey={activeTab}
      onSelect={handleTabChange}
      className="tab-title"
      id="uncontrolled-tab-example"
    >
      {data?.map((item, i) => {
        return (
          <Tab
            key={i}
            eventKey={item.route}
            title={item.name}
          >
            {item.component}
          </Tab>
        );
      })}
    </Tabs>
  );
}

export default CustomTab;
