import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseReactComponent, Form, FormElement, FormSubmitButton, FormValidator, SelectControl } from "../../utils/form";
import { Table, Image, Row, Col, Tabs, Tab, Button, Modal } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { getDetaisContentEventApi, getDetaisCustomerApi, getUserTrackingApi } from "./Api";
import closeIcon from '../../assets/images/greay-cancel-icon.svg';
import moment from "moment";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { ALT_THUMBNAIL, API_LIMIT, MAX_LIMIT, START_PAGE, durationObj } from "../../utils/Constant";
import { getCommonApi, postAuthCommonApi } from "../content/Api";
import Pagination from "../../utils/commonComponent/Pagination";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
class ViewSsoUser extends BaseReactComponent {
  constructor(props) {
    super(props);
    const { params } = props.match;
    const ssoPartnerId = params.ssoPartnerId;
    const ssoData = this.props?.location?.state?.id;
    const userType = this.props?.location?.state?.type;
    console.log(props)

    this.state = {
      params,
      detailsData: "",
      activeTab: '',
      // data: ssoData,
      type: userType,
      ssoId: ssoData,
      ssoPartnerId
    };
    // this.handleChildAction = this.handleChildAction.bind(this);
  }

  // handleChildAction() {
  //     getDetaisCustomerApi("customer", this);
  // }
  // handleChildAction() {
  //   getDetaisContentEventApi("/sso/web-user/details", this);
  // }

  componentDidMount = () => {
    const userType = this.state.type || sessionStorage.getItem('type');

    getDetaisContentEventApi(
      `${userType === "admin" || userType === "admin-web-user"
        ? "/sso/web-user/admin/details"
        : "/sso/web-user/details"
      }`,
      this
    );

    // this.props.getUserTrackingApi(this);
    this.props.history.push({ search: 'tab=details' })
    console.log(this, "this usersss")



  }
  // componentDidUpdate(prevProps) {
  //   if (this.props.location.search !== prevProps.location.search) {

  //     const newType = sessionStorage.getItem("type");
  //     this.setState({ type: newType })

  //   }
  // }
  componentWillUnmount() {
    // sessioStorage.removeItem("detail");
    sessionStorage.removeItem("type");

  }



  detailsTab = (data) => {

    return (
      <div className="view-details-content">
        <h3 className="inter-regular f-s-14 grey-2">
          Details
        </h3>
        <div className="line2"></div>
        <Table className="custom-table">
          <Row>
            <Col sm={3} xs={12}>
              <div className="detail">
                <h4 className="inter-semibold grey-2 ">First Name</h4>
                <h3 className="inter-semibold f-s-14 ">{data?.firstName} </h3>
              </div>
            </Col>
            <Col sm={3} xs={12}>
              <div className="detail">
                <h4 className="inter-semibold grey-2 ">Last Name</h4>
                <h3 className="inter-semibold f-s-14 ">{data?.lastName} </h3>
              </div>
            </Col>
            <Col sm={3} xs={12}>
              <div className="detail">
                <h4 className="inter-semibold grey-2 ">Email</h4>
                <h3 className="inter-semibold f-s-14 ">{data?.emailAddress || "NA"}</h3>
              </div>
            </Col>
            <Col sm={3} xs={12}>
              <div className="detail">
                <h4 className="inter-semibold grey-2 ">Phone no.</h4>
                <h3 className="inter-semibold f-s-14 ">{data?.mobileNumber || "Na"}</h3>
              </div>
            </Col>


            <Col sm={3} xs={12}>
              <div className="detail">
                <h4 className="inter-semibold grey-2 ">
                  Company Name
                </h4>
                <h3 className="inter-semibold f-s-14 ">{data?.companyName || "Na"}</h3>
              </div>
            </Col>
            <Col sm={3} xs={12}>
              <div className="detail">
                <h4 className="inter-semibold grey-2 ">
                  ARN Number
                </h4>
                <h3 className="inter-semibold f-s-14 ">{data?.arnNumber || "Na"}</h3>
              </div>
            </Col>
          </Row>
        </Table>
      </div>
    )
  }




  returnDate(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  }

  render() {
    const { data, activeTab, params, ssoId, ssoPartnerId } = this.state;
    const userType = this.state.type || sessionStorage.getItem('type');

    const { trackingList, totalPages, currentPage } = this.props.trackingState;
    // console.log(data)
    return (
      <section className="user-section">
        <div className="view-details-wrapper">
          <ComponentHeader
            title={`Customer Details`}
            isBackArrow={true}
            isReturn={
              userType === "admin"
                ? `/sso/view-users/${ssoPartnerId}`
                : userType === "admin-web-user"
                  ? "/customers"
                  : "/sso/web-user"}


          />
          <div className="view-details">
            <Tabs
              defaultActiveKey={'Details'}
              onSelect={(d) => { this.setState({ activeTab: d }); this.props.history.push({ search: 'tab=' + d }) }}
              className="inter-regular f-s-16 lh-22 user-details-tabs"
              id=""
            >
              <Tab eventKey={'Details'} title={'Details'}>
                {this.detailsTab(data)}
              </Tab>

              <Tab eventKey={'Activity'} title={'Activity'}>
                <MyActivity data={data} />

              </Tab>
              <Tab eventKey={'Subscription'} title={'Subscription'}>
                <CustomerSubscription data={data?.userSubscription} handleAction={this.handleChildAction} id={params.adsID} />
              </Tab>
              <Tab eventKey={'userEvents'} title={'User Events'}>
                {/* {data?.userEvents?.length > 0 ? data?.userEvents?.map((item => this.detailsTab1(item))) : <h3 className="inter-regular f-s-24 lh-32 py-5 text-center"> There is not event! </h3>} */}
                <MyEvents data={data?.userEvents} />
              </Tab>



            </Tabs>

          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ trackingState: state.SsoContentEventState });
const mapDispatchToProps = {
  // getPosts: fetchPosts
  // getUserTrackingApi

};
ViewSsoUser.propTypes = {
  // getPosts: PropTypes.func
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSsoUser);


function MyActivity({ data }) {
  const navigate = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [activityType, setActivityType] = useState('Liked');
  const [activityData, setActivityData] = useState([]);
  const [activityCount, setActivityCount] = useState(0);
  const uniqueTypes = ['Cobrand', 'Downloaded', 'Liked', 'Shared']; // List of all unique types
  const [currentPage, setCurrentPage] = useState(1);

  const TabsNames = [{ name: 'Downloads', type: "Downloaded" }, { name: "Co-Brands", type: "Cobrand" }, { name: "Likes", type: "Liked" }, { name: "Shares", type: "Shared" }, { name: "Recent Search", type: "Search" },]
  useEffect(async () => {

    const userId = data?.id
    const Type = (TabsNames.find((ele => ele.name === activityType)))?.type || 'Downloaded';
    if (userId) {
      const res = await getCommonApi(`sso/web-user/tracking?userId=${userId}&type=${activityType}&pageNumber=${params.get('p') || START_PAGE}&pageSize=${API_LIMIT}`);
      // console.log(res)

      setActivityData(res.data.data?.list)
      setActivityCount(res?.data?.data?.totalCount)

    }

  }, [activityType, data])
  return (
    <div className="activity-tabs-section">
      <Tabs
        defaultActiveKey={'Liked'}
        onSelect={(data) => { setActivityType(data) }}
        className="inter-regular f-s-16 lh-22 user-details-tabs bg-ECE"
        id=""
      >

        {uniqueTypes.map((type) => {
          const filteredData = activityData?.filter(item => item.type === type);

          return (
            <Tab eventKey={type} title={type} key={type}>
              {filteredData?.length > 0 ? (
                <div className="commom-table-wrapper">
                  <CustomTable
                    tableData={filteredData || []}
                    columnList={[
                      {
                        coumnWidth: 300,
                        labelName: "Title",
                        dataKey: "tile",
                        className: "name",
                        isCell: true,
                        cell: (rowData, dataKey) => {
                          if (dataKey === "tile") {
                            return rowData?.tile;
                          }
                        },
                      },

                      {
                        coumnWidth: 300,
                        labelName: "Content Type",
                        dataKey: "contentType",
                        className: "name",
                        isCell: true,
                        cell: (rowData, dataKey) => {
                          if (dataKey === "contentType") {
                            return rowData?.contentType;
                          }
                        },
                      },
                      // {
                      //     coumnWidth: 300,
                      //     labelName: "Thumbnail Image",
                      //     dataKey: "thumbnail",
                      //     className: "",
                      //     isCell: true,
                      //     cell: (rowData, dataKey) => {
                      //         if (dataKey === "thumbnail") {
                      //             return rowData.thumbnail ? (
                      //                 <Image
                      //                     src={rowData.thumbnail}
                      //                     className="table-img"
                      //                     alt={ALT_THUMBNAIL}
                      //                 />
                      //             ) : (
                      //                 "NA"
                      //             );
                      //         }
                      //     },
                      // },

                    ]}
                    message="No data found" // For Pagination
                    history={navigate}
                    location={location}
                    totalPages={Math.ceil(activityCount / API_LIMIT)}
                    currentPage={parseInt(params.get('p')) || START_PAGE}
                  />

                </div>
              ) : (
                <h1 className="inter-regular f-s-24 lh-32 text-center py-4 w-100">
                  No data...
                </h1>
              )}
            </Tab>
          );
        })}



      </Tabs>
    </div>
  )
}
class CustomerSubscription extends BaseReactComponent {
  constructor(props) {
    super(props);
    // console.log(props)
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      activeHistory: false,
      show: false,
      plan: '',
      offerLine: '',
      billingFrequency: '',
      plansData: [],
      subPremiumPlan: [],
      subPremiumPlanVariation: [],

      subscriptionDetails: [],
      userDetails: JSON.parse(localStorage.getItem('finAdmin'))

    };
  }
  handleClick() {
    this.props.handleAction();
  }






  render() {
    // const { show, plansData, subPremiumPlan, subPremiumPlanVariation, offerLine, plan } = this.state;
    const { data } = this.props;

    console.log("Data of tablle :-", data)

    return (
      <div className="activity-tabs-section">
        {/* =============== modal============== */}

        {/* =============== modal end============== */}

        <div className="commom-table-wrapper">
          <CustomTable
            tableData={data || []}
            columnList={[
              {
                coumnWidth: 150,
                labelName: "Name",
                dataKey: "premiumPlan",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "premiumPlan") {
                    return rowData?.premiumPlan;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Plan Name",
                dataKey: "subPremiumPlan",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "subPremiumPlan") {
                    return rowData?.subPremiumPlan;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Variation",
                dataKey: "subPremiumPlanVariation",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "subPremiumPlanVariation") {
                    return rowData?.subPremiumPlanVariation;
                  }
                },
              },



              {
                coumnWidth: 90,
                labelName: "isTopup",
                dataKey: "isTopup",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "isTopup") {
                    return rowData?.isTopup ? "Yes" : "No";
                  }
                },
              },
              {
                coumnWidth: 170,
                labelName: "Is Paid",
                dataKey: "Paid",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "Paid") {
                    return rowData?.isPaid ? "Yes" : 'No';
                  }
                },
              },
              {
                coumnWidth: 170,
                labelName: "Is Active",
                dataKey: "Active",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "Active") {
                    return rowData?.isActive ? "Yes" : 'No';
                  }
                },
              },
              {
                coumnWidth: 170,
                labelName: "Start Date",
                dataKey: "startDate",
                className: "startDate",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "startDate") {
                    return moment(rowData?.startDate).format('lll');
                  }
                },
              },
              {
                coumnWidth: 170,
                labelName: "End Date",
                dataKey: "endDate",
                className: "endDate",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "endDate") {
                    return moment(rowData?.endDate).format('lll');
                  }
                },
              },
              {
                coumnWidth: 200,
                labelName: "Action",
                dataKey: "endDate",
                className: "endDate",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "endDate") {
                    return <Button onClick={() => this.setState({ activeHistory: true, subscriptionDetails: rowData })} className="primary-btn" alt=""> View Details </Button>
                  }
                },
              },

            ]}
          />
        </div>
        <Modal className="subscription-details-modal" show={this.state.activeHistory} onHide={() => this.setState({ activeHistory: false })}>

          <Modal.Body className="sub-modal-body-modal py-5">
            <Image src={closeIcon} onClick={() => this.setState({ activeHistory: false })} alt="canle icon" className="close-btn pointer" />
            <h4 className="inter-regular  black-242 w-100 mb-3 text-start primary">Invoices</h4>
            <table className="w-100 invoice-table-frame">
              <thead>
                <tr className="invoice-header-row">
                  <th className=" inter-regular ">Id</th>
                  {/* <th className=" inter-regular ">Plan</th> */}
                  <th className=" inter-regular ">Date</th>
                  <th className=" inter-regular ">Amount</th>
                  <th className=" inter-regular ">Status</th>
                  <th className=" inter-regular ">Download</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.subscriptionDetails?.userBillingPlan?.map((ele, i) => {
                    return (
                      <tr className="invoice-body-row">
                        <td className=" inter-regular ">{i + 1}</td>
                        {/* <td className=" inter-regular ">{i + 1}</td> */}
                        <td className=" inter-regular ">{moment(ele?.startDate).format('lll')}</td>
                        <td className=" inter-regular ">{ele.amountPaid}</td>
                        <td className=" inter-regular ">{ele.isActive ? 'Yes' : 'No'}</td>
                        <td className=" inter-regular "><a href={ele?.invoicePdfPath} target="_blank" className=" ponter">Download</a></td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function MyEvents({ data }) {

  return (
    <div className="commom-table-wrapper">
      <CustomTable
        tableData={data || []}
        columnList={[
          {
            coumnWidth: 150,
            labelName: "Name",
            dataKey: "name",
            className: "name",
            isCell: true,
            cell: (rowData, dataKey) => {
              if (dataKey === "name") {
                return rowData?.event?.name;
              }
            },
          },
          {
            coumnWidth: 150,
            labelName: "Event Format",
            dataKey: "eventFormat",
            className: "name",
            isCell: true,
            cell: (rowData, dataKey) => {
              if (dataKey === "eventFormat") {
                return rowData?.event?.eventFormat;
              }
            },
          },
          {
            coumnWidth: 150,
            labelName: "Event Type",
            dataKey: "eventType",
            className: "name",
            isCell: true,
            cell: (rowData, dataKey) => {
              if (dataKey === "eventType") {
                return rowData?.event?.eventType;
              }
            },
          },

          {
            coumnWidth: 170,
            labelName: "Is Paid",
            dataKey: "Paid",
            className: "name",
            isCell: true,
            cell: (rowData, dataKey) => {
              if (dataKey === "Paid") {
                return rowData?.isPaid ? "Yes" : 'No';
              }
            },
          },

          {
            coumnWidth: 200,
            labelName: "Start Date",
            dataKey: "startDate",
            className: "startDate",
            isCell: true,
            cell: (rowData, dataKey) => {
              if (dataKey === "startDate") {
                return moment(rowData?.event?.startDate).format('lll');
              }
            },
          },
          {
            coumnWidth: 200,
            labelName: "End Date",
            dataKey: "endDate",
            className: "endDate",
            isCell: true,
            cell: (rowData, dataKey) => {
              if (dataKey === "endDate") {
                return moment(rowData?.event?.endDate).format('lll');
              }
            },
          },


        ]}
      />
    </div>

  )

}



