import React from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Button, Col, Image, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { ALT_THUMBNAIL, API_LIMIT, START_PAGE, isModuleExist } from "../../utils/Constant";
import { InformationModal, ComponentHeader, VerifiedModal, Spinner } from "../common";
import { deleteAuthCommonApi, getAllContentApi } from "./Api";
import editPencil from "../../assets/images/edit-icon.png";
import { toast } from "react-toastify";
import { replaceHistory } from "../../utils/ReusableFunctions";
import { CONTENT_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";
class ContentType extends BaseReactComponent {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist("Content", 'Content');
    this.state = {
      access: AccesData,
      search: "",
      dummyData: [],
      searchResult: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,
      isLoading: true,

      isVerify: false,
      show: false,

      verified: '',
      verifyUrl: ''
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllContentApi(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevSearch = prevParams.get("search") || "";
    const prevVerified = prevParams.get("isVerified") || "";

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const search = params.get("search") || "";
    const verified = params.get("isVerified") || "";

    if (prevPage !== page || prevSearch !== search || prevVerified !== verified) {
      this.setState({ currentPage: page, search }, () => {
        this.props.getAllContentApi(this);
      });
    }
  }
  onChangeMethod = (value) => {
    this.setState({ searchText: value.search, currentPage: START_PAGE }, () => {
      replaceHistory(this.props.history, START_PAGE, value.search);
    })
  };
  clearSearch = () => {
    this.onChangeMethod({ search: "" });
  }
  getDetailsUser = (id) => {
    this.props.history.push({ pathname: `/ads-customer-details/${id}`, state: { "id": id } });
  }



  showEditModal = (rowData = null) => {
    this.props.history.push({ pathname: "/content/add-content", state: { id: rowData.uuid } });
    // this.setState({
    //   showEdit: !this.state.showEdit,
    //   editData: rowData,
    // });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = async (id) => {
    const res = await deleteAuthCommonApi(`content/${id}`);
    // console.log(res)
    if (res.status === 200) {
      this.props.getAllContentApi(this);
      this.setState({
        delete: !this.state.delete
      });
      // toast.success("Successfully Deleted.")
    } else {
      toast.error("Somthis wrong...")
    }
    // deleteAmcApi(id, this, this.openCloseDeleteModal) updat this
  }
  getDetailsUser = (id) => {
    this.props.history.push({ pathname: `/content-details/${id}`, state: { "id": id } });
  }
  render() {
    const { dummyData, search, serchResult, tabName, activeTab, access, isLoading } = this.state;
    const { contentList, totalPages, currentPage } = this.props.contentState;
    console.log('from action reduction list', contentList)
    return (
      <>
        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.title}?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        <VerifiedModal
          // name={`this '${this.state.editData?.campaignName}' campaign.`}
          name={`'${this.state.editData?.title}'`}
          show={this.state.show}
          url={CONTENT_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getAllContentApi(this) }}
        />
        <section className="request-section">
          <ComponentHeader
            title="Content"
            isSearch={true}
            placeholder={"Search Content"}
            onChangeMethod={this.onChangeMethod}
            clearSearch={() => this.clearSearch()}
            primaryBtn="Add Content"
            handlePrimaryBtn={() => {
              this.props.history.push("/content/add-content");
            }}
          />
          <div className="">
            <Row>

              <Col sm={2}>
                <FilterVefiedDropDown ctx={this} />
              </Col>
            </Row>
          </div>
          <div className="commom-table-wrapper">
            <CustomTable
              tableData={isLoading ? [] : contentList?.list || []}
              columnList={[

                {
                  coumnWidth: 150,
                  labelName: "Title",
                  dataKey: "title",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "title") {
                      return rowData?.title || '';
                    }
                  },
                },
                {
                  coumnWidth: access.verificationAccess ? 80 : 0,
                  labelName: "Verified",
                  dataKey: "verify",
                  className: "name",
                  // isFilter:true,
                  // handleSortByCol:()=>console.log("sort aplycable"),
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "verify") {

                      return <div className=" d-flex align-items-center inter-medium">
                        {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                      </div>;
                    }
                  },
                },
                {
                  coumnWidth: 120,
                  labelName: "AMC",
                  dataKey: "amc",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    // console.log(rowData?.amc ?? "happy")
                    if (dataKey === "amc") {
                      return rowData?.amc?.amcName || ''
                    }
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Content Type",
                  dataKey: "contentType",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "contentType") {
                      return rowData?.contentType?.contentTypeName || "";
                    }
                  },
                },
                {
                  coumnWidth: 120,
                  labelName: "Thumbnail Image",
                  dataKey: "thumbnail",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "thumbnail") {
                      return (rowData.thumbnail ? <Image src={rowData.thumbnail} className="table-img" alt={ALT_THUMBNAIL} /> : "NA");
                    }
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Category",
                  dataKey: "category",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "category") {
                      return rowData?.category?.categoryName || ''
                    }
                  },
                },

                {
                  coumnWidth: 110,
                  labelName: "Updated By",
                  dataKey: "updatedBy",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "updatedBy") {
                      return rowData?.updatedBy?.name || 'Na';;
                    }
                  },
                },
                {
                  coumnWidth: 110,
                  labelName: "Created By",
                  dataKey: "createdBy",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "createdBy") {
                      return rowData?.createdBy?.name || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 350,
                  labelName: "Action",
                  dataKey: "",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "") {
                      return (
                        <>
                          <div className="d-flex justify-content-evenly">
                            <Button onClick={() => this.getDetailsUser(rowData?.uuid)} className="primary-btn inter-medium f-s-14 lh-18 mr-5">
                              View Detail
                            </Button>
                            {access.updateAccess && <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />}
                            {access.deleteAccess && <Image src={deleteIcon} className="pointer" onClick={() => { this.openCloseDeleteModal(rowData) }} />}
                          </div>
                        </>
                      );
                    }
                  },
                },
              ]}
              message="No data found" // For Pagination
              history={this.props.history}
              location={this.props.location}
              totalPages={totalPages}
              currentPage={currentPage}
            />
            {isLoading ?
              <div className="inter-medium f-s-18 lh-22 text-center py-5 d-flex align-items-center justify-content-center gap-12"> <Spinner classes={'black-border'} /> Loading...</div>
              : (contentList.length === 0) ?
                <div className="inter-medium f-s-18 lh-22 text-center py-5"> Result is not found.</div> : ''
            }
          </div>
        </section>
      </>
    );
  }
}


const mapStateToProps = (state) => ({
  contentState: state.ContentState,
});
const mapDispatchToProps = {
  getAllContentApi,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContentType));
