export const CAMPAGIN_VERIFY = 'campaign/verify';
export const CATEGORY_VERIFY = 'category/verify';
export const SUBCATEGORY_VERIFY = 'subCategory/verify';
export const KEYWORD_VERIFY = 'keyword/verify';
export const COLOR_VERIFY = 'color/verify';
export const LANGUAGE_VERIFY = 'languages/verify';
export const SOCIALMEDIA_VERIFY = 'socialMedia/verify';
export const TARGETED_CONTENT_VERIFY = 'targetedContent/verify';
export const POSTOFTHEDAY_VERIFY = 'postOfTheDay/verify';
export const COUPON_VERIFY = 'coupon/verify';
export const EVENT_AD_VERIFY = 'eventAd/verify';
export const CONTENT_AD_VERIFY = 'contentAd/verify';
export const CONTENT_VERIFY = 'content/verify';
export const BANNER_AD_VERIFY = 'bannerAd/verify';
export const TRENDING_VERIFY = 'trending/verify';
export const INAPPNOTIFFICATION_VERIFY = 'inAppNotification/verify';
export const VIDEO_POSITION_VERIFY = 'videoPosition/verify';
export const DISPLAY_POSITION_VERIFY = 'displayPosition/verify';
export const UPLOAD_SECTION_VERIFY = 'uploadSection/verify';
export const BRANDING_VERIFY = 'branding/verify';
export const TRENDING_KEYWORD_VERIFY = 'trendingKeyword/verify';
export const USER_ROLE_VERIFY = 'userRole/verify';
export const EVENT_CATEGORY_VERIFY = 'eventCategory/verify';
export const EVENT_SPEAKER_VERIFY = 'eventSpeaker/verify';
export const EVENT_VERIFY = 'events/verify';
export const CONTENT_TYPE_VERIFY = 'contentType/verify';
export const EVENT_ORGANISER_VERIFY = 'eventOrganiser/verify';
export const CONTENT_CREATOR_VERIFY = 'contentCreator/verify';
export const AMC_VERIFY = 'amc/verify';
export const NOTIFICATION_GROUP_VERIFY = 'notification-group/verify';
export const PREMIUM_PLAN_VERIFY = 'sub-premium-plan/verify';
export const PLAYLIST_VERIFY = 'playlist/verify';
export const PLATFORM_VERIFY = 'platform/verify';
export const OCCASSION_VERIFY = 'playlist/content/verify';
export const SSO_VERIFY = 'sso/verify';
export const SUBDOMAIN_VERIFY = 'sub-domain/verify';
export const CALENDER_VERIFY = 'calender/verify';
export const CALENDER_FILTER_VERIFY = 'calender-filter/verify';
export const PERSONALIZATION_CATEGORY_VERIFY = 'personalization/category/verify';
export const TEMPLATE_VERIFY = 'personalization/template/verify';










// export const  USER_ROLE_VERIFY='userRole/verify';
// export const  _VERIFY='coupon/verify';