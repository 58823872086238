import React from "react";
import { connect } from "react-redux";

import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  SelectControl
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { getAllAmcApi } from '../MasterCategory/Api';
import "react-datepicker/dist/react-datepicker.css";
import { getAllPlatformDataApi } from "./Api";
import { ClearButton, ImageUploader } from "../common";
import { getAllContentCreatorApi, getCommonApi, getDetailsApi, postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import ReactDOM from "react-dom";
import { MAX_LIMIT, MUTUAL_FUND_COMPANY, SUPER_ADMIN, Thefinpedia, uploadFrequency } from "../../utils/Constant";
import { userAmcUuids, userCreatorUuids, userRoleType } from "../../utils/ReusableFunctions";
import { SearchContent } from "../content";
import { toast } from "react-toastify";
class AddEditPlaylist extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props?.location.state?.data || null;
    const roleType = userRoleType() || SUPER_ADMIN;
    const orderedLabels = ["Daily", "Weekly", "Bi-Weekly", "Fortnightly", "As and When"];

    this.state = {
      editData: data,
      roleType,
      playlistUuid: data?.uuid || "",
      isUpdated: (props?.location.state ?? false) || false,
      title: data?.title || "",
      uploadFrequency: data?.uploadFrequency || "",
      isAmcShow: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
      creatorList: [],
      isAddContent: true,
      thumbnailPath: "",
      thumbnailFile: null,
      uploadImage: data?.thumbnailPath || null,
      amc: data?.amc?.uuid || (userAmcUuids().length > 0 ? userAmcUuids() : ''),
      creator: data?.contentCreator?.uuid || (userCreatorUuids() || ''),
      amcOption: [],
      platformOptions: [],
      platformUuid: data?.platforms?.map((item) => item?.uuid) || [],
      showSearchContent: false,
      selectedContent: data?.contents ? data?.contents : [],
      search: '',
      locationSearch: '',
      resLoading: false,
      loading: false,
      checklist: [],
      selectedItems: [],
      pageSize: MAX_LIMIT,

      frequencyOption: uploadFrequency
        .sort((a, b) => orderedLabels.indexOf(a.label) - orderedLabels.indexOf(b.label))
        .map(option => ({
          value: option.value,
          label: option.label
        }))
    };


  }

  imageUploaser = (e, name) => {
    // console.log(typeof (e.target.files[0]))
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })

  }

  componentDidMount() {
    this.fetchData()
    this.props.getAllAmcApi(this);
    getAllContentCreatorApi(this);
    this.props.getAllPlatformDataApi(this);
    if (this.props?.location?.state?.id) {
      this.setState({ loading: true })

      getDetailsApi("/playlist", this).finally(() => {
        this.setState({ loading: false })
      });

    };



  }
  fetchData = async () => {
    try {
      const response = await getCommonApi("website/playlist/content-types");

      if (response.status === 200) {
        const data = response?.data?.data;
        console.log("API Data:", data);

        // Check if the data is an array before filtering
        const selectedItems = Array.isArray(data) ? data.filter(item => item.selected) : [];

        this.setState({
          checklist: data?.list || [], // Assuming data.list should be an array
          selectedItems
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  componentDidUpdate(prevProps, prevState) {
    if (this.props.platformData !== prevProps.platformData) {
      const platformOptions = this.props.platformData?.list?.map(platform => ({
        value: platform.uuid,
        label: platform.platformName
      }));
      this.setState({ platformOptions });
    }

    if (this.state.creatorList !== prevState.creatorList) {
      if (userCreatorUuids()) {
        const isExist = this.state.creatorList?.find(item => userCreatorUuids().split(',')?.includes(item.value));
        this.setState({ isAmcShow: isExist?.label?.includes(MUTUAL_FUND_COMPANY) });
      }
    }

    if (this.state.creatorList !== prevState.creatorList) {
      const editData = this.props?.location.state?.data;
      if (userCreatorUuids() || editData?.contentCreator?.uuid) {
        const isExist = this.state.creatorList?.find(item => editData?.contentCreator?.uuid === item.value);
        const isShowAmc = isExist?.label?.includes(MUTUAL_FUND_COMPANY);
        const isShowRegister = isExist?.label?.includes(Thefinpedia);
        this.setState({ isAmcShow: isShowAmc, isRegistration: !isShowRegister });
      }
    }
  }
  handlePageSize = (pageSize) => {
    // console.log("Heyyy pageSize", pageSize);
  };
  handleSave = () => {
    ReactDOM.findDOMNode(this.form).dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  handleItemCheckboxChange = (option) => {
    const { selectedItems } = this.state;
    const isSelected = selectedItems.some(item => item.uuid === option.uuid);

    if (isSelected) {
      this.setState({
        selectedItems: selectedItems.filter(item => item.uuid !== option.uuid)
      });
    } else {
      this.setState({
        selectedItems: [
          ...selectedItems,
          {
            uuid: option.uuid,
            priority: "",
            ...(option.socialMediaName ? { isSocialMedia: true } : {}),
            ...(option.categoryName ? { isCategory: true } : {})
          }
        ]
      });
    }
  };


  handlePriorityKeyChange = (event, option) => {
    const { selectedItems } = this.state;
    const value = event // Safely access event.target.value
    const numValue = parseInt(value, 10);

    // Check if the priorityKey is within the allowed range and not already used
    const isValueInRange = numValue >= 1 && numValue <= this.state.checklist.length;
    const isValueUnique = !selectedItems.some(item => item.priority === numValue);

    this.setState({
      selectedItems: selectedItems.map(item =>
        item.uuid === option.uuid ? { ...item, priority: numValue } : item
      )
    });

  };

  onValidSubmit = async () => {
    this.setState({ resLoading: true });
    const { title, uploadFrequency, isPremium, creator, amc, platformUuid, selectedContent } = this.state;

    // Transform platformUuid and selectedContent to the required format
    const platforms = platformUuid?.map(uuid => ({ uuid }));
    const contents = selectedContent?.map(content => ({ uuid: content.uuid }));
    const requiredFields = [
      title,
      uploadFrequency,
      creator,
      platforms,
      this.state?.thumbnailFile
    ]
    const emptyField = requiredFields.some(field => field === "" || field == null);
    if (emptyField) {
      toast.error("Please fill out all required fields.");
      this.setState({ resLoading: false });


    }
    else {
      const playlistData = {
        "data": {
          "title": title,
          "isPremium": isPremium === "true",
          "uploadFrequency": uploadFrequency,
          ...(this.state.amc && {
            "amc": {
              "uuid": this.state.amc
            }
          }),
          "contentCreator": { "uuid": creator },
          "platforms": platforms,
          "contentTypes": this.state.selectedItems,

        }
      };
      const data = new FormData();
      data.append("key", JSON.stringify(playlistData));
      if (this.state.thumbnailFile) {
        data.append("file", this.state?.thumbnailFile);
      }
      if (this.state.playlistUuid) {
        const response = await putAuthCommonApi(`playlist/${this.state.playlistUuid}`, data);
        if (response.status === 200) {
          this.setState({ resLoading: false });
          this.props.history.push({ pathname: `/playlist`, search: 'pageSize=10&pageNumber=1' });
        } else {
          this.setState({ resLoading: false });
        }
      } else {
        const response = await postAuthCommonApi('/playlist', data);
        if (response.status === 200) {
          this.setState({ resLoading: false });
          this.props.history.push({ pathname: `/playlist`, search: 'pageSize=10&pageNumber=1' });
        } else {
          this.setState({ resLoading: false });
        }
      }
    }
  };


  render() {
    const {
      uploadImage,
      thumbnailFile,
      amcOption,
      platformOptions,
      loading,
      checklist,
      selectedItems
    } = this.state;
    // const {platformData}=this.props.autoShareState

    if (loading) {
      return (
        <div>loading...</div>
      )
    }
    return (
      <section className="add-section">
        <ComponentHeader
          title={this.state.playlistUuid ? "Update Playlist" : "Add Playlist"}
          primaryBtn="Save"
          isBackArrow={true}
          resLoading={this.state.resLoading}
          isReturn="/playlist?pageSize=10&pageNumber=1"
          handlePrimaryBtn={this.handleSave}
        />
        {this.state.showSearchContent &&
          <SearchContent
            showModal={this.state.showSearchContent}
            onHide={(selectedContent) => this.setState({ showSearchContent: false, selectedContent })}
            selectedContent={this.state.selectedContent}
            isMultiselect={true}
          />
        }
        <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "title")}
                label="Title"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Title cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter title",
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "uploadFrequency")}
                label="Upload Frequency"
                required
                control={{
                  type: SelectControl,
                  multiple: false,
                  settings: {
                    options: this.state.frequencyOption,
                    placeholder: "Select Upload Frequency",
                    onChangeCallback: (onBlur) => {
                      // Reset all priorities when uploadFrequency changes
                      this.setState({
                        selectedItems: this.state.selectedItems.map(item => ({
                          ...item,
                          priority: null // Resetting priority to null or any default value
                        })),
                      }, () => {
                        // Call onBlur after state update to ensure UI reflects changes
                        onBlur(this.state.uploadFrequency);
                      });
                    },
                  },
                }}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "creator")}
                label="Content Creator"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Creator cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Creator",
                    options: this.state.creatorList,
                    multiple: false,
                    searchable: true,
                    disabled: (userAmcUuids()?.length > 0),
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.creator);
                      const isExest = this.state.creatorList?.find(item => this.state.creator?.includes(item.value))
                      const isShowAmc = isExest?.label?.includes(MUTUAL_FUND_COMPANY);
                      const isShowRegister = isExest?.label?.includes(Thefinpedia);
                      this.setState({ isAmcShow: isShowAmc, isRegistration: !isShowRegister })
                    },
                  },
                }}
              />
            </Col>

            {(this.state.isAmcShow || (userAmcUuids()?.length > 0)) &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "amc")}
                  label="AMC"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "AMC cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Enter AMC Name",
                      options: (userAmcUuids()?.length > 0) ? amcOption?.filter((ele) => userAmcUuids()?.some(item => item === ele.value)) : amcOption,
                      multiple: false,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.amc);
                      },
                    },
                  }}
                />
              </Col>
            }
          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "platformUuid")}
                label="Platform"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Platform cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Platform",
                    options: platformOptions,
                    multiple: true,
                    searchable: true,
                  },
                }}
              />
              {this.state.platformUuid?.length > 0 &&
                <ClearButton onClick={() => this.setState({ platformUuid: [] })} />
              }
            </Col>
            <Col sm={6}>
              <ImageUploader required={true} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} />
            </Col>
            <hr />
            <Col md={12}>
              <div className="form-group " style={{ marginTop: "2rem" }}>
                <div className="form-checkbox-header d-flex ">
                  <label style={{ width: "33rem" }} htmlFor="" className="form-label ">Content Types</label>
                  <label className="form-label">Select Sequence</label>
                </div>
                {checklist?.filter(option =>
                  this.state.uploadFrequency == "" ||
                  (this.state.uploadFrequency == "0.00" && option.isAvailableCustomizedForPostPlanner) ||
                  (this.state.uploadFrequency != "0.00" && !option.isAvailableCustomizedForPostPlanner)
                )
                  ?.map((option, index) => (
                    <div key={index} className="form-checkbox-item d-flex " style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <div>
                        <input
                          type="checkbox"
                          className=""
                          id={`contentCheck${index}`}
                          checked={selectedItems.some(item => item.uuid === option.uuid)}
                          onChange={() => this.handleItemCheckboxChange(option)}
                        />
                        <label className="form-label mt-1" htmlFor={`contentCheck${index}`} style={{ marginLeft: '8px', marginRight: '8px', width: "30rem" }}>
                          {option?.socialMediaName || option?.categoryName}
                        </label>
                      </div>
                      {selectedItems?.some(item => item.uuid === option.uuid) && (
                        <div style={{ flex: 1, maxWidth: '120px', marginBottom: '8px' }}>
                          <FormElement
                            valueLink={{
                              value: selectedItems.find(item => item.uuid === option.uuid)?.priority || "",
                              requestChange: (e) => this.handlePriorityKeyChange(e, option)
                            }}
                            label=""
                            required
                            validations={[
                              {
                                validate: (value) => {
                                  const filteredChecklist = checklist?.filter(option =>
                                    this.state.uploadFrequency !== 10 || option.isAvailableCustomizedForPostPlanner
                                  );


                                  return FormValidator.isNum(value) &&
                                    value >= 1 &&
                                    value <= filteredChecklist.length &&
                                    !selectedItems.some(item => item.priority === value && item.uuid !== option.uuid);
                                },
                                message: `Please enter a unique numerical value between 1 and ${checklist?.filter(option => this.state.uploadFrequency !== 10 || option.isAvailableCustomizedForPostPlanner).length
                                  }`,
                              }
                            ]}
                            control={{
                              type: CustomTextControl,
                              settings: {
                                placeholder: `Priority`,
                              },
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </Col>



          </Row>
        </Form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  platformData: state.AutoShareState.platformData,
});
const mapDispatchToProps = {

  getAllAmcApi,
  getAllPlatformDataApi

};
AddEditPlaylist.propTypes = {
  // getPosts: PropTypes.func

};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPlaylist);