import React, { } from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";

import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import ComponentHeader from "../common/ComponentHeader";
import { API_LIMIT, AdminUsers, START_PAGE, isModuleExist } from "../../utils/Constant";
import { deleteUserApi, getUserNameApi } from "./Api";
import { InformationModal } from "../common";
class Users extends BaseReactComponent {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist(AdminUsers, 'Users');

    this.state = {
      access: AccesData,
      firstName: "",
      lastName: "",
      search: "",
      show: false,
      dummyData: [],
      searchResult: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      checkboxList: [
        {
          key: true,
          label: "On Premise Venue",
          // isDefault: false, isChecked: true,
        },
        {
          key: false,
          label: "Retail Venue",
          // isDefault: false, isChecked: false,
        },
      ],
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,
    };
  }
  componentDidMount() {
    this.props.getUserNameApi(this);
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    // const search = params.get("search") || "";

    if (prevPage !== page) {
      this.setState({ currentPage: page }, () => {
        this.props.getUserNameApi(this);

      });
    }
  }
  onChangeMethod = (value) => {
    console.log(value);

  };
  getDetailsUser = (id) => {
    this.props.history.push({ pathname: `/user-details/${id}`, state: { "id": id } });
  }
  clearSearch = () => { };


  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {
    deleteUserApi(id, this)
    this.props.getUserNameApi(this);
    // deleteAmcApi(id, this, this.openCloseDeleteModal) updat this
  }

  render() {
    const { usersList, totalPages, currentPage } = this.props.usersState;
    const { showEdit, access } = this.state;

    return (
      <>
        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.name}?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}

        <section className="request-section">
          <ComponentHeader
            title="User"

            primaryBtn={access.createAccess ? "Add User" : ""}
            handlePrimaryBtn={() => {
              this.props.history.push("/add-user");
            }}
          />

          <div className="commom-table-wrapper">
            <CustomTable
              tableData={usersList?.list || []}
              columnList={[
                {
                  coumnWidth: 250,
                  labelName: "Name",
                  dataKey: "name",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "name") {
                      return rowData.name;
                    }
                  },
                },
                {
                  coumnWidth: 250,
                  labelName: "Email Address",
                  dataKey: "emailAddress",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "emailAddress") {
                      return rowData.emailAddress;
                    }
                  },
                },

                {
                  coumnWidth: 250,
                  labelName: "Role",
                  dataKey: "role",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "role") {
                      return rowData.role?.roleName;
                    }
                  },
                },
                {
                  coumnWidth: 250,
                  labelName: "AMC",
                  dataKey: "userAmc",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "userAmc") {
                      return rowData.userAmc?.map((item) => item.amc.amcName).join(',');
                    }
                  },
                },

                {
                  coumnWidth: 150,
                  labelName: "Updated By",
                  dataKey: "updatedBy",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "updatedBy") {
                      return rowData.updatedBy?.name || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Created By",
                  dataKey: "createdBy",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "createdBy") {
                      return rowData.createdBy?.name || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: access.updateAccess || access.deleteAccess ? 170 : 0,
                  // coumnWidth: 170,
                  labelName: "Action",
                  dataKey: "",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "") {
                      return (
                        <>
                          {access.updateAccess && (
                            <Image src={editIcon} className="edit-icon" onClick={() => { this.props.history.push({ pathname: '/edit-user', state: { editData: rowData } }) }} />
                          )}
                          {access.deleteAccess && (
                            <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                          )}
                        </>
                      );
                    }
                  },
                },
              ]}
              message="No data found" // For Pagination
              history={this.props.history}
              location={this.props.location}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </div>
        </section>
      </>
    );
  }
}


const mapStateToProps = state => ({
  usersState: state.UsersState,
});
const mapDispatchToProps = {
  getUserNameApi
  // getPosts: fetchPosts
  // getUserNameApi
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users));
