import React, { Component } from "react";
import { connect } from "react-redux";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { ComponentHeader, InformationModal } from "../common";
import { API_LIMIT, Notifications, START_PAGE, isModuleExist } from "../../utils/Constant";
import { deleteTemplateApi, getAllTemplateApi } from "./Api";
import TemplateForm from "./TemplateForm";


export class TemplateTab extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist(Notifications, 'Template');
    this.state = {
      access: AccesData,
      dummyData: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      languageList: [],
      totalPages: null,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,

      isVerify: false,
      show: false,

      verified: '',
      verifyUrl: ''
    };
  }

  // todo---------------------------------------
  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllTemplateApi(this);
  }
  // todo---------------------------------------
  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";
    // const search = params.get("search") || "";

    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllTemplateApi(this);

      });
    }
  }
  // todo:-----------------------------------------

  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state?.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {

    deleteTemplateApi(id, this, this.openCloseDeleteModal)
  }
  render() {
    const { showEdit, access } = this.state;
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;

    return (
      <section className="request-section">

        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.title} ?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}

        {
          //   true &&
          showEdit &&
          <TemplateForm
            show={showEdit}
            onHide={this.showEditModal}
            editData={this.state.editData}
            this={this}
          />
        }
        <ComponentHeader
          title="Template"
          isSearch={false}
          placeholder={"Search Template"}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          // primaryBtn={access.createAccess?"Add Template":""}
          primaryBtn={""}
          handlePrimaryBtn={() => {

            this.setState({ showEdit: true })
          }}
        />

        <div className="commom-table-wrapper">

          <CustomTable
            tableData={listData.list || []}
            columnList={[

              {
                coumnWidth: 200,
                labelName: "Title",
                dataKey: "title",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "title") {
                    return rowData?.title || "Na";
                  }
                },
              },

              {
                coumnWidth: 200,
                labelName: "Template",
                dataKey: "template",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "template") {
                    return rowData?.template || "Na";
                  }
                },
              },
              {
                coumnWidth: 120,
                labelName: "Type",
                dataKey: "type",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "type") {
                    return rowData?.type?.toUpperCase() || "Na";
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Updated By",
                dataKey: "updatedBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "updatedBy") {
                    return rowData.updatedBy?.name || 'Na';
                  }
                },
              },

              {
                coumnWidth: 150,
                labelName: "Created By",
                dataKey: "createdBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "createdBy") {
                    return rowData.createdBy?.name || 'Na';
                  }
                },
              },


            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getAllTemplateApi
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateTab);
