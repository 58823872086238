import { toast } from "react-toastify";
import { postLoginInstance, preLoginInstance } from "../../utils";

export const forgotPasswordApi = async (url, data) => {
    try {
      const response = await preLoginInstance.post(url, data);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
      return false;
    }
  }