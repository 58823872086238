import { toast } from "react-toastify";
import { postLoginInstance, preLoginInstance } from "../../utils";
import { API_LIMIT, START_PAGE, contentBannerAds } from "../../utils/Constant";
import { getAllListData } from "../MasterCategory/MasterCategoryAction";

export const getAllBannerApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  // console.log('ctx', ctx);
  let url = ctx?.props?.history?.location?.pathname.includes("event") ? "eventBannerAd" : "bannerAd";
  // console.log('url', url);
  return function (dispatch, getState) {
    return postLoginInstance.get(
      `${url}?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
      }`
    )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        if (!ctx) {
          onHide();
        }
      });
  };
};
export const addBannerAdApi = (data, ctx, onHide,ctx2) => {
  // console.log("Post api props",data,ctx)
  return postLoginInstance.post((ctx?.state?.type === 'event-banner-ads') ?"eventBannerAd":"bannerAd", data)
    .then((res) => {
      toast.success("Successfully added")
      ctx2?.setState({resLoading:false})
      ctx.props.history.push(ctx?.state?.type === "content-ads" ? "/content-ads" : (ctx?.state?.type === 'event-banner-ads') ? '/event-banner-ads' : '/content-banner-ads')

      // ctx.props.history.push('/banner-ads')
    }).catch((err) => {
      ctx2?.setState({resLoading:false})
      toast.error(err?.response?.data?.message || "Something went wrong")
    })
};
export const updateBannerAdApi = (data, ctx, onHide, id,ctx2) => {
  // console.log("update api props", data, ctx);
  return postLoginInstance.put( (ctx?.state?.type === 'event-banner-ads') ?`eventBannerAd/${id}`:`bannerAd/${id}`, data)
    .then((res) => {
      toast.success("Successfully updated")
      ctx2?.setState({resLoading:false})
      ctx.props.history.push(ctx?.state?.type === "content-ads" ? "/content-ads" : (ctx?.state?.type === 'event-banner-ads') ? '/event-banner-ads' : '/content-banner-ads')
      // ctx.props.history.push('/banner-ads')
    }).catch((err) => {
      ctx2?.setState({resLoading:false})
      toast.error(err?.response?.data?.message || "Something went wrong")
    })
};

export const deleteBannerApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`bannerAd/${id}`)
    .then((res) => {
      onHide()
      toast.success("Successfully deleted")
      // ctx.props.history.push('/banner-ads');
      ctx.props.history.push((ctx?.state?.type !== contentBannerAds) ? '/event-banner-ads' : '/content-banner-ads')

      ctx.props.getAllBannerApi(ctx);
    })
    .catch((err) => {
      
      toast.error(err?.response?.data?.message || "Something went wrong")
    })
};

// =================================== BANNER API END =====================================
// =================================== ADS API START =====================================
export const getAllAdsApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance.get(
      `contentAd?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
      }`
    )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        if (!ctx) {
          onHide();
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong")
      })
  };
};
export const addContentAdApi = (data, ctx, onHide,ctx2) => {
  // console.log("Post api props", data, ctx)
  return postLoginInstance.post("contentAd", data)
    .then((res) => {
      toast.success("Successfully added")
      // ctx.props.history.push('/ads')
      ctx2?.setState({resLoading:false})
      ctx.props.history.push('/content-ads')
    })
    .catch((err) => {
      ctx2?.setState({resLoading:false})
      toast.success(err || "Something went wrong")
    })
};
export const updateContentAdApi = (data, ctx, onHide, id,ctx2) => {
  // console.log("update api props", data, ctx);
  return postLoginInstance.put(`contentAd/${id}`, data)
    .then((res) => {
      ctx2?.setState({resLoading:false})
      toast.success("Successfully updated")
      // ctx.props.history.push('/ads')
      ctx.props.history.push('/content-ads')
    })
    .catch((err) => {
      ctx2?.setState({resLoading:false})
      toast.error(err?.response?.data?.message || "Something went wrong")
    })
};

export const deleteAdsApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`contentAd/${id}`)
    .then((res) => {
      toast.success("Successfully deleted")
      ctx.props.getAllAdsApi(ctx)
      onHide()
      //  ctx.props.history.push('/ads')
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message || "Something went wrong")
    })
};

