import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
// import {GrFormClose} from 'react-icons/gr';
import close from '../../assets/images/greay-cancel-icon.svg'
export default function SelectKeywords({ label, onchangeHandler = () => { }, placeholder, options = [], selectedKeyWords = [], required = true, getKeys = () => { console.log("default call Kesys") } }) {
  const [keyword, setKeyword] = useState("");

  const [selectedOption, setSelectedOption] = useState(selectedKeyWords || [])
  // console.log(options)
  useEffect(() => {
    getKeys(selectedOption);
  }, [selectedOption])
  useEffect(() => {
    setSelectedOption(selectedKeyWords);
  }, [selectedKeyWords])
  function keywordChangeHandler(e) {
    setKeyword(e.target.value);
    onchangeHandler(e.target.value)



  }
  function selectHandler(data) {
    setKeyword("");
    setSelectedOption([...selectedOption, data])
    // setOption([])
  }
  function handleKeyPress(e) {
    if (e.key === "Enter" && keyword.trim() !== "") {
      setSelectedOption([...selectedOption, keyword])
      setKeyword("");
    }
  };
  function removeKeyword(data) {
    // let remove=option?.spalise(index,1)
    const newArray = selectedOption.filter(item => item !== data);
    setSelectedOption(newArray);
  }
  return (
    <div>
      <div className="img-form-group">
        <label className="form-label">
          {label}
          {required && <span> *</span>}
        </label>
        <div className="keywords-dropdown-frame">
          <div className="keywords-input-fields">
            <input type="text" placeholder={placeholder} required={required} className="input-box-keywords" name="keyword" value={keyword} onChange={keywordChangeHandler} onKeyPress={handleKeyPress} />
            {keyword && <Image src={close} onClick={() => setKeyword("")} alt="canle icon" className="cancel-field" />}
          </div>
          {options.length > 0 && keyword &&
            <ul className="autocomplete-keywords-dropdown">
              {
                options?.map((item, i) => {
                  return (
                    <li onClick={() => selectHandler(item.label)} key={i}>{item.label}</li>
                  )
                })
              }
            </ul>
          }
          <div className="show-selected-key-frame">
            {selectedOption?.map((item, i) => {
              return (
                <span>{item} <Image src={close} onClick={() => removeKeyword(item)} alt="canle icon" className="cancle-key" /></span>
              )
            })}

          </div>
        </div>
      </div>
    </div>
  );
}
