import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CustomButton, BaseReactComponent } from "../../utils/form";
import CustomTable from "../../utils/commonComponent/CustomTable";
import img from "../../assets/images/creative.svg";
import leftarrow1 from "../../assets/images/leftarrow1.png";
import { Col, Row, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { Image } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { gerEventregisterUser } from "../content/Api";
import moment from "moment";
import { IN_PERSON } from "../../utils/Constant";
import { getDetaisContentEventApi } from "./Api";
class ViewSsoEvent extends BaseReactComponent {
    constructor(props) {
        super(props);
        const { params } = props.match;
        this.state = {
            params,
            show: false,
            firstname: "",
            lastname: "",
            pleaseselect: "",
            detailsData: [],
            registerUser: []
        };
    }

    componentDidMount() {
        getDetaisContentEventApi("/sso/events", this);
        gerEventregisterUser(this)
    }
    render() {
        const { data, detailsData, params, registerUser } = this.state;
        // console.log('thi is get Regisster detila',registerUser);
        return (
            <section className="user-section">
                {/* // ---------------------------------------View details -------------------------------- */}

                <div className="view-details-wrapper">

                    <ComponentHeader title="View Event" isBackArrow={true} isReturn="/sso/events" />
                    <div className="view-details d-flex">
                        <div className="view-details-content">
                            <div className="user-details d-flex">
                                <div className="eclipse">
                                    <Image src={data?.thumbnailPath} className="eclipse" />
                                </div>
                                <div className="name-details">
                                    <h3 className="inter-medium f-s-20">{data?.name || ""}</h3>
                                    {/* <h4 className="inter-medium grey-3">Coach-Gynecologist</h4>
                  <h5 className="inter-medium f-s-10 grey-3">Mumbai</h5> */}
                                </div>
                                <div className="line"></div>
                                <div className="name-description">
                                    <h3 className="grey-2 ">About</h3>
                                    <p className="inter-medium f-s-14">
                                        {data?.description}
                                    </p>
                                </div>
                            </div>
                            <h3 className="inter-regular f-s-14 grey-2">
                                Event Details
                            </h3>
                            <div className="line2"></div>
                            <Table className="custom-table">
                                <Row>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Event Start Date</h4>
                                            <h3 className="inter-semibold f-s-14 ">   {moment(data?.startDate).format('lll')}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Event End Date</h4>
                                            <h3 className="inter-semibold f-s-14 "> {moment(data?.endDate).format('lll')}</h3>
                                        </div>
                                    </Col>
                                    {data?.eventFormat !== IN_PERSON &&
                                        <Col sm={3} xs={12}>
                                            <div className="detail">
                                                <h4 className="inter-semibold grey-2 ">Online Link</h4>
                                                <h3 className="inter-semibold f-s-14 "><a href={data?.onlineLink} target="_blank" className=" text-decoration-none ">{data?.onlineLink}</a></h3>
                                            </div>
                                        </Col>
                                    }
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Total Seats</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.totalSeats || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Address</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.address || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">AMC</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.amc?.amcName || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">City</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.city || ''}</h3>
                                        </div>
                                    </Col>
                                    {/* <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Start Date</h4>
                      <h3 className="inter-semibold f-s-14 ">{moment(data?.startDate).format('lll') || ''}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">End Date</h4>
                      <h3 className="inter-semibold f-s-14 ">{moment(data?.endDate).format('lll') || ''}</h3>
                    </div>
                  </Col> */}
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Is Premium</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.isPremium ? "Yes" : "No" || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Is Verified</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.isVerified ? "Yes" : "No" || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Is Premium</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.isPremium ? "Yes" : "No" || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Keywords</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.keywords || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Venue Name</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.venueName || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Event Type</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.eventType || ''}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Event Format</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.eventFormat || ''}</h3>
                                        </div>
                                    </Col>


                                </Row>

                            </Table>
                            <h3 className="inter-regular f-s-14 grey-2">Event Registered By User </h3>
                            <div className="line2"></div>
                            <CustomTable
                                tableData={registerUser}
                                columnList={[
                                    {
                                        coumnWidth: 200,
                                        labelName: "Users",
                                        dataKey: "user",
                                        className: "name",
                                        isCell: true,
                                        cell: (rowData, dataKey) => {
                                            if (dataKey === "user") {
                                                return rowData.user?.firstName + " " + rowData.user?.lastName;
                                            }
                                        },
                                    },
                                    {
                                        coumnWidth: 250,
                                        labelName: "Email Address",
                                        dataKey: "emailAddress",
                                        className: "name",
                                        isCell: true,
                                        cell: (rowData, dataKey) => {
                                            if (dataKey === "emailAddress") {
                                                return rowData.user?.emailAddress
                                            }
                                        },
                                    },
                                    {
                                        coumnWidth: 250,
                                        labelName: "Mobile Number",
                                        dataKey: "mobileNumber",
                                        className: "name",
                                        isCell: true,
                                        cell: (rowData, dataKey) => {
                                            if (dataKey === "mobileNumber") {
                                                return rowData.user?.mobileNumber
                                            }
                                        },
                                    },
                                    {
                                        coumnWidth: 250,
                                        labelName: "Registered At",
                                        dataKey: "createdAt",
                                        className: "name",
                                        isCell: true,
                                        cell: (rowData, dataKey) => {
                                            if (dataKey === "createdAt") {
                                                return moment(rowData.user?.createdAt).format('lll')
                                            }
                                        },
                                    },
                                    //   {

                                    //     coumnWidth:access.updateAccess && access.deleteAccess? 250:0,
                                    //     labelName: "Action",
                                    //     dataKey: "",
                                    //     className: "",
                                    //     isCell: true,
                                    //     cell: (rowData, dataKey) => {
                                    //       if (dataKey === "") {
                                    //         return (
                                    //           <>
                                    //             {access.updateAccess && (
                                    //               <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                                    //             )}
                                    //             {access.deleteAccess && (
                                    //               <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                                    //             )}
                                    //           </>
                                    //         );
                                    //       }
                                    //     },

                                    // }

                                ]}
                                message="No data found" // For Pagination
                            // history={this.props.history}
                            // location={this.props.location}
                            // totalPages={totalPages}
                            // currentPage={currentPage}
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({ usersState: state.UsersState });
const mapDispatchToProps = {
    // getPosts: fetchPosts
};
ViewSsoEvent.propTypes = {
    // getPosts: PropTypes.func
    location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSsoEvent);
