import React, { Component } from "react";
import { connect } from "react-redux";
import ComponentHeader from "../common/ComponentHeader";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Button, Col, Image, Row } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import editPencil from "../../assets/images/edit-icon.png";
import { getAllEventDataApi } from "./Api";
import { API_LIMIT, START_PAGE, isModuleExist } from "../../utils/Constant";
import { InformationModal, VerifiedModal } from "../common";
import moment from "moment";
import { deleteAuthCommonApi } from "../content/Api";
import { toast } from "react-toastify";
import { EVENT_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";

class Events extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist("Event", 'Event');

    this.state = {
      access: AccesData,
      search: "",
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,


      isVerify: false,
      show: false,

      verified: '',
      verifyUrl: ''

    };
  }


  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllEventDataApi(this);

  }

  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";


    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";



    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllEventDataApi(this);

      });
    }
  }

  getDetailsUser = (data) => {
    this.props.history.push({ pathname: `/events/details/${data?.uuid}`, state: { "id": data?.uuid } });
  }
  showEditModal = (rowData = null) => {
    this.props.history.push({ pathname: `/events/add-event`, state: { data: rowData } })
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };

  deleteHandle = async (id) => {
    const res = await deleteAuthCommonApi(`/events/${id}`);

    if (res.status === 200) {
      this.props.getAllEventDataApi(this);
      this.setState({
        delete: !this.state.delete
      });

    } else {
      toast.error("Somthis wrong...")
    }
  }
  render() {
    const { search, dummyData, access } = this.state;

    const { eventData, totalPages, currentPage } = this.props.eventsState;

    return (
      <>
        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.name}?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        <VerifiedModal
          name={`'${this.state.editData?.name}'`}
          show={this.state.show}
          url={EVENT_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getAllEventDataApi(this) }}
        />
        <section>
          <ComponentHeader
            title="Events"
            primaryBtn={access.createAccess ? "Add Event" : ""}

            handlePrimaryBtn={() => { this.props.history.push('/events/add-event') }}
          />
          <Row>
            <Col sm={2}>
              <FilterVefiedDropDown ctx={this} />
            </Col>
          </Row>
          <div className="commom-table-wrapper">
            <CustomTable
              tableData={eventData?.list}
              columnList={[
                {
                  coumnWidth: 200,
                  labelName: "Name",
                  dataKey: "name",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "name") {
                      return rowData.name;
                    }
                  },
                },
                {
                  coumnWidth: access.verificationAccess ? 100 : 0,
                  labelName: "Verified",
                  dataKey: "verify",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "verify") {
                      return <div className=" d-flex align-items-center inter-medium">
                        {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                      </div>;
                    }
                  },
                },

                {
                  coumnWidth: 200,
                  labelName: "Event Format",
                  dataKey: "eventFormat",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "eventFormat") {
                      return rowData.eventFormat;
                    }
                  },
                },

                {
                  coumnWidth: 200,
                  labelName: "Event Date",
                  dataKey: "date",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "date") {
                      return moment(rowData?.startDate).format('lll');
                    }
                  },
                },

                {
                  coumnWidth: 150,
                  labelName: "Updated By",
                  dataKey: "updatedBy",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "updatedBy") {
                      return rowData?.updatedBy?.name || 'Na';;
                    }
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Created By",
                  dataKey: "createdBy",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "createdBy") {
                      return rowData?.createdBy?.name || 'Na';
                    }
                  },
                },
                {

                  coumnWidth: access.updateAccess || access.deleteAccess ? 250 : 0,
                  labelName: "Action",
                  dataKey: "",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "") {
                      return (
                        <>
                          <Button onClick={() => this.getDetailsUser(rowData)} className=" primary-btn mr-4">
                            View Detail
                          </Button>
                          {access.updateAccess && (
                            <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                          )}
                          {access.deleteAccess && (
                            <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                          )}
                        </>
                      );
                    }
                  },

                }

              ]}
              message="No data found" // For Pagination
              history={this.props.history}
              location={this.props.location}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventsState: state.EventsState,
});
const mapDispatchToProps = {
  getAllEventDataApi
};
Events.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
