import { toast } from "react-toastify";
import { postLoginInstance, preLoginInstance } from "../../utils";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
import { getAllListData } from "../MasterCategory/MasterCategoryAction";


export const addSubscriptionApi = (data, history) => {
  // console.log("Post api props",data,ctx)
  return postLoginInstance.post("premium-plan", data)
    .then((res) => {
      // ctx.props.getAllSubscriptionApi(null, onHide);
      history.push('/subscription-plan');
    })
    .catch((error) => {
      // console.log(error)
      toast.error(error.data?.message || "Something went wrong")
    })
};
export const updateSubscriptionApi = (data, history, id) => {
  // console.log("update api props", data, ctx);
  return postLoginInstance.put(`premium-plan/${id}`, data).then((res) => {
    // ctx.props.getAllSubscriptionApi(null, onHide);
    history.push('/subscription-plan');
  })
    .catch((error) => {
      // console.log(error)
      toast.error(error.data?.message || "Something went wrong")
    })
};

export const deleteSubscriptionApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`sub-premium-plan/${id}`).then((res) => {
    ctx.props.getAllSubscriptionApi(null, onHide);
  })
    .catch((error) => {
      toast.error(error.data.message || "Something went wrong")
    })
};

// ============================== SUBSCRIPTION V-3 API START =========================
export const getAllSubscriptionApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance.get(
      `sub-premium-plan?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
      }`
    )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {

        toast.error(error.data?.message || "Something went wrong")
      })
  };
};
export const getAllSubscriptionsApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance.get(
      `sub-premium-plan?${verifyUrl ? verifyUrl + '&' : ''}`
    )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {

        toast.error(error.data?.message || "Something went wrong")
      })
  };
};
export const addSubscription1Api = (data, history) => {
  // console.log("Post api props",data,ctx)
  return postLoginInstance.post("sub-premium-plan", data)
    .then((res) => {
      // ctx.props.getAllSubscriptionApi(null, onHide);
      history.push('/subscription-plan');
    })
    .catch((error) => {
      // console.log(error)
      toast.error(error.data?.message || "Something went wrong")
    })
};
export const updateSubscription1Api = (data, history, id) => {

  return postLoginInstance.put(`sub-premium-plan/${id}`, data).then((res) => {

    history.push('/subscription-plan');
  })
    .catch((error) => {

      toast.error(error.data?.message || "Something went wrong")
    })
};

export const deleteSubscription1Api = (id, ctx, onHide) => {
  return postLoginInstance.delete(`sub-premium-plan/${id}`).then((res) => {
    ctx.props.getAllSubscriptionApi(null, onHide);
  })
    .catch((error) => {
      toast.error(error.data.message || "Something went wrong")
    })
};

// ============================== SUBSCRIPTION V-3 API END =========================