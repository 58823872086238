import React from "react";
import { connect } from "react-redux";

import {
    Form,
    FormElement,
    FormValidator,
    CustomTextControl,
    BaseReactComponent,
    SelectControl
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import "react-datepicker/dist/react-datepicker.css";
import { ClearButton, ImageUploader } from "../common";
import { getCommonApi, postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import ReactDOM from "react-dom";
import { durationObj, MAX_LIMIT, ModuleName, MUTUAL_FUND_COMPANY, selectLogoPosition, SUPER_ADMIN, Thefinpedia } from "../../utils/Constant";
import { userAmcUuids, userCreatorUuids, userRoleType } from "../../utils/ReusableFunctions";
import { SearchContent } from "../content";
import { toast } from "react-toastify";
import { getAllAmcSsoApi, getAllCreatorApi, getAllModulesApi, getAllTypeApi, getDetailsSsoApi } from "./Api";
import { getAllSubscriptionsApi } from "../subscription/Api";
import { getAllModulesDataApi } from "../subdomain/Api";
class AddEditSso extends BaseReactComponent {
    constructor(props) {
        super(props);
        const data = props?.location.state?.data || null;
        const roleType = userRoleType() || SUPER_ADMIN;



        this.state = {

            editData: data,
            ssoUuid: data?.uuid || "",
            isUpdated: (props?.location.state ?? false) || false,
            companyName: data?.companyName || "",
            isAmcShowContent: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
            isAddContent: true,
            isAmcShow: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
            creatorList: [],
            moduleNameOptions: Object.keys(ModuleName).map((key) => ({
                value: ModuleName[key],
                label: key.replace(/_/g, ' ').toLowerCase().replace(/^\w/, (c) => c.toUpperCase()),
            })),
            thumbnailPath: "",
            thumbnailFile: null,
            uploadImage: data?.companyLogo || null,
            amcContent: data?.amc?.map(item => item?.uuid) || (userAmcUuids().length > 0 ? userAmcUuids() : ''),
            creatorContent: data?.contentCreator?.map(item => item.uuid) || (userCreatorUuids() || []),
            amcEvent: data?.amc?.map(item => item?.uuid) || (userAmcUuids().length > 0 ? userAmcUuids() : ''),
            creatorEvent: data?.contentCreator?.map(item => item.uuid) || (userCreatorUuids() || []),
            amcOption: [],
            pageSize: MAX_LIMIT,

            showSearchContent: false,
            search: '',
            locationSearch: '',
            resLoading: false,
            loading: false,
            link: data?.websiteLink || "",
            contact: data?.mobileNumber || "",
            email: data?.emailAddress || "",
            type: data?.ssoPartnerType?.uuid || "",
            subscriptionOptions: [],
            typesOptions: [],
            subscription: "",
            premium: "",
            subPremium: "",
            variation: "",
            plansData: [],
            subPremiumPlan: [],
            subPremiumPlanVariation: [],
            isSubscriptionAllowed: "",
            isTFPAllowed: false,
            isOtherLinkAllowed: false,
            otherLink: "",
            isUpdateAllowed: "",
            isAdminAllowed: "",
            isShowLogo: "",
            isCompanyLogo: false,
            isBoth: false,
            isPowered: false,
            isBrought: false,
            isSubscriptionError: "",
            isUserViewAllowed: "",
            campaignOption: [],
            eventOptions: [],
            logoPositionOptions: selectLogoPosition.map((position) => ({
                label: position.name, // Displayed text in the dropdown
                value: position.val,  // Actual value of the option
            })),
            contentUuid: "",
            eventUuid: "",
            modules: [
                {
                    moduleName: "CONTENT",
                    moduleUuid: "",
                    creator: [],
                    amc: [],
                    isAmcShow: false,
                },
                {
                    moduleName: "EVENT",
                    moduleUuid: "",

                    creator: [],
                    amc: [],
                    isAmcShow: false
                }
            ]
        };


    };

    imageUploaser = (e, name) => {
        // console.log(typeof (e.target.files[0]))
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })

    }

    addMore = () => {
        let modules = this.state.modules ? this.state.modules.map(a => ({ ...a })) : [];
        modules.push({
            // isAmcShowContent: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
            // isAmcShow: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
            moduleName: "",
            creator: [],
            amc: [],
            campaign: [],
            event: "",
            isAmcShow: false,
            selectedContent: [],
            isAmcShowContent: false,
            showSearchContent: false,
            isRegistration: false,
        });

        this.setState({ modules });
    }

    async componentDidMount() {
        var planOptions
        this.props.getAllAmcSsoApi(this);
        getAllCreatorApi(this);
        this.props.getAllTypeApi(this);
        this.props.getAllModulesApi(this);

        await getCommonApi(`premium-plan?pageSize=${MAX_LIMIT}`).then((res) => {
            this.setState({ plansData: res.data.data.list });
            planOptions = res?.data?.data?.list

        });
        // this.props.getAllSubscriptionsApi(this);
        if (this.props?.location?.state?.data?.uuid) {
            this.setState({ loading: true })

            await getDetailsSsoApi("/sso", this, true, planOptions).finally(() => {
                this.setState({ loading: false })
            });

        };
        console.log(this.state.type, "type")



    }

    componentDidUpdate(prevProps, prevState) {



        if (this.state.creatorList !== prevState.creatorList) {
            if (userCreatorUuids()) {
                const isExist = this.state.creatorList?.find(item => userCreatorUuids().split(',')?.includes(item.value));
                this.setState({ isAmcShow: isExist?.label?.includes(MUTUAL_FUND_COMPANY) });
            }
        }

        if (this.state.creatorList !== prevState.creatorList) {
            const editData = this.props?.location.state?.data;
            if (userCreatorUuids() || editData?.contentCreator?.uuid) {
                const isExist = this.state.creatorList?.find(item => editData?.contentCreator?.uuid === item.value);
                const isShowAmc = isExist?.label?.includes(MUTUAL_FUND_COMPANY);
                const isShowRegister = isExist?.label?.includes(Thefinpedia);
                this.setState({ isAmcShow: isShowAmc, isRegistration: !isShowRegister, isAmcShowContent: isShowAmc });
            }
        }



    }
    joinMultipleArraysWithDelimiter = ((delimiter = ',', ...arrays) => {
        return arrays.flat().join(delimiter);
    })

    handlePageSize = (pageSize) => {
        // console.log("Heyyy pageSize", pageSize);
    };
    handleSave = () => {
        ReactDOM.findDOMNode(this.form).dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    };
    componentDidUpdate(prevProps, prevState) {
        // Only check if `amcEvent` has changed to avoid infinite loops

    }

    processModules(modules) {
        return modules.map(module => {

            // Initialize the new object structure
            const processedModule = {
                moduleName: module?.moduleName,
                masterSsoPartnerModuleUuid: module?.moduleUuid,
                contentCreators: module?.creator?.join(','),
                amcs: module?.isAmcShowContent ? module?.amc?.join(',') : "",
                ...(this.state.editData?.uuid && { uuid: module?.uuid || "" })

            };
            return processedModule;
        });
    }

    onValidSubmit = async () => {
        this.setState({ resLoading: true });
        const { name, link, email, contact, type, creatorContent, creatorEvent, amcContent, amcEvent, premium, subPremium, variation, companyName, isUpdateAllowed, isSubscriptionAllowed, isAdminAllowed, otherLink, isCompanyLogo, isBrought, isPowered, isPoweredText, isBroughtText, isShowLogo, isTFPAllowed, isOtherLinkAllowed, isBoth, isUserViewAllowed, modules, logoPosition } = this.state;

        if (isSubscriptionAllowed === "" || isShowLogo === "" || isUpdateAllowed === "" || isAdminAllowed === "") {

            toast.error("Please fill out all required fields.");
            this.setState({ resLoading: false });
            return; // Prevent form submission
        }
        if (isSubscriptionAllowed && (isTFPAllowed === false && isOtherLinkAllowed === false)) {
            toast.error("Please fill out all required fields.");
            this.setState({ resLoading: false });
            return;
        }
        if (isShowLogo && (isCompanyLogo === false && isBoth === false)) {
            toast.error("Please fill out all required fields.");
            this.setState({ resLoading: false });
            return;
        }
        if (isBoth && (isPowered === false && isBrought === false)) {

            toast.error("Please fill out all required fields.");
            this.setState({ resLoading: false });

            return;
        }

        else {
            const ssoData = {
                "data": {
                    "companyName": companyName,
                    "websiteLink": link,
                    "emailAddress": email,
                    "mobileNumber": contact,
                    "premiumPlanUuid": premium,
                    "subPremiumPlanUuid": subPremium,
                    "subPremiumPlanVariationUuid": variation,

                    // "contentsAllowedOf": this.joinMultipleArraysWithDelimiter(
                    //     ',',
                    //     creatorContent,
                    //     this.state.isAmcShowContent ? amcContent : []
                    // ),

                    // "eventsAllowedOf": this.joinMultipleArraysWithDelimiter(
                    //     ',',
                    //     creatorEvent,
                    //     this.state.isAmcShow ? amcEvent : []
                    // ),

                    "ssoPartnerTypeUuid": type,
                    "allowSubscriptionPlanPage": isSubscriptionAllowed,
                    "canUserUpdateProfile": isUpdateAllowed,
                    "allowPartnerAdminPanel": isAdminAllowed,
                    ...(isAdminAllowed && { "allowPartnerAdminUserDetails": isUserViewAllowed }),
                    "subscriptionPlanPageLink": otherLink,
                    "companyLogoVisibility": isShowLogo,
                    "companyLogoText": isCompanyLogo ? "" : (isBoth && isPowered) ? isPoweredText : isBroughtText,
                    "modules": this.processModules(modules)
                    // "logoPosition": logoPosition,


                }
            };

            console.log(ssoData)
            const data = new FormData();
            data.append("key", JSON.stringify(ssoData));
            if (this.state.thumbnailFile) {
                data.append("file", this.state?.thumbnailFile);
            }
            if (this.state.ssoUuid) {
                const response = await putAuthCommonApi(`sso/${this.state.ssoUuid}`, data);
                if (response.status === 200) {
                    this.setState({ resLoading: false });
                    this.props.history.push({ pathname: `/sso`, search: 'pageSize=10&pageNumber=1' });
                } else {
                    this.setState({ resLoading: false });
                }
            } else {
                const response = await postAuthCommonApi('/sso', data);
                if (response.status === 200) {
                    this.setState({ resLoading: false });
                    this.props.history.push({ pathname: `/sso`, search: 'pageSize=10&pageNumber=1' });
                } else {
                    this.setState({ resLoading: false });
                }
            }
        }
    };


    render() {
        const {
            uploadImage,
            thumbnailFile,
            amcOption,
            platformOptions,
            loading,
            checklist,
            selectedItems,
            subscriptionOptions,
            typesOptions,
            premium,
            subPremium,
            variation,
            plansData,
            subPremiumPlan,
            subPremiumPlanVariation,
            isSubscriptionAllowed,
            isSubscriptionNotAllowed,
            isTFPAllowed,
            isOtherLinkAllowed,
            isUpdateAllowed,
            isAdminAllowed,
            isShowLogo,
            isCompanyLogo,
            isBoth,
            isPowered,
            isBrought,
            isPoweredText,
            isBroughtToText,
            isSubscriptionError,
            otherLink,
            isUserViewAllowed,
            logoPositionOptions
        } = this.state;
        // const {platformData}=this.props.autoShareState
        const { listData } = this.props.masterCategoryState

        if (loading) {
            return (
                <div>loading...</div>
            )
        }
        return (
            <section className="add-section">
                <ComponentHeader
                    title={this.state.ssoUuid ? "Update SSO Partner" : "Add SSO Partner"}
                    primaryBtn="Save"
                    isBackArrow={true}
                    resLoading={this.state.resLoading}
                    isReturn="/sso?pageSize=10&pageNumber=1"
                    handlePrimaryBtn={this.handleSave}
                />
                {this.state.showSearchContent &&
                    <SearchContent
                        showModal={this.state.showSearchContent}
                        onHide={(selectedContent) => this.setState({ showSearchContent: false, selectedContent })}
                        selectedContent={this.state.selectedContent}
                        isMultiselect={true}
                    />
                }
                <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
                    <Row>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "companyName")}
                                label="Company Name"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Name cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Company Name",
                                    },
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "link")}
                                label="Website Link"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Link cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Website Link",
                                    },
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "contact")}
                                label="Contact Number"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Email cannot be empty",
                                    },

                                    {
                                        validate: FormValidator.isNumber,
                                        message: "Enter valid number",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Contact Number",
                                    },
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "email")}
                                label="Email"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Email cannot be empty",
                                    },
                                    {
                                        validate: FormValidator.isEmail,
                                        message: "Enter valid email",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Email",
                                    },
                                }}
                            />
                        </Col>
                        <Col sm={4}>
                            <ImageUploader required={true} id="Thumbnail" label="Upload Logo" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} showLabel={false} />
                        </Col>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "type")}
                                label="Type"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Type cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: SelectControl,
                                    settings: {
                                        placeholder: "Select Type",
                                        options: typesOptions,
                                        multiple: false,
                                        searchable: true,
                                        onChange: (selectedValue) => {
                                            // Assuming selectedValue is the single selected option
                                            this.setState({ type: selectedValue });
                                        },
                                    },
                                }}
                            />
                        </Col>


                    </Row>

                    <hr />
                    <Row>
                        <Col sm={4}>
                            <p className="roboto-grey f-s-18  lh-22">
                                Allow Subscription Plan Page? <span className=" text-danger">*</span>

                                <div>
                                    <label className="f-s-14 pt-2">
                                        <input
                                            type="checkbox"
                                            name="subscriptionAllowedYes"
                                            className="mr-2 pointer"
                                            checked={isSubscriptionAllowed === true}

                                            onChange={() => {
                                                if (!isSubscriptionAllowed) {
                                                    this.setState({
                                                        isSubscriptionAllowed: true,

                                                    });
                                                }
                                            }}

                                        />
                                        Yes
                                    </label>
                                    <label className="f-s-14 pt-2 ml-5">
                                        <input
                                            type="checkbox"
                                            name="subscriptionAllowedNo"
                                            className="mr-2 pointer"
                                            checked={isSubscriptionAllowed === false}
                                            onChange={() => {
                                                if (isSubscriptionAllowed !== false) {
                                                    this.setState({
                                                        isSubscriptionAllowed: false,

                                                    });
                                                }
                                            }}
                                        />
                                        No
                                    </label>
                                </div>


                            </p>


                        </Col>
                        {isSubscriptionAllowed && (
                            <Col sm={4}>
                                <div>
                                    <p className="roboto-grey f-s-18 pt-2 pb-2 lh-20">Select Option: <span className=" text-danger">*</span> </p>
                                    <label className="f-s-14 pt-2">
                                        <input
                                            type="checkbox"
                                            className="mr-2 pointer"
                                            checked={isTFPAllowed}
                                            onChange={() =>
                                                this.setState((prevState) => ({
                                                    isTFPAllowed: !prevState.isTFPAllowed ? true : prevState.isTFPAllowed, // Only allow toggling on if it's false
                                                    isOtherLinkAllowed: !prevState.isTFPAllowed ? false : prevState.isOtherLinkAllowed, // Deselect Other Link when toggling TFP on
                                                    otherLink: !prevState.isTFPAllowed ? "" : prevState.otherLink, // Clear otherLink if TFP is selected
                                                }))
                                            }
                                        />
                                        TFP
                                    </label>
                                    <label className="f-s-14 pt-2 ml-5">
                                        <input
                                            type="checkbox"
                                            className="mr-2 pointer"
                                            checked={isOtherLinkAllowed}
                                            onChange={() =>
                                                this.setState((prevState) => ({
                                                    isOtherLinkAllowed: !prevState.isOtherLinkAllowed ? true : prevState.isOtherLinkAllowed, // Only allow toggling on if it's false
                                                    isTFPAllowed: !prevState.isOtherLinkAllowed ? false : prevState.isTFPAllowed, // Deselect TFP when toggling Other Link on
                                                    otherLink: !prevState.isOtherLinkAllowed ? prevState.otherLink : "", // Set otherLink only if Other Link is selected
                                                }))
                                            }
                                        />
                                        Other Link
                                    </label>
                                </div>

                            </Col>
                        )}
                        {isSubscriptionAllowed && isOtherLinkAllowed &&
                            <Col sm={4}>
                                <FormElement
                                    valueLink={this.linkState(this, "otherLink")}
                                    label="Other Link"
                                    required
                                    validations={[
                                        {
                                            validate: FormValidator.isRequired,
                                            message: "Link cannot be empty",
                                        },
                                    ]}
                                    control={{
                                        type: CustomTextControl,
                                        settings: {
                                            placeholder: "Enter Other Link",
                                        },
                                    }}
                                />
                            </Col>
                        }

                    </Row>
                    <hr />
                    <Row>
                        <Col md={12}>
                            <p className="roboto-grey f-s-18  lh-22">
                                Can User Update their Profile? <span className=" text-danger">*</span>
                                <div>
                                    <label className="f-s-14 pt-2">
                                        <input
                                            type="checkbox"
                                            name="updateAllowed"
                                            className="mr-2 pointer"
                                            checked={isUpdateAllowed === true}
                                            onChange={() =>
                                                this.setState({
                                                    isUpdateAllowed: true, // Set to true when "Yes" is selected
                                                })
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label className="f-s-14 pt-2 ml-5">
                                        <input
                                            type="checkbox"
                                            name="updateAllowed"
                                            className="mr-2 pointer"
                                            checked={isUpdateAllowed === false}
                                            onChange={() =>
                                                this.setState({
                                                    isUpdateAllowed: false, // Set to false when "No" is selected
                                                })
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                            </p>

                        </Col>
                    </Row>
                    <hr />
                    <Row>

                        <Col sm={4}>
                            <p className="roboto-grey f-s-18  lh-22">
                                Allow Partner to login TFP admin panel? <span className=" text-danger">*</span>
                                <div>
                                    <label className="f-s-14 pt-2">
                                        <input
                                            type="checkbox"
                                            name="allowAdmin"
                                            className="mr-2 pointer"
                                            checked={isAdminAllowed === true}
                                            onChange={() =>
                                                this.setState({
                                                    isAdminAllowed: true, // Set to true when "Yes" is selected
                                                })
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label className="f-s-14 pt-2 ml-5">
                                        <input
                                            type="checkbox"
                                            name="allowAdmin"
                                            className="mr-2 pointer"
                                            checked={isAdminAllowed === false}
                                            onChange={() =>
                                                this.setState({
                                                    isAdminAllowed: false, // Set to false when "No" is selected
                                                })
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                            </p>

                        </Col>
                        {isAdminAllowed === true &&
                            <Col sm={4}>
                                <p className="roboto-grey f-s-18  lh-22">
                                    Allow Partner to view user details? <span className=" text-danger">*</span>
                                    <div>
                                        <label className="f-s-14 pt-2">
                                            <input
                                                type="checkbox"
                                                name="allowView"
                                                className="mr-2 pointer"
                                                checked={isUserViewAllowed === true}
                                                onChange={() =>
                                                    this.setState({
                                                        isUserViewAllowed: true, // Set to true when "Yes" is selected
                                                    })
                                                }
                                            />
                                            Yes
                                        </label>
                                        <label className="f-s-14 pt-2 ml-5">
                                            <input
                                                type="checkbox"
                                                name="allowAdmin"
                                                className="mr-2 pointer"
                                                checked={isUserViewAllowed === false}
                                                onChange={() =>
                                                    this.setState({
                                                        isUserViewAllowed: false, // Set to false when "No" is selected
                                                    })
                                                }
                                            />
                                            No
                                        </label>
                                    </div>

                                </p>

                            </Col>

                        }

                    </Row>
                    <hr />
                    <Row>
                        <Col sm={4}>
                            <p className="roboto-grey f-s-18  lh-22">
                                Show Company Logo on Header? <span className=" text-danger">*</span>

                                <div>
                                    <label className="f-s-14 pt-2">
                                        <input
                                            type="checkbox"
                                            name="showLogo"
                                            className="mr-2 pointer"
                                            checked={isShowLogo === true}
                                            onChange={() =>
                                                this.setState({
                                                    isShowLogo: true, // Set to true when "Yes" is selected
                                                })
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label className="f-s-14 pt-2 ml-5">
                                        <input
                                            type="checkbox"
                                            name="showLogo"
                                            className="mr-2 pointer"
                                            checked={isShowLogo === false}
                                            onChange={() =>
                                                this.setState({
                                                    isShowLogo: false,
                                                })
                                            }
                                        />
                                        No
                                    </label>
                                </div>


                            </p>
                        </Col>
                        {
                            isShowLogo === true && (
                                <Col sm={4}>
                                    <div>
                                        <p className="roboto-grey f-s-18 lh-20">Show Logo Of <span className=" text-danger">*</span> </p>
                                        <label className="f-s-16 pt-2">
                                            <input
                                                type="checkbox"
                                                className="mr-2 pointer"
                                                checked={isCompanyLogo && isShowLogo}
                                                onChange={(e) => {
                                                    if (!e.target.checked && !isBoth) {
                                                        // Prevent deselecting "Only Company" unless "Company with TFP" is selected
                                                        return;
                                                    }
                                                    this.setState({
                                                        isCompanyLogo: e.target.checked,
                                                        isBoth: e.target.checked ? false : isBoth,
                                                        isPowered: e.target.checked ? "" : isPowered,
                                                        isBrought: e.target.checked ? "" : isBrought,
                                                    });
                                                }}
                                            />
                                            Only Company
                                        </label>
                                        <label className="f-s-16 pt-2 ml-5">
                                            <input
                                                type="checkbox"
                                                className="mr-2 pointer"
                                                checked={isBoth && isShowLogo}
                                                onChange={(e) => {
                                                    if (!e.target.checked && !isCompanyLogo) {
                                                        // Prevent deselecting "Company with TFP" unless "Only Company" is selected
                                                        return;
                                                    }
                                                    this.setState({
                                                        isBoth: e.target.checked,
                                                        isCompanyLogo: e.target.checked ? false : isCompanyLogo, // Deselect "Only Company" if "Company with TFP" is selected
                                                        isPowered: e.target.checked ? "" : isPowered,
                                                        isBrought: e.target.checked ? "" : isBrought,
                                                    });
                                                }}
                                            />
                                            Company with TFP
                                        </label>
                                    </div>

                                </Col>
                            )
                        }
                        {isBoth && (
                            <Col sm={4}>
                                <div>
                                    <p className="roboto-grey f-s-18 lh-20">Text on Logo <span className=" text-danger">*</span></p>
                                    <label className="f-s-16 pt-2">
                                        <input
                                            type="checkbox"
                                            className="mr-2 pointer"
                                            checked={isPowered && isBoth}
                                            onChange={(e) => {
                                                if (!e.target.checked && !isBrought) {
                                                    // Prevent deselecting "Powered By" unless "Brought to you by" is selected
                                                    return;
                                                }
                                                this.setState({
                                                    isPowered: e.target.checked,
                                                    isBrought: e.target.checked ? false : isBrought, // Deselect "Brought to you by"
                                                    isPoweredText: e.target.checked ? "Powered By" : "", // Set text conditionally
                                                });
                                            }}
                                        />
                                        Powered By
                                    </label>
                                    <label className="f-s-16 pt-2 ml-5">
                                        <input
                                            type="checkbox"
                                            className="mr-2 pointer"
                                            checked={isBrought && isBoth}
                                            onChange={(e) => {
                                                if (!e.target.checked && !isPowered) {
                                                    // Prevent deselecting "Brought to you by" unless "Powered By" is selected
                                                    return;
                                                }
                                                this.setState({
                                                    isBrought: e.target.checked,
                                                    isPowered: e.target.checked ? false : isPowered, // Deselect "Powered By"
                                                    isBroughtText: e.target.checked ? "Brought to you by" : "", // Set text conditionally
                                                });
                                            }}
                                        />
                                        Brought to you by
                                    </label>
                                </div>

                            </Col>

                        )}


                    </Row>

                    <label>Default Subscription:</label>
                    <Row>
                        <Col sm={4}>

                            <FormElement
                                valueLink={this.linkState(this, "premium")}
                                label="Membership Name"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Field cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: SelectControl,
                                    settings: {
                                        options: plansData?.map((item) => ({ value: item.uuid, label: item.name })),
                                        // options: plansData.map((item) => ({ value: item.uuid, label: item.name })),
                                        placeholder: "Select Membership Name",
                                        multiple: false,
                                        searchable: true,
                                        onChangeCallback: (onBlur) => {
                                            onBlur(this.state.premium);
                                            // console.log("This is selected plan=>",this.state.plan);
                                            const subPremiumPlan = plansData?.find(ele => ele.uuid === this.state?.premium);
                                            // console.log("This is find Prenium plan =>",subPremiumPlan);
                                            this.setState({ subPremiumPlan });
                                            this.setState({
                                                subPremium: null,
                                                variation: null
                                            })
                                        },
                                    },
                                }}
                            />
                            {/* } */}
                        </Col>
                        {premium &&
                            <Col sm={4}>
                                <FormElement
                                    valueLink={this.linkState(this, "subPremium")}
                                    label="Plan Name"
                                    required
                                    validations={[
                                        {
                                            validate: FormValidator.isRequired,
                                            message: "Field cannot be empty",
                                        },
                                    ]}
                                    control={{
                                        type: SelectControl,
                                        settings: {
                                            options: subPremiumPlan?.subPremiumPlan?.map((item) => ({ value: item.uuid, label: item.name })),
                                            // options: plansData.map((item) => ({ value: item.uuid, label: item.name })),
                                            placeholder: "Select Plan Name",
                                            multiple: false,
                                            searchable: true,
                                            onChangeCallback: (onBlur) => {
                                                onBlur(this.state.subPremium);
                                                // console.log("This is selected plan=>",this.state.offerLine);
                                                const subPremiumPlanVariation = subPremiumPlan?.subPremiumPlan?.find(ele => ele.uuid === this.state?.subPremium);
                                                console.log("This is find subPremiumPlanVariation  =>", subPremiumPlanVariation);
                                                this.setState({ subPremiumPlanVariation });
                                                this.setState({

                                                    variation: null
                                                })
                                            },
                                        },
                                    }}
                                />
                                {/* } */}
                            </Col>
                        }
                        {
                            subPremium &&
                            <Col sm={4}>
                                <FormElement
                                    valueLink={this.linkState(this, "variation")}
                                    label="Variation "
                                    required
                                    validations={[
                                        {
                                            validate: FormValidator.isRequired,
                                            message: "Field cannot be empty",
                                        },
                                    ]}
                                    control={{
                                        type: SelectControl,
                                        settings: {
                                            options: subPremiumPlanVariation?.subPremiumPlanVariation?.map((item) => ({ value: item.uuid, label: durationObj[item.resetInDays] })),
                                            // options: plansData.map((item) => ({ value: item.uuid, label: item.name })),
                                            placeholder: "Select Variation",
                                            multiple: false,
                                            searchable: true,
                                            onChangeCallback: (onBlur) => {
                                                onBlur(this.state.variation);

                                            },
                                        },
                                    }}
                                />
                                {/* } */}
                            </Col>
                        }

                        <hr />
                    </Row>

                    <h3 className="inter-semibold f-s-19 lh-24 section-title">Modules</h3>
                    <br />
                    {
                        this.state.modules?.map((module, index) => {
                            return (
                                <>
                                    {index > 0 && <><hr /><br /></>}
                                    <div className="d-flex flex-row justify-content-between">
                                        <p className="variation-srno">Module - {index + 1} </p>

                                    </div>
                                    <br />
                                    <Row>
                                        <Col md={4}>
                                            <FormElement
                                                valueLink={this.linkState(this, `modules.${index}.moduleName`)}
                                                label="Module Name"
                                                required
                                                validations={[
                                                    {
                                                        validate: FormValidator.isRequired,
                                                        message: "Please enter module name",
                                                    },
                                                ]}
                                                control={{
                                                    type: SelectControl,
                                                    settings: {
                                                        placeholder: "Select Module Name",
                                                        options: this.state.moduleNameOptions,
                                                        multiple: false,
                                                        searchable: true,
                                                        disabled: true,
                                                        onChange: (selectedValue) => {
                                                            const modules = [...this.state.modules];
                                                            modules[index].moduleName = selectedValue;
                                                            this.setState({ modules });
                                                        },
                                                    },
                                                }}
                                            />
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col md={4} style={{ position: "relative" }}>
                                            <FormElement
                                                valueLink={this.linkState(this, `modules.${index}.creator`)}  // Update the creator field in each module
                                                label="Content Creator"
                                                required
                                                validations={[
                                                    {
                                                        validate: FormValidator.isRequired,
                                                        message: "Creator cannot be empty",
                                                    },
                                                ]}
                                                control={{
                                                    type: SelectControl,
                                                    settings: {
                                                        placeholder: "Select Creator",
                                                        options: this.state.creatorList,
                                                        multiple: true,
                                                        searchable: true,
                                                        onChangeCallback: (onBlur) => {
                                                            const selectedValues = this.state.modules[index].creator || [];  // Access creator directly from the module state
                                                            const allOptionSelected = selectedValues.includes("all");

                                                            if (allOptionSelected) {
                                                                // Select all values except "All" itself
                                                                const allValues = this.state.creatorList
                                                                    .filter(option => option.value !== "all")
                                                                    .map(option => option.value);

                                                                const modules = [...this.state.modules];
                                                                modules[index].creator = allValues;  // Update the creator field in the specific module
                                                                modules[index].isAmcShowContent = allValues.some(
                                                                    value => this.state.creatorList.find(item => item.value === value)?.label === MUTUAL_FUND_COMPANY
                                                                );
                                                                modules[index].isRegistration = !allValues.some(
                                                                    value => this.state.creatorList.find(item => item.value === value)?.label === Thefinpedia
                                                                );

                                                                this.setState({ modules });
                                                            } else {

                                                                const modules = [...this.state.modules];
                                                                modules[index].creator = selectedValues;
                                                                modules[index].isAmcShowContent = selectedValues.some(
                                                                    value => this.state.creatorList.find(item => item.value === value)?.label === MUTUAL_FUND_COMPANY
                                                                );
                                                                modules[index].isRegistration = !selectedValues.some(
                                                                    value => this.state.creatorList.find(item => item.value === value)?.label === Thefinpedia
                                                                );

                                                                this.setState({ modules });
                                                            }

                                                            onBlur(selectedValues);  // Update the form control
                                                        },
                                                    },
                                                }}
                                            />

                                            {module.creator?.length > 0 && (
                                                <ClearButton
                                                    onClick={() => {
                                                        const modules = [...this.state.modules];
                                                        modules[index].creator = [];  // Clear creator in the specific module
                                                        modules[index].isAmcShowContent = false;
                                                        modules[index].isRegistration = false;
                                                        this.setState({ modules });
                                                    }}
                                                />
                                            )}


                                        </Col>
                                        {
                                            (module?.isAmcShowContent || (userAmcUuids()?.length > 0)) && (
                                                <Col md={8} style={{ position: "relative" }}>
                                                    <FormElement
                                                        valueLink={this.linkState(this, `modules.${index}.amc`)}  // Store AMC in the module object
                                                        label="AMC"
                                                        required
                                                        validations={[
                                                            {
                                                                validate: FormValidator.isRequired,
                                                                message: "AMC cannot be empty",
                                                            },
                                                        ]}
                                                        control={{
                                                            type: SelectControl,
                                                            settings: {
                                                                placeholder: "Enter AMC Name",
                                                                options: (userAmcUuids()?.length > 0)
                                                                    ? amcOption?.filter((ele) => userAmcUuids()?.some(item => item === ele.value))
                                                                    : amcOption,
                                                                multiple: true,
                                                                searchable: true,
                                                                onChangeCallback: (onBlur) => {
                                                                    const selectedValues = this.state.modules[index].amc || [];  // Access AMC for the specific module
                                                                    const allOptionSelected = selectedValues.includes("all");
                                                                    if (allOptionSelected) {
                                                                        // Select all values except "All" itself
                                                                        const allValues = amcOption
                                                                            .filter(option => option.value !== "all")
                                                                            .map(option => option.value);

                                                                        // Update AMC in the specific module
                                                                        const modules = [...this.state.modules];
                                                                        modules[index].amc = allValues;
                                                                        this.setState({ modules });

                                                                        onBlur(allValues);
                                                                    } else {
                                                                        // Update AMC normally if "All" is not selected
                                                                        const modules = [...this.state.modules];
                                                                        modules[index].amc = selectedValues;  // Update AMC in the specific module
                                                                        this.setState({ modules });

                                                                        onBlur(selectedValues);  // Update the form control with selected values
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    {this.state.modules[index].amc?.length > 0 && (
                                                        <ClearButton
                                                            onClick={() => {
                                                                // Clear AMC for the specific module
                                                                const modules = [...this.state.modules];
                                                                modules[index].amc = [];
                                                                this.setState({ modules });
                                                            }}
                                                        />
                                                    )}
                                                </Col>
                                            )
                                        }
                                    </Row>

                                    <Col sm={12}>


                                    </Col>

                                    <br />

                                </>
                            );
                        })
                    }
                </Form>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    masterCategoryState: state.MasterCategoryState,
    ssoState: state.SsoState
});

const mapDispatchToProps = {


    getAllSubscriptionsApi,
    getAllTypeApi,
    getAllAmcSsoApi,
    getAllModulesApi



};


export default connect(mapStateToProps, mapDispatchToProps)(AddEditSso);
