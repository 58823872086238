import React, { Component } from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { deleteContentTypeApi, getContentTypeApi } from "./Api";
import { connect } from "react-redux";
import { ComponentHeader, InformationModal } from "../common";
import CategoryMasterForm from "./CategoryMasterForm";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";

export class ContentTypeTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dummyData: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      languageList: [],
      totalPages: null,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,
    };
  }
  // todo---------------------------------------
  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getContentTypeApi(this);
  }
  // todo---------------------------------------

  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {
    deleteContentTypeApi(id, this, this.openCloseDeleteModal)
  }

  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;


    if (prevPage !== page) {
      this.setState({ currentPage: page }, () => {
        this.props.getContentTypeApi(this);

      });
    }
  }
  // todo:-----------------------------------------

  render() {
    const { showEdit } = this.state;
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;
    return (
      <section className="request-section">

        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.categoryName} category?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        {
          showEdit &&
          <CategoryMasterForm
            show={showEdit}
            onHide={this.showEditModal}
            editData={this.state.editData}
            this={this}
          />
        }
        <ComponentHeader
          title="Content Type"
          isSearch={false}
          placeholder={"Search speaker"}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          primaryBtn=""
          handlePrimaryBtn={() => {
            this.setState({ showEdit: true })
          }}
        />
        <div className="commom-table-wrapper">

          <CustomTable
            tableData={listData?.list || []}
            columnList={[
              {
                coumnWidth: 250,
                labelName: "Name",
                dataKey: "contentTypeName",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "contentTypeName") {
                    return rowData.contentTypeName;
                  }
                },
              },

            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getContentTypeApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentTypeTab);
