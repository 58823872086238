import { postLoginInstance } from "../../utils";
import { START_PAGE } from "../../utils/Constant";
import { getAllDashboardData } from "./DashboardAction";


export const getDashboardApi = (ctx = null) => {

  return function (dispatch, getState) {
    return postLoginInstance.get(`/dashboardStats`)
      .then((res) => {

        dispatch(getAllDashboardData(res.data.data, ctx?.state?.currentPage || START_PAGE));

      });
  }

};