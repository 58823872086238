import { toast } from "react-toastify";
import { postLoginInstance, preLoginInstance } from "../../utils";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
import { getAllListData } from "../MasterCategory/MasterCategoryAction";

export const getAllCampaignApi = (ctx = {}, onHide = null) => {
  const { verifyUrl } = ctx.state;
  return function (dispatch, getState) {
    return postLoginInstance.get(
      `campaign?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
      }`
    )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.campaignName,
              value: item.uuid
            }))
          ctx.setState({
            campaignOption: option
          })
        } else {
          dispatch(getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }

        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addCampaignApi = async (data, ctx) => {
  try {
    const response = await postLoginInstance.post('campaign', data);
    if (response.status < 250 && response.status > 199) {
      toast.success("Successfully Save.")
      ctx.props.getAllCampaignApi(ctx);
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
    return false;
  }
}
export const updateCampaignApi = async (url, data, ctx = null) => {
  try {
    const response = await postLoginInstance.put(url, data);
    console.log(response)
    if (response.status < 250 && response.status > 199) {
      toast.success("Successfully Updated.")
      ctx.props.getAllCampaignApi(ctx);
    }
    return response;
  } catch (error) {
    console.log(error)
    toast.error(error?.response?.data?.message || 'Something went wrong.');
    return false;
  }
}


export const deleteCampaignApi = async (id, ctx) => {
  try {
    const response = await postLoginInstance.delete(`campaign/${id}`);
    if (response.status < 250 && response.status > 199) {
      toast.success("Successfully Deleted.")
      ctx.props.getAllCampaignApi(ctx);
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  }
}


// ========================= EVENT ADS API START =========================
export const getAllEventAdsApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state || {};
  return function (dispatch, getState) {
    return postLoginInstance.get(
      `eventAd?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
      }`
    )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.eventAdName,
              value: item.uuid
            }))
          ctx.setState({
            campaignOption: option
          })
        } else {
          dispatch(getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }

        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};
export const addEventAdsApi = (data, ctx, onHide, ctx2) => {

  return postLoginInstance.post("eventAd", data).then((res) => {

    toast.success("Successfully Save.")
    ctx2.props.history.push('/event-ads')
    ctx2?.setState({ resLoading: false })
  })
    .catch((error) => {
      ctx2?.setState({ resLoading: false })
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};
export const updateEventAdsApi = (data, ctx, onHide, id, ctx2) => {

  return postLoginInstance.put(`eventAd/${id}`, data).then((res) => {
    ctx2.props.history.push('/event-ads')
    toast.success("Successfully Updated.")
    ctx2?.setState({ resLoading: false })
  })
    .catch((error) => {
      ctx2?.setState({ resLoading: false })
      toast.error(error.message || 'Something went wrong.');
    })
};

export const deleteEventAdsApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`eventAd/${id}`).then((res) => {
    ctx.props.getAllEventAdsApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error.message || 'Something went wrong.');
  })
};
// =====================ASSIGN PLAN API START HERE
export const getAssignPlanApi = (ctx = {}, onHide = null) => {
  return function (dispatch, getState) {
    return postLoginInstance.get(
      `user-billing-plan?pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
      }`
    )
      .then((res) => {
        if (ctx?.state.isAddContent) {

        } else {
          dispatch(getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }

        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};
export const addAssignPlanApi = (data, ctx, onHide) => {

  return postLoginInstance.post("user-billing-plan", data).then((res) => {
    ctx.props.getAssignPlanApi(null, onHide);
    toast.success("Successfully Save.")
  })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};
export const updateAssignPlanApi = (data, ctx, onHide, id) => {

  return postLoginInstance.put(`user-billing-plan/${id}`, data).then((res) => {
    ctx.props.getAssignPlanApi(null, onHide);
    toast.success("Successfully Updated.")
  })
    .catch((error) => {
      toast.error(error.message || 'Something went wrong.');
    })
};

export const deleteAssignPlanApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`user-billing-plan/${id}`).then((res) => {
    ctx.props.getAssignPlanApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error.message || 'Something went wrong.');
  })
};
// ============================= USER GROPUP API START -==============
export const getAllUsergroupApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state || {};
  return function (dispatch, getState) {

    return postLoginInstance.get(
      `notification-group?${verifyUrl ? verifyUrl + '&' : ''}`

    )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.campaignName,
              value: item.uuid
            }))
          ctx.setState({
            campaignOption: option
          })
        } else {
          dispatch(getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }

        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error.message || 'Something went wrong.');
      })
  };
};

export const addUserGroupApi = (data, ctx, onHide, ctx2) => {

  return postLoginInstance.post("notification-group", data).then((res) => {
    ctx.props.getAllUsergroupApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Save.");
  })
    .catch((error) => {
      ctx2?.setState({ resLoading: false })
      toast.error(error.message || 'Something went wrong.');
    })
};
export const updateUserGroupApi = (data, ctx, onHide, id, ctx2) => {

  return postLoginInstance.patch(`notification-group/${id}`, data).then((res) => {
    ctx.props.getAllUsergroupApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Updated.")
  })
    .catch((error) => {
      ctx2?.setState({ resLoading: false })
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};

export const deleteUserGroupApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`notification-group/${id}`).then((res) => {
    ctx.props.getAllUsergroupApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
// ============================= TEMPLATE API START -==============
export const getAllTemplateApi = (ctx = {}, onHide = null) => {
  const { verifyUrl } = ctx.state;
  return function (dispatch, getState) {

    return postLoginInstance.get(
      `notification-template?${verifyUrl ? verifyUrl + '&' : ''}`

    )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.title + " - " + item.type.toUpperCase(),
              value: item.uuid
            }))
          ctx.setState({
            templateOptions: option
          })
        } else {
          dispatch(getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }

        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error.message || 'Something went wrong.');
      })
  };
};

export const addTemmplateApi = (data, ctx, onHide) => {

  return postLoginInstance.post("notification-template", data).then((res) => {
    ctx.props.getAllTemplateApi(null, onHide);
    toast.success("Successfully Save.");
  })
    .catch((error) => {
      toast.error(error.message || 'Something went wrong.');
    })
};
export const updateTemplateApi = (data, ctx, onHide, id) => {

  return postLoginInstance.patch(`notification-template/${id}`, data).then((res) => {
    ctx.props.getAllTemplateApi(null, onHide);
    toast.success("Successfully Updated.")
  })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};

export const deleteTemplateApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`notification-template/${id}`).then((res) => {
    ctx.props.getAllTemplateApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
// =========== NOTIFICATION SETUP APIS START
export const getAllNotificationSetupApi = (ctx = {}, onHide = null) => {
  const { verifyUrl } = ctx.state;
  return function (dispatch, getState) {
    return postLoginInstance.get(
      // `notification-setup`
      `notification-setup?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`
    )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.campaignName,
              value: item.uuid
            }))
          ctx.setState({
            campaignOption: option
          })

        } else {
          dispatch(getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }

        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error.message || 'Something went wrong.');
      })
  };
};

export const addNotificationSetupApi = (data, ctx, onHide) => {

  return postLoginInstance.post("notification-setup", data).then((res) => {
    ctx.props.getAllNotificationSetupApi(ctx, onHide);
    toast.success("Successfully Save.");
    ctx.setState({
      showEdit: false
    })
  })
    .catch((error) => {
      toast.error(error.message || 'Something went wrong.');
    })
};
export const updateNotificationSetupApi = (data, ctx, onHide, id) => {

  return postLoginInstance.put(`notification-setup/${id}`, data).then((res) => {
    ctx.props.getAllNotificationSetupApi(ctx, onHide);
    toast.success("Successfully Updated.")
    ctx.setState({
      showEdit: false
    })
  })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })

};




export const getAllTargetedListApi = (ctx = {}, onHide = null) => {
  return function (dispatch, getState) {
    return postLoginInstance.get(
      `targetedContent?pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
      }`
    )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.targetedContentName,
              value: item.uuid
            }))
          ctx.setState({
            targetedContentOption: option
          })
        } else {
          dispatch(
            getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }
        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addTargetedApi = (data, ctx, onHide, ctx2) => {

  return postLoginInstance.post("targetedContent", data).then((res) => {
    ctx.props.getAllTargetedListApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Save.")
  })
    .catch((error) => {
      ctx2?.setState({ resLoading: false })
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};
export const updateTargetedApi = (data, ctx, onHide, id, ctx2) => {

  return postLoginInstance.put(`targetedContent/${id}`, data).then((res) => {
    ctx.props.getAllTargetedListApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Updated.")
  })
    .catch((error) => {
      ctx2?.setState({ resLoading: false })
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};

export const deleteTargetedApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`targetedContent/${id}`).then((res) => {
    ctx.props.getAllTargetedListApi(null, onHide);
    toast.success("Successfully Deleted.")
  })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};


export const getCollectionDetailsApi = (ctx) => {
  return postLoginInstance.get(`/${ctx.state.url}/${ctx.state.id}`)
    .then((res) => {

      ctx.setState({
        data: res.data.data
      })
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
}
export const getAllUsersApi = (ctx) => {
  return postLoginInstance.get(`customer?pageSize=1000000000&pageNumber=1`)
    .then((res) => {

      ctx.setState({
        usersOptions: res.data.data
      })
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
}
export const getPlanApi = (ctx) => {
  return postLoginInstance.get(`premium-plan`)
    .then((res) => {

      ctx.setState({
        planOptions: res.data.data
      })
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
}
export const getAllShedularApi = (ctx) => {
  return postLoginInstance.get(`notification-sheduler`)
    .then((res) => {

      ctx.setState({
        scheudlerUuidOptions: res.data.data
      })
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
}
export const getGroupUserApi = (ctx) => {
  return postLoginInstance.get(`notification-group`)
    .then((res) => {

      ctx.setState({
        usersGroupOptions: res.data.data
      })
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
}
export const getAllGroupUsers = (ctx) => {
  return postLoginInstance.get(`notification-group/${ctx.props?.editData?.uuid}`)
    .then((res) => {

      ctx.setState({
        userUuids: res.data.data?.members?.map(item => item?.user?.uuid)
      })
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
}
export const getFilterListTargeted = (ctx) => {
  return postLoginInstance.get(`/targetedContentFilters`)
    .then((res) => {
      ctx.setState({
        targetedList: res.data.data
      })
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
}