import React, { Component } from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Col, Image, Row } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { connect } from "react-redux";
import { ALT_THUMBNAIL, API_LIMIT, Notifications, START_PAGE, isModuleExist } from "../../utils/Constant";
import { deleteAppNotificationApi, getAllAppNotificationApi } from "./Api";
import { ComponentHeader, InformationModal, VerifiedModal } from "../common";
import InAppNotificationForm from "./InAppNotificationForm";
import { INAPPNOTIFFICATION_VERIFY } from "../../utils/urls";
import editPencil from "../../assets/images/edit-icon.png";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";


export class InAppNotification extends Component {
  constructor(props) {

    super(props);
    const AccesData = isModuleExist(Notifications, 'In App Notification');
    this.state = {
      access: AccesData,
      dummyData: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      languageList: [],
      totalPages: null,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,

      isVerify: false,
      show: false,
      verified: '',
      verifyUrl: ''
    };
  }
  // todo---------------------------------------
  componentDidMount() {

    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllAppNotificationApi(this);
  }
  // todo---------------------------------------
  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";
    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;

    const verified = params.get("isVerified") || "";
    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllAppNotificationApi(this);

      });
    }
  }
  // todo:-----------------------------------------

  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {

    deleteAppNotificationApi(id, this, this.openCloseDeleteModal)
  }
  render() {
    const { showEdit, access } = this.state;
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;
    return (
      <section className="request-section">

        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.title} ?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        <VerifiedModal
          name={`'${this.state.editData?.title}'`}
          show={this.state.show}
          url={INAPPNOTIFFICATION_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getAllAppNotificationApi(this) }}
        />
        {
          showEdit &&
          <InAppNotificationForm
            show={showEdit}
            onHide={this.showEditModal}
            editData={this.state.editData}
            this={this}
          />
        }
        <ComponentHeader
          title="App Notification"
          isSearch={false}
          placeholder={"Search "}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          primaryBtn={access.createAccess ? "Add App Notification" : ""}
          handlePrimaryBtn={() => {

            this.setState({ showEdit: true })
          }}
        />
        <Row>
          <Col sm={2}>
            <FilterVefiedDropDown ctx={this} />
          </Col>
        </Row>
        <div className="commom-table-wrapper">

          <CustomTable
            tableData={listData?.list || []}
            columnList={[
              {
                coumnWidth: 250,
                labelName: "Title",
                dataKey: "title",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "title") {
                    return rowData.title;
                  }
                },
              },
              {
                coumnWidth: access.verificationAccess ? 100 : 0,
                labelName: "Verified",
                dataKey: "verify",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "verify") {
                    return <div className=" d-flex align-items-center inter-medium">
                      {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                    </div>;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Cta Label",
                dataKey: "ctaLabel",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "ctaLabel") {
                    return rowData.ctaLabel;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Updated By",
                dataKey: "updatedBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "updatedBy") {
                    return rowData.updatedBy?.name;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Created By",
                dataKey: "createdBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "createdBy") {
                    return rowData.createdBy?.name;
                  }
                },
              },
              {
                coumnWidth: 250,
                labelName: "Description",
                dataKey: "description",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "description") {
                    return rowData?.discription;
                  }
                },
              },

              {
                coumnWidth: 200,
                labelName: "Thumbnail Image",
                dataKey: "thumbnailPath",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "thumbnailPath") {
                    return (rowData.thumbnailPath ? <Image className="table-img" src={rowData.thumbnailPath} alt={ALT_THUMBNAIL} /> : "NA");
                  }
                },
              },


              {
                coumnWidth: 150,
                labelName: "Updated By",
                dataKey: "updatedBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "updatedBy") {
                    return rowData.updatedBy?.name || 'Na';
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Created By",
                dataKey: "createdBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "createdBy") {
                    return rowData.createdBy?.name || 'Na';
                  }
                },
              },
              {
                coumnWidth: 200,
                labelName: "",
                dataKey: "",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <>
                        {access.updateAccess && <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />}
                        {access.deleteAccess && <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />}


                      </>
                    );
                  }
                },
              },
            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getAllAppNotificationApi
};

export default connect(mapStateToProps, mapDispatchToProps)(InAppNotification);