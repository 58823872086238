import { START_PAGE } from "../../utils/Constant";
import { GET_ALL_LIST_DATA, GET_ALL_SPEAKER, GET_ALL_COLOR,GET_ALL_LANGUAGE, GET_ALL_KEYWORD, GET_ALL_CATEGORY, GET_ALL_EVENT, GET_ALL_CONTENT_CREATOR } from "./ActionTypes";

export const getAllListData = (payload, currentPage = START_PAGE) => ({
  type: GET_ALL_LIST_DATA,
  payload,
  currentPage,
});
export const getAllSpeaker = (payload, currentPage = START_PAGE) => ({
  type: GET_ALL_SPEAKER,
  payload,
  currentPage,
});
export const getAllLanguage = (payload, currentPage = START_PAGE) => ({
  type: GET_ALL_LANGUAGE,
  payload,
  currentPage,
});

export const getAllColor = (payload, currentPage = START_PAGE) => ({
  type: GET_ALL_COLOR,
  payload,
  currentPage,
});

export const getAllEvent = (payload, currentPage = START_PAGE) => ({
  type: GET_ALL_EVENT,
  payload,
  currentPage,
});

export const getAllKeyword = (payload, currentPage = START_PAGE) => ({
  type: GET_ALL_KEYWORD,
  payload,
  currentPage,
});

// export const getAllCategory = (payload, currentPage = START_PAGE) => ({
//   type: GET_ALL_CATEGORY,
//   payload,
//   currentPage,
// });

// export const getAllContentTypeApi = (payload, currentPage = START_PAGE) => ({
//   type: GET_ALL_CATEGORY,
//   payload,
//   currentPage,
// });

// export const getAllContentCreatorApi = (payload, currentPage = START_PAGE) => ({
//   type: GET_ALL_CONTENT_CREATOR,
//   payload,
//   currentPage,
// });
