import { toast } from "react-toastify";
import { postLoginInstance } from "../../utils";
import { API_LIMIT, durationObj, MAX_LIMIT, START_PAGE } from "../../utils/Constant";
import { getAllCalenderData, getAllCalenderFilterData, getAllSubdomainData, getAllTypesData, getSubdomainTypesData, getTargetedListData } from "./SubDomainAction";

export const getAllTypeApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`sso/types?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {

                // return ctx.setState({ dummyData: res.data.data ,});

                dispatch(getAllTypesData(res.data.data, ctx?.state.currentPage || START_PAGE));
                let options = res.data.data.list.map((item) => ({
                    value: item.uuid,
                    label: item.type
                }));

                ctx.setState({ typesOptions: options })
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getAllSubdomainTypeApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`sub-domain/types?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {

                // return ctx.setState({ dummyData: res.data.data ,});

                dispatch(getSubdomainTypesData(res.data.data, ctx?.state.currentPage || START_PAGE));
                let options = res.data.data.list.map((item) => ({
                    value: item.uuid,
                    label: item.type
                }));

                ctx.setState({ subdomainTypesOptions: options })
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getAllSubdomainApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`sub-domain?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {

                // return ctx.setState({ dummyData: res.data.data ,});
                dispatch(getAllSubdomainData(res.data.data, ctx?.state.currentPage || START_PAGE));

            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getSubdomainDetailsApi = (path, ctx) => {
    console.log(ctx, "subdomain")
    return postLoginInstance.get(`${path}/${ctx.props?.location?.state?.id || ctx.props.match.params.id}`)
        .then((res) => {
            return ctx.setState({ data: res.data.data });

        })
        .catch((err) => {
            console.log('Eroorr', err)
            toast.error(err?.response?.data?.message || 'Something went wrong.');
        })
};
export const getDetailsSubdomainApi = (path, ctx, plansOptions) => {
    console.log(ctx, "subdomain")
    return postLoginInstance.get(`${path}/${ctx.props?.location?.state?.id || ctx.props.match.params.id}`)
        .then((res) => {


            const d = res.data.data
            const variationData = plansOptions?.find(ele => ele?.uuid == d?.premiumPlan?.uuid)

            const processedModules = d.modules?.map(module => {
                const processedModule = {
                    uuid: module.uuid,
                    moduleName: module.moduleName,
                    amc: module.amcs.map(amc => amc.uuid), // Join amcUuids with comma
                    creator: module.contentCreators?.map(creator => creator.uuid), // Join contentCreatorUuids with comma
                    uuids: '', // Initialize uuids as an empty string,
                    isAmcShowContent: module?.amcs?.length > 0 ? true : false,
                    selectionType: module?.uuids?.length === 1 && module?.contentCreators?.length === 0 ? "single" : "multiple"
                };

                if (module?.uuids?.length > 0 && module?.contentCreators?.length === 0) {
                    // Case 1: Set states based on `moduleName`
                    switch (module.moduleName) {
                        case "CONTENT":
                            processedModule.selectedContent = module?.uuids;
                            break;
                        case "CAMPAIGN":
                            processedModule.campaign = module?.uuids?.map((item) => item.uuid);
                            break;
                        case "EVENT":
                            processedModule.event = module?.uuids?.map((item) => item.uuid);
                            break;
                        case "LANGUAGE":
                            processedModule.language = module?.uuids?.map((item) => item.uuid);
                            break;
                        case "TOOLS":
                            processedModule.tools = module?.uuids?.map((item) => item.uuid);
                            break;
                        case "PERSONALIZE":
                            processedModule.personalize = module?.uuids?.map((item) => item.uuid);
                            break;
                        case "CATEGORY":
                            processedModule.category = module?.uuids?.map((item) => item.uuid);
                            break;
                        default:
                            break;
                    }
                } else if (module?.contentCreators?.length > 0) {
                    // Case 2: Clear all states
                    processedModule.selectedContent = [];
                    processedModule.campaign = [];
                    processedModule.event = [];
                    processedModule.language = [];
                    processedModule.tools = [];
                    processedModule.personalize = [];
                    processedModule.category = [];
                }


                return processedModule;
            });
            return ctx.setState({
                editData: d, subdomainUuid: d?.uuid || "", companyName: d?.companyName || "", uploadImage: d?.companyLogo || null, amcContent: d?.amc?.map(item => item?.uuid) || "", creatorContent: d?.contentCreator?.map(item => item.uuid) || "", amcEvent: d?.amc?.map(item => item?.uuid) || "", creatorEvent: d?.contentCreator?.map(item => item.uuid) || "", link: d?.websiteLink || "",
                contact: d?.mobileNumber || "",
                email: d?.emailAddress || "",
                logoPosition: d?.logoPosition || "",
                subdomainType: d?.ssoPartnerType?.uuid || "", modules: processedModules,
                premium: d?.premiumPlan?.uuid,
                subPremiumPlan: variationData, subPremium: d?.subPremiumPlan?.uuid,
                subPremiumPlanVariation: variationData?.subPremiumPlan?.find(ele => ele?.uuid == d?.subPremiumPlan?.uuid), variation: d?.subPremiumPlanVariation?.uuid, creatorContent: d?.contentsAllowedOf
                    ?.filter(item => item?.contentCreatorName)
                    .map(item => item?.uuid || ""),
            });
        })
        .catch((err) => {
            console.log('Eroorr', err)
            toast.error(err?.response?.data?.message || 'Something went wrong.');
        })
};

export const getAllCalenderApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`calender?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {

                // return ctx.setState({ dummyData: res.data.data ,});
                dispatch(getAllCalenderData(res.data.data, ctx?.state.currentPage || START_PAGE));

            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getAllCalenderFilterApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`calender-filter?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {

                // return ctx.setState({ dummyData: res.data.data ,});
                dispatch(getAllCalenderFilterData(res.data.data, ctx?.state.currentPage || START_PAGE));
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getCalenderDetailsApi = (path, ctx) => {
    console.log(ctx, "subdomain")
    return postLoginInstance.get(`${path}/${ctx.props?.location?.state?.id || ctx.props.match.params.id}`)
        .then((res) => {
            return ctx.setState({ data: res.data.data });

        })
        .catch((err) => {
            console.log('Eroorr', err)
            toast.error(err?.response?.data?.message || 'Something went wrong.');
        })
};

export const getAllCalendersDataApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`calender?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {
                let option = [];
                res.data.data.list.map((item) =>
                    option.push({
                        label: item.calenderName,
                        value: item.uuid
                    }))
                ctx.setState({
                    personalizationOptions: option
                })

            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getAllToolsDataApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`tool?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {
                let option = [];
                res.data.data.list.map((item) =>
                    option.push({
                        label: item.toolName,
                        value: item.uuid
                    }))
                ctx.setState({
                    toolOptions: option
                })

            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getAllModulesDataApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`sub-domain/master-modules?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {
                let option = [];
                res.data.data.list.map((item) =>
                    option.push({
                        label: item.moduleName,
                        value: item.moduleName,
                        uuid: item.uuid
                    }))
                ctx.setState({
                    moduleNameOptions: option
                })


            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getTargetedContentSubdomainApi = (ctx = {}, onHide = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {
        return postLoginInstance
            .get(
                `targetedContent?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT
                }&pageNumber=${ctx?.state.currentPage || START_PAGE}`
            )
            .then((res) => {
                let option = [];

                res?.data?.data?.list?.map((item) =>
                    option.push({
                        label: item?.targetedContentName,
                        value: item.uuid
                    }))
                ctx.setState({
                    targetedContentOptions: option

                })
            }).catch((error) => {
                toast.error(error?.response?.data?.message || 'Something went wrong.');
            })
    };
};
