import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import { Table, Row, Col } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { isModuleExist } from "../../utils/Constant";
import { getDetaisContentApi } from "../content/Api";
class PremiumDetails extends BaseReactComponent {
  constructor(props) {
    // console.log(props)
    super(props);
    const { params } = props.match;
    const AccesData = isModuleExist("Content", 'Content');
    this.state = {
      access: AccesData,
      params,
      show: false,
      firstname: "",
      lastname: "",
      pleaseselect: "",
      detailsData: [],
    };
  }
  componentDidMount = () => {
    getDetaisContentApi("/sub-premium-plan", this);
  }
  // componentDidMount() { }
  returnDate(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  }
  updatedVerifiedHandler = () => {
    // const info = {
    //   "data": {
    //     "isVerified": !this.state?.data?.isVerified
    //   }
    // }
    // const newData = new FormData();
    // newData.append("key", JSON.stringify(info));
    // patchAuthCommonApi(`content/${this.props.match.params.contentID}`, newData, this);



  }
  render() {
    const { data, detailsData, params } = this.state;
    // console.log(data)
    return (
      <section className="user-section">
        {/* // ---------------------------------------View details -------------------------------- */}

        <div className="view-details-wrapper ">

          <ComponentHeader
            title={`Plan Details`}
            isBackArrow={true}
            isReturn={"/subscription-plan"}
          />
          <div className="view-details d-flex">
            <div className="view-details-content">
              <div className="user-details d-flex">

                <div className="name-details ml-0">
                  <h3 className="inter-medium overflow-eclipse mr-5 f-s-20">{data?.premiumPlan?.name || ""} </h3>
                  <h4 className="inter-medium grey-3 f-s-14">{data?.name}</h4>

                </div>
                <div className="line "></div>
                <div className="name-description">
                  <h3 className="grey-2 ">Description</h3>
                  <p className="inter-medium f-s-14">{data?.premiumPlan?.description || ""}</p>
                </div>
              </div>
              <h3 className="inter-regular f-s-14 grey-2">
                Plan Details
              </h3>
              <div className="line2"></div>
              <Table className="custom-table">
                <Row>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Premium Plan</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.premiumPlan?.name || ""}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Plan Name</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.name || ""}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Is Custom Plan</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.premiumPlan?.isCustomPlan ? 'Yes' : 'No' || ""}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Is Verified</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.isVerified ? 'Yes' : 'No' || ""}</h3>
                    </div>
                  </Col>

                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Is Top up</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.premiumPlan?.isTopup ? 'Yes' : 'No' || ""}</h3>
                    </div>
                  </Col>

                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Expiry In Days</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.premiumPlan?.expiryInDays || ""}</h3>
                    </div>
                  </Col>

                </Row>
              </Table>


              {data?.subPremiumPlanVariations?.length > 0 &&
                <div className="variation-wrapper">
                  <h3 className="inter-regular f-s-14 grey-2">Premium Plan Variation</h3>
                  <div className="line2"></div>
                  {
                    data?.subPremiumPlanVariations?.map((item, i) => {
                      return (
                        <Table>
                          <Row>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Title</h4>
                                <h3 className="inter-semibold f-s-14 ">{item?.title || ""}</h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Description</h4>
                                <h3 className="inter-semibold f-s-14 " dangerouslySetInnerHTML={{ __html: item?.description }}></h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Price</h4>
                                <h3 className="inter-semibold f-s-14 ">{item?.price || ""}</h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Discounted Price</h4>
                                <h3 className="inter-semibold f-s-14 ">{item?.discountedPrice || ""}</h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Reset In Days</h4>
                                <h3 className="inter-semibold f-s-14 ">{item?.resetInDays || ""}</h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Is Verified</h4>
                                <h3 className="inter-semibold f-s-14 ">{item?.isVerified ? 'Yes' : 'No' || ""}</h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Reset In Days</h4>
                                <h3 className="inter-semibold f-s-14 ">{item?.resetInDays || ""}</h3>
                              </div>
                            </Col>
                            <Col sm={12} xs={12}>
                              <h3 className="inter-regular f-s-14 grey-2">Features / Benefits</h3>
                              <div className="line2 mt-2 w-100"></div>
                            </Col>

                            {item?.subPremiumPlanVariationSpecification?.map((ele, id) => {
                              return (<>
                                <Col sm={12} xs={12}>
                                  <p className="inter-regular grey-757 f-s-12 mb-4">Features -{id + 1}  </p>
                                </Col>

                                <Col sm={3} xs={12}>
                                  <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">Type</h4>
                                    <h3 className="inter-semibold f-s-14 ">{ele?.type || ""}</h3>
                                  </div>
                                </Col>
                                <Col sm={3} xs={12}>
                                  <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">Action Type</h4>
                                    <h3 className="inter-semibold f-s-14 ">{ele?.actionType || ""}</h3>
                                  </div>
                                </Col>
                                <Col sm={3} xs={12}>
                                  <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">Content Category</h4>
                                    <h3 className="inter-semibold f-s-14 ">{ele?.contentCategory || ""}</h3>
                                  </div>
                                </Col>
                                <Col sm={3} xs={12}>
                                  <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">Limit</h4>
                                    <h3 className="inter-semibold f-s-14 ">{ele?.limit || ""}</h3>
                                  </div>
                                </Col>
                                <Col sm={3} xs={12}>
                                  <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">Reset In Days</h4>
                                    <h3 className="inter-semibold f-s-14 ">{ele?.resetInDays || ""}</h3>
                                  </div>
                                </Col>
                                <Col sm={3} xs={12}>
                                  <div className="detail">
                                    <h4 className="inter-semibold grey-2 ">Reset In Days</h4>
                                    <h3 className="inter-semibold f-s-14 ">{ele?.resetInDays || ""}</h3>
                                  </div>
                                </Col>
                                <Col sm={12} xs={12}>
                                  <div className="line2 w-100"> </div>
                                </Col>
                              </>
                              )
                            })

                            }

                          </Row>
                        </Table>
                      )
                    })
                  }


                </div>
              }

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ usersState: state.UsersState });
const mapDispatchToProps = {
  // getPosts: fetchPosts
};
PremiumDetails.propTypes = {
  // getPosts: PropTypes.func
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumDetails);
