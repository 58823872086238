import React, { Component } from 'react'
import { Button, Col, Image, Row, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { ComponentHeader } from '../common'
import SearchContent from '../content/SearchContent'
import { getCollectionDetailsApi } from './Api'

export class TargetedDetails extends Component {
    constructor(props) {
        super(props)

        const id = props.match.params.id;
        this.state = {
            url: 'targetedContent/details',
            type: 'targeted-details',
            id,
            data: "",
            showSearchContent: false,
        }
    }
    componentDidMount() {
        getCollectionDetailsApi(this);
    }
    render() {
        const { data } = this.state;

        return (
            <div className="page-padding">
                {
                    this.state.showSearchContent &&
                    <SearchContent
                        showModal={this.state.showSearchContent}
                        onHide={() => this.setState({ showSearchContent: false })}
                    />
                }
                <div className="view-details-wrapper">
                    <ComponentHeader
                        title="Targeted Details"
                        isBackArrow={true}
                        isReturn="/contentCollections?tab=targeted&p=1" />
                    <div className="view-details d-flex">
                        {
                            data &&
                            <div className="view-details-content">
                                <div className="user-details d-flex">
                                    <div className="eclipse">
                                        <Image src={data?.circleImagePath} alt='' />
                                    </div>
                                    <div className="name-details">
                                        <h3 className="inter-medium f-s-20">{data[0]?.targetedContentName}</h3>
                                        <h4 className="inter-medium grey-3">{data[0]?.uuid}</h4>

                                    </div>
                                    {data?.description && <>
                                        <div className="line"></div>
                                        <div className="name-description">
                                            <h3 className="grey-2 ">Description</h3>
                                            <p className="inter-medium f-s-14">
                                                {data[0]?.description}
                                            </p>
                                        </div>
                                    </>
                                    }
                                </div>

                                <Table className="custom-table">
                                    <Row>

                                        {Object.keys(data[0]?.contentTypeStats || {}).map((item) => {
                                            return (
                                                <Col sm={3} xs={12}>
                                                    <div className="detail">
                                                        <h4 className="inter-semibold grey-2 ">{item} </h4>
                                                        <h3 className="inter-semibold f-s-14 ">{data[0]?.contentTypeStats[item] || 'Na'}</h3>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                        <Col sm={3} xs={12}>
                                            <div className="detail">
                                                <h4 className="inter-semibold grey-2 ">Thumbnail Image</h4>
                                                <Image src={data[0]?.thumbnailPath} alt="icon" className="details-image-box" />
                                            </div>
                                        </Col>
                                        <Col sm={3} xs={12}>
                                            <div className="detail">
                                                <h4 className="inter-semibold grey-2 ">Banner Image</h4>
                                                <Image src={data[0]?.bannerPath} alt="icon" className="details-image-box" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Table>
                                <div className="d-flex justify-content-end">
                                    <Button onClick={() => this.setState({ showSearchContent: true })} className='primary-btn'>Add Content</Button>
                                </div>
                                <div className="line2"></div>
                                <div className="content-type-tab">

                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TargetedDetails)