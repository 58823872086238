import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  BaseReactComponent,
  FormSubmitButton,
  SelectControl,
} from "../../utils/form";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CustomModal } from "../common";
import { addNotificationSetupApi, getAllTemplateApi, getAllUsersApi, getGroupUserApi, getPlanApi, updateNotificationSetupApi, } from "./Api";
import { toast } from "react-toastify";

class NotificationSetupForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props?.editData || null;

    this.state = {
      userUuid: data?.user?.uuid || "",
      notificationGroupUuid: data?.notificationGroups?.uuid || '',
      scheudlerUuid: data?.dateTime ? new Date(data?.dateTime) : "",
      planUuid: data?.premiumPlan?.uuid || "",
      notificationTempleteUuid: data?.notificationTemplete?.uuid || "",
      isAddContent: true,
      usersOptions: [],
      usersGroupOptions: [],
      scheudlerUuidOptions: [],
      planOptions: [],
      templateOptions: [],

    };

  }

  componentDidMount() {
    getAllUsersApi(this);
    this.props.getAllTemplateApi(this);
    getGroupUserApi(this);
    getPlanApi(this);

  }
  componentDidUpdate() {

  }



  onValidSubmit = () => {
    const { userUuid, notificationGroupUuid, notificationTempleteUuid, scheudlerUuid, planUuid } = this.state;

    let groupData = {
      "data": {
        ...(userUuid && { "userUuid": userUuid }),
        ...(notificationGroupUuid && { "notificationGroupUuid": notificationGroupUuid }),
        ...(notificationTempleteUuid && { "notificationTempleteUuid": notificationTempleteUuid }),
        ...(scheudlerUuid && { "date": scheudlerUuid }),
        ...(planUuid && { 'planUuid': planUuid })
      }
    };


    if (this.props?.editData?.uuid) {
      updateNotificationSetupApi(groupData, this.props.this, this.props.onHide, this.props.editData?.uuid)
    } else {
      if (userUuid || notificationGroupUuid || notificationTempleteUuid) {
        addNotificationSetupApi(groupData, this.props.this, this.props.onHide)
      } else {
        toast.error('At least select user.')
      }
    }
  }
  onHide = () => {

    this.props.onHide();
  }
  render() {
    const { usersOptions, usersGroupOptions, planOptions, templateOptions } = this.state;

    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props?.editData?.uuid ? "Edit notification-setup" : "Add Notification Setup"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>

            <Col md={6}>
              <FormElement
                valueLink={this.linkState(this, "userUuid")}
                label="Select Users"

                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select User",
                    options: usersOptions?.list?.map(item => ({
                      label: `${item?.firstName || 'Na'} ${item?.lastName || ''}`,
                      value: `${item?.uuid}`
                    })),
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.userUuid);
                    },
                  },
                }}
              />
            </Col>
            <Col md={6}>
              <FormElement
                valueLink={this.linkState(this, "notificationGroupUuid")}
                label="Select Users Group"

                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select User",
                    options: usersGroupOptions?.list?.map(item => ({
                      label: `${item.groupName || 'Na'}`,
                      value: `${item.uuid}`
                    })),
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.notificationGroupUuid);
                    },
                  },
                }}
              />
            </Col>

            <Col md={6}>
              <FormElement
                valueLink={this.linkState(this, "planUuid")}
                label="Select Plan"

                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Plan",
                    options: planOptions?.list?.map(item => ({
                      label: `${item?.name || 'Na'}`,
                      value: `${item?.uuid}`
                    })),
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.planUuid);
                    },
                  },
                }}
              />
            </Col>
            <Col md={6}>
              <FormElement
                valueLink={this.linkState(this, "notificationTempleteUuid")}
                label="Select Template"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Content type cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Template",
                    options: templateOptions,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.notificationTempleteUuid);
                    },
                  },
                }}
              />
            </Col>
            <Col md={12}>
              <div className="form-group custom-date-range-picker">
                <label htmlFor="" className="form-label">Schedule Date</label>
                <DatePicker
                  selected={this.state.scheudlerUuid}
                  onChange={(date) => this.setState({ scheudlerUuid: date })}
                  minDate={new Date()}
                  showTimeSelect
                  // filterTime={filterPassedTime}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  // dateFormat="dd/MM/yyyy"
                  placeholderText="Select Date"
                  className="form-control "
                  // showMonthDropdown
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                  filterTime={(time) => {
                    const selectedDate = new Date();
                    // Allow past time only if the selected day is not today
                    if (this.state.scheudlerUuid && this.state.scheudlerUuid.toDateString() === selectedDate.toDateString()) {
                      return time.getTime() > selectedDate.getTime(); // Filters out times before current time
                    }
                    return true;
                  }}

                />
              </div>
            </Col>

          </Row>

          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              Save
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
  // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {
  getAllUsersApi,
  getAllTemplateApi
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSetupForm);
