// import Home from "./app/home/Home";
import { Route } from 'react-router-dom';
import { Home } from './app/home';
import { Login } from './app/login';
import Sandbox from './utils/form/Sandbox';
import Profile from './app/profile/Profile';
import { Users, UserDetails, BulkCoBrand, BulkCoBrandForm } from './app/users';
import PrivateRoute from './utils/PrivateRoute';
import AddUser from './app/users/AddUser';
import { Events, AddEditEvent, ViewEventDetails } from './app/events';
import { AddEditSubscription, PremimumMainForm, PremimumPlanForm, PremimumPlanTab, PremiumDetails, Subscription, ViewSubscriptionDetails } from './app/subscription';
import { AddEditCustomers, CustomerDetails, Customers, ViewCustomersDetails } from './app/customers';
import { AddEditReports, Reports, ViewReportsDetails } from './app/reports';
import { ContentManagment, AddEditContentManagment, ViewContentManagmentDetails } from './app/contentManagment';
import { AddEditAds, Ads, BannerTab, ContentAds } from './app/ads';
import { AddEditMasterCategory, AmcForm, AmcTab, CategoryMasterForm, CategoryMasterTab, ColorForm, ColorTab, ContentCreatorForm, ContentCreatorTab, ContentTypeTab, CouponTab, EventCategoryForm, EventCategoryTab, EventOrganiserForm, EventOrganiserTab, EventSpeakerForm, EventSpeakerTab, InAppNotification, KeywordForm, KeywordTab, LanguageForm, LanguageTab, MasterCategory, Postoftheday, SocialMediaForm, SocialMediaTab, SubCategoryMaster, SubCategoryMasterForm, TargetedContentForm, TargetedContentTab, TrendingKeywordsTab, TrendingTab, ViewMasterCategoryDetails } from './app/MasterCategory';
import ContentTypeForm from "./app/MasterCategory/ContentTypeForm";
import { Content, AddEditContent, ViewContent, ContentDetails } from './app/content';
import { AmcDetails, Campaign, CampaignDetails, CategoryDetails, ContentCollections, EventsAds, EventsAdsForm, LanguageDetails, NotificationSetup, TargetedDetails, TemplateTab, UserGroup } from './app/contentCollections';
import { Dashboard } from './app/dashboard';
import { Roles } from './app/roles';
import AddEditRole from './app/roles/AddEditRole';
import { AddEditOccassion, AddEditPlaylist, Occassion, Platform, Playlist, ViewPlaylistDetails } from './app/autoshare';
import AddEditPlatform from './app/autoshare/AddEditPlatform';
import { AddEditSso, SsoPartners, ViewSsoDetails, ViewSsoUsers } from './app/sso';
import { SsoContent, SsoEvent, ViewSsoContent, ViewSsoEvent, ViewSsoUser } from './app/ssoContentEvent';
import SsoUser from './app/ssoContentEvent/SsoUser';
import { AddEditCalender, AddEditCalenderFilter, AddEditSubdomain, Calender, CalenderFilter, SubDomainPartner, ViewCalenderDetails, ViewCalenderFilter, ViewSubDomainUser } from './app/subdomain';
import { AddEditTemplate, PersonalizationCategory, Templates } from './app/personalization';
import { Canvas, CobrandContent } from './app/common';
import ViewTemplateDetails from './app/personalization/ViewTemplateDetails';

const routes = [
  {
    path: "/home",
    name: "Home",
    type: Route,
    component: Home
  },
  {
    path: ["/", '/reset-password/:token'],
    name: "Login",
    type: Route,
    component: Login
  },
  // {
  //   path: "/reset-password/:token",
  //   name: "Login",
  //   type: Route,
  //   component: Login
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    type: PrivateRoute,
    component: Dashboard
  },
  {
    path: "/profile",
    name: "Profile",
    type: PrivateRoute,
    component: Profile
  },
  {
    path: ["/sso/add-sso", "/edit-sso"],
    name: "AddEditSso",
    type: PrivateRoute,
    component: AddEditSso
  },
  {
    path: "/sso",
    name: "Sso",
    type: PrivateRoute,
    component: SsoPartners
  },
  {
    path: "/sso/view-users/:id",
    name: "ViewUsers",
    type: PrivateRoute,
    component: ViewSsoUsers
  },
  {
    path: "/sso/content",
    name: "Content",
    type: PrivateRoute,
    component: SsoContent
  },
  {
    path: "/sso/content-details/:id",
    name: "ViewContent",
    type: PrivateRoute,
    component: ViewSsoContent
  },
  {
    path: "/sso/events",
    name: "Event",
    type: PrivateRoute,
    component: SsoEvent
  },
  {
    path: "/sso/events-details/:id",
    name: "ViewEvent",
    type: PrivateRoute,
    component: ViewSsoEvent
  },
  {
    path: "/sso/web-user",
    name: "Web User",
    type: PrivateRoute,
    component: SsoUser
  },
  {
    path: "/sso/web-user/details/:ssoPartnerId/:id",
    name: "ViewWebUser",
    type: PrivateRoute,
    component: ViewSsoUser
  },
  {
    path: "/sso/:id",
    name: "ViewSsoDetails",
    type: PrivateRoute,
    component: ViewSsoDetails
  },
  {
    path: "/roles",
    name: "Roles",
    type: PrivateRoute,
    component: Roles
  },
  {
    path: ["/add-role", "/edit-role"],
    name: "AddEditRole",
    type: PrivateRoute,
    component: AddEditRole
  },
  {
    path: "/users",
    name: "Users",
    type: PrivateRoute,
    component: Users
  },
  {
    path: "/app-notification",
    name: "InAppNotification",
    type: PrivateRoute,
    component: InAppNotification
  },
  {
    path: "/users-details",
    name: "UsersDetails",
    type: PrivateRoute,
    component: UserDetails
  },
  {
    path: ["/campaign-detail/:id",],
    name: "CampaignDetail",
    type: PrivateRoute,
    component: CampaignDetails
  },
  {
    path: "/amc-detail/:id",
    name: "AmcDetails",
    type: PrivateRoute,
    component: AmcDetails
  },
  {
    path: "/category-detail/:id",
    name: "CategoryDetails",
    type: PrivateRoute,
    component: CategoryDetails
  },
  {
    path: "/language-detail/:id",
    name: "LanguageDetails",
    type: PrivateRoute,
    component: LanguageDetails
  },
  {
    path: '/targeted-details/:id',
    name: "TargetedDetails",
    type: PrivateRoute,
    component: TargetedDetails
  },
  // {
  //   path:"add-detail/:id",
  //   name: "AmcDetails",
  //   type: PrivateRoute,
  //   component: AmcDetails
  // },
  {
    path: ["/add-user", "/edit-user"],
    name: "AddUser",
    type: PrivateRoute,
    component: AddUser
  },

  {
    path: "/user-details/:id",
    name: "UserDetails",
    type: PrivateRoute,
    component: UserDetails
  },
  {
    path: "/events",
    name: "Events",
    type: PrivateRoute,
    component: Events
  },
  {
    path: "/bulk-cobrand",
    name: "Events",
    type: PrivateRoute,
    component: BulkCoBrand
  },
  {
    path: "/bulk-cobrand-form",
    name: "BulkCoBrandForm",
    type: PrivateRoute,
    component: BulkCoBrandForm
  },
  {
    path: ["/events/add-event", "/edit-event"],
    name: "AddEditEvent",
    type: PrivateRoute,
    component: AddEditEvent
  },
  {
    path: "/events/details/:id",
    name: "EventDetails",
    type: PrivateRoute,
    component: ViewEventDetails
  },
  {
    path: "/content",
    name: "Content",
    type: PrivateRoute,
    component: Content
  },
  {
    path: "/content-ads",
    name: "ContentAds",
    type: PrivateRoute,
    component: ContentAds
  },
  {
    path: "/event-ads",
    name: "EventsAds",
    type: PrivateRoute,
    component: EventsAds
  },
  {
    path: "/event-ads-form",
    name: "EventsAdsForm",
    type: PrivateRoute,
    component: EventsAdsForm
  },
  {
    path: ["/content-banner-ads", '/event-banner-ads'],
    name: "BannerTab",
    type: PrivateRoute,
    component: BannerTab
  },
  {
    path: ['/ads/:type/add', "/ads/:type/edit"],
    name: "AddEditAds",
    type: PrivateRoute,
    component: AddEditAds
  },
  {
    path: "/event-speaker",
    name: "EventSpeakerTab",
    type: PrivateRoute,
    component: EventSpeakerTab
  },
  {
    path: "/event-organiser",
    name: "EventOrganiserTab",
    type: PrivateRoute,
    component: EventOrganiserTab
  },
  {
    path: "/color",
    name: "ColorTab",
    type: PrivateRoute,
    component: ColorTab
  },
  {
    path: "/language",
    name: "LanguageTab",
    type: PrivateRoute,
    component: LanguageTab
  },

  {
    path: "/social-media",
    name: "SocialMediaTab",
    type: PrivateRoute,
    component: SocialMediaTab
  },
  {
    path: "/post",
    name: "Postoftheday",
    type: PrivateRoute,
    component: Postoftheday
  },
  {
    path: "/amc",
    name: "AmcTab",
    type: PrivateRoute,
    component: AmcTab
  },
  {
    path: "/category",
    name: "EventCategoryTab",
    type: PrivateRoute,
    component: EventCategoryTab
  },
  {
    path: "/coupon",
    name: "CouponTab",
    type: PrivateRoute,
    component: CouponTab
  },
  {
    path: "/campaign",
    name: "Campaign",
    type: PrivateRoute,
    component: Campaign
  },
  {
    path: "/user-group",
    name: "UserGroup",
    type: PrivateRoute,
    component: UserGroup
  },
  {
    path: "/template",
    name: "TemplateTab",
    type: PrivateRoute,
    component: TemplateTab
  },
  {
    path: "/notification-setup",
    name: "NotificationSetup",
    type: PrivateRoute,
    component: NotificationSetup
  },
  {
    path: "/subscription-plan",
    name: "UserGroupForm",
    type: PrivateRoute,
    component: PremimumPlanTab
  },
  {
    path: "/category-master",
    name: "CategoryMasterTab",
    type: PrivateRoute,
    component: CategoryMasterTab
  },
  {
    path: "/targeted-content",
    name: "TargetedContentTab",
    type: PrivateRoute,
    component: TargetedContentTab
  },
  {
    path: "/edit-targeted-content",
    name: "TargetedContentForm",
    type: PrivateRoute,
    component: TargetedContentForm
  },
  {
    path: "/sub-category",
    name: "SubCategoryMaster",
    type: PrivateRoute,
    component: SubCategoryMaster
  },
  {
    path: "/content-creator",
    name: "ContentCreatorTab",
    type: PrivateRoute,
    component: ContentCreatorTab
  },
  {
    path: "/content-type",
    name: "ContentTypeTab",
    type: PrivateRoute,
    component: ContentTypeTab
  },
  {
    path: ["/content/add-content", "/edit-content"],
    name: "AddEditContent",
    type: PrivateRoute,
    component: AddEditContent
  },

  {
    path: "/content-details/:contentID",
    name: "ContentDetails",
    type: PrivateRoute,
    component: ContentDetails
  },
  {
    path: "/contentCollections",
    name: "contentCollections",
    type: PrivateRoute,
    component: ContentCollections,
  },
  {
    path: "/subscription",
    name: "Subscription",
    type: PrivateRoute,
    component: Subscription
  },
  {
    path: "/premimum-planForm",
    name: "PremimumPlanForm",
    type: PrivateRoute,
    component: PremimumPlanForm
  },
  {
    path: "/premium-plan-details/:id",
    name: "PremiumDetails",
    type: PrivateRoute,
    component: PremiumDetails
  },
  {
    path: ["/add-subscription", "/edit-subscription"],
    name: "AddEditSubscription",
    type: PrivateRoute,
    component: AddEditSubscription
  },
  {
    path: "/subscription/:subscriptionID",
    name: "SubscriptionDetails",
    type: PrivateRoute,
    component: ViewSubscriptionDetails
  },
  {
    path: "/customers",
    name: "Customers",
    type: PrivateRoute,
    component: Customers
  },
  {
    path: ["/add-customers", "/edit-customers"],
    name: "AddEditCustomers",
    type: PrivateRoute,
    component: AddEditCustomers
  },
  {
    path: "/customers/:customersID",
    name: "CustomersDetails",
    type: PrivateRoute,
    component: ViewCustomersDetails
  },
  {
    path: "/reports",
    name: "Reports",
    type: PrivateRoute,
    component: Reports
  },
  {
    path: ["/add-reports", "/edit-reports"],
    name: "AddEditReports",
    type: PrivateRoute,
    component: AddEditReports
  },
  {
    path: "/reports/:reportsID",
    name: "ReportsDetails",
    type: PrivateRoute,
    component: ViewReportsDetails
  },
  {
    path: "/content-managment",
    name: "ContentManagment",
    type: PrivateRoute,
    component: ContentManagment
  },
  {
    path: ["/add-content-managment", "/edit-content-managment"],
    name: "AddEditContentManagment",
    type: PrivateRoute,
    component: AddEditContentManagment
  },
  {
    path: "/content-managment/:contentmanagmentID",
    name: "ContentManagment Details",
    type: PrivateRoute,
    component: ViewContentManagmentDetails
  },
  {
    path: "/master-category",
    name: "MasterCategory",
    type: PrivateRoute,
    component: MasterCategory
  },
  {
    path: "/content-type-form",
    name: "ContentTypeForm",
    type: PrivateRoute,
    component: ContentTypeForm
  },
  {
    path: "/category-master-form",
    name: "CategoryMasterForm",
    type: PrivateRoute,
    component: CategoryMasterForm
  },
  {
    path: "/sub-category-form",
    name: "SubCategoryMasterForm",
    type: PrivateRoute,
    component: SubCategoryMasterForm
  },
  {
    path: "/anc",
    name: "AmcTab",
    type: PrivateRoute,
    component: AmcTab
  },
  {
    path: "/trending",
    name: "TrendingTab",
    type: PrivateRoute,
    component: TrendingTab
  },
  {
    path: "/keyword",
    name: "KeywordTab",
    type: PrivateRoute,
    component: KeywordTab
  },
  {
    path: "/trending-keywords",
    name: "TrendingKeywordsTab",
    type: PrivateRoute,
    component: TrendingKeywordsTab
  },
  {
    path: "/anc-form",
    name: "AmcForm",
    type: PrivateRoute,
    component: AmcForm
  },
  {
    path: "/language-form",
    name: "LanguageForm",
    type: PrivateRoute,
    component: LanguageForm
  },
  {
    path: "/color-form",
    name: "ColorForm",
    type: PrivateRoute,
    component: ColorForm
  },
  {
    path: "/social-media-form",
    name: "SocialMediaForm",
    type: PrivateRoute,
    component: SocialMediaForm
  },
  {
    path: "/keyword-form",
    name: "KeywordForm",
    type: PrivateRoute,
    component: KeywordForm
  },
  {
    path: "/event-category-form",
    name: "EventCategoryForm",
    type: PrivateRoute,
    component: EventCategoryForm
  },
  {
    path: "/event-speaker-form",
    name: "EventSpeakerForm",
    type: PrivateRoute,
    component: EventSpeakerForm
  },
  {
    path: "/evetn-organiser-form",
    name: "EventOrganiserForm",
    type: PrivateRoute,
    component: EventOrganiserForm
  },
  {
    path: "/content-creator-form",
    name: "ContentCreatorForm",
    type: PrivateRoute,
    component: ContentCreatorForm
  },
  {
    path: ["/add-master-category", "/edit-master-category"],
    name: "AddEditMasterCategory",
    type: PrivateRoute,
    component: AddEditMasterCategory
  },
  {
    path: "/master-category/:master-categoryID",
    name: "MasterCategoryDetails",
    type: PrivateRoute,
    component: ViewMasterCategoryDetails
  },
  {
    path: "/ads",
    name: "Ads",
    type: PrivateRoute,
    component: Ads
  },
  {
    path: "/playlist",
    name: "Playlist",
    type: PrivateRoute,
    component: Playlist
  },
  {
    path: "/platform",
    name: "Platform",
    type: PrivateRoute,
    component: Platform
  },
  {
    path: "/occassion",
    name: "Occassion",
    type: PrivateRoute,
    component: Occassion
  },


  {
    path: ["/occassion/add-occassion", "/edit-occassion"],
    name: "AddEditOccassion",
    type: PrivateRoute,
    component: AddEditOccassion
  },
  {
    path: "/playlist/details/:id",
    name: "ViewPlaylistDetails",
    type: PrivateRoute,
    component: ViewPlaylistDetails
  },
  {
    path: ["/playlist/add-playlist", "/edit-playlist"],
    name: "AddEditPlaylist",
    type: PrivateRoute,
    component: AddEditPlaylist
  },
  {
    path: ["/platform/add-platform", "/edit-platform"],
    name: "AddEditPlatform",
    type: PrivateRoute,
    component: AddEditPlatform
  },
  {
    path: "/subdomain",
    name: "Sub Domain Partner",
    type: PrivateRoute,
    component: SubDomainPartner
  },

  {
    path: "/subdomain/details/:id",
    name: "ViewSubDomainUser",
    type: PrivateRoute,
    component: ViewSubDomainUser
  },
  {
    path: ["/subdomain/add-subdomain", "/edit-subdomain"],
    name: "AddEditSubdomain",
    type: PrivateRoute,
    component: AddEditSubdomain
  },
  {
    path: "/calender",
    name: "Calender",
    type: PrivateRoute,
    component: Calender
  },
  {
    path: ["/subdomain/add-calender", "/subdomain/edit-calender"],
    name: "AddEditCalender",
    type: PrivateRoute,
    component: AddEditCalender
  },
  {
    path: "/calender/details/:id",
    name: "ViewCalenderDetails",
    type: PrivateRoute,
    component: ViewCalenderDetails
  },
  {
    path: "/calender-filter",
    name: "Calender Filter",
    type: PrivateRoute,
    component: CalenderFilter
  },
  {
    path: ["/subdomain/add-filter", "/subdomain/edit-filter"],
    name: "AddEditCalenderFilter",
    type: PrivateRoute,
    component: AddEditCalenderFilter
  },
  {
    path: "/calender-filter/details/:id",
    name: "ViewCalenderFilter",
    type: PrivateRoute,
    component: ViewCalenderFilter
  },
  {
    path: "/templates",
    name: "Template",
    type: PrivateRoute,
    component: Templates
  },
  {
    path: ["/personalization/add-template", "/personalization/edit-template"],
    name: "AddEditTemplate",
    type: PrivateRoute,
    component: AddEditTemplate
  },
  {
    path: "/template/details/:id",
    name: "ViewTemplateDetails",
    type: PrivateRoute,
    component: ViewTemplateDetails
  },
  {
    path: "/personalization-category",
    name: "Category",
    type: PrivateRoute,
    component: PersonalizationCategory
  },
  {
    path: "/sandbox",
    name: "Sandox",
    type: Route,
    component: Sandbox
  },
  {
    path: "/ads-customer-details/:adsID",
    name: "CustomerDetails",
    type: PrivateRoute,
    component: CustomerDetails
  },
];
export default routes