import React, { Component } from "react";
import { connect } from "react-redux";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Button, Col, Image, Row } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { ComponentHeader, InformationModal, VerifiedModal } from "../common";
import { API_LIMIT, START_PAGE, Subscription, isModuleExist, subCoupon } from "../../utils/Constant";
import { deleteSubscriptionApi, getAllSubscriptionApi } from "./Api";
import PremimumPlanForm from "./PremimumPlanForm";
import { PREMIUM_PLAN_VERIFY } from "../../utils/urls";
import editPencil from "../../assets/images/edit-icon.png";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";


export class PremimumPlaneTab extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist(Subscription, subCoupon.coupon);

    this.state = {
      access: AccesData,
      dummyData: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      languageList: [],
      totalPages: null,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,

      isVerify: false,
      show: false,

      verified: '',
      verifyUrl: ''
    };
  }


  // todo---------------------------------------
  componentDidMount() {
    const { history, access } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllSubscriptionApi(this);
  }
  // todo---------------------------------------
  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";
    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";
    // const search = params.get("search") || "";

    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllSubscriptionApi(this);

      });
    }
  }
  // todo:-----------------------------------------

  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {
    //  console.log("yes delete  this id",)
    deleteSubscriptionApi(id, this, this.openCloseDeleteModal)
  }

  getDetailsUser = (id) => {
    this.props.history.push({ pathname: `/premium-plan-details/${id}`, state: { "id": id } });
  }

  render() {
    const { showEdit, access } = this.state;
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;
    return (
      <section className="request-section">

        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete  '${this.state.deleteData.name}' Plan ?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}

        <VerifiedModal
          name={`'${this.state.editData?.name}'`}
          show={this.state.show}
          url={PREMIUM_PLAN_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getAllSubscriptionApi(this) }}
        />
        {
          showEdit &&
          <PremimumPlanForm
            show={showEdit}
            onHide={this.showEditModal}
            editData={this.state.editData}
            this={this}
          />
        }
        <ComponentHeader
          title="Premium Plan"
          isSearch={false}
          placeholder={"Search speaker"}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          primaryBtn={access.createAccess ? "Add Plan" : ""}
          handlePrimaryBtn={() => {
            this.props.history.push('/premimum-planForm');
            // this.setState({showEdit:true})
          }}
        />
        <Row>
          <Col sm={2}>
            <FilterVefiedDropDown ctx={this} />
          </Col>
        </Row>
        <div className="commom-table-wrapper">
          <CustomTable
            tableData={listData?.list || []}
            columnList={[

              {
                coumnWidth: 150,
                labelName: "Plan Name ",
                dataKey: "name",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "name") {
                    return rowData?.name;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Membership Name",
                dataKey: "name",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "name") {
                    return rowData?.premiumPlan?.name;
                  }
                },
              },
              {
                coumnWidth: access.verificationAccess ? 100 : 0,
                labelName: "Verified",
                dataKey: "verify",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "verify") {
                    return <div className=" d-flex align-items-center inter-medium">
                      {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                    </div>;
                  }
                },
              },

              {
                coumnWidth: 130,
                labelName: "Updated By",
                dataKey: "updatedBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "updatedBy") {
                    return rowData.updatedBy?.name || 'Na';
                  }
                },
              },
              {
                coumnWidth: 130,
                labelName: "Created By",
                dataKey: "createdBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "createdBy") {
                    return rowData.createdBy?.name || 'Na';
                  }
                },
              },
              {
                coumnWidth: 140,
                labelName: "",
                dataKey: "",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <Button onClick={() => this.getDetailsUser(rowData?.uuid)} className="primary-btn inter-medium f-s-14 lh-18 mr-5">
                        View Detail
                      </Button>
                    );
                  }
                },
              },
              {
                // coumnWidth: 150,
                coumnWidth: access.updateAccess || access.deleteAccess ? 150 : 0,

                labelName: "Action",
                dataKey: "",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <>

                        {access.updateAccess && (
                          <Image src={editIcon} className="edit-icon" onClick={() => { this.props.history.push({ pathname: '/premimum-planForm', state: { editData: rowData } }) }} />
                        )}
                        {access.deleteAccess && (
                          <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                        )}
                      </>
                    );
                  }
                },
              },
            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getAllSubscriptionApi
};

export default connect(mapStateToProps, mapDispatchToProps)(PremimumPlaneTab);
