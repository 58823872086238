
import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ComponentHeader from "../common/ComponentHeader";
import { getAllSubscriptionApi, getAllBannerApi } from './Api';
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
import PremimumPlanTab from "./PremimumPlanTab";
import PremimumPlanForm from "./PremimumPlanForm";
class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      dataList: [],
      totalPages: null,
      activeTab: {
        tabName: "Premium-Plan",
        eventKey: "premium-plan",
        primaryBtn: "Add premium-plan",
        searchPlaceholder: "Search premium-plan",
        api: this.props.getAllSubscriptionApi,
        componentName: <PremimumPlanTab {...this.props} />,
        showAddEdit: false,
        addEditModal: (
          <PremimumPlanForm show={true} onHide={this.handlePrimaryBtn} ctx={this} />
        ),
      },
      tabArray: [
        {
          tabName: "Premium-Plan",
          eventKey: "premium-plan",
          primaryBtn: "Add premium-plan",
          searchPlaceholder: "Search premium-plan",
          api: this.props.getAllSubscriptionApi,
          componentName: <PremimumPlanTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <PremimumPlanForm show={true} onHide={this.handlePrimaryBtn} ctx={this} />
          ),
        },

      ]
    };
  }
  handlePrimaryBtn = () => {
    const { activeTab } = this.state;
    this.setState({
      activeTab: {
        ...activeTab,
        showAddEdit: !activeTab.showAddEdit,
      },
    });
    if(activeTab?.tabName==="Premium-Plan"){
      this.props.history.push({
        pathname:'/premimum-planForm'
      })
    }
  };
  componentDidMount() {
    const { history } = this.props;
    history.push({
      search: `?tab=${this.state.activeTab.eventKey}&p=${START_PAGE}`,
    });
    this.state.activeTab.api(this);
  }
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const search = params.get("search") || "";

    if (prevPage !== page) {
      this.setState({ currentPage: page }, () => {
        if (search) {
          this.state.activeTab.api(this);
        } else {
          this.state.activeTab.api(this);
        }
      });
    }
  }



  handleTabChange = (tab) => {
    let activeTab = this.state.tabArray.filter((item) => item.eventKey === tab);
    this.setState({ activeTab: activeTab[0] });
    this.props.history.push({
      search: `?tab=${activeTab[0].eventKey}&p=${START_PAGE}`,
    });
    activeTab[0].api(this);
  };

  render() {
    const { search, tabName, activeTab, tabArray } = this.state;

    return (
      <section className="contentCollections-main-frame">
        {activeTab.showAddEdit && activeTab.addEditModal}
        <ComponentHeader
          title="Plans and Subscriptions"
          // primaryBtn={tabName}
          primaryBtn={activeTab.primaryBtn}
          // isSearch={true}
          // isBackArrow={true}
          // isReturn="/contentCollections"
          handlePrimaryBtn={this.handlePrimaryBtn}
        // handlePrimaryBtn={() => { this.props.history.push('/add-content') }}
        />
        <Tabs
          defaultActiveKey={activeTab.eventKey}
          onSelect={this.handleTabChange}
          className="tab-title"
          id="uncontrolled-tab-example"
        >
          {
            tabArray.map((tab) => {
              return (
                <Tab eventKey={tab.eventKey} title={tab.tabName}>
                  {tab.componentName}
                </Tab>
              )
            })
          }
        </Tabs>
      </section>
    );
  }
}


const mapStateToProps = (state) => ({
  contentCollectionsState: state.ContentCollectionsState,
});
const mapDispatchToProps = {
  getAllSubscriptionApi,


};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Subscription));
