import React, { Component } from "react";
import { connect } from "react-redux";
import ComponentHeader from "../common/ComponentHeader";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Col, Image, Row } from "react-bootstrap";
import editPencil from "../../assets/images/edit-icon.png";
import { getAllPlatformDataApi } from "./Api";
import { API_LIMIT, START_PAGE, isModuleExist } from "../../utils/Constant";
import { InformationModal, VerifiedModal } from "../common";
import { toast } from "react-toastify";
import { EVENT_VERIFY, PLATFORM_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";
import { deleteAuthCommonApi } from "../content/Api";
import editIcon from '../../assets/images/edit-icon.svg'



class Platform extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist("Auto Share", 'Platform');

    this.state = {
      access: AccesData,
      search: "",
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,


      isVerify: false,
      show: false,

      verified: '',
      verifyUrl: ''

    };
  }


  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllPlatformDataApi(this);

  }

  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";


    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";

    // const search = params.get("search") || "";

    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllPlatformDataApi(this);

      });
    }
  }

  getDetailsUser = (data) => {
    this.props.history.push({ pathname: `/platform/details/${data?.uuid}`, state: { "id": data?.uuid } });
  }

  showEditModal = (rowData = null) => {
    this.props.history.push({ pathname: `/platform/add-platform`, state: { data: rowData } })
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };

  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };

  deleteHandle = async (id) => {
    const res = await deleteAuthCommonApi(`/platform/${id}`);
    // console.log(res)
    if (res.status === 200) {
      this.props.getAllPlatformDataApi(this);
      this.setState({
        delete: !this.state.delete
      });

    } else {
      toast.error("Somthis wrong...")
    }
  }

  render() {
    const { access } = this.state;

    const { platformData, totalPages, currentPage } = this.props.autoShareState;
    // console.log(eventData)
    return (
      <>
        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.platformName}?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        <VerifiedModal
          name={`'${this.state.editData?.platformName}'`}
          show={this.state.show}
          url={PLATFORM_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getAllPlatformDataApi(this) }}
        />
        <section>
          <ComponentHeader
            title="Platform"
          // primaryBtn={access.createAccess ? "Add Platform" : ""}
          // isSearch={true}
          // isBackArrow={true}
          // isReturn="/contentCollections"
          // handlePrimaryBtn={() => { this.props.history.push('/platform/add-platform') }}
          />
          <Row>
            <Col sm={2}>
              <FilterVefiedDropDown ctx={this} />
            </Col>
          </Row>
          <div className="commom-table-wrapper">
            <CustomTable
              tableData={platformData?.list}
              columnList={[
                {
                  coumnWidth: 200,
                  labelName: "Platform Name",
                  dataKey: "platformName",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "platformName") {
                      return rowData.platformName;
                    }
                  },
                },
                {
                  coumnWidth: access.verificationAccess ? 100 : 0,
                  labelName: "Verified",
                  dataKey: "verify",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "verify") {
                      return <div className=" d-flex align-items-center inter-medium">
                        {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                      </div>;
                    }
                  },
                },

                {
                  coumnWidth: 200,
                  labelName: "Code",
                  dataKey: "code",
                  className: "code",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "code") {
                      return rowData.code;
                    }
                  },
                },
                {
                  coumnWidth: 200,
                  labelName: "Icon Image",
                  dataKey: "iconImagePath",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "iconImagePath") {
                      return <Image height={30} width={30} src={rowData?.iconImagePath} className="table-img" alt="Photo" />;
                    }
                  },
                },


                {
                  coumnWidth: 200,
                  labelName: "Updated By",
                  dataKey: "updatedBy",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "updatedBy") {
                      return rowData?.updatedBy?.name || 'Na';;
                    }
                  },
                },
                {
                  coumnWidth: 200,
                  labelName: "Created By",
                  dataKey: "createdBy",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "createdBy") {
                      return rowData?.createdBy?.name || 'Na';
                    }
                  },
                },
                {

                  coumnWidth: access.updateAccess || access.deleteAccess ? 250 : 0,
                  labelName: "Action",
                  dataKey: "",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "") {
                      return (
                        <>

                          {access.updateAccess && (
                            <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                          )}
                          {/* {access.deleteAccess && (
                            <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                          )} */}
                        </>
                      );
                    }
                  },

                }

              ]}
              message="No data found" // For Pagination
              history={this.props.history}
              location={this.props.location}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  autoShareState: state.AutoShareState,
});
const mapDispatchToProps = {
  getAllPlatformDataApi,
  deleteAuthCommonApi

};
Platform.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Platform);
