import React from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { BaseReactComponent, Form, FormSubmitButton } from "../../utils/form";
import { API_LIMIT, isModuleExist, START_PAGE, SUPER_ADMIN } from "../../utils/Constant";
import { ComponentHeader, Spinner } from "../common";
import { getSsoUserApi, getSsoViewUserApi } from "./Api";
import { replaceHistory, userRoleType } from "../../utils/ReusableFunctions";
import { postAuthCommonApi } from "../content/Api";
import closeIcon from '../../assets/images/greay-cancel-icon.svg';

class ViewSsoUsers extends BaseReactComponent {
  constructor(props) {
    super(props);

    // const roleType = userRoleType() || SUPER_ADMIN;
    this.state = {

      search: "",
      dummyData: [],
      searchResult: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,
      isLoading: true,
      show: false,
      uploadedFile: null,
      uploading: false
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getSsoViewUserApi(this);
    console.log(this.props.match.params.id, "props")

  }

  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevSearch = prevParams.get("search") || "";

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const search = params.get("search") || "";

    if (prevPage !== page || prevSearch !== search) {
      this.setState({ currentPage: page, search }, () => {
        this.props.getSsoViewUserApi(this);

      });
    }
  }


  clearSearch = () => {
    this.onChangeMethod({ search: "" })
  }

  onChangeMethod = (value) => {
    this.setState({ searchText: value.search, currentPage: START_PAGE }, () => {
      replaceHistory(this.props.history, START_PAGE, value.search)
    })
  }

  getDetailsUser = (rowData) => {

    const { id } = this.props.match.params
    const type = sessionStorage.setItem("type", "admin")
    this.props.history.push({
      pathname: `/sso/web-user/details/${this.props.match.params.id}/${rowData?.id}`,
      state: {
        "id": rowData?.id,
        "ssoPartnerId": this.props.match.params.id,
        "type": "admin"
      }
    });
  }

  onValidSubmit = async () => {
    const fileData = {
      "data": {
        "ssoPartnerId": this.props?.match.params?.id
      }
    }

    const data = new FormData();
    data.append("file", this.state?.uploadedFile);
    data.append("key", JSON.stringify(fileData));

    this.setState({ uploading: true })
    const res = await postAuthCommonApi(`admin/bulk-user`, data)
    // console.log(res)
    if (res.status == 200) {
      this.setState({ show: false });
      this.setState({ uploading: false });
      this.props.getSsoViewUserApi(this);



    }
    else {
      this.setState({ uploading: false })

    }
  }
  onHide = () => {
    this.setState({ show: false });
  };
  handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (file) {
      this.setState({ uploadedFile: file });
      // console.log("File uploaded:", file);
    }

  };

  render() {

    const { viewList, totalPages, currentPage } = this.props.usersState;
    const { isLoading } = this.state;
    console.log(viewList);
    return (
      <>

        <section className="request-section">
          <ComponentHeader
            title="View Users"
            primaryBtn={"Import"}

            // isSearch={true}
            // placeholder={"Search User"}
            // onChangeMethod={this.onChangeMethod}
            // clearSearch={() => this.clearSearch()}
            isBackArrow={true}
            isReturn={"/sso"}
            handlePrimaryBtn={() => { this.setState({ show: true }) }}

          />
          {/* <CustomTab data={nav_obj} /> */}


          <div className="commom-table-wrapper">
            <CustomTable
              tableData={isLoading ? [] : viewList?.list || []}
              columnList={[

                {
                  coumnWidth: 250,
                  labelName: "Full Name",
                  dataKey: "firstName",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "firstName") {
                      const firstName = rowData.firstName ?? "";
                      const lastName = rowData.lastName ?? "";
                      return `${firstName} ${lastName}`.trim();
                    }
                  },
                },
                {
                  coumnWidth: 250,
                  labelName: "Email Id",
                  dataKey: "emailAddress",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "emailAddress") {
                      return rowData.emailAddress || 'Na';
                    }
                  },
                },

                {
                  coumnWidth: 250,
                  labelName: "Mobile Number",
                  dataKey: "mobileNumber",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "mobileNumber") {
                      return rowData.mobileNumber || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 250,
                  labelName: "Company Name",
                  dataKey: "companyName",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "companyName") {
                      return rowData.companyName || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 200,
                  labelName: "No of Events",
                  dataKey: "noOfEvent",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "noOfEvent") {
                      return rowData.noOfEvent;
                    }
                  },
                },
                {
                  coumnWidth: 350,
                  labelName: "Subscription Plan Name",
                  dataKey: "premiumPlanName",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "premiumPlanName") {
                      return (
                        <>
                          {rowData?.premiumPlanName || 'NA'}
                          <br />
                          {rowData?.subPremiumPlanName || 'NA'}
                          <br />
                          {rowData?.subPremiumPlanVariation || 'NA'}
                        </>
                      );
                    }
                  },
                },
                {
                  coumnWidth: 100,
                  labelName: "Liked",
                  dataKey: "liked",
                  className: "liked",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "liked") {
                      return rowData?.liked;

                    }

                  },
                },
                {
                  coumnWidth: 100,
                  labelName: "Shared",
                  dataKey: "shared",
                  className: "shared",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "shared") {

                      return rowData?.shared;
                    }
                    return '0';
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Downloaded",
                  dataKey: "downloaded",
                  className: "downloaded",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "downloaded") {

                      return rowData?.downloaded;
                    }

                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Cobrand",
                  dataKey: "cobrand",
                  className: "cobrand",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "cobrand") {
                      return rowData?.cobrand;

                    }

                  },
                },
                {
                  coumnWidth: 100,
                  labelName: "Saved",
                  dataKey: "saved",
                  className: "saved",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "saved") {
                      return rowData?.saved;

                    }

                  },
                },
                {
                  coumnWidth: 300,
                  labelName: "Action",
                  dataKey: "",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "") {
                      return (
                        <>
                          <Button onClick={() => this.getDetailsUser(rowData)} className="primary-btn inter-medium f-s-14 lh-18">
                            View Detail
                          </Button>

                        </>

                      );
                    }
                  },
                },




              ]}
              message="No data found" // For Pagination
              history={this.props.history}
              location={this.props.location}
              totalPages={totalPages}
              currentPage={currentPage}
            />
            {isLoading ?
              <div className="inter-medium f-s-18 lh-22 text-center py-5 d-flex align-items-center justify-content-center gap-12"> <Spinner classes={'black-border'} /> Loading...</div>
              : (viewList?.list.length === 0) ?
                <div className="inter-medium f-s-18 lh-22 text-center py-5"> No Data Found</div> : ''
            }

          </div>

        </section>
        <Modal show={this.state.show} onHide={this.onHide} dialogClassName={`custom-modal user-details-modal `}>
          <Modal.Header>
            <Modal.Title className='red-hat-display-bold f-s-20'>{"Upload File"}</Modal.Title>
            <Image src={closeIcon} className='close-icon model-icon-close' alt='icon' onClick={this.onHide} />
          </Modal.Header>

          <Modal.Body>
            <Form onValidSubmit={this.onValidSubmit}>
              <Row>
                <Col sm={12}>
                  <div className="img-upload-wrapper">
                    <input
                      type="file"

                      // id={`carouselUpload${id}`}
                      onChange={this.handleFileChange}
                      accept=".csv"
                    />

                  </div>

                </Col>


              </Row>
              <div className="submit-wrapper" style={{ justifyContent: "center" }}>
                <FormSubmitButton customClass={"primary-btn"} >
                  {this.state.uploading ? <Spinner /> : "Save"}
                </FormSubmitButton>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}


const mapStateToProps = state => ({
  usersState: state.SsoState,
});
const mapDispatchToProps = {
  getSsoViewUserApi

};
export default connect(mapStateToProps, mapDispatchToProps)(ViewSsoUsers);
