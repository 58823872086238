import React, { Component } from "react";
import { connect } from "react-redux";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Col, Image, Row } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import EventSpeakerForm from "./EventSpeakerForm";
import { ComponentHeader, InformationModal, VerifiedModal } from "../common";
import { deleteColorApi, deleteEventSpeakerApi, getAllSpeakerApi } from "./Api";
import { EventMaster, START_PAGE, isModuleExist } from "../../utils/Constant";
import { EVENT_SPEAKER_VERIFY } from "../../utils/urls";
import editPencil from "../../assets/images/edit-icon.png";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";

export class EventSpeakerTab extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist(EventMaster, 'Speaker');
    this.state = {
      access: AccesData,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,


      isVerify: false,
      show: false,

      verified: '',
      verifyUrl: ''
    };
  }
  componentDidMount() {
  }
  // todo---------------------------------------
  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getAllSpeakerApi(this);
  }
  // todo---------------------------------------
  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";


    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";

    // const search = params.get("search") || "";

    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getAllSpeakerApi(this);

      });
    }
  }
  // todo:-----------------------------------------

  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {
    deleteEventSpeakerApi(id, this, this.openCloseDeleteModal)
  }


  render() {
    const { showEdit, access } = this.state;
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;
    return (
      <section className="request-section">
        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.speakerName} ?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        {
          showEdit &&
          <EventSpeakerForm
            show={showEdit}
            onHide={this.showEditModal}
            editData={this.state.editData}
            this={this}
          />
        }
        <VerifiedModal
          name={`'${this.state.editData?.speakerName}'`}
          show={this.state.show}
          url={EVENT_SPEAKER_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getAllSpeakerApi(this) }}
        />
        <ComponentHeader
          title="Event Speaker"
          isSearch={false}
          placeholder={"Search speaker"}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          primaryBtn={access.createAccess ? "Add Speaker" : ''}
          handlePrimaryBtn={() => {
            // this.props.history.push('/ads/banner-ads/add');
            this.setState({ showEdit: true })
          }}
        />
        <Row>
          <Col sm={2}>
            <FilterVefiedDropDown ctx={this} />
          </Col>
        </Row>
        <div className="commom-table-wrapper">

          <CustomTable
            tableData={listData?.list || []}
            columnList={[
              {
                coumnWidth: 250,
                labelName: "Name",
                dataKey: "speakerName",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "speakerName") {
                    return rowData.speakerName;
                  }
                },
              },
              {
                coumnWidth: access.verificationAccess ? 100 : 0,
                labelName: "Verified",
                dataKey: "verify",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "verify") {
                    return <div className=" d-flex align-items-center inter-medium">
                      {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                    </div>;
                  }
                },
              },
              {
                coumnWidth: 250,
                labelName: "Profile Picture",
                dataKey: "profilePicture",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "profilePicture") {
                    return rowData.profilePicture ? <Image className="table-img" src={rowData.profilePicture} alt="Speaker Image" /> : "Na";
                  }
                },
              },

              {
                coumnWidth: 250,
                labelName: "URL",
                dataKey: "link",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "link") {
                    return rowData.link;
                  }
                },
              },
              {
                coumnWidth: 250,
                labelName: "Description",
                dataKey: "description",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "description") {
                    return rowData.description;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Updated By",
                dataKey: "updatedBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "updatedBy") {
                    return rowData?.updatedBy?.name || 'Na';;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Created By",
                dataKey: "createdBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "createdBy") {
                    return rowData?.createdBy?.name || 'Na';
                  }
                },
              },
              {
                coumnWidth: 250,
                labelName: "Action",
                dataKey: "",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <>
                        {access.updateAccess && <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />}
                        {access.deleteAccess && <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />}
                      </>
                    );
                  }
                },
              },
            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getAllSpeakerApi
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSpeakerTab);
