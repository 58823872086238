import { DEFAULT_LIST } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { GET_ALL_CONTENT } from "./ActionTypes";

const INITIAL_STATE = {
  contentList: DEFAULT_LIST,
  totalPages: null,
};
const ContentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_CONTENT:
            return {
              contentList: action.payload,
              totalPages: calculateTotalPageCount(action.payload.length),
              currentPage: action.currentPage
             };
        default:
            return state
    }
};
export default ContentReducer