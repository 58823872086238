import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import editPencil from "../../assets/images/edit-icon.png";
import { Table, Image, Row, Col, Button, Modal } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import closeIcon from '../../assets/images/greay-cancel-icon.svg';
import { getDetaisContentApi, patchAuthCommonApi } from "./Api";
import ContentIfame from "./_utils/ContentIframe";
import { isModuleExist } from "../../utils/Constant";
class ContentDetails extends BaseReactComponent {
  constructor(props) {
    // console.log(props)
    super(props);
    const { params } = props.match;
    const AccesData = isModuleExist("Content", 'Content');
    this.state = {
      access: AccesData,
      params,
      show: false,
      firstname: "",
      lastname: "",
      pleaseselect: "",
      detailsData: [],
    };
  }
  componentDidMount = () => {
    getDetaisContentApi("/admin/content/allDetails", this);
  }
  // componentDidMount() { }
  returnDate(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  }
  updatedVerifiedHandler = () => {
    const info = {
      "data": {
        "isVerified": !this.state?.data?.isVerified
      }
    }
    const newData = new FormData();
    newData.append("key", JSON.stringify(info));
    // console.log("Test this",this.props.match.params.contentID)
    // console.log(  this.state.data.isVerified,!this.state.data.isVerified)
    patchAuthCommonApi(`content/${this.props.match.params.contentID}`, newData, this);
    // console.log(res)


  }
  render() {
    const { data, detailsData, params } = this.state;
    // console.log(data)
    return (
      <section className="user-section">
        {/* // ---------------------------------------View details -------------------------------- */}

        <div className="view-details-wrapper ">


          <Modal show={this.state.show} onHide={() => this.setState({ show: false })} dialogClassName={`custom-modal vh-100  `}>
            {

              <Modal.Header className="p-0 border-0">

                <Image src={closeIcon} className='close-icon model-icon-close' alt='icon' onClick={() => this.setState({ show: false })} />
              </Modal.Header>
            }
            <Modal.Body className="pb-0">
              <h3 className='red-hat-display-bold f-s-20'>  Do you wish to {!data?.isVerified ? 'verify' : 'un verify'} content ?</h3>

              <div className=" d-flex align-items-center justify-content-end pt-5">
                <Button className="primary-btn py-2 mr-3" onClick={this.updatedVerifiedHandler}>Yes</Button>
                <Button className=" secondary-btn py-2">No</Button>
              </div>
            </Modal.Body>
          </Modal>
          <ComponentHeader
            title={`${data?.defaultVariation?.title} Details`}
            isBackArrow={true}
            isReturn={"/content"}
          />
          <div className="view-details d-flex">
            <div className="view-details-content">
              <div className="user-details d-flex">
                <div className="eclipse">
                  <Image src={data?.amc?.circleImagePath} className="eclipse" />
                </div>
                <div className="name-details">
                  <h3 className="inter-medium overflow-eclipse mw-200 f-s-20">{data?.defaultVariation?.title || ""} </h3>
                  <h4 className="inter-medium grey-3 f-s-14">{data?.amc?.amcName}</h4>
                  {
                    data?.uploadSection?.map((item) => {
                      return (
                        <h4 className="inter-medium grey-3 f-s-14 pills">{item.uploadSection}</h4>
                      )
                    })
                  }
                  {/*<h5 className="inter-medium grey-3 f-s-14">End Date: {moment(data?.endDate).format('lll')}</h5> */}
                </div>
                <div className="line "></div>
                <div className="name-description">
                  <h3 className="grey-2 ">Description</h3>
                  <p className="inter-medium f-s-14">{data?.defaultVariation?.description || ""}</p>
                </div>
              </div>
              <h3 className="inter-regular f-s-14 grey-2">
                Content Details
              </h3>
              <div className="line2"></div>
              <Table className="custom-table">
                <Row>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Content Type</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.contentType?.contentTypeName || "na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Content Creator</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.contentCreator?.contentCreatorName || "na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">AMC</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.amc?.amcName || "Na"}</h3>
                    </div>
                  </Col>

                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Social Media</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.socialMedia.map(item => item?.socialMediaName) || "na"}</h3>
                    </div>
                  </Col>

                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Category</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.category?.categoryName || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Sub Category
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.subCategory?.subCategoryName || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Default Language
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.defaultLanguage?.languageName || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Display Position</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.displayPosition.map(item => item?.position + ' ') || "xyz@gmail.com"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Source</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.source}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Duration (minutes)</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.duration}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 d-flex w-100 gap-12 ">Verified
                        {this.state.access.verificationAccess &&
                          <Image onClick={() => { this.setState({ show: true }) }} src={editPencil} className="edit-pencil mt-1" />}
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.isVerified ? "Yes" : "No"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Premium</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.isPremium ? "Yes" : 'No'}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Keywords</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.keywords}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Published At
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.publishedAt || "Na"}</h3>
                    </div>
                  </Col>

                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Download Count
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.downloadCount || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Like Count
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.likeCount || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Saved Count
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.savedCount || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Share Count
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.shareCount || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Download Count
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.downloadCount || "Na"}</h3>
                    </div>
                  </Col>


                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Targeted Content</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.targetedContent?.map((item) => item?.targetedContentName + " ") || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Campaign</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.campaign?.map((item) => item?.campaignName + " ") || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Branding</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.branding?.brandingName || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Thumbnail Image</h4>
                      <a href={data?.thumbnail} target="_blank" rel="noreferrer">
                        <Image src={data?.thumbnail} alt="icon" className="details-image-box" />
                      </a>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Age Group</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.ageGroup || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Employment Type</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.employmentType || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Financial Understanding</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.financialUnderstanding || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Gender</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.gender || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Goal</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.goal || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Marital Status</h4>
                      <h3 className="inter-semibold f-s-14 ">{(data?.maritalStatus) || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Risk Appetide</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.riskAppetide || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Income Group</h4>
                      <h3 className="inter-semibold f-s-14 ">{data?.incomeGroup || "Na"}</h3>
                    </div>
                  </Col>
                </Row>
              </Table>

              <div className="variation-wrapper">
                <h3 className="inter-regular f-s-14 grey-2">Default Variation</h3>
                <div className="line2"></div>
                <Table>
                  <Row>
                    <Col sm={4} xs={12}>
                      <div className="detail">
                        <h4 className="inter-semibold grey-2 ">Title</h4>
                        <h3 className="inter-semibold f-s-14 ">{data?.defaultVariation?.title || "Na"}</h3>
                      </div>
                    </Col>
                    <Col sm={4} xs={12}>
                      <div className="detail">
                        <h4 className="inter-semibold grey-2 ">Description</h4>
                        <h3 className="inter-semibold f-s-14 ">{data?.defaultVariation?.description || "Na"}</h3>
                      </div>
                    </Col>
                    <Col sm={4} xs={12}>
                      <div className="detail">
                        <h4 className="inter-semibold grey-2 ">Color</h4>
                        <h3 className="inter-semibold f-s-14 ">{data?.defaultVariation?.color?.colorName || "Na"}</h3>
                      </div>
                    </Col>
                    <Col sm={4} xs={12}>
                      <div className="detail">
                        <h4 className="inter-semibold grey-2 ">Language</h4>
                        <h3 className="inter-semibold f-s-14 ">{data?.defaultVariation?.language?.languageName || "Na"}</h3>
                      </div>
                    </Col>
                    <Col sm={4} xs={12}>
                      <div className="detail">
                        <h4 className="inter-semibold grey-2 ">External Url</h4>
                        <h3 className="inter-semibold f-s-14 ">
                          <a href={data?.defaultVariation?.extUrlLink || ""} target="_blank" rel="noopener noreferrer">
                            {data?.defaultVariation?.extUrlLink || "Na"}
                          </a>
                        </h3>
                      </div>
                    </Col>


                  </Row>
                </Table>

              </div>
              {data?.contentVariation?.length > 0 &&
                <div className="variation-wrapper">
                  <h3 className="inter-regular f-s-14 grey-2">Content Variation</h3>
                  <div className="line2"></div>
                  {
                    data?.contentVariation?.map((item, i) => {
                      return (
                        <Table>
                          <Row>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Title</h4>
                                <h3 className="inter-semibold f-s-14 ">{item?.title || "Na"}</h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Description</h4>
                                <h3 className="inter-semibold f-s-14 ">{item?.description || "Na"}</h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Color</h4>
                                <h3 className="inter-semibold f-s-14 ">{item?.color?.colorName || "Na"}</h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Language</h4>
                                <h3 className="inter-semibold f-s-14 ">{item?.language?.languageName || "Na"}</h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">External Url</h4>
                                <h3 className="inter-semibold f-s-14 ">
                                  <a href={item?.extUrlLink || ""} target="_blank" rel="noopener noreferrer">
                                    {item?.extUrlLink || "Na"}
                                  </a>
                                </h3>
                              </div>
                            </Col>
                            <Col sm={4} xs={12}>
                              <div className="detail">
                                <h4 className="inter-semibold grey-2 ">Content Image</h4>
                                <ContentIfame extUrlLink={item?.extUrlLink || ""} contentFilePath={item?.contentFilePath} height="100" classes={'custom-images-height'} />

                                {/* <Image src={item?.thumbnailPath} alt="icon" className="details-image-box" /> */}
                              </div>
                            </Col>
                          </Row>
                        </Table>
                      )
                    })
                  }


                </div>
              }

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ usersState: state.UsersState });
const mapDispatchToProps = {
  // getPosts: fetchPosts
};
ContentDetails.propTypes = {
  // getPosts: PropTypes.func
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetails);
