import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { ComponentHeader, InformationModal, VerifiedModal } from '../common';
import { getAllSubdomainApi } from './Api';
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { Button, Col, Image, Row } from "react-bootstrap";
import { API_LIMIT, START_PAGE, isModuleExist } from "../../utils/Constant";
import editPencil from "../../assets/images/edit-icon.png";
import { SSO_VERIFY, SUBDOMAIN_VERIFY, USER_ROLE_VERIFY } from '../../utils/urls';
import FilterVefiedDropDown from '../common/FilterVefiedDropDown';
import { deleteAuthCommonApi } from "../content/Api";
import { toast } from 'react-toastify';



class SubDomainPartner extends Component {
    constructor(props) {
        super(props);
        const AccesData = isModuleExist("Sub Domain", 'Sub Domain Partner');

        this.state = {
            access: AccesData,
            delete: false,
            deleteData: {},
            editData: "",
            showEdit: false,
            isVerify: false,
            show: false,
            verified: '',
            verifyUrl: ''
        }
    }

    componentDidMount() {
        const { history } = this.props;
        history.push({
            pathname: this.props.location.pathname,
            search: `?p=${this.state.currentPage || START_PAGE}`,
        });
        this.props.getAllSubdomainApi(this);
    }
    componentDidUpdate(prevProps, prevState) {
        const prevParams = new URLSearchParams(prevProps.location.search);
        const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
        const prevVerified = prevParams.get("isVerified") || "";

        const params = new URLSearchParams(this.props.location.search);
        const page = parseInt(params.get("p"), 10) || START_PAGE;
        const verified = params.get("isVerified") || "";

        // const search = params.get("search") || "";

        if (prevPage !== page || prevVerified !== verified) {
            this.setState({ currentPage: page }, () => {
                this.props.getAllSubdomainApi(this);

            });
        }
    }
    getDetailsSubdomain = (data) => {
        this.props.history.push({ pathname: `/subdomain/details/${data?.uuid}`, state: { "data": data } });
    }

    showEditModal = (rowData = null) => {
        this.props.history.push({ pathname: `/edit-subdomain`, state: { "data": rowData, "id": rowData.uuid } })
        this.setState({
            showEdit: !this.state.showEdit,
            editData: rowData
        });
    };
    openCloseDeleteModal = (rowData) => {
        this.setState({
            delete: !this.state.delete,
            deleteData: rowData,
        });
    };
    deleteHandle = async (id) => {
        const res = await deleteAuthCommonApi(`/sub-domain/${id}`);
        // console.log(res)
        if (res.status === 200) {
            this.props.getAllSubdomainApi(this);
            this.setState({
                delete: !this.state.delete
            });

        } else {
            toast.error("Somthis wrong...")
        }
    }

    render() {
        const { access } = this.state;

        const { subdomainList, totalPages, currentPage } = this.props.subdomainState;

        return (
            <>

                {this.state.delete && (
                    <InformationModal
                        show={this.state.delete}
                        id={this.state.deleteData?.uuid}
                        title={`Are you sure you want to delete ${this.state.deleteData?.companyName} ?`}
                        handleClose={this.openCloseDeleteModal}
                        handleDelete={this.deleteHandle}
                    />
                )}
                <VerifiedModal
                    // name={`this '${this.state.editData?.campaignName}' campaign.`}
                    name={`'${this.state.editData?.companyName}'`}
                    show={this.state.show}
                    url={SUBDOMAIN_VERIFY + `/${this.state?.editData?.uuid}`}
                    isVerified={this.state.isVerify}
                    handleCancel={() => { this.setState({ show: false }) }}
                    successRes={() => { this.props.getAllSubdomainApi(this) }}
                />
                <section className="request-section">
                    <ComponentHeader
                        title="Sub Domain"
                        // isSearch={true}
                        // placeholder={"Search User"}
                        // onChangeMethod={this.onChangeMethod}
                        // clearSearch={() => this.clearSearch()}
                        primaryBtn={access.createAccess ? "Add Partner" : ""}
                        handlePrimaryBtn={() => {
                            this.props.history.push({ pathname: "/subdomain/add-subdomain", state: { subdomainList } });
                        }}
                    />
                    <Row>
                        <Col sm={2}>
                            <FilterVefiedDropDown ctx={this} />
                        </Col>
                    </Row>
                    <div className="commom-table-wrapper">
                        <CustomTable
                            tableData={subdomainList?.list || []}
                            columnList={[
                                {
                                    coumnWidth: 180,
                                    labelName: "Company Name",
                                    dataKey: "companyName",
                                    className: "name",
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "companyName") {
                                            return rowData?.companyName;
                                        }
                                    },
                                },
                                {
                                    coumnWidth: access.verificationAccess ? 110 : 0,
                                    labelName: "Verified",
                                    dataKey: "isVerified",
                                    className: "verify",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "isVerified") {
                                            return <div className=" d-flex align-items-center inter-medium">
                                                {(rowData?.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData?.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                                            </div>;
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 150,
                                    labelName: "Company Logo",
                                    dataKey: "companyLogo",
                                    className: "logo",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "companyLogo") {
                                            return <Image className="table-img" src={rowData?.companyLogo} alt="Company Logo" />;

                                        }
                                    },
                                },

                                {
                                    coumnWidth: 130,
                                    labelName: "Contact",
                                    dataKey: "mobileNumber",
                                    className: "contact",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "mobileNumber") {
                                            return rowData?.mobileNumber || 'Na';
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 200,
                                    labelName: "Email",
                                    dataKey: "emailAddress",
                                    className: "email",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "emailAddress") {
                                            return rowData?.emailAddress || 'Na';
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 350,
                                    coumnWidth: access.updateAccess || access.deleteAccess ? 350 : 0,
                                    labelName: "Action",
                                    dataKey: "",
                                    className: "",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "") {
                                            return (


                                                <>
                                                    <Button onClick={() => this.getDetailsSubdomain(rowData)} className=" primary-btn mr-4">
                                                        View Detail
                                                    </Button>

                                                    {access.updateAccess && (
                                                        <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                                                    )}
                                                    {access.deleteAccess && (
                                                        <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                                                    )}
                                                </>

                                            );
                                        }
                                    },
                                },
                            ]}
                            message="No data found" // For Pagination
                            history={this.props.history}
                            location={this.props.location}
                            totalPages={totalPages}
                            currentPage={currentPage}
                        />
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => ({
    subdomainState: state.SubdomainState
});
const mapDispatchToProps = {
    getAllSubdomainApi,
}
SubDomainPartner.propTypes = {
    // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SubDomainPartner);