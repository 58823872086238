import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import { Col, Row, Table } from "react-bootstrap";
import { Image } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { getCalenderDetailsApi } from "./Api";

class ViewCalenderDetails extends BaseReactComponent {
    constructor(props) {
        super(props);
        const { params } = props.match;
        const calenderDetails = this.props.location.state.data;

        this.state = {
            params,
            show: false,
            details: calenderDetails
        };
    }

    componentDidMount() {
        getCalenderDetailsApi("/calender", this);

    }
    render() {
        const { data, detailsData, params, registerUser } = this.state;

        // console.log('thi is get Regisster detila',registerUser);
        return (
            <section className="user-section">
                {/* // ---------------------------------------View details -------------------------------- */}

                <div className="view-details-wrapper">

                    <ComponentHeader title="View Calender Details" isBackArrow={true} isReturn="/calender" />
                    <div className="view-details d-flex">
                        <div className="view-details-content">
                            <div className="user-details d-flex">
                                <div className="eclipse">
                                    <Image src={data?.calenderImage} className="eclipse" />
                                </div>
                                <div className="name-details">
                                    <h3 className="inter-medium f-s-20">{data?.calenderName || ""}</h3>

                                </div>
                                <div className="line"></div>
                                <div className="name-description">
                                    <h3 className="grey-2 ">About</h3>

                                </div>
                            </div>
                            <h3 className="inter-regular f-s-14 grey-2">
                                Calender Details
                            </h3>
                            <div className="line2"></div>
                            <Table className="custom-table">
                                <Row>


                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Calender Name</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.calenderName} </h3>
                                        </div>
                                    </Col>


                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Description</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.description || 'NA'}</h3>
                                        </div>
                                    </Col>



                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Is Verified</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.isVerified ? "Yes" : "No" || ''}</h3>
                                        </div>
                                    </Col>

                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Filters</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.calenderFilters.map((item) => item?.calenderFilter?.filterName + ",")}</h3>
                                        </div>
                                    </Col>



                                </Row>




                            </Table>


                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({ usersState: state.UsersState });
const mapDispatchToProps = {
    // getPosts: fetchPosts
};
ViewCalenderDetails.propTypes = {
    // getPosts: PropTypes.func
    location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCalenderDetails);
