import React from 'react';
import { Image } from "react-bootstrap";
import { API_LIMIT } from './Constant';

// GET EXTENSION NAME WRAPPER
export const getExtensionNameWrapper = (attachmentName, attachmentUrl, className) => {
  const re = /(?:\.([^.]+))?$/;
  let ext = re.exec(attachmentName)[1];
  let attachmentWrapper;

  if (ext === "jpeg" || ext === "png" || ext === "jpg") {
    attachmentWrapper = (
      <Image
        src={attachmentUrl}
        alt="link-preview"
      />
    )
  } else {
    attachmentWrapper = (
      <span className={className}>{ext}</span>
    )
  }
  return attachmentWrapper
}

export const calculateTotalPageCount = (totalCount) => {
  return Math.ceil(totalCount / API_LIMIT)
}

// export const getDropdownOption = (data) =>{
//   let options = data.map((item)=>{
//       "label": item.name,
//       "value": item.uuid
//     })
//     return options
// }

// export const getDropdownOption = (data) =>{
//   Object.keys(data).map(
//     (type) => ({
//       label: PricingType.presentable[type],
//       value: type
//     })
//   )
// }



const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp']; // Define your image extensions

export const isImage = (filePath) => {
  if (typeof filePath !== 'string') {
    return false;
  }

  const extension = filePath.toLowerCase().slice(filePath.lastIndexOf('.'));
  return imageExtensions.includes(extension);
};
export const isPDF = (filePath) => {
  if (typeof filePath !== 'string') {
    return false;
  }

  const extension = filePath.toLowerCase().slice(filePath.lastIndexOf('.'));
  return ['.pdf'].includes(extension);
};



export const replaceHistory = (history, page = 1, searchValue = "") => {
  history.replace({
    search: `?p=${page}${searchValue && `&search=${searchValue}`}`
  })
}

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
}

export const userRoleType = () => {
  return JSON.parse(localStorage.getItem("finAdmin"))?.user?.role?.roleType || '';

}
export const userAmcUuids = () => {
  return JSON.parse(localStorage.getItem("finAdmin"))?.amcUuids || '';

}
export const isFinpediaUser = () => {
  return JSON.parse(localStorage.getItem("finAdmin"))?.user?.contentCreator?.isFinpediaUser || false;

}
export const userCreatorUuids = () => {
  return JSON.parse(localStorage.getItem("finAdmin"))?.contentCreatorUuid || '';

}

export function isBtnShow(url) {
  if (!url) {
    return true;
  }
  const urlExclude = ['.jpg', '.mp3', '.ogg', '.png', '.jpg', 'jpeg'];
  const lastDotIndex = url?.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return true;
  }

  const extension = url.slice(lastDotIndex).toLowerCase();
  return !urlExclude.includes(extension);
}


export const camelCaseTitle = (name) => {
  let camelCaseTitle = name.replace(/\s+(.)/g, function (match, group1) {
    return group1.toUpperCase();
  });
  camelCaseTitle = camelCaseTitle.charAt(0).toLowerCase() + camelCaseTitle.slice(1);
  camelCaseTitle = camelCaseTitle.replace(/\s/g, '');
  return camelCaseTitle;
};

