import React, { Component } from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { connect } from "react-redux";
import { deleteKeywordApi, getKeywordNameApi } from "./Api";
import { Col, Image, Row } from "react-bootstrap";

import editPencil from "../../assets/images/edit-icon.png";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import KeywordForm from "./KeywordForm";
import { ComponentHeader, InformationModal, IsVerified, VerifiedModal } from "../common";
import { START_PAGE, Trending, contentMaster, isModuleExist, subConternMaster, subTrending } from "../../utils/Constant";
import { KEYWORD_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";
import { replaceHistory } from "../../utils/ReusableFunctions";
export class KeywordTab extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist('Trending', subConternMaster.keywords);

    this.state = {
      access: AccesData,
      delete: false,
      listData2: '',
      deleteData: {},
      editData: "",
      showEdit: false,

      isVerify: false,
      show: false,
      verified: '',
      verifyUrl: '',

      search: "",
    };
  }
  filterData = (isSort = false) => {

    const getData = this.props.masterCategoryState.listData;
    const sortedData = getData.list.sort((a, b) => {
      return (a.isVerified === b.isVerified ? 0 : a.isVerified ? 1 : -1);
    });
    this.setState({ listData2: sortedData });
  }
  // todo---------------------------------------.
  componentDidMount() {

    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getKeywordNameApi(this);
  }
  // todo---------------------------------------
  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";
    const prevSearch = prevParams.get("search") || "";

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const search = params.get("search") || "";
    const verified = params.get("isVerified") || "";

    if (prevPage !== page || prevVerified !== verified || prevSearch !== search) {
      this.setState({ currentPage: page, listData2: '', search }, () => {
        this.props.getKeywordNameApi(this);

      });
    }
  }
  // todo:-----------------------------------------


  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {

    deleteKeywordApi(id, this, this.openCloseDeleteModal)
  }
  onChangeMethod = (value) => {
    this.setState({ searchText: value.search, currentPage: START_PAGE }, () => {
      replaceHistory(this.props.history, START_PAGE, value.search)
    })
  };
  clearSearch = () => {
    this.onChangeMethod({ search: "" })
  }
  render() {
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;
    const { access, listData2 } = this.state;


    return (
      <section className="request-section">

        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.keyword} ?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        <VerifiedModal
          name={`'${this.state.editData?.keyword}'`}
          show={this.state.show}
          url={KEYWORD_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getKeywordNameApi(this); }}
        />
        {
          this.state.showEdit &&
          <KeywordForm
            show={this.state.showEdit}
            onHide={this.showEditModal}
            editData={this.state.editData}
            this={this}
          />
        }
        <ComponentHeader
          title="Keywords"
          isSearch={true}
          placeholder={"Search Keywords"}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          primaryBtn={access.createAccess ? "Add keywords" : ""}
          handlePrimaryBtn={() => {

            this.setState({ showEdit: true })
          }}
        />
        <Row>
          <Col sm={2}>
            <FilterVefiedDropDown ctx={this} />
          </Col>
        </Row>
        <div className="commom-table-wrapper">

          <CustomTable
            tableData={listData2 || listData.list || []}
            columnList={[
              {
                coumnWidth: 250,
                labelName: "Keyword",
                dataKey: "keyword",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "keyword") {
                    return <span className="d-flex align-items-center gap-12"> {rowData.keyword}
                      {/* <IsVerified data={rowData}/>  */}
                    </span>;
                  }
                },
              },
              {
                coumnWidth: 100,
                labelName: "Search Count",
                dataKey: "searchCount",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "searchCount") {
                    return rowData.searchCount;
                  }
                },
              },
              {
                coumnWidth: access.verificationAccess ? 130 : 0,
                labelName: "Verified",
                dataKey: "verify",
                className: "name",
                isFilter: true,
                handleSortByCol: () => { this.filterData(true); },
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "verify") {
                    return <div className=" d-flex align-items-center inter-medium">
                      {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                    </div>;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Updated By",
                dataKey: "updatedBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "updatedBy") {
                    return rowData?.updatedBy?.name || 'Na';;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Created By",
                dataKey: "createdBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "createdBy") {
                    return rowData?.createdBy?.name || 'Na';
                  }
                },
              },

              {

                coumnWidth: access.updateAccess || access.deleteAccess ? 250 : 0,
                labelName: "Action",
                dataKey: "",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <>
                        {access.updateAccess && (
                          <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                        )}
                        {access.deleteAccess && (
                          <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                        )}
                      </>
                    );
                  }
                },

              }


            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getKeywordNameApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordTab);
