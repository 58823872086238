import React, { Component } from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Col, Image, Row } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import editPencil from "../../assets/images/edit-icon.png";
import { connect } from "react-redux";
import { ALT_THUMBNAIL, API_LIMIT, START_PAGE, contentMaster, isModuleExist } from "../../utils/Constant";

import { ComponentHeader, InformationModal, VerifiedModal } from "../common";
import { CATEGORY_VERIFY, PERSONALIZATION_CATEGORY_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";
import PersonalizationCategoryForm from "./PersonalizationCategoryForm";
import { deletePersonalizationCategoryApi, getPersonalizationCategoryApi } from "./Api";

export class PersonalizationCategory extends Component {
    constructor(props) {

        super(props);
        const AccesData = isModuleExist('Personalization', 'Category');
        this.state = {
            access: AccesData,
            dummyData: [],
            pageSize: API_LIMIT,
            currentPage: START_PAGE,
            languageList: [],
            totalPages: null,
            delete: false,
            deleteData: {},
            editData: "",
            showEdit: false,

            isVerify: false,
            show: false,

            verified: '',
            verifyUrl: ''
        };
    }
    // todo---------------------------------------
    componentDidMount() {
        const { history } = this.props;
        history.push({
            pathname: this.props.location.pathname,
            search: `?p=${this.state.currentPage || START_PAGE}`,
        });
        this.props.getPersonalizationCategoryApi(this);
    }
    // todo---------------------------------------
    // todo:-----------------------------------------
    componentDidUpdate(prevProps, prevState) {
        const prevParams = new URLSearchParams(prevProps.location.search);
        const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
        const prevVerified = prevParams.get("isVerified") || "";

        const params = new URLSearchParams(this.props.location.search);
        const page = parseInt(params.get("p"), 10) || START_PAGE;
        const verified = params.get("isVerified") || "";

        if (prevPage !== page || prevVerified !== verified) {

            this.setState({ currentPage: page }, () => {
                this.props.getPersonalizationCategoryApi(this);

            });
        }
    }
    // todo:-----------------------------------------

    showEditModal = (rowData = null) => {
        this.setState({
            showEdit: !this.state.showEdit,
            editData: rowData,
        });
    };
    openCloseDeleteModal = (rowData) => {
        this.setState({
            delete: !this.state.delete,
            deleteData: rowData,
        });
    };
    deleteHandle = (id) => {
        deletePersonalizationCategoryApi(id, this, this.openCloseDeleteModal)
    }
    render() {
        const { showEdit, access } = this.state;
        const { categoryList, totalPages, currentPage } = this.props.personalizationState;
        return (
            <section className="request-section">

                {this.state.delete && (
                    <InformationModal
                        show={this.state.delete}
                        id={this.state.deleteData.uuid}
                        title={`Are you sure you want to delete ${this.state.deleteData.categoryName} category?`}
                        handleClose={this.openCloseDeleteModal}
                        handleDelete={this.deleteHandle}

                    />
                )}
                {
                    showEdit &&
                    <PersonalizationCategoryForm
                        show={showEdit}
                        onHide={this.showEditModal}
                        editData={this.state.editData}
                        this={this}
                    />
                }
                <VerifiedModal
                    name={`'${this.state.editData?.categoryName}'`}
                    show={this.state.show}
                    url={PERSONALIZATION_CATEGORY_VERIFY + `/${this.state?.editData?.uuid}`}
                    isVerified={this.state.isVerify}
                    handleCancel={() => { this.setState({ show: false }) }}
                    successRes={() => { this.props.getPersonalizationCategoryApi(this); }}
                />
                <ComponentHeader
                    title="Personalization Category"
                    isSearch={false}
                    placeholder={"Search "}
                    onChangeMethod={this.onChangeMethod}
                    clearSearch={() => this.clearSearch()}
                    primaryBtn={access.createAccess ? "Add Category " : ""}
                    handlePrimaryBtn={() => {
                        this.setState({ showEdit: true })
                    }}
                />
                <Row>
                    <Col sm={2}>
                        <FilterVefiedDropDown ctx={this} />
                    </Col>
                </Row>
                <div className="commom-table-wrapper">

                    <CustomTable
                        tableData={categoryList?.list || []}
                        columnList={[
                            {
                                coumnWidth: 250,
                                labelName: "Name",
                                dataKey: "categoryName",
                                className: "name",
                                isCell: true,
                                cell: (rowData, dataKey) => {
                                    if (dataKey === "categoryName") {
                                        return rowData.categoryName;
                                    }
                                },
                            },
                            {
                                coumnWidth: access.verificationAccess ? 100 : 0,
                                labelName: "Verified",
                                dataKey: "verify",
                                className: "name",
                                isCell: true,
                                cell: (rowData, dataKey) => {
                                    if (dataKey === "verify") {
                                        return <div className=" d-flex align-items-center inter-medium">
                                            {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                                        </div>;
                                    }
                                },
                            },
                            {
                                coumnWidth: 250,
                                labelName: "Description",
                                dataKey: "description",
                                className: "name",
                                isCell: true,
                                cell: (rowData, dataKey) => {
                                    if (dataKey === "description") {
                                        return rowData.description;
                                    }
                                },
                            },

                            {
                                coumnWidth: 200,
                                labelName: "Thumbnail Image",
                                dataKey: "thumbnailPath",
                                className: "",
                                isCell: true,
                                cell: (rowData, dataKey) => {
                                    if (dataKey === "thumbnailPath") {
                                        return (rowData.thumbnailPath ? <Image className="table-img" src={rowData.thumbnailPath} alt={ALT_THUMBNAIL} /> : "NA");
                                    }
                                },
                            },

                            {
                                coumnWidth: 150,
                                labelName: "Updated By",
                                dataKey: "updatedBy",
                                className: "name",
                                isCell: true,
                                cell: (rowData, dataKey) => {
                                    if (dataKey === "updatedBy") {
                                        return rowData.updatedBy?.name || "Na";
                                    }
                                },
                            },
                            {
                                coumnWidth: 150,
                                labelName: "Created By",
                                dataKey: "createdBy",
                                className: "name",
                                isCell: true,
                                cell: (rowData, dataKey) => {
                                    if (dataKey === "createdBy") {
                                        return rowData.createdBy?.name || "Na";
                                    }
                                },
                            },
                            {
                                coumnWidth: 200,
                                labelName: "Action",
                                dataKey: "",
                                className: "",
                                isCell: true,
                                cell: (rowData, dataKey) => {
                                    if (dataKey === "") {
                                        return (
                                            <>
                                                {access.updateAccess && <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />}
                                                {access.deleteAccess && <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />}


                                            </>
                                        );
                                    }
                                },
                            },
                        ]}
                        message="No data found" // For Pagination
                        history={this.props.history}
                        location={this.props.location}
                        totalPages={totalPages}
                        currentPage={currentPage}
                    />
                </div>
            </section>
        );
    }
}
const mapStateToProps = (state) => ({
    personalizationState: state.PersonalizationState,
});
const mapDispatchToProps = {
    getPersonalizationCategoryApi
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalizationCategory);