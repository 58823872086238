import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import { CustomModal, ImageUploader, Spinner } from "../common";
import { addEventSpeakerApi, updateEventSpeakerApi } from "./Api";
import { connect } from "react-redux";

class EventSpeakerForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;
    this.state = {
      name: data?.speakerName || "",
      url: data?.link || "",
      description: data?.description || "",
      attachments: data?.profilePicture || null,
      uploadImage: null,
      resLoading: false,
    };
  }

  componentDidMount() { }

  imageUploaser = (e) => {

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({ attachments: e.target.files[0], uploadImage: imageUrl })
  }
  onValidSubmit = () => {
    this.setState({ resLoading: true })
    let eventData = {
      "data": {
        "speakerName": this.state.name,
        "description": this.state.description,
        "link": this.state.url,
        //  "publishedAt": new Date().getTime(),
      }
    }
    const data = new FormData();
    data.append("key", JSON.stringify(eventData));
    data.append("file", this.state.attachments);

    if (this.props?.editData?.uuid) {
      updateEventSpeakerApi(data, this.props.this, this.props.onHide, this.props.editData.uuid, this)
    } else {
      addEventSpeakerApi(data, this.props.this, this.props.onHide, this);
    }

  }

  onHide = () => {
    this.props.getAllSpeakerApi();
    this.props.onHide();
  }

  render() {
    const { uploadImage, attachments } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={(this.props?.editData?.uuid) ? ' Edit Event Speaker' : "Add Event Speaker"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "name")}
                label="Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Name",
                  },
                }}
              />
            </Col>
            <Col md={12}>
              <ImageUploader required={true} size="152 x 152" name={typeof (attachments) == 'object' ? uploadImage : attachments} onChange={this.imageUploaser} onDelete={() => this.setState({ attachments: "" })} />
            </Col>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "url")}
                label="URL"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter URL",
                  },
                }}
              />
            </Col>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "description")}
                label="Description"
                helpText="Description can't be more than 4000 character"

                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    as: "textarea",
                    placeholder: "Enter Description",
                  },
                }}
              />
            </Col>
          </Row>

          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              {this.state.resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
  // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(EventSpeakerForm);
