import React from "react";
import FileUploadControl from "../../utils/form/FileUploadControl";
import {
    Form,
    FormElement,
    FormValidator,
    CustomTextControl,
    BaseReactComponent,
    FormSubmitButton,
} from "../../utils/form";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CustomModal, ImageUploader, Spinner } from "../common";
import { addTargetedApi, updateTargetedApi } from "./Api";

class TargatedForm extends BaseReactComponent {
    constructor(props) {
        super(props);
        const data = props.editData || null;
        this.state = {
            targetedContentName: data?.targetedContentName || "",
            description: data?.description || "",
            thumbnailPath: "",
            thumbnailFile: null,
            bannerPath: "",
            bannerfile: null,
            circleImagePath: "",
            circleImageFile: null,
            uploadImage: data?.thumbnailPath || null,
            uploadImage2: data?.bannerPath || null,
            uploadImage3: data?.circleImagePath || null,
            startDate: "",
            endDate: "",

            resLoading: false,
        };
    }

    componentDidMount() { }

    imageUploaser = (e, name) => {

        const imageUrl = URL.createObjectURL(e.target.files[0]);
        if (name === "banner") {
            this.setState({ bannerPath: e.target.files[0].name, bannerfile: e.target.files[0], uploadImage2: imageUrl })
        } else if (name === "circleImage") {
            this.setState({ circleImagePath: e.target.files[0].name, circleImageFile: e.target.files[0], uploadImage3: imageUrl })
        } else {
            this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
        }
    }

    onValidSubmit = () => {
        this.setState({ resLoading: true })
        let TargetedContent = {
            "data": {
                "targetedContentName": this.state.targetedContentName,
                "description": this.state.description,
                "thumbnailPath": this.state.thumbnailPath,
                "bannerPath": this.state.bannerPath,
                "circleImagePath": this.state.circleImagePath
            }
        };
        const data = new FormData();
        data.append("key", JSON.stringify(TargetedContent));
        if (this.state.thumbnailFile ?? false) {
            data.append("file", this.state.thumbnailFile);
        }
        if (this.state.bannerfile ?? false) {
            data.append("file", this.state.bannerfile);
        }

        if (this.props.editData) {
            updateTargetedApi(data, this.props.this, this.props.onHide, this.props.editData.uuid, this)
        } else {
            addTargetedApi(data, this.props.this, this.props.onHide, this)
        }
    }
    onHide = () => {
        this.props.getAllCampaignApi();
        this.props.onHide();
    }
    render() {
        const { uploadImage, resLoading, thumbnailFile, bannerfile, uploadImage2, circleImageFile, uploadImage3 } = this.state;
        return (
            <CustomModal
                show={this.props.show}
                onHide={this.props.onHide}
                title={this.props.editData ? "Edit Targeted" : "Add Targeted"}
                modalClass={"change-password"}
            >
                <Form onValidSubmit={this.onValidSubmit}>
                    <Row>
                        <Col sm={6}>
                            <FormElement
                                valueLink={this.linkState(this, "targetedContentName")}
                                label="Targeted Content  Name"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Field cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        //   type: "",
                                        placeholder: "Enter Name",
                                    },
                                }}
                            />
                        </Col>
                        <Col sm={6}>
                            <ImageUploader required={true} id="circleImage" label="Upload logo" name={typeof (circleImageFile) == 'object' ? uploadImage3 : circleImageFile} onChange={(e) => this.imageUploaser(e, "circleImage")} onDelete={() => this.setState({ circleImageFile: "" })} />
                        </Col>
                        <Col sm={6}>
                            <ImageUploader required={true} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} />
                        </Col>
                        <Col sm={6}>
                            <ImageUploader required={true} id="Banner" label="Upload Banner" name={typeof (bannerfile) == 'object' ? uploadImage2 : bannerfile} onChange={(e) => this.imageUploaser(e, "banner")} onDelete={() => this.setState({ bannerPath: "" })} />
                        </Col>
                        <Col sm={12}>
                            <FormElement
                                valueLink={this.linkState(this, "description")}
                                label="Description"
                                helpText="Description can't be more than 4000 character"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Field cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        //   type: "",
                                        as: "textarea",
                                        placeholder: "Enter Description",
                                    },
                                }}
                            />
                        </Col>
                    </Row>

                    <div className="submit-wrapper" style={{ justifyContent: "center" }}>
                        <FormSubmitButton customClass={"primary-btn"}>
                            {resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
                        </FormSubmitButton>
                    </div>
                </Form>
            </CustomModal>
        );
    }
}

const mapStateToProps = state => ({
    // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TargatedForm);
