import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PasswordHide from "../../assets/images/password-hide.svg";
import PasswordShow from "../../assets/images/password-show.svg";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  SelectControl,
} from "../../utils/form";
import { Row, Col, Image } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { registerUserApi, updateUserApi } from "./Api";
import { getAllRolesApi } from '../roles/Api';
import { getAllAmcApi } from "../MasterCategory/Api";
import { userAmcUuids } from "../../utils/ReusableFunctions";
import { CREATOR_ID, SUPERADMIN, SUPER_ADMIN } from "../../utils/Constant";
import { getEventCategoryApi } from "../events/Api";
import { getAllContentCreatorApi } from "../content/Api";
class AddUser extends BaseReactComponent {
  constructor(props) {
    super(props);
    const editData = props.location?.state?.editData || {};
    // console.log('role', editData);
    const selectedAmc = [];
    editData?.userAmc?.map((item) => selectedAmc.push(item.amc.uuid))
    this.state = {
      userId: editData?.uuid || "",
      name: editData?.name || "",
      email: editData?.emailAddress || "",
      password: "",
      showPassowrd: false,
      isAddContent: true,
      role: editData?.role?.uuid || "",
      roleOptions: [],
      roleName:'',
      // amcOption: [],
      // amc: editData?.userAmc?.map(e=>e.amc.uuid) || [],
      rows: [
        { checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false },
      ],
      isAddContent: true,

      resLoading:false,

      isAmcShow: editData?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
      creatorList: [],
      amc: editData?.amc?.uuid || (userAmcUuids().length>0 ? userAmcUuids() :''),
      creator: editData?.contentCreator?.uuid || (userAmcUuids()?.length > 0 ? CREATOR_ID : ''),
      amcOption: [],
      isUserPage:true,
    };
  }

  componentDidMount() {
    this.props.getAllRolesApi(this);
    this.props.getAllAmcApi(this);
    getAllContentCreatorApi(this);
  }
  
  componentDidUpdate(prevProps, prevState) {
    // const { roleOptions, role } = this.state;
    // console.log(roleOptions, role, prevState.role);
    // if (role !== prevState.role && roleOptions) {
     
    // }
  }
  
  
  handlePageSize = (pageSize) => {
    // console.log("Heyyy pageSize", pageSize);
  };
  handleCheckboxChange = (rowIndex, checkboxName) => {
    this.setState((prevState) => {
      const updatedRows = [...prevState.rows];
      updatedRows[rowIndex][checkboxName] = !prevState.rows[rowIndex][checkboxName];
      return { rows: updatedRows };
    });
  };
  handleSave = () => {
    ReactDOM.findDOMNode(this.form).dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };
  onValidSubmit = () => {
    this.setState({resLoading:true})
    if (this.state.userId) {
      const data = {
        data: {
          "name": this.state.name,
          "role": { "uuid": this.state.role },
          // "amc": this.state.amc?.map((item) => ({
          //   "uuid": item
          // })),
          ...(this.state.amc && {
            "amc":[ {
              "uuid": this.state.amc
            }]
          }),
          "contentCreator": {
            "uuid": this.state.creator
          },
        }
      }
      updateUserApi(data, this);
    } else {
      const data = {
        data: {
          "emailAddress": this.state.email,
          "password": this.state.password,
          "name": this.state.name,
          "role": { "uuid": this.state.role },
          // "amc": this.state.amc?.map((item) => ({
          //   "uuid": item
          // })),
          ...(this.state.amc && {
            "amc": [{
              "uuid": this.state.amc
            }]
          }),
          ...(this.state.creator && {
            "contentCreator": {
              "uuid": this.state.creator
            }
          }),

          // "contentCreator": {
          //   "uuid": this.state.creator
          // },
        }
      }
      registerUserApi(data, this);
    }

  }
  render() {
    const { roleOptions,amcOption,isAmcShow ,role,roleName,resLoading} = this.state;
    return (
      <section className="add-section">
        <ComponentHeader
          title={this.state.userId ? 'Edit User' : "Add User"}
          primaryBtn="Save"
          handlePrimaryBtn={() => this.handleSave()}
          isBackArrow={true}
          resLoading={resLoading}
          isReturn="/users"
        />
        <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "name")}
                label="Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Please enter name",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter Name",
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "email")}
                label="Email"
                required

                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Email cannot be empty",
                  },
                  {
                    validate: FormValidator.isEmail,
                    message: "Enter valid email",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    type: "email",
                    placeholder: "Enter Email",
                    disabled: this.state.userId ? true : false
                  },
                }}
              />
            </Col>
            {
              !this.state.userId &&
              <Col sm={4}>
                <div className="password-wrapper">
                  <FormElement
                    valueLink={this.linkState(this, "password")}
                    label="Password"
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Password cannot be empty",
                      },
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        type: this.state.showPassowrd ? "text" : "password",
                        placeholder: "Enter Password",
                      },
                    }}
                  />
                  <Image
                    src={this.state.showPassowrd ? PasswordShow : PasswordHide}
                    className='password-hide pos-abs pointer'
                    onClick={() => this.setState({ showPassowrd: !this.state.showPassowrd })}
                  />
                </div>
              </Col>
            }


            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "role")}
                label="Select Role"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Role cannot be empty"
                  }
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    options: roleOptions,
                    multiple: false,
                    searchable: true,
                    placeholder: "Select Role",
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.role);
                      const roleName = roleOptions?.find(ele => ele?.value === this.state.role);
                      // console.log(roleName,this.state.role)
                      this.setState({roleName: roleName?.type})
                    }
                  }
                }}
              />
            </Col>
            
            {roleName !== SUPER_ADMIN &&
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "creator")}
                label="Creator"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Creator cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Creator",
                    options: this.state.creatorList,
                    multiple: false,
                    searchable: true,
                    disabled: (userAmcUuids()?.length > 0),
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.creator);
                      const isExest = this.state.creatorList?.find(item => this.state.creator?.includes(item.value))
                      // console.log(this.state.creator,' is selected thate this',this.state.creatorList,isExest)
                      this.setState({ isAmcShow: isExest?.label?.includes('Mutual Fund Company') })
                    },
                  },
                }}
              />
            </Col>
            }
            {(this.state.isAmcShow || (userAmcUuids()?.length > 0)) &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "amc")}
                  label="AMC"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "AMC cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Enter AMC Name",
                      // options: (roleType === MAKER) ? amcOption?.filter((ele) => ["12741c18-4815-4904-999a-4ecdb1dd2670"].some(item => item === ele.value)) : amcOption,
                      options: (userAmcUuids()?.length > 0) ? amcOption?.filter((ele) => userAmcUuids()?.some(item => item === ele.value)) : amcOption,//if adil give data then use this
                      multiple: false,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.amc);
                      },
                    },
                  }}
                />
              </Col>
            }


            {/* <Col md={12}>
              <div className="w-100">
                <h2 className="inter-medium f-s-24 lh-29">Module List</h2>
                <table className=" w-100">
                  <thead>
                    <tr>
                      <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-center ">Module Name</th>
                      <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-center ">Create Acces</th>
                      <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-center ">Read Acces</th>
                      <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-center ">Update Access</th>
                      <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-center ">Delete Acces</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.rows.map((row, rowIndex) => {
                      return (
                        <tr className="hover-gray" key={rowIndex}>
                          <td className="inter-normal f-s-16 py-4 lh-24 text-center ">Dashboard</td>
                          <td className="inter-normal f-s-16 py-4 lh-24 text-center ">
                            <input
                              type="checkbox"
                              value={row.checkbox1}
                              onChange={() => this.handleCheckboxChange(rowIndex, 'checkbox1')}
                            />
                          </td>
                          <td className="inter-normal f-s-16 py-4 lh-24 text-center ">
                            <input
                              type="checkbox"
                              value={row.checkbox2}
                              onChange={() => this.handleCheckboxChange(rowIndex, 'checkbox2')}
                            />
                          </td>
                          <td className="inter-normal f-s-16 py-4 lh-24 text-center ">
                            <input
                              type="checkbox"
                              value={row.checkbox3}
                              onChange={() => this.handleCheckboxChange(rowIndex, 'checkbox3')}
                            />
                          </td>
                          <td className="inter-normal f-s-16 py-4 lh-24 text-center ">
                            <input
                              type="checkbox"
                              value={row.checkbox4}
                              onChange={() => this.handleCheckboxChange(rowIndex, 'checkbox4')}
                            />
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </table>
              </div>

            </Col> */}
          </Row>
        </Form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  usersState: state.UsersState,
});
const mapDispatchToProps = {
  getAllRolesApi,
  getAllAmcApi
};
AddUser.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
