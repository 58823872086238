import React, { Component } from "react";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfileIcon from '../../assets/images/profile.png'

export class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div className="nav-component">
        {/* <Container> */}
        <div className="nav-wrapper">
          <Link to="/profile">
            <h3 className="inter-medium f-s-14 lh-21 black"><Image src={ProfileIcon} className="w-100" /> </h3>
          </Link>
        </div>
        {/* </Container> */}
      </div>
    );
  }
}

export default Navbar;
