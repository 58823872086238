import { DEFAULT_LIST, START_PAGE } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { GET_ALL_SSO_DATA, GET_ALL_TYPES_DATA, GET_SSO_VIEW_USER_DATA } from "./ActionTypes";

const INITIAL_STATE = {
    totalPages: null,
    currentPage: START_PAGE,
    ssoList: DEFAULT_LIST
};
const SsoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_SSO_DATA:
            return {
                ...state,
                ssoList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        case GET_ALL_TYPES_DATA:
            return {
                ...state,
                typesList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        case GET_SSO_VIEW_USER_DATA:
            return {
                ...state,
                viewList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };

        default:
            return state
    }
};
export default SsoReducer