import React, { Component } from "react";
import { connect } from "react-redux";
import profile from "../../assets/images/profile.png";
import setting from "../../assets/images/setting.png";
import arrow from "../../assets/images/arrow.png";
import { Image } from "react-bootstrap";
import logout from "../../assets/images/logout.png";
import ChangePasswordModal from "./_utils/ChangePasswordModal";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPassword: false,
      finAdmin: JSON.parse(localStorage.getItem("finAdmin")),
    };
  }

  componentDidMount() {

  }
  openCloseForgotPasswordModal = () => {
    this.setState({
      forgotPassword: !this.state.forgotPassword,
    });
  };
  render() {
    return (
      <section className="profile-section">
        {this.state.forgotPassword && (
          <ChangePasswordModal
            show={this.state.forgotPassword}
            handleClose={this.openCloseForgotPasswordModal}
          />
        )}
        <div className="profile-div">
          <div className="profile-content">
            <Image src={profile} className="profile-img" />
            <h3 className="inter-semibold f-s-16 lh-24 black">{this.state.finAdmin?.user.name}</h3>
            <h3 className="inter-semibold f-s-16 lh-24 black">{this.state.finAdmin?.user.emailAddress}</h3>
          </div>
          <div className="profile-setting-wrapper">
            <div className="setting">
              <div className="setting-content">
                <Image src={setting} className="" />
                <div>
                  <h4 className="inter-semibold f-s-18 lh-21 black">Setting</h4>
                  <p onClick={this.openCloseForgotPasswordModal} className="inter-regular f-s-14 grey-757 lh-19">
                    Change Password
                  </p>
                </div>
              </div>
              <Image src={arrow} className="" />
            </div>
            <hr />
            <div className="setting">
              <div className="setting-content">
                <Image src={logout} className="" />

                <div onClick={() => (window.location.href = "/")}>
                  <h4 className="inter-semibold f-s-18 lh-21 black">Logout</h4>
                  <p className="inter-regular f-s-14 grey-757 lh-19">
                    Logout from TheFinpedia
                  </p>
                </div>
              </div>
              <Image src={arrow} className="" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  profileState: state.ProfileState,
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
};
Profile.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
