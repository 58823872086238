import { START_PAGE } from "../../utils/Constant";
import { GET_ALL_CALENDER_DATA, GET_ALL_CALENDER_FILTER_DATA, GET_ALL_SUBDOMAIN_DATA, GET_ALL_SUBDOMAIN_TYPES_DATA, GET_ALL_TYPES_DATA, GET_SUBDOMAIN_VIEW_USER_DATA } from "./ActionTypes";

export const getAllSubdomainData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_SUBDOMAIN_DATA,
    payload,
    currentPage,
});
export const getAllTypesData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_TYPES_DATA,
    payload,
    currentPage,
});
export const getSubdomainTypesData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_SUBDOMAIN_TYPES_DATA,
    payload,
    currentPage,
});

export const getAllSubdomainViewUserData = (payload, currentPage = START_PAGE) => ({
    type: GET_SUBDOMAIN_VIEW_USER_DATA,
    payload,
    currentPage,
});
export const getAllCalenderData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_CALENDER_DATA,
    payload,
    currentPage,
});
export const getAllCalenderFilterData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_CALENDER_FILTER_DATA,
    payload,
    currentPage,
});
