import React from "react";
import { connect } from "react-redux";
import cancel from '../../assets/images/greay-cancel-icon.svg';
import checked from '../../assets/images/cobranv2-checked.jpg';

import {
  Form,
  BaseReactComponent,
} from "../../utils/form";
import { Row, Col, Button, Image } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { registerUserApi, updateUserApi } from "./Api";
import { getAllRolesApi } from '../roles/Api';
import { SearchContent } from "../content";
import { CustomDropDown, CustomModal, ImageUploader } from "../common";
class BulkCoBrandForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const editData = props.location?.state?.editData || {};
    // console.log('role', editData);
    this.state = {
      userId: editData?.uuid || "",
      name: editData?.name || "",
      email: editData?.emailAddress || "",
      password: "",
      role: editData?.role?.uuid || "",
      roleOptions: [],
      rows: [
        { checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false },
      ],
      isAddContent: true,
      showSearchContent: false,
      selectedContent: [],

      submitModel: false,

      logoPosition: '',
      textPosition: '',
      labelStart: false,
      lableEnd: false,
      labelFull: false,
      fullText: false,
      fullLogo: false,
      lableEnd: false,
      endLogo: false,
      endText: false,
      labelStart: false,
      startLogo: false,
      startText: false,


      textCsvFilePath: "",
      textCsvFile: null,
      bannerPath: "",
      LogoCsvFilePath: null,
      circleImagePath: "",
      circleImageFile: null,
      uploadImage: null,
      uploadImage2: null,
      uploadImage3: null,

    };
  }

  componentDidMount() {
    this.props.getAllRolesApi(this);
  }
  handlePageSize = (pageSize) => {
    // console.log("Heyyy pageSize", pageSize);

  };
  handleCheckboxChange = (rowIndex, checkboxName) => {
    this.setState((prevState) => {
      const updatedRows = [...prevState.rows];
      updatedRows[rowIndex][checkboxName] = !prevState.rows[rowIndex][checkboxName];
      return { rows: updatedRows };
    });
  };
  handleSave = () => {
    // ReactDOM.findDOMNode(this.form).dispatchEvent(
    //   new Event("submit", { cancelable: true, bubbles: true })
    // );
    console.log("Set true this state");
    this.setState({ submitModel: true });
  };
  onValidSubmit = () => {
    if (this.state.userId) {
      const data = {
        data: {
          "name": this.state.name,
          "role": { "uuid": this.state.role }
        }
      }
      updateUserApi(data, this);
    } else {
      const data = {
        data: {
          "emailAddress": this.state.email,
          "password": this.state.password,
          "name": this.state.name,
          "role": { "uuid": this.state.role }
        }
      }
      registerUserApi(data, this);
    }

  }


  imageUploaser = (e, name) => {
    // console.log(typeof (e.target.files[0]))
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (name === "LogoCsvFile") {
      this.setState({ LogoCsvFilePath: e.target.files[0].name, LogoCsvFile: e.target.files[0], uploadImage2: imageUrl })
    } else {
      this.setState({ textCsvFilePath: e.target.files[0].name, textCsvFile: e.target.files[0], uploadImage: imageUrl })
    }
  }
  render() {
    const { roleOptions, uploadImage, LogoCsvFileName, textCsvFileName, textCsvFilePath, textCsvFile, LogoCsvFile, LogoCsvFilePath, uploadImage2, submitModel, labelFull, labelStart, lableEnd, logoPosition, textPosition, fullLogo, fullText, endLogo, endText, startLogo, startText } = this.state;
    return (
      <section className="add-section">
        {
          <CustomModal
            show={submitModel}
            onHide={() => this.setState({ submitModel: false })}
            title={"Successfully Co-brand"}
            modalClass={"change-password"}
          >
            <p className=" inter-regular  f-s-15 lh-19 black-242">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus, ea. Reprehenderit,</p>

            <div className="submit-wrapper d-flex justify-content-end gap-12">
              <Button onClick={() => { }} className="secondary-btn">Yes</Button>
              {/* <Button onClick={()=>this.setState({submitModel:false})} className="primary-btn">
              No
            </Button> */}
            </div>
          </CustomModal>
        }
        {
          this.state.showSearchContent &&
          <SearchContent
            showModal={this.state.showSearchContent}
            onHide={(selectedContent) => this.setState({ showSearchContent: false, selectedContent })}
            selectedContent={this.state.selectedContent}
            isMultiselect={false}
          />
        }
        <ComponentHeader
          title="Add Bulk Co-brand"
          primaryBtn="Save"
          handlePrimaryBtn={() => this.handleSave()}
          isBackArrow={true}
          isReturn="/bulk-cobrand"
        />

        <div className="form-group mt-5">
          {/* <label htmlFor="" className="form-label f-s-24 lh-29  fw-600">Bulk Co-brand Group</label> */}
          {this.state.selectedContent.length > 0 &&

            <div className=" mb-3 d-flex align-items-center gap-20 w-100" ><span className='inter-regular f-s-14 ellipsis lh-22'><Image src={this.state.selectedContent[0]?.amcCircleImagePath} className='' alt='img' /> {this.state.selectedContent[0]?.title} </span> <Image src={cancel} onClick={() => { this.setState({ selectedContent: {} }) }} className='search-icons pointer' alt='canle imge0' /> </div>
          }

          {/* <p>{this.state.selectedContent[0]?.title}</p> */}
          <Button onClick={() => this.setState({ showSearchContent: true })} className="primary-btn mb-5 d-block">Select Content</Button>
        </div>





        <div className="co-branding-leabling">
          <h5 className='lato-bold f-s-20 lh-28 black-242 op-7 mb-16'>Co-branding/White-labelling</h5>
          <p className=' lato-regular fs-14-13 black-242 op-5'>Co-branding/White-labelling display positions.</p>
          <div className="start-label-frame">
            <input type="checkbox" name="labelStart" checked={labelStart} onChange={(e) => this.setState({ labelStart: e.target.checked })} id="start-check-box" className='d-none ' />
            <label htmlFor="start-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
              <img src={checked} alt="" />
            </label>
            <div className="check-box-text-frame">
              <p className=' lato-regular fs-14-13 black-242'>Start</p>
              <p className=' lato-regular f-s-12 lh-16 black-242 op-5 '>Co-branding/White-labelling will be positioned in the Center before any content</p>
              {labelStart &&

                <div className="start-logo-frame">
                  {/* CHECK BOX WRAPPER START */}
                  <div className=" d-flex align-items-center gap-8">
                    <input type="checkbox" name="startText" checked={startText} onChange={(e) => this.setState({ startText: e.target.checked })} id="start-check-box1" className='d-none ' />
                    <label htmlFor="start-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                      <img src={checked} alt="" />
                    </label>
                    <p className=' lato-regular fs-14-13 black-242'>Text</p>
                  </div>
                  {/* CHECK BOX WRAPPER END */}
                  {/* CHECK BOX WRAPPER START */}
                  <div className=" d-flex align-items-center gap-8">
                    <input type="checkbox" name="startLogo" checked={startLogo} onChange={(e) => this.setState({ startLogo: e.target.checked })} id="start-check-box2" className='d-none ' />
                    <label htmlFor="start-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                      <img src={checked} alt="" />
                    </label>
                    <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                  </div>
                  {/* CHECK BOX WRAPPER END */}
                </div>


              }
            </div>
          </div>
          <div className="start-label-frame">
            <input type="checkbox" name="lableEnd" checked={lableEnd} onChange={(e) => this.setState({ lableEnd: e.target.checked })} id="end-check-box" className='d-none ' />
            <label htmlFor="end-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
              <img src={checked} alt="" />
            </label>
            <div className="check-box-text-frame">
              <p className=' lato-regular fs-14-13 black-242'>End</p>
              <p className=' lato-regular f-s-12 lh-16 black-242 op-5 '>Co-branding/White-labelling will be positioned in the Center after any content.</p>
              {lableEnd &&
                <div className="start-logo-frame">
                  {/* CHECK BOX WRAPPER START */}
                  <div className=" d-flex align-items-center gap-8">
                    <input type="checkbox" name="endText" checked={endText} onChange={(e) => this.setState({ endText: e.target.checked })} id="end-check-box1" className='d-none ' />
                    <label htmlFor="end-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                      <img src={checked} alt="" />
                    </label>
                    <p className=' lato-regular fs-14-13 black-242'>Text</p>
                  </div>
                  {/* CHECK BOX WRAPPER END */}
                  {/* CHECK BOX WRAPPER START */}
                  <div className=" d-flex align-items-center gap-8">
                    <input type="checkbox" name="endLogo" checked={endLogo} onChange={(e) => this.setState({ endLogo: e.target.checked })} id="end-check-box2" className='d-none ' />
                    <label htmlFor="end-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                      <img src={checked} alt="" />
                    </label>
                    <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                  </div>
                  {/* CHECK BOX WRAPPER END */}
                </div>
              }
            </div>
          </div>
          <div className="start-label-frame">
            <input type="checkbox" name="labelFull" checked={labelFull} onChange={(e) => this.setState({ labelFull: e.target.checked })} id="full-check-box" className='d-none ' />
            <label htmlFor="full-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
              <img src={checked} alt="" />
            </label>
            <div className="check-box-text-frame">
              <p className=' lato-regular fs-14-13 black-242'>Full</p>
              <p className=' lato-regular f-s-12 lh-16 black-242 op-5'>Co-branding/White-labelling will be allowed on available positions on the content.</p>
              {labelFull &&
                <>
                  <div className="start-logo-frame d-flex flex-row align-items-start gap-12 flex-wrap mb-3">
                    {/* CHECK BOX WRAPPER START */}
                    <div className=" d-flex align-items-center gap-8 mw-184 mb-full ">
                      <input type="checkbox" name="fullText" checked={fullText} onChange={(e) => this.setState({ fullText: e.target.checked })} id="full-check-box1" className='d-none ' />
                      <label htmlFor="full-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                        <img src={checked} alt="" />
                      </label>
                      <p className=' lato-regular fs-14-13 black-242'>Text</p>
                    </div>
                    {/* CHECK BOX WRAPPER END */}
                    {/* CHECK BOX WRAPPER START */}
                    <div className=" d-flex align-items-center gap-8 ">
                      <input type="checkbox" name="fullLogo" checked={fullLogo} onChange={(e) => this.setState({ fullLogo: e.target.checked })} id="full-check-box2" className='d-none ' />
                      <label htmlFor="full-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                        <img src={checked} alt="" />
                      </label>
                      <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                    </div>
                    {/* CHECK BOX WRAPPER END */}
                  </div>



                  <div className=" d-flex flex-row align-items-start gap-12 flex-wrap">
                    {fullText &&
                      <div className=' d-flex mb-full'>
                        <CustomDropDown
                          label={"Select Text Position"}
                          options={[{ languageName: "abcd" }]}
                          getData={(data) => this.setState({ textPosition: data.position })}
                          id="normal"
                          disabled={fullText ? false : true}
                          classes='custom-button-font'
                        />
                      </div>
                    }
                    {
                      fullLogo &&
                      <div className=' d-flex mb-full'>
                        <CustomDropDown
                          label={"Select Logo Position"}
                          options={[{ position: "Top left" }]}
                          getData={(data) => this.setState({ logoPosition: data.position })}
                          id="normal"
                          disabled={fullLogo ? false : true}
                          classes='custom-button-font'
                        />
                      </div>
                    }

                  </div>
                </>
              }
            </div>
          </div>
        </div>
        <div className="my-5">
          <Form onValidSubmit={this.onValidSubmit}>
            <Row>
              <Col sm={6}>
                <ImageUploader required={false}
                  id="textCsvFile"
                  label="Upload Text CSV File"
                  name={typeof (textCsvFile) == 'object' ? uploadImage : textCsvFile}
                  onChange={(e) => this.imageUploaser(e, "textCsvFile")}
                  onDelete={() => this.setState({ textCsvFile: "", textCsvFilePath: "" })}
                  acceptedFileType={".csv"}
                  uploadFileName={textCsvFilePath}
                  isFull={textCsvFilePath}

                />
              </Col>
              <Col sm={6}>
                <ImageUploader required={false}
                  id="LogoCsvFile"
                  label="Upload Logo CSV File"
                  name={typeof (LogoCsvFile) == 'object' ? uploadImage2 : LogoCsvFile}
                  onChange={(e) => this.imageUploaser(e, "LogoCsvFile")}
                  onDelete={() => this.setState({ LogoCsvFilePath: "", LogoCsvFile: "" })}
                  uploadFileName={LogoCsvFilePath}
                  acceptedFileType={".csv"}
                  isFull={LogoCsvFilePath}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  usersState: state.UsersState,
});
const mapDispatchToProps = {
  getAllRolesApi
};


export default connect(mapStateToProps, mapDispatchToProps)(BulkCoBrandForm);
