import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import close from '../../assets/images/close.png'
import ContentIfame from '../content/_utils/ContentIframe';
export default function Image_Uploader({ id = "coomomId", name, onChange, onChangeMultiple = false, onDelete, label = "Upload Image", required = false, acceptedFileType = "image/*", uploadFileName = '', NameContentFile = '', isFull = false, size = '', showLabel = true }) {

  const [imageName, setImageName] = useState('')

  function commonChangHandler(e) {


    setImageName(e.target.files[0].name);
    onChange(e.target.files[0]);
  }
  function onThisChange(e) {
    // const file = e.target.files[0];
    // const image = new window.Image();
    // image.src = URL.createObjectURL(file);
    // // setNewLogo(false)
    // image.onload = async function () {
    //   const width = image.width;
    //   const height = image.height;

    //   if (width > DEFAULT_IMAGE_WIDTH || height > DEFAULT_IMAGE_HEIGHT) {
    //     toast.error(`Image dimensions must be ${DEFAULT_IMAGE_WIDTH}x${DEFAULT_IMAGE_HEIGHT} or smaller.Uploaded image is(${width}x${height}) `);
    //   }else{
    //     console.log("check Image Uploader",e.target.files[0])
    //   }
    // onChange(e);

  }

  function dimantions(name) {
    switch (label) {
      case "Upload Thumbnail":
        return '373 x 187';
      case "Upload Banner":
        return '1170 x 300';
      case "Upload logo":
        return '100 x 100';
      case "Upload Logo":
        return '100 x 100';

    }
  }
  return (
    <div>
      <div className="img-form-group">
        <label className="form-label">
          {label}
          {required && <span> *</span>}
        </label>
        {
          name ?
            <div className={`uploaded-image-container ${isFull && 'w-75'}`}>
              <Image onClick={onDelete} className='cancel-img' src={close} alt="close" />
              {acceptedFileType === "image/*" ? <Image src={name} className="uploaded-image" />
                :
                (uploadFileName)
                  ? <>
                    <p className='inter-semibold f-s-16 lh-22 w-100 h-100 overflow-hidden'>{uploadFileName}</p>

                  </>
                  : (acceptedFileType == "audio/*") ?
                    <audio controls className='w-100'>
                      <source src={name} type="audio/ogg" />
                      Your browser does not support the audio element.
                    </audio>
                    :
                    <ContentIfame extUrlLink={name || ""} contentFilePath={name} uploadFileName={NameContentFile} height='60' />
              }
            </div>
            :
            <div className="img-upload-wrapper">
              <input type="file" accept={acceptedFileType} required={required} name={label} id={id} onChange={onChangeMultiple ? commonChangHandler : onChange} />
              <label for={id}>
                <span>+</span>
              </label>
              {showLabel &&
                <div>
                  {label === 'Upload Content' ?

                    <p className='inter-regular f-s-8 lh-10 grey-757'>Content Upload size should be max ( 50mb )</p>
                    :
                    <p className='inter-regular f-s-8 lh-10 grey-757'>Upload image size should be ({size || dimantions(label)})</p>
                  }
                </div>
              }
            </div>
        }
      </div>
    </div>
  )
}
