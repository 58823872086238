import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CustomModal, ImageUploader, Spinner } from "../common";
import { addSocialMediaApi, updateSocialMediaApi } from "./Api";

class SocialMediaForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props?.editData || null;

    this.state = {
      name: data?.socialMediaName || "",

      thumbnailPath: "",
      thumbnailFile: null,
      bannerPath: "",
      bannerfile: null,
      circleImagePath: "",
      circleImageFile: null,
      uploadImage: data?.thumbnailPath || null,
      uploadImage2: data?.bannerPath || null,
      uploadImage3: data?.circleImagePath || null,

      resLoading: false,
    };
  }

  componentDidMount() { }

  imageUploaser = (e, name) => {

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (name === "banner") {
      this.setState({ bannerPath: e.target.files[0].name, bannerfile: e.target.files[0], uploadImage2: imageUrl })
    } else if (name === "circleImage") {
      this.setState({ circleImagePath: e.target.files[0].name, circleImageFile: e.target.files[0], uploadImage3: imageUrl })
    } else {
      this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
    }

  }

  onValidSubmit = () => {
    this.setState({ resLoading: true })
    const { thumbnailPath, bannerPath, circleImagePath, bannerfile, thumbnailFile, circleImageFile } = this.state;

    let eventData = {

      "data": {
        "socialMediaName": this.state.name,
        ...(thumbnailPath ? { "thumbnailPath": thumbnailPath } : {}),
        ...(bannerPath ? { "bannerPath": bannerPath } : {}),


      }

    }
    const data = new FormData();
    data.append("key", JSON.stringify(eventData));
    thumbnailFile !== null && data.append("file", thumbnailFile);
    bannerfile !== null && data.append("file", bannerfile);



    if (this.props?.editData?.uuid) {
      updateSocialMediaApi(data, this.props.this, this.props.onHide, this.props.editData.uuid, this)
    } else {
      addSocialMediaApi(data, this.props.this, this.props.onHide, this);
    }

  }

  onHide = () => {
    this.props.getsocialMediaApi();
    this.props.onHide();
  }
  render() {
    const { uploadImage, resLoading, thumbnailFile, bannerfile, uploadImage2, circleImageFile, uploadImage3 } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props?.editData?.uuid ? "Edit Social Media" : "Add Social Media"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "name")}
                label="Social Media Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Name",
                  },
                }}
              />
            </Col>

            <Col sm={6}>
              <ImageUploader required={false} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "", uploadImage: "" })} />
            </Col>
            <Col sm={6}>
              <ImageUploader required={false} id="Banner" size="270 * 460" label="Upload Home Image" name={typeof (bannerfile) == 'object' ? uploadImage2 : bannerfile} onChange={(e) => this.imageUploaser(e, "banner")} onDelete={() => this.setState({ bannerPath: "", uploadImage2: '' })} />
            </Col>
          </Row>

          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              {resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
  // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SocialMediaForm);
