import { DEFAULT_LIST, START_PAGE } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { GET_ALL_DASHBOARD_DATA } from "./ActionTypes";
const INITIAL_STATE = {
    totalPages: null,
    currentPage: START_PAGE,
    dashboardList:DEFAULT_LIST
};
const DashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_DASHBOARD_DATA:
            return {
                dashboardList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
             };
        default:
            return state
    }
};
export default DashboardReducer