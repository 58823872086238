import { toast } from "react-toastify";
import { postLoginInstance } from "../../utils";
import { API_LIMIT, durationObj, MAX_LIMIT, START_PAGE } from "../../utils/Constant";
import { getAllSsoData, getAllSsoViewUserData, getAllTypesData, getSsoDetails } from "./SsoAction";
import { getAllListData } from "../MasterCategory/MasterCategoryAction";

export const getAllTypeApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`sso/types?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {

                // return ctx.setState({ dummyData: res.data.data ,});

                dispatch(getAllTypesData(res.data.data, ctx?.state.currentPage || START_PAGE));
                let options = res.data.data.list.map((item) => ({
                    value: item.uuid,
                    label: item.type
                }));

                ctx.setState({ typesOptions: options })
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getAllSsoApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`sso?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {

                // return ctx.setState({ dummyData: res.data.data ,});
                dispatch(getAllSsoData(res.data.data, ctx?.state.currentPage || START_PAGE));

            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getDetailsSsoApi = (path, ctx, isEdit = false, plansOptions) => {
    console.log(ctx)



    return postLoginInstance.get(`${path}/${ctx.props?.location?.state?.data?.uuid}`)
        .then((res) => {
            const r = res.data.data;
            const variationData = plansOptions?.find(ele => ele?.uuid == r?.premiumPlan?.uuid)
            const processedModules = r?.modules.map(module => {
                const processedModule = {
                    uuid: module.uuid,
                    moduleName: module.moduleName,
                    moduleUuid: module?.masterSsoPartnerModule?.uuid,
                    amc: module?.amcs?.map(amc => amc.uuid), // Join amcUuids with comma
                    creator: module.contentCreators?.map(creator => creator.uuid), // Join contentCreatorUuids with
                    isAmcShowContent: module?.amcs?.length > 0 ? true : false,


                };

                return processedModule;
            });


            return isEdit ? (ctx.setState({
                editData: r, companyName: r.companyName, email: r.emailAddress, link: r.websiteLink, uploadImage: r.companyLogo, contact: r.mobileNumber, type: r.ssoPartnerType?.uuid, premium: r?.premiumPlan?.uuid,
                subPremiumPlan: variationData, subPremium: r?.subPremiumPlan?.uuid,
                subPremiumPlanVariation: variationData?.subPremiumPlan?.find(ele => ele?.uuid == r?.subPremiumPlan?.uuid), variation: r?.subPremiumPlanVariation?.uuid, creatorContent: r?.contentsAllowedOf
                    ?.filter(item => item?.contentCreatorName)
                    .map(item => item?.uuid || ""),
                amcContent: r?.contentsAllowedOf
                    ?.filter(item => item?.amcName)
                    .map(item => item?.uuid || ""),

                isAmcShowContent: r?.contentsAllowedOf?.some(item => item?.contentCreatorName === "Mutual Fund Company") || false,
                creatorEvent: r?.eventsAllowedOf
                    ?.filter(item => item?.contentCreatorName)
                    .map(item => item?.uuid || ""),
                amcEvent: r?.eventsAllowedOf
                    ?.filter(item => item?.amcName)
                    .map(item => item?.uuid || ""),
                isAmcShow: r?.eventsAllowedOf?.some(item => item?.contentCreatorName === "Mutual Fund Company") || false,
                isSubscriptionAllowed: r?.allowSubscriptionPlanPage,
                isAdminAllowed: r?.allowPartnerAdminPanel,
                isUserViewAllowed: r?.allowPartnerAdminUserDetails,
                isUpdateAllowed: r?.canUserUpdateProfile,
                isShowLogo: r?.companyLogoVisibility,
                isTFPAllowed: r?.allowSubscriptionPlanPage && r?.subscriptionPlanPageLink
                    === "",
                isOtherLinkAllowed: r?.subscriptionPlanPageLink !== "",
                otherLink: r?.subscriptionPlanPageLink,
                isCompanyLogo: r?.companyLogoVisibility && r?.companyLogoText === "",
                isBoth: r?.companyLogoVisibility && r?.companyLogoText !== "",
                isPowered: r?.companyLogoText === "Powered By" ? true : false,
                isBrought: r?.companyLogoText === "Brought to you by" ? true : false,
                logoPosition: r?.logoPosition,
                modules: processedModules





            })) : ctx.setState({ data: res?.data?.data });


        })
        .catch((err) => {
            console.log('Eroorr', err)
            toast.error(err?.response?.data?.message || 'Something went wrong.');
        })

}
export const getAllCreatorApi = (ctx) => {
    const newUrl = ctx?.state?.isUserPage ? 'contentCreator' : `contentCreator?type=${ctx?.state?.isEvent ? 'event' : 'content'}`;

    return postLoginInstance.get(newUrl)
        .then((res) => {
            // Map API response to the options array
            let options = res.data.data.list.map((item) => ({
                label: item.contentCreatorName,
                value: item.uuid,
                // code: item.code
            }));

            // Add the "All" option at the beginning of the options array
            options.unshift({
                label: "All",
                value: "all"
            });

            // Update state with the modified options
            ctx.setState({
                creatorList: options
            });
        });
};
export const getAllAmcSsoApi = (ctx = null, onHide = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {
        return postLoginInstance
            .get(
                `amc?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || (ctx?.state.isAddContent ? MAX_LIMIT : API_LIMIT)}&pageNumber=${ctx?.state.currentPage || START_PAGE}`
            )
            .then((res) => {
                if (ctx?.state.isAddContent) {
                    // Map the API response to create the options array
                    let options = res.data.data.list.map((item) => ({
                        label: item.amcName,
                        value: item.uuid
                    }));

                    // Add the "All" option at the beginning of the options array
                    options.unshift({
                        label: "All",
                        value: "all"
                    });

                    // Set state with the modified options array
                    ctx.setState({
                        amcOption: options
                    });
                } else {
                    dispatch(
                        getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
                    );
                }

                if (!ctx) {
                    onHide();
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || 'Something went wrong.');
            });
    };
};
export const getSsoViewUserApi = (ctx) => {


    ctx.setState({ isLoading: true })
    return function (dispatch, getState) {
        return postLoginInstance.get(`sso/users/dashboard?ssoPartnerId=${ctx?.props?.location?.state?.ssoPartnerId || ctx.props.match.params.id}&pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}${ctx.state.search && `&searchValue=${ctx.state.search}`}`)
            .then((res) => {

                // return ctx.setState({ dummyData: res.data.data ,});
                dispatch(getAllSsoViewUserData(res.data.data, ctx?.state.currentPage || START_PAGE));
                ctx.setState({ isLoading: false })
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getAllModulesApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {

        return postLoginInstance.get(`sub-domain/master-modules?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {

                const contentItem = res.data?.data?.list?.find((item) => item.code === "CONTENT");
                const contentUuid = contentItem?.uuid || null;
                const eventItem = res.data?.data?.list?.find((item) => item.code === "EVENT");
                const eventUuid = eventItem?.uuid || null;

                ctx.setState((prevState) => {
                    const updatedModules = prevState.modules.map((module) => {
                        if (module.moduleName === "CONTENT") {
                            return { ...module, moduleUuid: contentUuid };
                        } else if (module.moduleName === "EVENT") {
                            return { ...module, moduleUuid: eventUuid };
                        }
                        return module;
                    });

                    return {
                        modules: updatedModules,
                    };
                });


            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};