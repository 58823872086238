import React from "react";

import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
  SelectControl,
} from "../../utils/form";

import { Row, Col } from "react-bootstrap";
import { CustomModal, Spinner } from "../common";
import { addSubCategoryApi, updateSubCategoryApi, getCategoryApiForsub } from "./Api";

class ContentTypeForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;

    this.state = {
      subCategoryName: data?.subCategoryName || "",
      categoryName: data?.category?.uuid || "",
      AllCategeory: [],
      options: [],

      resLoading: false,
    };
  }


  componentDidMount() {
    // let fromsubCategory=true;
    getCategoryApiForsub(this);
    console.log(this.state.AllCategeory)
  }

  onValidSubmit = () => {
    this.setState({ resLoading: true })
    let data = {
      "data": {
        "subCategoryName": this.state.subCategoryName,
        "category": {
          "uuid": this.state.categoryName,
        }
      }
    };
    if (this.props.editData?.uuid) {

      updateSubCategoryApi(JSON.stringify(data), this.props.this, this.props.onHide, this.props.editData.uuid)
    } else {

      addSubCategoryApi(JSON.stringify(data), this.props.this, this.props.onHide);
    }

  };
  render() {
    const { AllCategeory, resLoading } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props.editData?.uuid ? "Edit Sub Category Master" : "Add Sub Category Master"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "subCategoryName")}
                label="Sub Category Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Name",
                  },
                }}
              />
            </Col>
            <Col sm={12}>
              {/* {this.state.subCategoryName===""?null: */}
              <FormElement
                valueLink={this.linkState(this, "categoryName")}
                label="Select Category Name"
                required
                helpText=""
                hint={{
                  title: "Title Custom",
                  description: <span> Custom Hint Bro! </span>
                }}
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty"
                  }
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    options: AllCategeory.map((obj, index) => ({
                      value: obj.uuid,
                      label: obj.categoryName,
                    })),
                    multiple: false,
                    searchable: true,
                    // disabled:true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.categoryName);

                    }
                  }
                }}
              />
              {/* } */}
            </Col>
          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              {resLoading ? <span className="d-flex align-items-center justify-content-center gap-12"> <Spinner /> Saving...</span> : "Save"}

            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

export default ContentTypeForm;
