import { DEFAULT_LIST, START_PAGE } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { GET_ALL_CALENDER_DATA, GET_ALL_CALENDER_FILTER_DATA, GET_ALL_SUBDOMAIN_DATA, GET_ALL_TYPES_DATA, GET_SUBDOMAIN_VIEW_USER_DATA } from "./ActionTypes";

const INITIAL_STATE = {
    totalPages: null,
    currentPage: START_PAGE,
    subdomainList: DEFAULT_LIST
};
const SubDomainReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_SUBDOMAIN_DATA:
            return {
                ...state,
                subdomainList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };

        case GET_ALL_TYPES_DATA:
            return {
                ...state,
                typesList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        case GET_SUBDOMAIN_VIEW_USER_DATA:
            return {
                ...state,
                viewSubdomainList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        case GET_ALL_CALENDER_DATA:
            return {
                ...state,
                calenderList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        case GET_ALL_CALENDER_FILTER_DATA:
            return {
                ...state,
                calenderFilterList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };

        default:
            return state
    }
};
export default SubDomainReducer