import { toast } from "react-toastify";
import { postLoginInstance } from "../../utils";
import { API_LIMIT, MAX_LIMIT, START_PAGE } from "../../utils/Constant";
import { getAllListData } from "./MasterCategoryAction";


export const getAllSpeakerApi = (ctx = null, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `eventSpeaker?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
        );
        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

// ADD NEW SPEAKER
export const addEventSpeakerApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance
    .post("eventSpeaker", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      // ctx.props.getAllSpeakerApi(null, onHide);
      ctx2?.setState({ resLoading: false })
      ctx.props?.getAllSpeakerApi(null, onHide);
      toast.success("Successfully Save.")
    })
    .catch((error) => {
      ctx2?.setState({ resLoading: false })
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};

// DELETE SPEAKER
export const deleteEventSpeakerApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`eventSpeaker/${id}`).then((res) => {
    ctx.props.getAllSpeakerApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};

// UPDATE SPEAKER
export const updateEventSpeakerApi = (data, ctx, onHide, id, ctx2) => {
  return postLoginInstance
    .put(`eventSpeaker/${id}`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      ctx.props.getAllSpeakerApi(null, onHide);
      ctx2?.setState({ resLoading: false })
      toast.success("Successfully Updated.")
    }).catch((error) => {
      ctx2?.setState({ resLoading: false })
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};


// CONTENT CREATOR==============================================================

export const getContentCreatorApi = (ctx = null, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {

    return postLoginInstance
      .get(
        `contentCreator?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)

        );
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addContentCreatorApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance
    .post("contentCreator", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      ctx.props.getContentCreatorApi(null, onHide);
      toast.success("Successfully Save.")
    }).catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};

export const deleteContentCreatorApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`contentCreator/${id}`).then((res) => {
    ctx.props.getContentCreatorApi(null, onHide);
    // ctx2?.setState({resLoading:false})
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    // ctx2?.setState({resLoading:false})
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateContentCreatorApi = (data, ctx, onHide, id, ctx2) => {

  return postLoginInstance.put(`contentCreator/${id}`, data).then((res) => {
    ctx.props.getContentCreatorApi(null, onHide);
    toast.success("Successfully Updated.")
    ctx2?.setState({ resLoading: false })
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};




// COLOR API START ===================================================

export const getAllColorApi = (ctx = null, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `color?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        if (ctx?.state?.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.colorName,
              value: item.uuid
            }))
          ctx.setState({
            colorOption: option
          })
        } else {
          dispatch(getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }
        // dispatch(getAllListData(res.data.data, ctx?.state.currentPage));
        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addColorApi = (data, ctx, onHide, ctx2) => {

  return postLoginInstance.post("color", data).then((res) => {
    ctx.props.getAllColorApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Save.")
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateColorApi = (data, ctx, onHide, id, ctx2) => {

  return postLoginInstance.put(`color/${id}`, data).then((res) => {
    ctx.props.getAllColorApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Updated.")
  })
    .catch((error) => {
      ctx2?.setState({ resLoading: false })
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};

export const deleteColorApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`color/${id}`)
    .then((res) => {

      ctx.props.getAllColorApi(null, onHide);
      toast.success("Successfully Deleted.")
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};
//COLOR API END ======================================================
// LANGUAGES API START =======================================================
export const getLanguageNameApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `languages?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.languageName,
              value: item.uuid
            }))
          ctx.setState({
            languageOption: option
          })
        } else {
          dispatch(getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};
export const addLanguageNameApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance.post("languages", data).then((res) => {
    ctx.props.getLanguageNameApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Save.")
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deleteLanguageApi = (id, ctx, onHide) => {

  return postLoginInstance.delete(`languages/${id}`).then((res) => {

    ctx.props.getLanguageNameApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {

    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateLanguageNameApi = (data, ctx, onHide, id, ctx2) => {

  return postLoginInstance.put(`languages/${id}`, data).then((res) => {
    ctx.props.getLanguageNameApi(null, onHide);
    toast.success("Successfully Updated.")
    ctx2?.setState({ resLoading: false })
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
// LANGUAGES API END =============================================================================
// KeyWord API START ================================================================================

export const getKeywordNameApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `keyword?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }${ctx?.state.search && `&searchValue=${ctx?.state.search}`}`
      )
      .then((res) => {
        if (ctx?.state?.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.keyword,
              value: item.uuid
            }))
          ctx.setState({
            keywordOption: option
          })
        } else {
          dispatch(getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE))
        }

        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addKeywordNameApi = (data, ctx, onHide) => {
  return postLoginInstance.post("keyword", data).then((res) => {
    ctx.props.getKeywordNameApi(null, onHide);
    toast.success("Successfully Save.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deleteKeywordApi = (id, ctx, onHide) => {

  return postLoginInstance.delete(`keyword/${id}`).then((res) => {
    ctx.props.getKeywordNameApi(null, onHide);
    toast.success("Successfully Delete.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateKeywordApi = (data, ctx, onHide, id, ctx2) => {

  return postLoginInstance.put(`keyword/${id}`, data).then((res) => {
    ctx.props.getKeywordNameApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Updated.")
  }).catch((error) => {

    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};

//Category Tab
export const getCategoryApiForsub = (ctx = {}) => {
  return postLoginInstance
    .get("category")
    .then((res) => {
      ctx.setState({
        AllCategeory: res.data.data.list,
      })

    }).catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })

};


export const getAllCategoryApi = (ctx = null, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `category?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.categoryName,
              value: item.uuid
            }))
          ctx.setState({
            categoryOptions: option
          })
        } else {
          dispatch(
            getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
          );
        }
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addCategoryApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance.post("category", data).then((res) => {
    ctx.props.getAllCategoryApi(null, onHide);
    toast.success("Successfully Save.")
    ctx2?.setState({ resLoading: false })
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deleteCategoryApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`category/${id}`).then((res) => {
    ctx.props.getAllCategoryApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateCategoryApi = (data, ctx, onHide, id) => {
  return postLoginInstance.put(`category/${id}`, data).then((res) => {
    ctx.props.getAllCategoryApi(null, onHide);
    toast.success("Successfully Updated.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
// ============================ APP IN NOTIFICATION API START ================
export const getAllAppNotificationApi = (ctx = null, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `inAppNotification?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.title,
              value: item.uuid
            }))
          ctx.setState({
            appNotificationOptions: option
          })
        } else {
          dispatch(
            getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
          );
        }
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addAppNotificationApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance.post("inAppNotification", data).then((res) => {
    ctx.props.getAllAppNotificationApi(null, onHide);
    toast.success("Successfully Save.")
    ctx2?.setState({ resLoading: false })
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deleteAppNotificationApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`inAppNotification/${id}`).then((res) => {
    ctx.props.getAllAppNotificationApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateAppNotificationApi = (data, ctx, onHide, id, ctx2) => {
  return postLoginInstance.put(`inAppNotification/${id}`, data).then((res) => {
    ctx.props.getAllAppNotificationApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Updated.")
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
// COUPON API START
export const getCouponApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `coupon?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT
        }&pageNumber=${ctx?.state.currentPage || START_PAGE}`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
        );
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addCouponApi = (data, ctx, onHide) => {
  return postLoginInstance.post("coupon", data).then((res) => {
    ctx.props.getCouponApi(null, onHide);
    toast.success("Successfully Save.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deleteCouponApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`coupon/${id}`).then((res) => {
    ctx.props.getCouponApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateCouponApi = (data, ctx, onHide, id) => {
  return postLoginInstance.put(`coupon/${id}`, data).then((res) => {
    ctx.props.getCouponApi(null, onHide);
    toast.success("Successfully Updated.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
// COUPON API END
//EVENT CATEGORY START
export const getEventApi = (ctx = {}, onHide = null) => {
  return function (dispatch, getState) {
    const { verifyUrl = false } = ctx?.state ?? {};
    return postLoginInstance
      .get(
        `eventCategory?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT
        }&pageNumber=${ctx?.state.currentPage || START_PAGE}`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
        );
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addEventcategoryApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance.post("eventCategory", data).then((res) => {
    ctx.props.getEventApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Save.")
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deleteEvenCategoryApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`eventCategory/${id}`).then((res) => {
    ctx.props.getEventApi(null, onHide);
    toast.success("Successfully Delete.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateEvenCategoryApi = (data, ctx, onHide, id, ctx2) => {
  return postLoginInstance.put(`eventCategory/${id}`, data).then((res) => {
    ctx.props.getEventApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Updated.")
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
//CONTENT TYPE

// * Event Oginiser start
export const geteventOrganiserApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `eventOrganiser?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT
        }&pageNumber=${ctx?.state.currentPage || START_PAGE}`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
        );
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addeventOrganiserApi = (data, ctx, onHide) => {
  return postLoginInstance.post("eventOrganiser", data).then((res) => {
    ctx.props.geteventOrganiserApi(null, onHide);
    toast.success("Successfully Save.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deleteeventOrganiserApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`eventOrganiser/${id}`).then((res) => {
    ctx.props.geteventOrganiserApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateeventOrganiserApi = (data, ctx, onHide, id) => {
  return postLoginInstance.put(`eventOrganiser/${id}`, data).then((res) => {
    ctx.props.geteventOrganiserApi(null, onHide);
    toast.success("Successfully Updated.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
// * Event Oginiser end
export const getAllPremiumPlanApi = (ctx) => {
  return postLoginInstance.get(`premium-plan`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.name,
          value: item.uuid,
          contentCode: item.contentCode ?? '',
          isTopup: (item?.isTopup ?? false)
        }))
      ctx.setState({
        premiumPlanList: option
      })
    })
}
export const getAllContentTypeApi = (ctx) => {
  return postLoginInstance.get(`contentType`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.contentTypeName,
          value: item.uuid,
          contentCode: item.contentCode
        }))
      ctx.setState({
        contentTypeOptions: option
      })
    })
}
export const getContentTypeApi = (ctx = {}, onHide = null) => {
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `contentType?pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        if (ctx.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.contentTypeName,
              value: item.uuid,
              contentCode: item.contentCode
            }))

          ctx.setState({
            contentTypeOptions: option,
          })
          option.forEach((item) => ctx.setState({ [item.value]: 0 }))
        } else {
          dispatch(getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE));
        }
        if (!ctx) {
          onHide();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addContentTypeApi = (data, ctx, onHide) => {
  return postLoginInstance.post("contentType", data).then((res) => {
    ctx.props.getContentTypeApi(null, onHide);
    toast.success("Successfully Save.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deleteContentTypeApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`contentType/${id}`).then((res) => {
    ctx.props.getContentTypeApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateContentTypeApi = (data, ctx, onHide, id) => {
  return postLoginInstance.put(`contentType/${id}`, data).then((res) => {
    ctx.props.getContentTypeApi(null, onHide);
    toast.success("Successfully Updated.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};

// SUB-CATOGARY MASTER START =======================================================

export const getAllSubCategoryApi = (ctx) => {
  return postLoginInstance.get(`subCategory`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.subCategoryName,
          value: item.uuid
        }))
      ctx.setState({
        subCategoryOptions: option
      })
    })
}
export const getSubCategoryApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `subCategory?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
        );
        if (!ctx) {
          onHide();
        }
      });
  };
};

export const addSubCategoryApi = (data, ctx, onHide) => {
  return postLoginInstance.post("subCategory", data).then((res) => {
    ctx.props.getSubCategoryApi(null, onHide);
    toast.success("Successfully Save.")
    ctx.setState({ resLoading: false })
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
    ctx.setState({ resLoading: false })
  })
};
export const deleteSubCategoryApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`subCategory/${id}`)
    .then((res) => {
      ctx.props.getSubCategoryApi(null, onHide);
      toast.success("Successfully Deleted.")
    }).catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};
export const updateSubCategoryApi = (data, ctx, onHide, id) => {
  return postLoginInstance.put(`subCategory/${id}`, data)
    .then((res) => {
      ctx.props.getSubCategoryApi(null, onHide);
      toast.success("Successfully Updated.")
      ctx.setState({ resLoading: false })
    }).catch((error) => {
      ctx.setState({ resLoading: false })
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};

// SUBCATOGARY MASTER END =======================================================



//?=========== AMC API start ==============================

// CONTENT CREATOR==============================================================

export const getAllAmcApi = (ctx = null, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `amc?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || (ctx?.state.isAddContent) ? MAX_LIMIT : API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let option = [];
          res.data.data.list.map((item) =>
            option.push({
              label: item.amcName,
              value: item.uuid
            }))
          ctx.setState({
            amcOption: option
          })
        } else {
          dispatch(
            getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
          );
        }
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addAmcApi = (data, ctx, onHide) => {
  return postLoginInstance
    .post("amc", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      ctx.props.getAllAmcApi(null, onHide);
      toast.success("Successfully Save.")
    }).catch((error) => {
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};

export const deleteAmcApi = (id, ctx, onHide, ctx2) => {
  return postLoginInstance.delete(`amc/${id}`).then((res) => {
    ctx.props.getAllAmcApi(null, onHide);
    toast.success("Successfully Deleted.")
    ctx2?.setState({ resLoading: false })
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateAmcApi = (data, ctx, onHide, id, ctx2) => {

  return postLoginInstance.put(`amc/${id}`, data).then((res) => {
    ctx.props.getAllAmcApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Updated.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
    ctx2?.setState({ resLoading: false })
  })
};


//?=========== AMC API END ==============================
//
//
//
//
//
//
//
// ?================= getsocialMediaApi APi start ====================
export const getsocialMediaApi = (ctx = null, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `socialMedia?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
        );
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addSocialMediaApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance
    .post("socialMedia", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      ctx2?.setState({ resLoading: false })
      ctx.props.getsocialMediaApi(null, onHide);
      toast.success("Successfully Save.")
    }).catch((error) => {
      ctx2?.setState({ resLoading: false })
      toast.error(error?.response?.data?.message || 'Something went wrong.');
    })
};

export const deleteSocialMediaApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`socialMedia/${id}`).then((res) => {
    ctx.props.getsocialMediaApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateSocialMediaApi = (data, ctx, onHide, id, ctx2) => {

  return postLoginInstance.put(`socialMedia/${id}`, data).then((res) => {
    ctx2?.setState({ resLoading: false })
    ctx.props.getsocialMediaApi(null, onHide);
    toast.success("Successfully Updated.")
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};

// ?================= getsocialMediaApi APi ENd ====================
//
//
//
//
// ?================= TARGETED CONTENT API START ======================
export const getTargetedContentApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `targetedContent?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT
        }&pageNumber=${ctx?.state.currentPage || START_PAGE}`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
        );
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addTargetedContentApi = (data, ctx, onHide) => {
  return postLoginInstance.post("targetedContent", data).then((res) => {
    ctx.props.getTargetedContentApi(null, onHide);
    toast.success("Successfully Save.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deleteTargetedContentApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`targetedContent/${id}`).then((res) => {
    ctx.props.getTargetedContentApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateTargetedContentApi = (data, ctx, onHide, id) => {
  return postLoginInstance.put(`targetedContent/${id}`, data).then((res) => {
    ctx.props.getTargetedContentApi(null, onHide);
    toast.success("Successfully Updated.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
// ?================= TARGETED CONTENT API END ======================
//
//
//
//
//
//
//
//
// ?================= TRENDING.... CONTENT API START ======================
export const getTrendingkeywordsApi = (ctx = {}, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `trendingKeywords?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT
        }&pageNumber=${ctx?.state.currentPage || START_PAGE}`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
        );
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addTrendingkeywordsApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance.post("trendingKeywords", data).then((res) => {
    ctx.props.getTrendingkeywordsApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Save.")
  }).catch((error) => {
    const errMas = error?.response?.data?.message;
    ctx2?.setState({ resLoading: false })
    toast.error(errMas?.includes('Duplicate entry') ? "This position is alrady occupied." : errMas || 'Something went wrong.');
  })
};
export const deleteTrendingkeywordsApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`trendingKeywords/${id}`).then((res) => {
    ctx.props.getTrendingkeywordsApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateTrendingkeywordsApi = (data, ctx, onHide, id, ctx2) => {
  return postLoginInstance.put(`trendingKeywords/${id}`, data).then((res) => {
    ctx.props.getTrendingkeywordsApi(null, onHide);
    toast.success("Successfully Updated.")
    ctx2?.setState({ resLoading: false })
  }).catch((error) => {
    const errMas = error?.response?.data?.message;
    ctx2?.setState({ resLoading: false })
    toast.error(errMas?.includes('Duplicate entry') ? "This position is already occupied." : errMas || 'Something went wrong.');
  })
};
// ?======================== TRENDING.... End ================
//
//
// ?================= TRENDING.... CONTENT API START ======================
export const getTrendingContentApi = (ctx = {}, onHide = null) => {
  return function (dispatch, getState) {
    const { verifyUrl = false } = ctx?.state ?? {};
    return postLoginInstance
      .get(
        `trending?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT
        }&pageNumber=${ctx?.state.currentPage || START_PAGE}`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
        );
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addTrendingContentApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance.post("trending", data).then((res) => {
    ctx.props.getTrendingContentApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Save.")
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const deleteTrendingContentApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`trending/${id}`).then((res) => {
    ctx.props.getTrendingContentApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updateTrendingContentApi = (data, ctx, onHide, id, ctx2) => {
  return postLoginInstance.put(`trending/${id}`, data).then((res) => {
    ctx2?.setState({ resLoading: false })
    ctx.props.getTrendingContentApi(null, onHide);
  }).catch((error) => {
    ctx2?.setState({ resLoading: false })
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
// ?======================== TRENDING.... End ================
//
//

export const getCommonApi = async (url) => {
  try {
    const response = await postLoginInstance.get(url);
    return response;
  } catch (error) {
    return error;
  }
}


export const getPostOfDayApi = (ctx = null, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance
      .get(
        `postOfTheDay?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE
        }`
      )
      .then((res) => {
        dispatch(
          getAllListData(res.data.data, ctx?.state.currentPage || START_PAGE)
        );
        if (!ctx) {
          onHide();
        }
      }).catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong.');
      })
  };
};

export const addPostofthedayApi = (data, ctx, onHide, ctx2) => {
  return postLoginInstance
    .post("postOfTheDay", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      ctx.props.getPostOfDayApi(null, onHide);
      ctx2?.setState({ resLoading: false })
      toast.success("Successfully Save.")
    }).catch((error) => {
      const errMas = error?.response?.data?.message;
      ctx2?.setState({ resLoading: false })
      toast.error(errMas?.includes('Duplicate entry') ? "This position is alrady occupied." : errMas || 'Something went wrong.');

    })
};

export const deletePostofthedayApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`postOfTheDay/${id}`).then((res) => {
    ctx.props.getPostOfDayApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};
export const updatePostofthedayApi = (data, ctx, onHide, id, ctx2) => {

  return postLoginInstance.put(`postOfTheDay/${id}`, data).then((res) => {
    ctx.props.getPostOfDayApi(null, onHide);
    ctx2?.setState({ resLoading: false })
    toast.success("Successfully Updated.")
  }).catch((error) => {
    const errMas = error?.response?.data?.message;
    ctx2?.setState({ resLoading: false })
    toast.error(errMas?.includes('Duplicate entry') ? "This position is alrady occupied." : errMas || 'Something went wrong.');

  })
};