import React, { useEffect, useState } from 'react';
import { CustomModal } from '../common';
import serch from '../../assets/images/Search_1.svg';
import cancel from '../../assets/images/greay-cancel-icon.svg';
import { Button, Dropdown, DropdownButton, Image } from 'react-bootstrap';
import { getCommonApi, getSearchApi } from './Api';
import { isArray } from 'lodash';
import { toast } from 'react-toastify';
import { START_PAGE } from '../../utils/Constant';

export default function SearchContent({ showModal, onHide, selectedContent, isMultiselect = false }) {
  const [show, setShow] = useState(false);
  const [keyData, setKeyData] = useState([]);
  const [keyDataMatch, setKeyDataMatch] = useState([]);
  const [searchApiData, setSearchApiData] = useState([]);
  const [serchKey, setSerchKey] = useState('');
  const [contentType, setContentType] = useState('Videos');
  const [contentTypeData, setContentTypeData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  async function fetchData() {
    const restype = await getCommonApi('website/contentType/list');
    const res = await getCommonApi(`keyword?pageSize=1000&pageNumber=${START_PAGE}`);
    if (res.status === 200 && restype.status === 200) {
      setKeyData(res.data.data.list);
      setContentTypeData(restype.data.data);
      sessionStorage.setItem("keyData", JSON.stringify(res.data.data.list));
      sessionStorage.setItem("ContentTypes", JSON.stringify(restype.data.data));
    } else {
      toast.error(res.message);
    }
  }

  const handleSearchApiCalled = async () => {
    const res = await getSearchApi(`content/search?contentType=${contentType}&searchValue=${serchKey}&pageNumber=1&pageSize=50`);
    if (res.status === 200) {
      setSearchApiData(res.data.data?.contents[0]?.list || []);
      setShow(false);
    } else {
      alert(res);
    }
  };

  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem("keyData"));
    let data2 = JSON.parse(sessionStorage.getItem("ContentTypes"));
    handleSearchApiCalled(); //remove if not wanat befor search
    if (isArray(selectedContent) && selectedContent.length > 0) {
      setSelectedData(selectedContent);
    }
    if (!data || !(data2 ?? false)) {
      fetchData();
    } else {
      setKeyData(data);
      setContentTypeData(data2);
    }
  }, []);

  function changeHandler(e) {
    setSerchKey(e.target.value);
    let dataMatch = keyData.filter((item) => item.keyword.toLowerCase().startsWith(e.target.value.toLowerCase()));
    setKeyDataMatch(dataMatch);
    setShow(true);
  }

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchApiCalled();
    }
  };

  function addData(data) {
    if (!isMultiselect) {
      setSelectedData([data]);
    } else {
      setSelectedData([...selectedData, data]);
    }
  }

  function removeDataHandler(id) {
    let filterData = selectedData.filter((item) => item.uuid !== id);
    setSelectedData(filterData);
  }

  return (
    <div>
      <CustomModal
        show={showModal}
        onHide={() => onHide(selectedData)}
        title={"Search Content"}
        modalClass={"change-password search-modal-class"}>
        <div className="input-search-drop-frame">
          <div className="dropdown-frame">
            <DropdownButton
              id="dropdown-basic-button"
              onSelect={(e) => setContentType(e)}
              className='inter-regular f-s-18 lh-24 '
              title={contentType}>
              {contentTypeData?.map((item, i) => (
                <Dropdown.Item key={i} eventKey={item.contentTypeName}>{item.contentTypeName}</Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className="search-input-frame">
            <input type="text" className='input-box' onKeyPress={handleEnterKeyPress} value={serchKey} onChange={changeHandler} placeholder='Search...' />
            {serchKey && keyDataMatch.length > 0 && show && (
              <ul className="search-key-dropdown">
                {keyDataMatch?.map((item, i) => (
                  <li key={i} onClick={() => { setSerchKey(item.keyword); setShow(false) }} className='inter-regular f-s-14 lh-22'>{item.keyword}</li>
                ))}
              </ul>
            )}
          </div>
          {serchKey && (
            <Image src={cancel} onClick={() => { setSerchKey("") }} alt='search-icons pointer' className='search-icons' />
          )}
          <Image src={serch} onClick={handleSearchApiCalled} alt='search-icons' className='search-icons' />
        </div>
        <h4 className='inter-medium mt-5 f-s-22 lh-29'>Selected Content :</h4>
        <ul className="selected-content-box">
          {selectedData?.length > 0 ? (
            selectedData?.map((item, i) => (
              <li key={i}><span className='inter-regular f-s-14 ellipsis lh-22'><Image src={item?.amcCircleImagePath} className='' alt='img' /> {item?.title} </span> <Image src={cancel} onClick={() => removeDataHandler(item?.uuid)} className='search-icons pointer' alt='canle imge0' /> </li>
            ))
          ) : (
            <li><span className='inter-medium f-s-16 lh-22 w-100 text-center'>Not Selected Yet...  </span></li>
          )}
        </ul>
        <h4 className='inter-medium f-s-22 lh-29'>Select Content :</h4>
        <ul className="selected-content-box">
          {searchApiData.length > 0 ? (
            searchApiData?.map((item, i) => {
              if (!(selectedData?.some((e) => e?.uuid === item?.uuid))) {
                return (
                  <li key={i}>
                    <span className='inter-regular ellipsis f-s-14 lh-22'>
                      <Image src={item?.amcCircleImagePath} className='' alt='img' /> {item?.title}
                    </span>
                    <Button
                      onClick={isMultiselect ? () => addData(item) : (selectedData.length === 1 ? null : () => addData(item))}
                      disabled={isMultiselect ? false : (selectedData.length === 1 ? true : false)}
                      className={`primary-btn  f-s-14 py-2 text-nowrap  text ${isMultiselect ? false : (selectedData.length === 1 ? true : false) ? "disable-pointer" : 'pointer'} `}
                    >
                      + ADD
                    </Button>
                  </li>
                );
              }
              return null;
            })
          ) : (
            <li><span className='inter-medium f-s-16 lh-22 w-100 text-center'> Search ...  </span></li>
          )}
        </ul>
        <div className="submit-wrapper  gap-20">
          <Button onClick={() => onHide(selectedData)} className="secondary-btn">Close</Button>
          <Button onClick={() => onHide(selectedData)} className="primary-btn">Submit</Button>
        </div>
      </CustomModal>
    </div>
  );
}
