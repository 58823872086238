import React from "react";
import { connect } from 'react-redux';
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
  SelectControl,
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import { CustomModal, Spinner } from "../common";
import { addTrendingContentApi, updateTrendingContentApi } from "./Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCustomerNameApi } from "../customers/Api";
import { MAX_LIMIT, START_PAGE, TrendingType } from "../../utils/Constant";
class TrendingForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;

    this.state = {
      editData: data || "",
      pageSize: MAX_LIMIT,
      currentPage: START_PAGE,
      isDropdown: true,
      name: data?.name,
      link: data?.link,
      type: data?.type,
      date: data?.date ? new Date(data?.date) : "",
      resLoading: false,
    };
  }

  componentDidMount() {

  }


  onValidSubmit = () => {
    this.setState({ resLoading: true })
    let TrendingData = {
      "data": {
        "name": this.state.name,
        "link": this.state.link,
        "type": this.state.type,
        "date": this.state.date
      }
    };

    if (this.props.editData) {
      updateTrendingContentApi(
        JSON.stringify(TrendingData),
        this.props.this,
        this.props.onHide,
        this.props.editData.uuid,
        this
      );
    } else {
      addTrendingContentApi(
        JSON.stringify(TrendingData),
        this.props.this,
        this.props.onHide,
        this
      );
    }
  };

  render() {
    const { resLoading } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props.editData ? "Edit Trending" : "Add Trending"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "name")}
                label="Trending Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter Name",
                  },
                }}
              />
            </Col>
            <Col sm={6}>
              <FormElement
                valueLink={this.linkState(this, "link")}
                label="Link"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter Link",
                  },
                }}
              />
            </Col>

            <Col sm={6}>
              {/* {this.state.subCategoryName===""?null: */}
              <FormElement
                valueLink={this.linkState(this, "type")}
                label="Type"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    options: Object.keys(TrendingType).map((item, i) => ({ 'value': TrendingType[item], 'label': item })),

                    multiple: false,
                    searchable: true,
                    // disabled:true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.type);

                    },
                  },
                }}
              />
              {/* } */}
            </Col>


            <Col md={6}>
              <div className="form-group custom-date-range-picker">
                <label htmlFor="" className="form-label">Date</label>
                <DatePicker
                  selected={this.state.date}
                  onChange={(date) => this.setState({ date: date })}
                  // maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Date"
                  className="form-control "
                  // showMonthDropdown
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                />
              </div>
            </Col>

          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              {resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
  getCustomerNameApi
}
export default connect(mapStateToProps, mapDispatchToProps)(TrendingForm);
