import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CustomModal, ImageUploader, Spinner } from "../common";
import { addPostofthedayApi, updatePostofthedayApi } from "./Api";

class PostofthedayForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props?.editData || null;
    this.state = {
      editData: data,
      link: data?.link || "",
      thumbnailPath: "",
      thumbnailFile: null,
      position: data?.position || "",
      uploadImage: data?.thumbnailPath || null,
      resLoading: false,
    };
  }

  componentDidMount() { }

  imageUploaser = (e, name) => {

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
  }
  isEditDataChanged = (key) => {
    const { editData } = this.state;
    return editData[key] === this.state[key];
  }
  onValidSubmit = () => {
    this.setState({ resLoading: true })
    let postOFDay = {
      "data": {
        "link": this.state.link,
        "thumbnailPath": this.state.thumbnailPath,
        "position": this.state.position,
      }

    }
    const data = new FormData();
    data.append("file", this.state.thumbnailFile);

    if (this.props?.editData?.uuid) {
      const postOFDay2 = {
        "data": {
          ...(this.isEditDataChanged("link") ? {} : { "link": this.state.link }),
          ...(this.isEditDataChanged("position") ? {} : { "position": parseInt(this.state.position) }),
          ...(this.state.thumbnailPath ? { "thumbnailPath": this.state.thumbnailPath } : {})
        }
      };
      data.append("key", JSON.stringify(postOFDay2));
      updatePostofthedayApi(data, this.props.this, this.props.onHide, this.props.editData.uuid, this)
    } else {
      data.append("key", JSON.stringify(postOFDay));
      addPostofthedayApi(data, this.props.this, this.props.onHide, this);
    }

  }

  render() {
    const { uploadImage, thumbnailFile, resLoading } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props.editData?.uuid ? "Edit Post" : "Add Post"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "link")}
                label="Link"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Link cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Link",
                  },
                }}
              />
            </Col>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "position")}
                label="Position"
                required
                validations={[
                  {
                    validate: FormValidator.isLimit,
                    message: 'Position not be empty or between 1 to 10',
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter position between 1 to 10",
                  },
                }}
              />
            </Col>
            <Col sm={6}>
              <ImageUploader required={false} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "", uploadImage: "" })} />
            </Col>
          </Row>

          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              {resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
  // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PostofthedayForm);
