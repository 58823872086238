import React from 'react';
import { toast } from 'react-toastify';
import { BaseReactComponent, CustomTextControl, Form, FormElement, FormSubmitButton, FormValidator } from '../../utils/form';
import { resetPasswordApi } from './Api';
import PasswordHide from "../../assets/images/password-hide.svg";
import PasswordShow from "../../assets/images/password-show.svg";
import { Image } from 'react-bootstrap';
import { CustomModal } from '../common';


class ResetPassword extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      inProgress: false,
      token: props.show ? props.show : "",
      newPassword: '',
      confirmPassword: '',
      showPassowrd: false,
      showConfirmPassword: false,
    }
  }
  componentDidMount() { }

  onSubmit = () => {
    if (this.state.newPassword !== this.state.confirmPassword) {
      toast.error("New password and Confirm password don't match");
      return;
    }
    const data = {
      data: {
        "newPassword": this.state.newPassword,
      }
    }
    resetPasswordApi(this, data);
  }

  render() {
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.handleClose}
        // title={}
        modalClass={"forgot-password-modal"}
      >
        <div className="login-wrapper">
          <div className="login-content">
            <h1 className="red-hat-display-bold f-s-24 mb-3 text-center">Set Password</h1>
            <Form onValidSubmit={this.onSubmit}>
              <div className="password-wrapper">
                <FormElement
                  valueLink={this.linkState(this, "newPassword")}
                  label="Password"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Password cannot be empty",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      type: this.state.showPassowrd ? "text" : "password",
                      placeholder: "Enter Password",
                    },
                  }}
                />
                <Image
                  src={this.state.showPassowrd ? PasswordShow : PasswordHide}
                  className='password-hide pos-abs pointer'
                  onClick={() => this.setState({ showPassowrd: !this.state.showPassowrd })}
                />
              </div>
              <div className='password-wrapper'>
                <FormElement
                  valueLink={this.linkState(this, "confirmPassword")}
                  label="Confirm Password"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty"
                    }
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter Password",
                      type: this.state.showConfirmPassword ? "text" : "password",
                    }
                  }}
                />
                <Image
                  src={this.state.showConfirmPassword ? PasswordShow : PasswordHide}
                  className='password-hide pos-abs pointer'
                  onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })}
                />
              </div>
              <div className="d-flex justify-content-center">
                <FormSubmitButton customClass="primary-btn">Reset Password</FormSubmitButton>
              </div>
            </Form>
          </div>
        </div>
      </CustomModal>
    )
  }
}

ResetPassword.propTypes = {
};

export default ResetPassword;