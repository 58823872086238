import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { isImage } from '../../utils/ReusableFunctions';

const CarouselFileUploader = ({ id, isUploadContentFile = [], isPdf = false, updateContentFile = () => { } }) => {
  const [images, setImages] = useState([]);
  const [pdf, setPdf] = useState("");
  const [isPdfState, setIsPdfState] = useState(false)

  useEffect(() => {
    // const isFile = Array.isArray(isUploadContentFile) ? isUploadContentFile : [isUploadContentFile];

    if (Array.isArray(isUploadContentFile)) {
      setImages(isUploadContentFile?.map((e) => ({ name: (e?.filePath || 'Content Image'), file: e?.filePath })) || []);
      setIsPdfState(isPdf)
    } else {
      console.log("is Not Arry may bePDF", isUploadContentFile)
      setImages([{ name: isUploadContentFile, file: isUploadContentFile }])
      setIsPdfState(true)
    }
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target?.files);
    const imageFiles = [...images];

    // Check if PDF is already uploaded
    const existingPdf = imageFiles.some((file) => file.file.type === "application/pdf");

    // Prevent uploading images if a PDF exists
    if (existingPdf && files.some((file) => file.type.startsWith("image/"))) {
      toast.warn("Cannot upload images when a PDF is already uploaded.");
      return;
    }

    // Prevent uploading a PDF if images exist
    if (imageFiles.some((file) => file.file.type.startsWith("image/")) && files.some((file) => file.type === "application/pdf")) {
      toast.warn("Cannot upload a PDF when images are already uploaded.");
      return;
    }

    // Count the number of images being uploaded
    const totalImageCount = imageFiles.filter((file) => file.file.type.startsWith("image/")).length +
      files.filter((file) => file.type.startsWith("image/")).length;

    if (totalImageCount > 10) {
      toast.warn("Cannot upload more than 10 images.");
      return;
    }

    files.forEach((file) => {
      if (file.type === "application/pdf") {
        if (!existingPdf) {
          setIsPdfState(true);
          imageFiles.push({ name: file.name ?? '', file });
        } else {
          toast.warn("Only one PDF is allowed.");
        }
      } else if (file.type.startsWith("image/")) {
        imageFiles.push({ name: file.name ?? '', file });
      }
    });

    setImages(imageFiles);
    updateContentFile(imageFiles);
  };

  useMemo(() => { updateContentFile(images); }, [images])
  const removeImage = (e, index) => {
    e.preventDefault();
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  return (
    <div className="img-form-group">
      <label className="form-label">
        Upload Content
      </label>
      {
        !(images?.length > 0) ?
          <div className="img-upload-wrapper">
            <input
              type="file"
              multiple
              id={`carouselUpload${id}`}
              onChange={handleFileChange}
              accept="image/*,application/pdf"
            />
            <label htmlFor={`carouselUpload${id}`}>
              <span>+</span>
            </label>
          </div>
          :
          <div>
            <div className="img-upload-wrapper">
              <input
                type="file"
                multiple
                id={`carouselUpload${id}`}
                onChange={handleFileChange}
                accept="image/*,application/pdf"
              />
              <label htmlFor={`carouselUpload${id}`}>
                <span>+</span>
              </label>
            </div>
            {(images?.length > 0) && (

              <div className="selected-images">
                {images?.map((img, index) => {
                  if (img.file?.type?.startsWith("image/") || isImage(img?.file)) {
                    return (
                      <div key={index} className="image-preview">
                        <img
                          src={
                            (!isPdfState)
                              ? (img?.file ?? '')
                              : (img?.file && typeof img.file === 'object' && window.URL?.createObjectURL)
                                ? URL.createObjectURL(img.file)
                                : img.file
                          }
                          alt={img?.name || "Content Image"}
                        />
                        <button className='pointer' onClick={(e) => removeImage(e, index)}>❌</button>
                      </div>
                    );
                  } else {
                    return (
                      (isPdfState) ?
                        <div className=" d-flex align-items-center justify-content-between">
                          <a href={img.file} target='_blank'>
                            <div className=" primary-btn">View Pdf</div>
                          </a>
                          <span onClick={(e) => removeImage(e, index)}>❌</span>
                        </div>
                        :
                        <p key={index} className='pdf-name inter-regular f-s-12'>
                          {img.name}
                          <span onClick={(e) => removeImage(e, index)} className='pdf-close-icon'>❌</span>
                        </p>
                    );
                  }
                })}
              </div>

            )}
            {pdf && (
              <>
                {/* <h3>Selected PDF:</h3> */}
                <p className='pdf-name inter-regular f-s-12'>{pdf}  <span onClick={() => setPdf('')} className='pdf-close-icon'>❌</span> </p>
              </>
            )}
          </div>
      }
    </div>
  );
};

export default CarouselFileUploader;
