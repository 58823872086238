import React, { Component } from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Col, Image, Row } from "react-bootstrap";
import { deleteTrendingContentApi, getTrendingContentApi } from "./Api";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import editPencil from "../../assets/images/edit-icon.png";

import { ComponentHeader, InformationModal, VerifiedModal } from "../common";
import { connect } from "react-redux";
import TrendingForm from "./TrendingForm";
import { START_PAGE, isModuleExist } from "../../utils/Constant";
import { TRENDING_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";
export class TrendingTab extends Component {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist("Trending", "Global");

    this.state = {
      access: AccesData,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,


      isVerify: false,
      show: false,

      verified: '',
      verifyUrl: ''

    };
  }
  // todo---------------------------------------
  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getTrendingContentApi(this);
  }
  // todo---------------------------------------
  // todo:-----------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevVerified = prevParams.get("isVerified") || "";


    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const verified = params.get("isVerified") || "";

    if (prevPage !== page || prevVerified !== verified) {
      this.setState({ currentPage: page }, () => {
        this.props.getTrendingContentApi(this);

      });
    }
  }
  // todo:-----------------------------------------

  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };
  openCloseDeleteModal = (rowData) => {
    this.setState({
      delete: !this.state.delete,
      deleteData: rowData,
    });
  };
  deleteHandle = (id) => {

    deleteTrendingContentApi(id, this, this.openCloseDeleteModal)
  }

  render() {
    const { showEdit, access } = this.state;
    const { listData, totalPages, currentPage } = this.props.masterCategoryState;

    return (
      <section className="request-section">
        {this.state.delete && (
          <InformationModal
            show={this.state.delete}
            id={this.state.deleteData.uuid}
            title={`Are you sure you want to delete ${this.state.deleteData.name} ?`}
            handleClose={this.openCloseDeleteModal}
            handleDelete={this.deleteHandle}
          />
        )}
        <VerifiedModal
          name={`'${this.state.editData?.name}'`}
          show={this.state.show}
          url={TRENDING_VERIFY + `/${this.state?.editData?.uuid}`}
          isVerified={this.state.isVerify}
          handleCancel={() => { this.setState({ show: false }) }}
          successRes={() => { this.props.getTrendingContentApi(this) }}
        />
        {
          showEdit &&
          <TrendingForm
            show={showEdit}
            onHide={this.showEditModal}
            editData={this.state.editData}
            this={this}
          />
        }
        <ComponentHeader
          title="Trending"
          isSearch={false}
          placeholder={"Search Trending"}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          primaryBtn={access.createAccess ? "Add trending" : ""}
          handlePrimaryBtn={() => {
            // this.props.history.push('/ads/banner-ads/add');
            this.setState({ showEdit: true, editData: '' })
          }}
        />
        <Row>
          <Col sm={2}>
            <FilterVefiedDropDown ctx={this} />
          </Col>
        </Row>
        <div className="commom-table-wrapper">

          <CustomTable
            tableData={listData?.list || []}
            columnList={[
              {
                coumnWidth: 200,
                labelName: "Name",
                dataKey: "name",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "name") {
                    return rowData.name;
                  }
                },
              },
              {
                coumnWidth: access.verificationAccess ? 100 : 0,
                labelName: "Verified",
                dataKey: "verify",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "verify") {
                    return <div className=" d-flex align-items-center inter-medium">
                      {(rowData.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                    </div>;
                  }
                },
              },
              {
                coumnWidth: 250,
                labelName: "Link",
                dataKey: "link",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "link") {
                    return rowData.link;
                  }
                },
              },
              {
                coumnWidth: 200,
                labelName: "Type",
                dataKey: "type",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "type") {
                    return rowData.type;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Updated By",
                dataKey: "updatedBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "updatedBy") {
                    return rowData?.updatedBy?.name || 'Na';;
                  }
                },
              },
              {
                coumnWidth: 150,
                labelName: "Created By",
                dataKey: "createdBy",
                className: "name",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "createdBy") {
                    return rowData?.createdBy?.name || 'Na';
                  }
                },
              },


              {

                coumnWidth: access.updateAccess || access.deleteAccess ? 250 : 0,
                labelName: "Action",
                dataKey: "",
                className: "",
                isCell: true,
                cell: (rowData, dataKey) => {
                  if (dataKey === "") {
                    return (
                      <>
                        {access.updateAccess && (
                          <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                        )}
                        {access.deleteAccess && (
                          <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                        )}
                      </>
                    );
                  }
                },

              }

            ]}
            message="No data found" // For Pagination
            history={this.props.history}
            location={this.props.location}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </section>
    );
  }
}


const mapStateToProps = state => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  getTrendingContentApi
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendingTab);
