import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
  SelectControl,
} from "../../utils/form";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CustomModal, ImageUploader, Spinner } from "../common";
import { addUserGroupApi, getAllGroupUsers,getAllUsersApi, updateUserGroupApi, } from "./Api";

class UserGroupForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props?.editData || null;
    this.state = {
      groupName: data?.groupName || "",
      userUuids:[],
      usersOptions:[],

      resLoading:false,
 
    };
  }

  componentDidMount() {
    getAllUsersApi(this);
    if (this.props?.editData?.uuid){
      // console.log("edit Data is exist")
      getAllGroupUsers(this)
    }
   }
  componentDidUpdate(){
    
  }



  onValidSubmit = () => {
    this.setState({resLoading:true})
    let groupData = {
        "data": {
            "groupName": this.state.groupName,
            "userUuids": this.state.userUuids
        }
    };

    if (this.props?.editData?.uuid) {
      updateUserGroupApi(groupData, this.props.this, this.props.onHide, this.props.editData.uuid,this)
    } else {
      addUserGroupApi(groupData, this.props.this, this.props.onHide,this)
    }
  }
  onHide = () => {
   
    this.props.onHide();
  }
  render() {
    const { usersOptions ,resLoading} = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props.editData ? "Edit Group Name" : "Add Group Name"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "groupName")}
                label="Group Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Group Name",
                  },
                }}
              />
            </Col>
            <Col md={12}>
              <FormElement
                valueLink={this.linkState(this, "userUuids")}
                label="Select Users"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Content type cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Users",
                    options: usersOptions?.list?.map(item => ({
                        label: `${item.firstName || 'Na'} ${item.lastName || ''}`,
                        value: `${item.uuid}`
                      })),
                    multiple: true,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.userUuids);
                    },
                  },
                }}
              />
            </Col>
         
          </Row>

          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
            {resLoading?<span className="d-flex align-items-center gap-12"> <Spinner/> Saving...</span> :"Save"}
             </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
  // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {
    getAllUsersApi
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupForm);
