export const API_LIMIT = 10
export const MAX_LIMIT = 1000
export const START_PAGE = 1
export const DEFAULT_LIST = { list: [], length: 0 }
export const YesNoOptions = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" }
];

export const ONLINE = "Online";
// export const TOOLS = "tools";
export const IN_PERSON = "In Person";

export const Thefinpedia = "Thefinpedia";
export const MUTUAL_FUND_COMPANY = "Mutual Fund Company";

export const ALT_CIRCLE = "circle image";
export const ALT_THUMBNAIL = "thumbnail image";
export const ALT_BANNER = "banner image";
export const FULLVIDEO = "FULL-VIDEO";
export const NoBranding = 'No Branding';

export const CREATEACCESS = "createAccess";
export const UPDATEACCESS = "updateAccess";
export const READEACCESS = "readAccess";
export const DELATEACCESS = "deleteAccess";
export const VALIDATEACCESS = 'validateAccess';
export const CAROUSEL = 'CAROUSEL';
export const SOCIALMEDIA_URL = "website/socialMedia/list";
export const SELECT_ROLE_TYPE = 'Select Role Type';

export const SUPER_ADMIN = 'super admin';
export const SUPERADMIN = 'Superadmin';
export const CHECKER = 'checker';
export const MAKER = 'maker';
export const MARKET_PLACE = '2a042be9-dc98-4ed7-8168-17686f05d6c5';
export const CREATOR_ID = "6f3c9c6e-3e0d-11ee-a134-00ffb10a2847";

export const IMAGEFILETYPE = "image/*"

export const ACCEPTEDFILETYPE = {
  VIDEOS: "video/*,.mkv",
  INFOGRAPHICS: "application/pdf",
  MINIMALISTS: "image/*",
  MINIMALIST: "image/*",
  PRESENTATIONS: ".ppt,.pptx,.pdf",
  PRESENTATION: ".ppt,.pptx,.pdf",
  PODCASTS: "audio/*,video/*,.mkv",
  REPORTS: ".pdf,.doc,.docx",
  EBOOKS: ".pdf",
  'E-BOOKS': ".pdf",
  EMAILERS: "image/*",
  CAROUSEL: "application/pdf,image/*",
  ARTICLES: ".pdf,.doc,.docx",
  REELS: "video/*,.mkv",
}
export const FILETYPENAME = {
  IMAGEFILE: ".png,.jpg,.jpeg",
  VIDEOS: ".mp4,.mkv",
  REELS: ".mp4,.mkv",
  INFOGRAPHICS: ".pdf",
  infographics: ".pdf",
  MINIMALISTS: "image",
  MINIMALIST: "image",
  PRESENTATIONS: ".ppt,.pptx,.pdf",
  // PRESENTATION :"presentation",
  PRESENTATION: ".ppt,.pptx,.pdf",
  PODCASTS: "audio,.mp4,.mkv",
  REPORTS: "pdf,doc,docx",
  EBOOKS: "pdf",
  // 'E-BOOKS': "pdf",
  'E-BOOKS': ".pdf",
  EMAILERS: "image",
  CAROUSEL: ".pdf,.png,.jpg,.jpeg",
  ARTICLES: ".pdf,.doc,.docx"
}
export const Trending = "Trending";
export const PREMIUM = "Premium";
export const NON_PREMIUM = "Free";

export const DOWNLOAD = 'Download';
export const COBRAND = 'Cobrand';

export const uploadFrequency = [
  { label: "Fortnightly", value: "15.00" },
  { label: "Weekly", value: "7.00" },
  { label: "Bi-Weekly", value: "3.50" },
  { label: "Daily", value: "1.00" },
  { label: "As and When ", value: "0.00" },


];
export const subscriptionDuration = [
  { label: "7 Days", value: "7" },
  { label: "15 Days", value: "15" },
  { label: "1 Month", value: "30" },
  { label: "3 Months", value: "90" },
  { label: "6 Months", value: "180" },
  { label: "1 Year", value: "365" },
  { label: "10 Years", value: "3650" },
];

export const uploadFrequencies = {
  "7.00": "Weekly",
  "3.50": "Bi-Weekly",
  "15.00": "Fortnightly",
  '1.00': 'Daily',
  '10': 'As and When',
};

export const subTrending = {
  trendingKeywords: 'Trending Keywords',
  manual: 'Manual',
  systemGenerated: 'System Generated',
}
export const TrendingType = {
  INSTAGRAM: "INSTAGRAM",
  FACEBOOK: "FACEBOOK",
  LINKEDIN: "LINKEDIN",
  X: "X",
  OTHER: "OTHER"
};
export const ModuleName = {
  LANGUAGE: "LANGUAGE",
  EVENT: "EVENT",
  CONTENT: "CONTENT",
  CAMPAIGN: "CAMPAIGN",
  TARGETED_CONTENT: "TARGETED_CONTENT",
  CATEGORY: "CATEGORY",
  TOOLS: "TOOLS",
  PERSONALIZE: "PERSONALIZE"
}

export const NumberOfDays = [
  { label: "Plan expiry", value: "0" },
  { label: "Daily", value: "1" },
  { label: "Monthly", value: "30" },
  { label: "Quarterly", value: "90" },
  { label: "Half Yearly", value: "180" },
  { label: "Yearly", value: "365" },
]

export const Validity = [

  { label: "Monthly", value: "30" },
  { label: "Quarterly", value: "90" },
  { label: "Half Yearly", value: "180" },
  { label: "Plan Expiry", value: "0" },
]

export const groupBy = (data, key) => {
  return data.reduce((acc, item) => {
    const group = item[key];

    if (!acc[group]) {
      acc[group] = [];
    }

    acc[group].push(item);

    return acc;
  }, {});
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const EventType = {
  FREE: 'Free',
  PAID: 'Paid',
  SPONSORED: 'Sponsored'
}
export const durationObj = {
  "30": "Monthly",
  "90": "Quarterly",
  "180": "Half Yearly",
  "365": "Yearly",
  '1': 'Daily',
  '0': "Plan expiry",
  "3650": "Lifetime"
}
export const PackOptions = [
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "15", value: "15" },
  { label: "20", value: "20" },
  { label: "30", value: "30" },


]
export const TYPE_OPTION = [
  { label: 'EVENT', value: "Event" },
  { label: 'CONTENT', value: "Content" },
  { label: 'TOOLS', value: "Tools" },
  { label: 'PERSONALIZATION', value: "Personalization" },

]

// export const subPremiumPlanTestData = {
//   "name": "Siver",
//   "subPremiumPlanVariation": [
//     {
//       "title": "monthly",
//       "description": "<p>https://Yesh.com/<br>abcd efght <strong>this is not faire ererererer fdf</strong><del><strong>fdfafadfaf</strong></del><code><strong>fdafdafdfdf</strong></code></p>\n",
//       "price": 5000,
//       "discountedPrice": 4000,
//       "resetInDays": 180,
//       "subPremiumPlanVariationSpecification": [
//         {
//           "type": "content",
//           "contentType": ["4d25bcb8-5372-11ee-a6a5-b48c9dd517c6", "4d25bac3-5372-11ee-a6a5-b48c9dd517c6"],
//           "actionType": ["download"],
//           "contentCategory": ["premium"],
//           "limit": 5,
//           "resetInDays":90,
//           'uploadSection':["a20c98ef-ed60-4fe6-8210-56ace331e05d"]
//         }
//       ]
//     },
//     {
//       "title": "Yearly",
//       "description": "<p>https://Adil.com/<br>abcd efght <strong>this is not faire ererererer fdf</strong><del><strong>fdfafadfaf</strong></del><code><strong>fdafdafdfdf</strong></code></p>\n",
//       "price": 8000,
//       "discountedPrice": 3000,
//       "resetInDays": 365,
//       "subPremiumPlanVariationSpecification": [
//         {
//           "type": "event",
//           "contentType": ["4d25bcb8-5372-11ee-a6a5-b48c9dd517c6", "4d25bac3-5372-11ee-a6a5-b48c9dd517c6"],
//           "actionType": ["download"],
//           "contentCategory": ["non-premium"],
//           "limit": 54,
//           "resetInDays":30,
//           'uploadSection':["a20c98ef-ed60-4fe6-8210-56ace331e05d"]
//         }
//       ]
//     },
//     {
//       "title": "Yearly",
//       "description": "<p>https://muzaffar.com/<br>abcd efght <strong>this is not faire ererererer fdf</strong><del><strong>fdfafadfaf</strong></del><code><strong>fdafdafdfdf</strong></code></p>\n",
//       "price": 8000,
//       "discountedPrice": 3000,
//       "resetInDays": 365,
//       "subPremiumPlanVariationSpecification": [
//         {
//           "type": "tools",
//           "contentType": ["4d25bcb8-5372-11ee-a6a5-b48c9dd517c6", "4d25bac3-5372-11ee-a6a5-b48c9dd517c6"],
//           "actionType": ["download"],
//           "contentCategory": ["non-premium"],
//           "limit": 4,
//           "resetInDays":90,
//           'uploadSection':["a20c98ef-ed60-4fe6-8210-56ace331e05d"]
//         }
//       ]
//     },
//   ]
// }

export const isModuleExist = (module, subModule) => {
  // console.log(module,subModule);
  const modules = JSON.parse(localStorage.getItem("finAdmin"))?.modules || [];
  // console.log("Data is coming",modules);
  const findMoule = modules?.find((item) => item.moduleName === module && item.subModuleName === subModule);

  // console.log("AccesDeletRead obj",findMoule)
  return findMoule;
}


export const contentMaster = "Content Master";
export const subConternMaster = {
  campaign: 'Campaign',
  postOfTheDay: 'Post of the Day',
  keywords: 'Keywords',
  contenttype: 'Content Type',
  subCategory: 'Sub Category',
  category: 'Category',
  targetedContentList: "Targeted Content List",
  AMC: 'AMC',
  socialMediaContentType: 'Social Media Content Type',
  contentCreator: 'Creator',
  language: 'Language',
  event: 'Event',
  color: "Color"
}
export const Ads = "Ads";
export const contentBannerAds = '/content-banner-ads';
// export const contentBannerAds = '/content-banner-ads';
// export const contentBannerAds = '/content-banner-ads';
export const subAds = {
  contentBannerAd: 'Content Banner Ad',
  eventBannerAd: 'Event Banner Ad',
  contentAd: 'Content Ad',
}
export const Role = "Role";
export const subRole = {
  RoleModule: 'Role Module',
  Module: 'Module',
  userRole: 'User Role',
}

export const GENERIC = 'generic';
export const KEYWORD = 'keyword';


export const AdminUsers = "Admin Users";
export const VerifyContent = 'Verify Content';
export const Content = "Content";
export const EventMaster = "Event Master";
export const Notifications = "Notifications";
export const SOCIALMEDIA = "SOCIAL-MEDIA";

export const Subscription = "Subscription";
export const Sso = "Sso";

export const subCoupon = {
  coupon: 'Coupon',
  subscriptionPlan: 'Subscription Plan',
}

export const selectPosition = [
  { name: 'Top Left', val: "TOP-LEFT-CORNER" },
  { name: "Top Right", val: 'TOP-RIGHT-CORNER' },
  { name: 'Top Center', val: 'TOP-CENTER-CORNER' },
  { name: 'Bottom Left', val: 'BOTTOM-LEFT-CORNER' },
  { name: 'Bottom Right', val: 'BOTTOM-RIGHT-CORNER' },
  { name: 'Bottom Center', val: 'BOTTOM-CENTER-CORNER' },
  // { name: 'Logo top, Text Bottom',val:'' },
  // { name: 'Text top, Logo Bottom',val:'' },
  // { name: 'Full Top',val:'' },
  // { name: 'Full Bottom',val:'' },
  { name: 'Center Left', val: 'CENTER-LEFT-CORNER' },
  { name: 'Center Center', val: 'CENTER-CENTER-CORNER' },
  { name: 'Center Right', val: 'CENTER-RIGHT-CORNER' },
];
export const selectLogoPosition = [
  { name: 'Right', val: "Right" },
  { name: "Left", val: 'Left' },
  { name: 'Center', val: 'Center' },

]