import { DEFAULT_LIST, START_PAGE } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { GET_ALL_OCCASSION_DATA, GET_ALL_PLATFORM_DATA, GET_ALL_PLAYLIST_DATA } from "./ActionTypes";

const INITIAL_STATE = {
  totalPages: null,
  currentPage: START_PAGE,
  playlistData: DEFAULT_LIST,
  platformData: DEFAULT_LIST,
  occassionData: DEFAULT_LIST
};

const AutoshareReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PLAYLIST_DATA:
      console.log('action.payload:', action.payload);
      console.log('Previous state:', state);

      return {
        ...state,
        playlistData: action.payload,
        totalPages: calculateTotalPageCount(action.payload.length),
        currentPage: action.currentPage,
      };
    case GET_ALL_PLATFORM_DATA:
      console.log('action.payload:', action.payload);
      console.log('Previous state:', state);
      return {
        ...state,
        platformData: action.payload,
        totalPages: calculateTotalPageCount(action.payload.length),
        currentPage: action.currentPage,
      };
    case GET_ALL_OCCASSION_DATA:
      console.log('action.payload:', action.payload);
      console.log('Previous state:', state);
      return {
        ...state,
        occassionData: action.payload,
        totalPages: calculateTotalPageCount(action.payload.length),
        currentPage: action.currentPage,
      };

    default:

      return state;
  }
};

export default AutoshareReducer;

