import React, { Component, useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ComponentHeader from "../common/ComponentHeader";
import { Campaign, } from "./";
import CampaignForm from "./CampingForm";
import { getAllCampaignApi, getAllTargetedListApi } from './Api';
import { getAllAmcApi, getAllCategoryApi, getLanguageNameApi } from '../MasterCategory/Api';
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
import { AmcForm, AmcTab, CategoryMasterForm, CategoryMasterTab, LanguageForm, LanguageTab } from "../MasterCategory";
import TargatedTab, { TargetedTab } from "./TargatedTab";
import TargatedForm from "./TargatedForm";
class ContentCollections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      dataList: [],
      totalPages: null,
      activeTab: {
        tabName: "Category Master",
        eventKey: "category",
        primaryBtn: "Add Category",
        searchPlaceholder: "Search Category",
        api: this.props.getAllCategoryApi,
        componentName: <CategoryMasterTab {...this.props} />,
        showAddEdit: false,
        addEditModal: (
          <CategoryMasterForm
            show={true}
            onHide={this.handlePrimaryBtn}
            ctx={this}
          />
        ),
      },
      tabArray: [
        {
          tabName: "Campaign",
          eventKey: "campaign",
          primaryBtn: "Add Campaign",
          searchPlaceholder: "Search Campaign",
          api: this.props.getAllCampaignApi,
          componentName: <Campaign {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <CampaignForm show={true} onHide={this.handlePrimaryBtn} ctx={this} />
          ),
        },
        {
          tabName: "Category Master",
          eventKey: "category",
          primaryBtn: "Add Category",
          searchPlaceholder: "Search Category",
          api: this.props.getAllCategoryApi,
          componentName: <CategoryMasterTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <CategoryMasterForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },
        {
          tabName: "AMC",
          eventKey: "amc",
          primaryBtn: "Add AMC",
          searchPlaceholder: "Search AMC",
          api: this.props.getAllAmcApi,
          componentName: <AmcTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <AmcForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },
        {
          tabName: "Language",
          eventKey: "language",
          primaryBtn: "Add Language",
          searchPlaceholder: "Search Language",
          api: this.props.getLanguageNameApi,
          componentName: <LanguageTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <LanguageForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },
        {
          tabName: "Targeted List",
          eventKey: "Targeted",
          primaryBtn: "Add Targeted List",
          searchPlaceholder: "Search Targeted Name",
          api: this.props.getAllTargetedListApi,
          componentName: <TargatedTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <TargatedForm show={true} onHide={this.handlePrimaryBtn} ctx={this} />
          ),
        },
      ]
    };
  }
  handlePrimaryBtn = () => {
    const { activeTab } = this.state;
    this.setState({
      activeTab: {
        ...activeTab,
        showAddEdit: !activeTab.showAddEdit,
      },
    });
  };
  componentDidMount() {
    const { history } = this.props;
    history.push({
      search: `?tab=${this.state.activeTab.eventKey}&p=${START_PAGE}`,
    });
    this.state.activeTab.api(this);
  }
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const search = params.get("search") || "";

    if (prevPage !== page) {
      this.setState({ currentPage: page }, () => {
        if (search) {
          this.state.activeTab.api(this);
        } else {
          this.state.activeTab.api(this);
        }
      });
    }
  }




  handleTabChange = (tab) => {
    let activeTab = this.state.tabArray.filter((item) => item.eventKey === tab);
    this.setState({ activeTab: activeTab[0] });
    this.props.history.push({
      search: `?tab=${activeTab[0].eventKey}&p=${START_PAGE}`,
    });
    activeTab[0].api(this);
  };

  render() {
    const { activeTab, tabArray } = this.state;

    return (
      <section className="contentCollections-main-frame">
        {activeTab.showAddEdit && activeTab.addEditModal}
        <ComponentHeader
          title="Content Collection"
          primaryBtn={activeTab.primaryBtn}
          handlePrimaryBtn={this.handlePrimaryBtn}
        />
        <Tabs
          defaultActiveKey={activeTab.eventKey}
          onSelect={this.handleTabChange}
          className="tab-title"
          id="uncontrolled-tab-example"
        >
          {
            tabArray.map((tab) => {
              return (
                <Tab eventKey={tab.eventKey} title={tab.tabName}>
                  {tab.componentName}
                </Tab>
              )
            })
          }
        </Tabs>
      </section>
    );
  }
}


const mapStateToProps = (state) => ({
  contentCollectionsState: state.ContentCollectionsState,
});
const mapDispatchToProps = {
  getAllCampaignApi,
  getAllAmcApi,
  getAllTargetedListApi,
  getAllCategoryApi,
  getLanguageNameApi,
};
ContentCollections.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContentCollections));
