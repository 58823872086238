import { toast } from "react-toastify";
import { postLoginInstance } from "../../utils";
import { MAX_LIMIT, START_PAGE } from "../../utils/Constant";
import { getAllRoleData } from "./RolesAction";

export const getAllRolesApi = (ctx = null, onHide = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance.get(`userRole?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${MAX_LIMIT}&pageNumber=${START_PAGE}`)
      .then((res) => {
        if (ctx?.state.isAddContent) {
          let roleOptions = [];
          res.data.data.list.map((item) => {
            roleOptions.push({
              label: item?.role?.roleName,
              type: item?.role?.roleType,
              value: item?.role?.uuid,
            })
          })
          ctx.setState({
            roleOptions,
            roleList: res?.data?.data,
            // delete:false,

          });

        } else {
          dispatch(getAllRoleData(res.data?.data, ctx?.state?.currentPage || START_PAGE));
          if (!ctx) {
            onHide();
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message || "Something went wrong")
      })
  }
};

export const getAllModulesApi = async () => {
  try {
    const response = await postLoginInstance.get(`module?pageSize=${MAX_LIMIT}&pageNumber=${START_PAGE}`);
    return response.data.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong");
    throw error;
  }
};
export const putAuthCommonApi = async (url, data) => {
  try {
    const response = await postLoginInstance.put(url, data);
    if (response.status < 250 && response.status > 199) {
      toast.success("Successfully Updated.")
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong");
    return error;
  }
}
export const deleteAuthCommonApi = async (url, onHide = null) => {
  try {
    const response = await postLoginInstance.delete(url);
    if (response.status < 250 && response.status > 199) {
      toast.success("Successfully Deleted.");
      onHide()

      return response;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong");
    throw error;
  }
}
export const deleteRolsApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`userRole/${id}`).then((res) => {
    ctx.props.getAllRolesApi(null, onHide);
    toast.success("Successfully Deleted.")
  }).catch((error) => {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  })
};