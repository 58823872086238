import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { ComponentHeader } from "../common";
import {
  ContentCreatorTab,
  ContentTypeTab,
  SubCategoryMaster,
  ColorTab,
  SocialMediaTab,
  KeywordTab,
  EventCategoryTab,
  SubCategoryMasterForm,
  SocialMediaForm,
  ColorForm,
  CouponForm,
  CouponTab,
  TrendingKeywordsTab,
  TrendingKeywordsForm
} from "./index";
import ContentTypeForm from "./ContentTypeForm";
import EventCategoryForm from "./EventCategoryForm";
import ContentCreatorForm from "./ContentCreatorForm";
import KeywordForm from "./KeywordForm";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
import {
  getAllColorApi,
  getAllSpeakerApi,
  getLanguageNameApi,
  getKeywordNameApi,
  getAllCategoryApi,
  getEventApi,
  getContentTypeApi,
  getSubCategoryApi,
  getContentCreatorApi,
  getsocialMediaApi,
  getAllAmcApi,
  getCouponApi,
  getTargetedContentApi,
  geteventOrganiserApi,
  getTrendingContentApi,
  getTrendingkeywordsApi,
} from "./Api";
import TrendingTab from "./TrendingTab";
import TrendingForm from "./TrendingForm";

class MasterCategory extends Component {
  constructor(props) {

    super(props);
    this.state = {
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      colorList: [],
      totalPages: null,
      activeTab: {
        tabName: "Color",
        eventKey: "colors",
        primaryBtn: "Add Color",
        searchPlaceholder: "Search Color",
        api: this.props.getAllColorApi,
        componentName: <ColorTab {...this.props} />,
        addEditModal: (
          <ColorForm show={true} onHide={this.handlePrimaryBtn} ctx={this} />
        ),
      },
      tabArray: [
        {
          tabName: "Color",
          eventKey: "colors",
          primaryBtn: "Add Color",
          searchPlaceholder: "Search Color",
          api: this.props.getAllColorApi,
          componentName: <ColorTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <ColorForm show={true} onHide={this.handlePrimaryBtn} ctx={this} />
          ),
        },

        {
          tabName: "Content Creator",
          eventKey: "contentCreator",
          primaryBtn: "Add Content Creator",
          searchPlaceholder: "Search Content Creator",
          api: this.props.getContentCreatorApi,
          componentName: <ContentCreatorTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <ContentCreatorForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },

        {
          tabName: "Keyword",
          eventKey: "keyword",
          primaryBtn: "Add Keyword",
          searchPlaceholder: "Search Keyword",
          api: this.props.getKeywordNameApi,
          componentName: <KeywordTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <KeywordForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },

        {
          tabName: "Event Category",
          eventKey: "event",
          primaryBtn: "Add Event",
          searchPlaceholder: "Search Event",
          api: this.props.getEventApi,
          componentName: <EventCategoryTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <EventCategoryForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },


        {
          tabName: "Content Type",
          eventKey: "contentType",
          // primaryBtn: "Add Content",
          searchPlaceholder: "Search Content",
          api: this.props.getContentTypeApi,
          componentName: <ContentTypeTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <ContentTypeForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },
        {
          tabName: "Sub Category",
          eventKey: "subCategory",
          primaryBtn: "Add Sub Category",
          searchPlaceholder: "Search Sub Category",
          api: this.props.getSubCategoryApi,
          componentName: <SubCategoryMaster {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <SubCategoryMasterForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },

        {
          tabName: "Social Media",
          eventKey: "socailmedia",
          primaryBtn: "Add Social Media",
          searchPlaceholder: "Search Sub Amc",
          api: this.props.getsocialMediaApi,
          componentName: <SocialMediaTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <SocialMediaForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },
        {
          tabName: "Coupon",
          eventKey: "coupon",
          primaryBtn: "Add Coupon",
          searchPlaceholder: "Search coupon",
          api: this.props.getCouponApi,
          componentName: <CouponTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <CouponForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },

        {
          tabName: "Trending",
          eventKey: "trending",
          primaryBtn: "Add Trending Content",
          searchPlaceholder: "Search Trending Content",
          api: this.props.getTrendingContentApi,
          componentName: <TrendingTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <TrendingForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },
        {
          tabName: "Trending Keywords",
          eventKey: "trendingkeywords",
          primaryBtn: "Add Trending Keywords",
          searchPlaceholder: "Search Trending Keywords",
          api: this.props.getTrendingkeywordsApi,
          componentName: <TrendingKeywordsTab {...this.props} />,
          showAddEdit: false,
          addEditModal: (
            <TrendingKeywordsForm
              show={true}
              onHide={this.handlePrimaryBtn}
              ctx={this}
            />
          ),
        },

      ],
    };
  }
  handleTabChange = (tab) => {
    let activeTab = this.state.tabArray.filter((item) => item.eventKey === tab);
    this.setState({ activeTab: activeTab[0] });
    this.props.history.push({
      search: `?tab=${activeTab[0].eventKey}&p=${START_PAGE}`,
    });
    activeTab[0].api(this);
  };

  handlePrimaryBtn = () => {
    const { activeTab } = this.state;
    this.setState({
      activeTab: {
        ...activeTab,
        showAddEdit: !activeTab.showAddEdit,
      },
    });
  };

  componentDidMount() {
    const { history } = this.props;
    history.push({
      search: `?tab=${this.state.activeTab.eventKey}&p=${START_PAGE}`,
    });
    this.state.activeTab.api(this);
  }
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const search = params.get("search") || "";

    if (prevPage !== page) {
      this.setState({ currentPage: page }, () => {
        if (search) {
          this.state.activeTab.api(this);
        } else {
          this.state.activeTab.api(this);
        }
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
      <section>
        {activeTab.showAddEdit && activeTab.addEditModal}
        <ComponentHeader
          title="Master Collection"
          isSearch={true}
          placeholder={activeTab.searchPlaceholder}
          onChangeMethod={this.onChangeMethod}
          clearSearch={() => this.clearSearch()}
          primaryBtn={activeTab.primaryBtn}
          handlePrimaryBtn={this.handlePrimaryBtn}
        />
        <div className="tab-wrapper">
          <Tabs
            defaultActiveKey={activeTab.eventKey}
            onSelect={this.handleTabChange}
            className="tab-title"
            id="uncontrolled-tab-example"
          >
            {this.state.tabArray.map((tab) => {
              return (
                <Tab eventKey={tab.eventKey} title={tab.tabName}>
                  {tab.componentName}
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  masterCategoryState: state.MasterCategoryState,
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
  getAllColorApi,
  getAllSpeakerApi,
  getLanguageNameApi,
  getKeywordNameApi,
  getAllCategoryApi,
  getEventApi,
  getContentTypeApi,
  getSubCategoryApi,
  getContentCreatorApi,
  getAllAmcApi,
  getsocialMediaApi,
  getCouponApi,
  getTargetedContentApi,
  geteventOrganiserApi,
  getTrendingContentApi,
  getTrendingkeywordsApi,
};
MasterCategory.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterCategory);
