import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import { Table, Image, Row, Col } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { getDetailsApi } from "./Api";
class UserDetails extends BaseReactComponent {
  constructor(props) {
    super(props);
    const { params } = props.match;
    this.state = {
      params,
      firstname: "",
      lastname: "",
      pleaseselect: "",
      checkboxList: [
        {
          key: true,
          label: "On Premise Venue",
          // isDefault: false, isChecked: true,
        },
        {
          key: false,
          label: "Retail Venue",
          // isDefault: false, isChecked: false,
        },
      ],
      detailsData: [],
      dummyData: [
        {
          id: 1,
          firstName: "Nirav",
          lastName: "Panchal",
          Reporting_Count: "3",
        },
        {
          id: 2,
          firstName: "Mahesh",
          lastName: "Kadam",
          Reporting_Count: "4",
        },
        {
          id: 3,
          firstName: "Dharmik",
          lastName: "Joshi",
          Reporting_Count: "1",
        },
        {
          id: 4,
          firstName: "Uday",
          lastName: "Naidu",
          Reporting_Count: "0",
        },
      ],
    };
  }
  componentDidMount = () => {
    getDetailsApi("user", this);
  }
  componentDidMount() { }

  render() {
    const { data, detailsData, params } = this.state;
    return (
      <section className="user-section">
        {/* // ---------------------------------------View details -------------------------------- */}

        <div className="view-details-wrapper">

          <ComponentHeader
            title={`${params?.type} Details`}
            isBackArrow={true}
            isReturn={"/users"}
          />
          <div className="view-details d-flex">
            <div className="view-details-content">
              <div className="user-details d-flex">
                <div className="eclipse">
                  <Image src={data?.thumbnailPath} className="eclipse" />
                </div>
                <div className="name-details">
                  <h3 className="inter-medium f-s-20">{data?.emailAddress || ""}</h3>
                  <h4 className="inter-medium grey-3 f-s-14">Role: {data?.role}</h4>
                  {/* <h4 className="inter-medium grey-3 f-s-14">Start Date: {moment(data?.startDate).format('lll')}</h4>
                  <h5 className="inter-medium grey-3 f-s-14">End Date: {moment(data?.endDate).format('lll')}</h5> */}
                </div>
                <div className="line"></div>
                <div className="name-description">
                  <h3 className="grey-2 ">Description</h3>
                  <p className="inter-medium f-s-14">{data?.description}</p>
                </div>
              </div>
              <h3 className="inter-regular f-s-14 grey-2">
                Registration Details
              </h3>
              <div className="line2"></div>
              <Table className="custom-table">
                <Row>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Email</h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.emailAddress || "xyz@gmail.com"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Phone no.</h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.mobileNumber || "na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Whatsapp no.</h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.whatsappMobileNumber || "na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">DOB</h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.dateOfBirth || ""}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Gender</h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.gender}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Company Inceptin
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.companyInception || "na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Organization Name
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.organizationName || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">
                        Organization Type
                      </h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.organizationType || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">ARN Number</h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.arnNumber || "na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">City</h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.city || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">State</h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.state || "Na"}</h3>
                    </div>
                  </Col>
                  <Col sm={3} xs={12}>
                    <div className="detail">
                      <h4 className="inter-semibold grey-2 ">Pincode</h4>
                      <h3 className="inter-semibold f-s-14 ">{detailsData?.pincode || "Na"}</h3>
                    </div>
                  </Col>
                </Row>
              </Table>
              <h3 className="inter-regular f-s-14 grey-2">Document</h3>
              <div className="line2"></div>
              <div className="square-boxes">
                <div></div>
                <div></div>
              </div>
              <div className="button-div">
                <button class="custom-button bg-green-4CA inter-regular ">
                  Accept
                </button>
                <button class="custom-button bg-red-D43 inter-regular ">
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ usersState: state.UsersState });
const mapDispatchToProps = {
  // getPosts: fetchPosts
};
UserDetails.propTypes = {
  // getPosts: PropTypes.func
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
