
import { toast } from "react-toastify";
import { postLoginInstance } from "../../utils";
import { API_LIMIT, SOCIALMEDIA, START_PAGE } from "../../utils/Constant";
import { getAllSsoContent, getAllSsoEventData, getAllSsoUserData, getAllTrackingData } from "./SsoContentEventAction";
export const getAllSsoContentApi = (ctx = null) => {
    // const params = new URLSearchParams(ctx.props.location.search);
    // const verify = params.get("isVerified") || "";
    ctx.setState({ isLoading: true })
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {
        // return postLoginInstance.get(`admin/content?${verify ?'isVerified=' + verify + '&' : ''} pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}${ctx.state.search && `&searchValue=${ctx.state.search}`}`)
        return postLoginInstance.get(`sso/content?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}${ctx.state.search && `&searchValue=${ctx.state.search}`}`)
            .then((res) => {
                dispatch(getAllSsoContent(res.data.data, ctx?.state.currentPage || START_PAGE));
                ctx.setState({ isLoading: false })
            });
    };
};

export const getDetaisContentEventApi = (path, ctx) => {
    console.log(ctx)
    return postLoginInstance.get(`${path}/${ctx.props?.location?.state?.id || ctx.props.match.params.id}`)
        .then((res) => {
            return ctx.setState({ data: res.data.data });

        })
        .catch((err) => {
            console.log('Eroorr', err)
            toast.error(err?.response?.data?.message || 'Something went wrong.');
        })
};

export const getAllSsoEventDataApi = (ctx = null) => {
    const { verifyUrl = false } = ctx?.state ?? {};
    return function (dispatch, getState) {
        return postLoginInstance.get(`/sso/events?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
            .then((res) => {
                // return ctx.setState({ dummyData: res.data.data ,});
                dispatch(getAllSsoEventData(res.data.data, ctx?.state?.currentPage || START_PAGE));

            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong")
            })
    }

};
export const getSsoUserApi = (ctx = null) => {

    return function (dispatch, getState) {
        return postLoginInstance.get(`sso/web-user?pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}${ctx.state.search && `&searchValue=${ctx.state.search}`}`)
            .then((res) => {
                if (ctx.state.isDropdown) {
                    const userList = res.data.data.list.map((item) => ({
                        ...item,
                        label: item.firstName + " " + item.lastName,
                        value: item.uuid
                    }))
                    return ctx.setState({ userList });
                } else {
                    dispatch(getAllSsoUserData(res.data.data, ctx?.state.currentPage || START_PAGE));
                }
            });
    }

};
export const getUserTrackingApi = (ctx = null) => {
    // const params = new URLSearchParams(ctx.props.location.search);
    // const verify = params.get("isVerified") || "";
    ctx.setState({ isLoading: true })
    const { verifyUrl = false } = ctx?.state ?? {};
    const getUserDetails = () => {
        return JSON.parse(localStorage.getItem('finAdmin'));


    };
    const userDetails = getUserDetails();
    const partnerId = userDetails?.user?.ssoPartnerId;
    const userId = userDetails?.user?.id

    return function (dispatch, getState) {
        // return postLoginInstance.get(`admin/content?${verify ?'isVerified=' + verify + '&' : ''} pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}${ctx.state.search && `&searchValue=${ctx.state.search}`}`)
        return postLoginInstance.get(`sso/web-user/tracking?ssoPartnerId=${partnerId}?userId=${userId}?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}${ctx.state.search && `&searchValue=${ctx.state.search}`}`)
            .then((res) => {
                dispatch(getAllTrackingData(res.data.data, ctx?.state.currentPage || START_PAGE));
                ctx.setState({ isLoading: false })
            });
    };

};
