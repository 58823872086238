import { toast } from "react-toastify";
import { postLoginInstance } from "../../utils";
import { API_LIMIT, MAX_LIMIT, START_PAGE } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { getAllUserData } from "./UsersAction";
// import { API_LIMIT, START_PAGE } from "../../utils/Constant";
// import { getAllContent } from "./ContentAction";




export const getUserNameApi = (ctx = null) => {
  return function (dispatch, getState) {
    return postLoginInstance.get(`admin/user?pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
      .then((res) => {
        // return ctx.setState({ dummyData: res.data.data ,});
        // console.log('ctx', ctx);
        dispatch(getAllUserData(res?.data?.data, ctx?.state?.currentPage || START_PAGE))

      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Something went wrong")
      })
  }

};

export const registerUserApi = (data, ctx) => {
  return postLoginInstance.post(`admin/user/register`, data)
    .then((res) => {
      toast.success(res.data.message || "User Added Successfully");
      ctx?.setState({resLoading:false})
      ctx.props.history.push("/users");
    })
    .catch((error) => {
      // console.log('error', error);
      ctx?.setState({resLoading:false})
      toast.error(error?.response?.data?.message || "Something went wrong")
    })
};
export const updateUserApi = (data, ctx) => {
  return postLoginInstance.patch(`admin/user/${ctx.state.userId}`, data)
    .then((res) => {
      toast.success(res.data.message || "User Added Successfully");
      ctx?.setState({resLoading:false})
      ctx.props.history.push("/users");
    })
    .catch((error) => {
      // console.log('error', error);
      ctx?.setState({resLoading:false})
      toast.error(error?.response?.data?.message || "Something went wrong")
    })
};
export const deleteUserApi = (id, ctx) => {
  return postLoginInstance.delete(`admin/user/${id}`)
    .then((res) => {
      toast.success(res.data.message || "Deleted Successfully");
      ctx.props.history.push("/users");
      ctx.openCloseDeleteModal();
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "Something went wrong")
    })
};

















export const getDetaisUserApi = (ctx = null, id) => {
  return postLoginInstance.get(`user/${id}`)
    .then((res) => {
      return ctx.setState({ detailsData: res.data.data });
    });
}

export const getDetailsApi = (path, ctx) => {
  // console.log(path)
  return postLoginInstance.get(`${path}/${ctx.props.location.state.id}`)
    .then((res) => {
      return ctx.setState({ data: res.data.data });
    })
    .catch((err) => {
      // console.log('err', err);
    })
}
export const getContentDetailsByKeyApi = (path, name, ctx) => {
  // console.log(path)
  return postLoginInstance.get(`content/searchDetails?key=${path}&value=${name}`)
    .then((res) => {
      let activeTabSet = res.data.data[0] ? `${res.data.data[0].title}` : '';
      if (activeTabSet) {
        getContentDetailsByKeywithPageNoApi(path, name, ctx, activeTabSet)
      }
      return ctx.setState({
        tabList: res.data.data?.map((item) => { return { "title": item.title, "count": item.count } }),
        ActiveTab: activeTabSet,
      });
    })
    .catch((err) => {
      // console.log('err', err);
    })
}
export const getContentDetailsByKeywithPageNoApi = (path, name, ctx, activeTabSet) => {
  // console.log(path)
  // return postLoginInstance.get(`content/searchDetails?contentType=${activeTabSet || "videos"}&key=${path}&value=${name}&pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
  return postLoginInstance.get(`content/searchDetails?contentType=${activeTabSet || "videos"}&key=${path}&value=${name}&pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
    .then((res) => {
      return ctx.setState({
        contentData: res.data.data,
        currentPage: ctx?.state?.currentPage || START_PAGE,
        totalPages: calculateTotalPageCount(res.data.data[0]?.count),
      });
    })
    .catch((err) => {
      // console.log('err', err);
    })
}