import axios from 'axios';

const preLoginInstance = axios.create(
    {
        // baseURL: 'http://13.232.184.100/hbits/dev',  // Url for Dev
        baseURL: process.env.REACT_APP_API_BASE_URL, // Url for UAT
        // baseURL: 'http://13.126.239.61:3001/finpedia/v1/', // Url for Local
        // baseURL: 'https://cors-anywhere.herokuapp.com/http://samples.openweathermap.org/data/2.5/',  // Url for Production
    }
);

export default preLoginInstance;
