import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Image } from "react-bootstrap";
import logo from "../../assets/images/finpedia-logo.svg";
import Ads from "../../assets/images/sidebar-icons/ads-icon.svg";
import Contenttype from "../../assets/images/sidebar-icons/content-type-icon.svg";
import Events from "../../assets/images/sidebar-icons/event-icon.svg";
import Lists from "../../assets/images/sidebar-icons/list-icon.svg";
import Users from "../../assets/images/sidebar-icons/user-icon.svg";
import Dashborad from "../../assets/images/sidebar-icons/dashborad-icon.svg";
import List2 from "../../assets/images/sidebar-icons/list2-icon.svg";
import Alert2 from "../../assets/images/sidebar-icons/alert2-icon.svg";
import calendar2 from "../../assets/images/sidebar-icons/calendar2-icon.svg";
import category2 from "../../assets/images/sidebar-icons/category2-icon.svg";
import element2 from "../../assets/images/sidebar-icons/element2.svg";
import profile2 from "../../assets/images/sidebar-icons/profile2-icon.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Notifications, groupBy } from "../../utils/Constant";
import { loginApi } from "../login/Api";
import { connect } from "react-redux";




const sideNav = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: Dashborad,
    activeIcon: element2,
    submodule: [

    ]
  },

  {
    name: "Content",
    url: "/content",
    icon: Contenttype,
    activeIcon: category2,
    submodule: [

    ]
  },
  {
    name: "Content Master",
    url: "/content-master",
    icon: Lists,
    activeIcon: List2,
    submodule: [

      {
        name: "Campaign",
        url: "/campaign",
        // icon: Events,
        // activeIcon: calendar2,
      },
      {
        name: "Category",
        url: "/category-master",
        // icon: Events,
        // activeIcon: calendar2,
      },
      {
        name: "Sub Category",
        url: '/sub-category',
        // icon: Events,
        // activeIcon: calendar2,
      },

      {
        name: "Color",
        url: "/color",
        // icon: Events,
        // activeIcon: calendar2,
      },
      {
        name: "Language",
        url: '/language',
        // icon: Events,
        // activeIcon: calendar2,
      },

      {
        name: "Creator",
        url: '/content-creator',
        // icon: Events,
        // activeIcon: calendar2,
      },
      {
        name: "Social Media Content Type",
        url: '/social-media',
        // icon: Events,
        // activeIcon: calendar2,
      },
      {
        name: "Post of the Day",
        url: '/post',
        // icon: Events,
        // activeIcon: calendar2,
      },
      {
        name: "AMC",
        url: '/amc',
        // icon: Events,
        // activeIcon: calendar2,
      },
      {
        name: "Targeted Content List",
        url: '/targeted-content',
        // icon: Events,
        // activeIcon: calendar2,
      },
      {
        name: "Content Type",
        url: '/content-type',
        // icon: Events,
        // activeIcon: calendar2,
      },

    ]
  },
  {
    name: "Ads",
    url: "/Ads",
    icon: Ads,
    activeIcon: Alert2,
    submodule: [
      {
        name: "Content Ad",
        url: "/content-ads",
        // icon: Events,
        // activeIcon: calendar2,
      },

      {
        name: "Content Banner Ad",
        url: '/content-banner-ads',
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "Event Ad",
        url: '/event-ads',
        // icon: Events,
        // activeIcon: calendar2,
      },
      {
        name: "Event Banner Ad",
        url: '/event-banner-ads',
        // icon: Events,
        // activeIcon: calendar2,
      },

    ]
  },
  {
    name: "Trending",
    url: "/events",
    icon: Events,
    activeIcon: calendar2,
    submodule: [
      {
        name: "Global",
        url: "/trending",
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "Trending Keywords",
        url: "/trending-keywords",
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "Keywords",
        url: "/keyword",
        icon: Events,
        activeIcon: calendar2,
      },


    ]
  },
  {
    name: "Event",
    url: "/events",
    icon: Contenttype,
    activeIcon: category2,
    submodule: [

    ]
  },
  {
    name: "Event Master",
    url: "/events",
    icon: Events,
    activeIcon: calendar2,
    submodule: [
      {
        name: "Speaker",
        url: "/event-speaker",
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "Organizer",
        url: "/event-organiser",
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "Category",
        url: "/category",
        icon: Events,
        activeIcon: calendar2,
      },

    ]
  },
  {
    name: Notifications,
    url: "/events",
    icon: Events,
    activeIcon: calendar2,
    submodule: [
      {
        name: "Template",
        url: "/template",
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "User Group",
        url: "/user-group",
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "Send",
        url: "/notification-setup",
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "In App Notification",
        url: "/app-notification",
        icon: Users,
        activeIcon: calendar2,
      },

    ]
  },
  {
    name: "Admin Users",
    url: "/users",
    icon: Events,
    activeIcon: calendar2,
    submodule: []
  },
  {
    name: "Role",
    url: "/roles",
    icon: Events,
    activeIcon: calendar2,
    submodule: []
  },

  {
    name: "Web Users",
    url: "/events",
    icon: Users,
    activeIcon: profile2,
    submodule: [
      {
        name: "Web Users",
        url: "/customers",
        icon: Users,
        activeIcon: calendar2,
      },
    ]
  },
  {
    name: "Subscription",
    url: "/events",
    icon: Events,
    activeIcon: calendar2,
    submodule: [
      {
        name: "Subscription Plan",
        url: "/subscription-plan",
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "Coupon",
        url: "/coupon",
        icon: Events,
        activeIcon: calendar2,
      },
    ]
  },

  {
    name: "Auto Share",
    url: "/events",
    icon: Events,
    activeIcon: calendar2,
    submodule: [
      {
        name: "Playlist",
        url: "/playlist",
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "Platform",
        url: "/platform",
        icon: Events,
        activeIcon: calendar2,
      },
      {
        name: "Occassion",
        url: "/occassion",
        icon: Events,
        activeIcon: calendar2,
      },
    ]
  },

  // {
  //   name: "Sso",
  //   url: "/sso",
  //   icon: Users,
  //   activeIcon: profile2,
  //   submodule: []
  // },
  // {
  //   name: "Sso",
  //   url: "/events",
  //   icon: Users,
  //   activeIcon: profile2,
  //   submodule: [
  //     {
  //       name: "Content",
  //       url: "/sso/content",
  //       icon: Events,
  //       activeIcon: calendar2,
  //     },
  //     {
  //       name: "Event",
  //       url: "/sso/events",
  //       icon: Events,
  //       activeIcon: calendar2,
  //     },
  //   ]
  // },
]




const Sidebar = ({ loginState }) => {

  const modules = JSON.parse(localStorage.getItem("finAdmin"))?.modules || [];
  const moduleNamesContent = groupBy(modules, 'moduleName');
  const moduleNames = Object.keys(moduleNamesContent);
  // const [accesNavBar1,setAccesNaveBar1]=useState([])
  const [accesNaveBar, setAccesNaveBar] = useState([])


  const location = useLocation();
  const [active, setActive] = useState(null);
  const [user, setUser] = useState();


  // const [accesNaveBar,setAccesNaveBar]=useState([])

  const setActiveSubmodule = () => {
    const currentPath = location.pathname;

    for (let i = 0; i < accesNaveBar.length; i++) {
      const mainNavItem = accesNaveBar[i];
      // console.log(mainNavItem.submodule.length)
      if (mainNavItem.submodule.length > 0) {
        for (let j = 0; j < mainNavItem.submodule.length; j++) {
          const submodule = mainNavItem.submodule[j];

          if (currentPath.startsWith(submodule.url)) {
            setActive(i);
            // console.log('Happy', i)
            return;
          }
        }
      } else {
        setActive(null)
      }
    }
  };
  const getUserDetails = () => {
    return JSON.parse(localStorage.getItem('finAdmin'));


  }
  useEffect(() => {

    const userDetails = getUserDetails();
    const ssoExists = sideNav.some((item) => item.name === "Sso");
    if (!ssoExists) {
      if (loginState?.loginList?.user?.ssoPartner || userDetails?.user?.ssoPartner) {
        sideNav.push({
          name: "Sso",
          url: "/events",
          icon: Users,
          activeIcon: profile2,
          submodule: [
            { name: "Content", url: "/sso/content", icon: Events, activeIcon: calendar2 },
            { name: "Event", url: "/sso/events", icon: Events, activeIcon: calendar2 },
            { name: "Web User", url: "/sso/web-user", icon: Events, activeIcon: calendar2 },

          ],
        });

      } else {
        sideNav.push({
          name: "Sso",
          url: "/sso",
          icon: Users,
          activeIcon: profile2,
          submodule: [],
        });

        sideNav.push({
          name: "Sub Domain",
          url: "/events",
          icon: Events,
          activeIcon: calendar2,
          submodule: [
            {
              name: "Sub Domain Partner",
              url: "/subdomain",
              icon: Events,
              activeIcon: calendar2,
            },
            {
              name: "Calender",
              url: "/calender",
              icon: Events,
              activeIcon: calendar2,
            },
            {
              name: "Calender Filter",
              url: "/calender-filter",
              icon: Events,
              activeIcon: calendar2,
            },
          ]
        });
        sideNav.push({
          name: "Personalization",
          url: "/events",
          icon: Events,
          activeIcon: calendar2,
          submodule: [
            {
              name: "Template",
              url: "/templates",
              icon: Events,
              activeIcon: calendar2,
            },
            {
              name: "Category",
              url: "/personalization-category",
              icon: Events,
              activeIcon: calendar2,
            },

          ]
        });


      }
    }

    const accesNaveBar1 = sideNav.reduce((acc, item) => {
      if (moduleNames.includes(item.name)) {
        const arr1 = moduleNamesContent[item.name]?.map(sub => sub.subModuleName) || [];
        const arr2 = item.submodule?.map(ele => ele.name) || [];

        if (item.submodule?.length > 0 && arr1.some(sub => arr2.includes(sub))) {
          // console.warn(item.name);
          acc.push({
            name: item.name,
            url: item.url,
            icon: item?.icon,
            activeIcon: item?.activeIcon,
            submodule: item.submodule?.filter(mod => arr1.includes(mod.name)),
          });
        } else {
          acc.push(item);
        }
      }
      return acc;
    }, []);



    const accesNav = [sideNav[0], ...accesNaveBar1]
    setAccesNaveBar(accesNav)



  }, [loginState]); // Dependency array ensures it runs only once



  useEffect(() => {
    setActiveSubmodule();
  }, [location.pathname])
  return (
    <div className="sidebar">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <div className="sidebar-nav">

        {
          accesNaveBar?.map((item, i) => {
            const isOpen = active === i;
            if (item.submodule.length > 0) {
              return (
                <div key={i} className="side-nav-custom-dropdown">
                  <div
                    className="dropdown-header"
                    onClick={() => setActive(isOpen ? null : i)}
                  >
                    <a className={"route-name"} activeClassName="active">
                      <Image src={item.icon} className="profile main" />
                      <Image src={item.activeIcon} className="dashborad active" />
                      <span>{item.name || "Na"}</span>
                    </a>
                    <div className="custom-arrow"></div>
                  </div>
                  <div className={`dropdown-body ${isOpen ? 'open' : ''}`}>
                    {item.submodule?.map((subItem, j) => (
                      <NavLink
                        key={j}
                        to={subItem.url}
                        className={"route-name"}
                        activeClassName="active"
                        onClick={() => setActive(i)}
                      > &nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp;
                        {/* <Image src={subItem.icon} className="profile main" />
                      <Image src={subItem.activeIcon} className="dashborad active" /> */}
                        <span>{subItem.name || "Na"}</span>
                      </NavLink>
                    ))}
                  </div>
                </div>
              )

            } else {
              return (
                <NavLink
                  to={item.url}
                  className={"route-name"}
                  activeClassName="active"
                >
                  <Image src={item.icon} className="profile main" />
                  <Image src={item.activeIcon} className="dashborad active" />
                  <span>{item.name || "Na"}</span>
                </NavLink>
              )
            }

          })
        }
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  loginState: state.LoginState,
});
const mapDispatchToProps = {
  loginApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
