import React from "react";
import { connect } from "react-redux";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
} from "../../utils/form";
import CustomCheckbox from '../../utils/form/CustomCheckbox';
import { Row, Col } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
class AddEditContectCollections extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      pleaseselect: "",
      checkboxList: [
        {
          key: true,
          label: "On Premise Venue",

        },
        {
          key: false,
          label: "Retail Venue",

        }
      ],
      dummyData: [
        {
          firstName: "Nirav",
          lastName: "Panchal",
        },
        {
          firstName: "Mahesh",
          lastName: "Kadam",
        },
        {
          firstName: "Dharmik",
          lastName: "Joshi",
        },
        {
          firstName: "Uday",
          lastName: "Naidu",
        },
      ],
    };
  }

  componentDidMount() { }
  handlePageSize = (pageSize) => {

  }

  render() {
    return (
      <section className="add-section">
        <ComponentHeader
          title="Add User"
          primaryBtn="Save"
          isBackArrow={true}
          isReturn="/contentCollections"

        />
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "firstname")}
                label="First Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Please enter username",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "First Name",
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "lastname")}
                label="Last Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Password cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "password",
                    placeholder: "Last Name",
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "checkboxList")}
                label="Hobby"
                required

                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty"
                  }
                ]}
                control={{
                  type: CustomCheckbox,
                  settings: {
                    radioId: "hobby",
                    radioName: "hobby",
                    options: this.state.checkboxList,
                    // isInline: true,
                  }
                }}
              />
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "address")}
                label="Address"
                required

                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty"
                  }
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Please enter address",
                    as: "textarea",
                    // multiline: true,
                    rows: 5,

                  }
                }}
                classes={{
                  inputField: "custom-input",
                  label: "custom-label"
                }}
              />
            </Col>
            <Col sm={4}></Col>
          </Row>
        </Form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  usersState: state.UsersState,
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
};
AddEditContectCollections.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditContectCollections);
