import { DEFAULT_LIST } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { GET_ALL_SSO_CONTENT, GET_ALL_SSO_EVENT, GET_ALL_TRACKING_DATA, GET_ALL_USER_DATA } from "./ActionTypes";

const INITIAL_STATE = {
    ssoContentList: DEFAULT_LIST,
    totalPages: null,
};
const ContentEventReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_SSO_CONTENT:
            return {
                ssoContentList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        case GET_ALL_SSO_EVENT:
            return {
                ssoEventData: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        case GET_ALL_USER_DATA:
            return {
                userList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        case GET_ALL_TRACKING_DATA:
            return {
                trackingList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        default:
            return state
    }
};
export default ContentEventReducer