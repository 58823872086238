import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomModal from "./CustomModal";
import { Image, Button } from "react-bootstrap";
// import { InformationApi } from './Api';
import alert from "../../assets/images/carbon-warning-filled.svg";

class InformationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      email: "",
      inProgress: false,
    };
  }
  componentDidMount() {

  }

  render() {
    const {id,handleClose,show,title,handleDelete}=this.props
    return (
      <CustomModal
        show={show}
        onHide={handleClose}
        title={""}
        modalClass={"information-modal"}
      >
        <div className="information-modal-wrapper">
          <p className="inter-Medium f-s-14 lh-17">
            <Image src={alert} />
            &nbsp;&nbsp;&nbsp; {title}
          </p>
          <div className="submit-wrapper">
            <Button onClick={()=>handleDelete(id)} className="secondary-btn">Yes</Button>
            <Button onClick={handleClose} className="primary-btn">
              No
            </Button>
          </div>
        </div>
      </CustomModal>
    );
  }
}

InformationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default InformationModal;
