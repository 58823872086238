import React from "react";
import { connect } from "react-redux";

import {
    Form,
    FormElement,
    FormValidator,
    CustomTextControl,
    BaseReactComponent,
    SelectControl
} from "../../utils/form";
import { Row, Col, Button, Image } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { getAllAmcApi, getAllCategoryApi, getLanguageNameApi, getTargetedContentApi } from '../MasterCategory/Api';
import "react-datepicker/dist/react-datepicker.css";

import { ClearButton, ImageUploader } from "../common";
import { getAllContentCreatorApi, getCommonApi, getDetailsApi, postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import ReactDOM from "react-dom";
import { durationObj, MAX_LIMIT, ModuleName, MUTUAL_FUND_COMPANY, selectLogoPosition, SUPER_ADMIN, Thefinpedia, uploadFrequency } from "../../utils/Constant";
import { userAmcUuids, userCreatorUuids, userRoleType } from "../../utils/ReusableFunctions";
import { SearchContent } from "../content";
import { toast } from "react-toastify";
import { getAllCalendersDataApi, getAllModulesDataApi, getAllSubdomainTypeApi, getAllToolsDataApi, getAllTypeApi, getDetailsSsoApi, getDetailsSubdomainApi, getSubdomainDetailsApi, getTargetedContentSubdomainApi } from "./Api";
import { getAllSubscriptionsApi } from "../subscription/Api";
import { getAllAmcSsoApi, getAllCreatorApi } from "../sso/Api";
import closeIcon from '../../assets/images/close.png';
import cancel from '../../assets/images/greay-cancel-icon.svg';
import { getAllCampaignApi } from "../contentCollections/Api";
import { getAllEventsApi } from "../events/Api";


class AddEditSubdomain extends BaseReactComponent {
    constructor(props) {
        super(props);
        const data = props?.location.state?.data || null;
        this.state = {

            editData: data,
            subdomainUuid: data?.uuid || "",
            isUpdated: (props?.location.state ?? false) || false,
            companyName: data?.companyName || "",
            isAddContent: true,
            creatorList: [],
            thumbnailPath: "",
            thumbnailFile: null,
            uploadImage: data?.companyLogo || null,
            amcContent: data?.amc?.map(item => item?.uuid) || (userAmcUuids().length > 0 ? userAmcUuids() : ''),
            creatorContent: data?.contentCreator?.map(item => item.uuid) || (userCreatorUuids() || []),
            amcEvent: data?.amc?.map(item => item?.uuid) || (userAmcUuids().length > 0 ? userAmcUuids() : ''),
            creatorEvent: data?.contentCreator?.map(item => item.uuid) || (userCreatorUuids() || []),
            amcOption: [],
            search: '',
            locationSearch: '',
            resLoading: false,
            loading: false,
            link: data?.websiteLink || "",
            contact: data?.mobileNumber || "",
            email: data?.emailAddress || "",
            type: data?.ssoPartnerType?.uuid || "",
            subdomainType: "",
            subscriptionOptions: [],
            typesOptions: [],
            subscription: "",
            premium: "",
            subPremium: "",
            variation: "",
            plansData: [],
            subPremiumPlan: [],
            subPremiumPlanVariation: [],
            isSubscriptionAllowed: "",
            isTFPAllowed: false,
            isOtherLinkAllowed: false,
            otherLink: "",
            isUpdateAllowed: "",
            isAdminAllowed: "",
            isShowLogo: "",
            isCompanyLogo: false,
            isBoth: false,
            isPowered: false,
            isBrought: false,
            isSubscriptionError: "",
            isUserViewAllowed: "",
            subdomainTypesOptions: [],
            campaignOption: [],
            pageSize: MAX_LIMIT,

            variations: [],
            moduleNameOptions: [],
            logoPosition: "",
            logoPositionOptions: selectLogoPosition.map((position) => ({
                label: position.name, // Displayed text in the dropdown
                value: position.val, // Actual value of the option
            })),
            showSearchContent: [],
            selectedContent: data ? [data?.content] : [],
            eventOptions: [],
            targetedContentOptions: [],
            categoryOptions: [],
            languageOption: [],
            personalizationOptions: [],
            toolOptions: []
        };


    };

    imageUploaser = (e, name) => {
        // console.log(typeof (e.target.files[0]))
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })

    };
    addMore = () => {
        let modules = this.state.modules ? this.state.modules.map(a => ({ ...a })) : [];
        modules.push({
            // isAmcShowContent: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
            // isAmcShow: data?.contentCreator?.contentCreatorName === "Mutual Fund Company" || false,
            moduleName: "",
            creator: [],
            amc: [],
            campaign: [],
            event: [],
            tools: [],
            targetedContent: [],
            category: [],
            language: [],
            personalize: [],

            isAmcShow: false,
            selectedContent: [],
            isAmcShowContent: false,
            showSearchContent: false,
            isRegistration: false,
            showSelectionOptions: false,
            selectionType: "",
        });

        this.setState({ modules });


    }
    removeModules = (i) => {
        let modules = this.state.modules.map(a => ({ ...a }));
        modules.splice(i, 1);
        this.setState({ modules });
    }

    async componentDidMount() {

        var planOptions
        this.props.getAllAmcSsoApi(this);
        getAllCreatorApi(this);
        this.props.getAllTypeApi(this);
        this.props.getAllSubdomainTypeApi(this);
        this.props.getAllCampaignApi(this);
        getAllEventsApi(this);
        this.props.getAllCategoryApi(this);
        this.props.getTargetedContentSubdomainApi(this);
        this.props.getLanguageNameApi(this);
        this.props.getAllCalendersDataApi(this);
        this.props.getAllToolsDataApi(this);
        this.props.getAllModulesDataApi(this);



        await getCommonApi(`premium-plan?pageSize=${MAX_LIMIT}`).then((res) => {
            this.setState({ plansData: res.data.data.list });
            planOptions = res?.data?.data?.list

        });
        this.props.getAllSubscriptionsApi(this);
        if (this.props?.location?.state?.data?.uuid) {
            this.setState({ loading: true })

            await getDetailsSubdomainApi("/sub-domain", this, planOptions).finally(() => {
                this.setState({ loading: false })
            });

        };
        console.log(this.state.type, "type")



    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.creatorList !== prevState.creatorList) {
            if (userCreatorUuids()) {
                const isExist = this.state.creatorList?.find(item => userCreatorUuids()?.split(',')?.includes(item.value));
                this.setState({ isAmcShowContent: isExist?.label?.includes(MUTUAL_FUND_COMPANY) });
            }
        }

        if (this.state.creatorList !== prevState.creatorList) {
            const editData = this.props?.location.state?.data;
            if (userCreatorUuids() || editData?.contentCreator?.uuid) {
                const isExist = this.state.creatorList?.find(item => editData?.contentCreator?.uuid === item.value);
                const isShowAmc = isExist?.label?.includes(MUTUAL_FUND_COMPANY);
                const isShowRegister = isExist?.label?.includes(Thefinpedia);
                this.setState({ isAmcShow: isShowAmc, isRegistration: !isShowRegister, isAmcShowContent: isShowAmc });
            }
        }



    }
    joinMultipleArraysWithDelimiter = ((delimiter = ',', ...arrays) => {
        return arrays.flat().join(delimiter);
    })

    handlePageSize = (pageSize) => {
        // console.log("Heyyy pageSize", pageSize);
    };
    handleSave = () => {
        ReactDOM.findDOMNode(this.form).dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    };
    componentDidUpdate(prevProps, prevState) {
        // Only check if `amcEvent` has changed to avoid infinite loops

    }

    processModules(modules) {
        return modules?.map(module => {
            // Initialize the new object structure
            const processedModule = {
                masterSsoPartnerModuleUuid: (() => {
                    const matchingModule = this.state.moduleNameOptions.find(
                        (option) => option.label === module?.moduleName
                    );
                    return matchingModule ? matchingModule.uuid : module?.moduleName;
                })(),
                uuids: '',
                ...(this.state.editData?.uuid && { uuid: module?.uuid || "" })
            };


            // Handle contentCreators only for specific module types
            if (["CAMPAIGN", "EVENT", "CONTENT"].includes(module.moduleName)) {
                processedModule.contentCreators = module?.creator?.join(',');
                processedModule.amcs = module?.isAmcShowContent ? module?.amc?.join(',') : ""; // Join amcUuids with comma

            }

            // Handle the logic based on moduleName and selectionType
            if (module.moduleName === "CONTENT") {
                const selectedContentArray = Array.isArray(module.selectedContent)
                    ? module.selectedContent
                    : [module.selectedContent];
                processedModule.uuids = module.selectionType === "single"
                    ? selectedContentArray[0]?.uuid || '' // Single selection
                    : selectedContentArray.map(content => content.uuid).join(','); // Multiple selection
            } else if (module.moduleName === "CAMPAIGN") {
                const campaignArray = Array.isArray(module.campaign)
                    ? module.campaign
                    : [module.campaign];
                processedModule.uuids = module.selectionType === "single"
                    ? campaignArray[0] || '' // Single selection
                    : campaignArray.join(','); // Multiple selection
            } else if (module.moduleName === "EVENT") {
                const eventArray = Array.isArray(module.event)
                    ? module.event
                    : [module.event];
                processedModule.uuids = module.selectionType === "single"
                    ? eventArray[0] || '' // Single selection
                    : eventArray.join(','); // Multiple selection
            }

            else if (module.moduleName === "LANGUAGE") {
                const languageArray = Array.isArray(module.language) ? module.language : [module.language];
                processedModule.uuids = module.selectionType === "single"
                    ? languageArray[0] || '' // Single selection
                    : languageArray.join(','); // Multiple selection
            }

            else if (module.moduleName === "TARGETED_CONTENT") {
                const targetedContentArray = Array.isArray(module.targetedContent)
                    ? module.targetedContent
                    : [module.targetedContent];
                processedModule.uuids = module.selectionType === "single"
                    ? targetedContentArray[0] || '' // Single selection
                    : targetedContentArray.join(','); // Multiple selection
            }
            else if (module.moduleName === "CATEGORY") {
                const categoryArray = Array.isArray(module.category)
                    ? module.category
                    : [module.category];
                processedModule.uuids = module.selectionType === "single"
                    ? categoryArray[0] || '' // Single selection
                    : categoryArray.join(','); // Multiple selection
            }

            else if (module.moduleName === "TOOLS") {
                const toolsArray = Array.isArray(module.tools)
                    ? module.tools
                    : [module.tools];
                processedModule.uuids = module.selectionType === "single"
                    ? toolsArray[0] || '' // Single selection
                    : toolsArray.join(','); // Multiple selection
            }

            else if (module.moduleName === "PERSONALIZE") {
                const personalizeArray = Array.isArray(module.personalize)
                    ? module.personalize
                    : [module.personalize];
                processedModule.uuids = module.selectionType === "single"
                    ? personalizeArray[0] || '' // Single selection
                    : personalizeArray.join(','); // Multiple selection
            }

            Object.keys(processedModule).forEach(key => {
                if (processedModule[key] === "" && !(key === "amcs" && this.state.subdomainUuid)) {
                    delete processedModule[key];
                }
            });


            return processedModule;
        });
    }


    onValidSubmit = async () => {
        this.setState({ resLoading: true });
        const { name, link, email, contact, premium, subPremium, variation, companyName, isUpdateAllowed, isSubscriptionAllowed, isAdminAllowed, otherLink, isCompanyLogo, isBrought, isPowered, isPoweredText, isBroughtText, isShowLogo, isTFPAllowed, isOtherLinkAllowed, isBoth, isUserViewAllowed, modules, logoPosition, subdomainType } = this.state;

        const ssoData = {
            "data": {
                "companyName": companyName,
                "websiteLink": link,
                "emailAddress": email,
                "mobileNumber": contact,
                "premiumPlanUuid": premium,
                "subPremiumPlanUuid": subPremium,
                "subPremiumPlanVariationUuid": variation,
                "logoPosition": logoPosition,
                "modules": this.processModules(modules)
            }
        };

        console.log(ssoData)
        const data = new FormData();
        data.append("key", JSON.stringify(ssoData));
        if (this.state.thumbnailFile) {
            data.append("file", this.state?.thumbnailFile);
        }
        if (this.state.subdomainUuid) {
            const response = await putAuthCommonApi(`sub-domain/${this.state.subdomainUuid}`, data);
            if (response.status === 200) {
                this.setState({ resLoading: false });
                this.props.history.push({ pathname: `/subdomain`, search: 'pageSize=10&pageNumber=1' });
            } else {
                this.setState({ resLoading: false });
            }
        } else {
            const response = await postAuthCommonApi('/sub-domain', data);
            if (response.status === 200) {
                this.setState({ resLoading: false });
                this.props.history.push({ pathname: `/subdomain`, search: 'pageSize=10&pageNumber=1' });
            } else {
                this.setState({ resLoading: false });
            }
        }

    };


    render() {
        const {
            uploadImage,
            thumbnailFile,
            amcOption,
            platformOptions,
            loading,
            checklist,
            selectedItems,
            subscriptionOptions,
            typesOptions,
            premium,
            subPremium,
            variation,
            plansData,
            subPremiumPlan,
            subPremiumPlanVariation,
            isSubscriptionAllowed,
            isSubscriptionNotAllowed,
            isTFPAllowed,
            isOtherLinkAllowed,
            isUpdateAllowed,
            isAdminAllowed,
            isShowLogo,
            isCompanyLogo,
            isBoth,
            isPowered,
            isBrought,
            isPoweredText,
            isBroughtToText,
            isSubscriptionError,
            otherLink,
            isUserViewAllowed,
            subdomainTypesOptions,
            moduleNameOptions,
            logoPositionOptions,
            campaignOption,
            eventOptions,
            targetedContentOptions,
            categoryOptions,
            languageOption,
            personalizationOptions,
            toolOptions

        } = this.state;
        // const {platformData}=this.props.autoShareState
        const { listData } = this.props.masterCategoryState

        if (loading) {
            return (
                <div>loading...</div>
            )
        }
        return (
            <section className="add-section">
                <ComponentHeader
                    title={this.state.subdomainUuid ? "Update Subdomain" : "Add Subdomain"}
                    primaryBtn="Save"
                    isBackArrow={true}
                    resLoading={this.state.resLoading}
                    isReturn="/subdomain?pageSize=10&pageNumber=1"
                    handlePrimaryBtn={this.handleSave}
                />

                <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
                    <Row>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "companyName")}
                                label="Company Name"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Name cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Company Name",
                                    },
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "link")}
                                label="Website Link"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Link cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Website Link",
                                    },
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "contact")}
                                label="Contact Number"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Email cannot be empty",
                                    },

                                    {
                                        validate: FormValidator.isNumber,
                                        message: "Enter valid number",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Contact Number",
                                    },
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "email")}
                                label="Email"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Email cannot be empty",
                                    },
                                    {
                                        validate: FormValidator.isEmail,
                                        message: "Enter valid email",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Email",
                                    },
                                }}
                            />
                        </Col>
                        <Col sm={4}>
                            <ImageUploader required={true} id="Thumbnail" label="Upload Logo" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} showLabel={false} />
                        </Col>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "logoPosition")}
                                label="Select Logo Position"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Type cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: SelectControl,
                                    settings: {
                                        placeholder: "Select Logo Position",
                                        options: logoPositionOptions,
                                        multiple: false,
                                        searchable: true,
                                        onChange: (selectedValue) => {
                                            // Assuming selectedValue is the single selected option
                                            this.setState({ logoPosition: selectedValue });
                                        },
                                    },
                                }}
                            />
                        </Col>



                    </Row>

                    <hr />

                    <label>Default Subscription:</label>
                    <Row>
                        <Col sm={4}>

                            <FormElement
                                valueLink={this.linkState(this, "premium")}
                                label="Membership Name"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Field cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: SelectControl,
                                    settings: {
                                        options: plansData?.map((item) => ({ value: item.uuid, label: item.name })),
                                        // options: plansData.map((item) => ({ value: item.uuid, label: item.name })),
                                        placeholder: "Select Membership Name",
                                        multiple: false,
                                        searchable: true,
                                        onChangeCallback: (onBlur) => {
                                            onBlur(this.state.premium);
                                            // console.log("This is selected plan=>",this.state.plan);
                                            const subPremiumPlan = plansData?.find(ele => ele.uuid === this.state?.premium);
                                            // console.log("This is find Prenium plan =>",subPremiumPlan);
                                            this.setState({ subPremiumPlan });
                                            this.setState({
                                                subPremium: null,
                                                variation: null
                                            })
                                        },
                                    },
                                }}
                            />
                            {/* } */}
                        </Col>
                        {premium &&
                            <Col sm={4}>
                                <FormElement
                                    valueLink={this.linkState(this, "subPremium")}
                                    label="Plan Name"
                                    required
                                    validations={[
                                        {
                                            validate: FormValidator.isRequired,
                                            message: "Field cannot be empty",
                                        },
                                    ]}
                                    control={{
                                        type: SelectControl,
                                        settings: {
                                            options: subPremiumPlan?.subPremiumPlan?.map((item) => ({ value: item.uuid, label: item.name })),
                                            // options: plansData.map((item) => ({ value: item.uuid, label: item.name })),
                                            placeholder: "Select Plan Name",
                                            multiple: false,
                                            searchable: true,
                                            onChangeCallback: (onBlur) => {
                                                onBlur(this.state.subPremium);
                                                // console.log("This is selected plan=>",this.state.offerLine);
                                                const subPremiumPlanVariation = subPremiumPlan?.subPremiumPlan?.find(ele => ele.uuid === this.state?.subPremium);
                                                console.log("This is find subPremiumPlanVariation =>", subPremiumPlanVariation);
                                                this.setState({ subPremiumPlanVariation });
                                                this.setState({

                                                    variation: null
                                                })
                                            },
                                        },
                                    }}
                                />
                                {/* } */}
                            </Col>
                        }
                        {
                            subPremium &&
                            <Col sm={4}>
                                <FormElement
                                    valueLink={this.linkState(this, "variation")}
                                    label="Variation "
                                    required
                                    validations={[
                                        {
                                            validate: FormValidator.isRequired,
                                            message: "Field cannot be empty",
                                        },
                                    ]}
                                    control={{
                                        type: SelectControl,
                                        settings: {
                                            options: subPremiumPlanVariation?.subPremiumPlanVariation?.map((item) => ({ value: item.uuid, label: durationObj[item.resetInDays] })),
                                            // options: plansData.map((item) => ({ value: item.uuid, label: item.name })),
                                            placeholder: "Select Variation",
                                            multiple: false,
                                            searchable: true,
                                            onChangeCallback: (onBlur) => {
                                                onBlur(this.state.variation);

                                            },
                                        },
                                    }}
                                />
                                {/* } */}
                            </Col>
                        }

                        <hr />
                    </Row>

                    <h3 className="inter-semibold f-s-19 lh-24 section-title">Modules</h3>
                    <br />
                    {
                        this.state.modules?.map((module, index) => {
                            return (
                                <>
                                    {index > 0 && <><hr /><br /></>}
                                    <div className="d-flex flex-row justify-content-between">
                                        <p className="variation-srno">Module - {index + 1} </p>
                                        <Image
                                            height={30}
                                            width={30}
                                            src={closeIcon}
                                            className="remove-icon1"
                                            onClick={() => this.removeModules(index)}
                                        />
                                    </div>
                                    <br />
                                    <Row>
                                        <Col md={4}>
                                            <FormElement
                                                valueLink={this.linkState(this, `modules.${index}.moduleName`)}
                                                label="Module Name"
                                                required
                                                validations={[
                                                    {
                                                        validate: FormValidator.isRequired,
                                                        message: "Please enter module name",
                                                    },
                                                ]}
                                                control={{
                                                    type: SelectControl,
                                                    settings: {
                                                        placeholder: "Select Module Name",
                                                        options: moduleNameOptions,
                                                        multiple: false,
                                                        searchable: true,
                                                        onChange: (selectedValue) => {
                                                            const modules = [...this.state.modules];
                                                            modules[index].moduleName = selectedValue.value;
                                                            modules[index].showSelectionOptions = true;
                                                            this.setState({ modules });
                                                        },
                                                    },
                                                }}
                                            />
                                        </Col>

                                        {/* Add checkboxes if selection options are visible */}
                                        {module?.moduleName && (
                                            <Col md={4}>
                                                <label>Select Option:</label>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.modules[index].selectionType === "single"}
                                                            style={{ margin: "1.5rem 1rem 0 0 " }}
                                                            onChange={(e) => {

                                                                const modules = [...this.state.modules];
                                                                const selectionType = e.target.checked ? "single" : "";
                                                                modules[index] = {
                                                                    ...modules[index],
                                                                    event: [],
                                                                    campaign: [],
                                                                    targetedContent: [],
                                                                    tools: [],
                                                                    category: [], // Clear categories
                                                                    language: [], // Clear languages
                                                                    personalize: [], // Clear personalizations
                                                                    selectedContent: [], // Clear selected content
                                                                    selectionType,
                                                                };

                                                                this.setState({ modules });


                                                            }}
                                                        />
                                                        Single Select
                                                    </label>
                                                    <label style={{ marginLeft: "20px" }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.modules[index].selectionType === "multiple"}
                                                            style={{ margin: "1.5rem 1rem 0 0 " }}

                                                            onChange={(e) => {
                                                                const modules = [...this.state.modules];
                                                                const selectionType = e.target.checked ? "multiple" : "";
                                                                modules[index] = {
                                                                    ...modules[index],
                                                                    event: [],
                                                                    campaign: [],
                                                                    targetedContent: [],
                                                                    tools: [],
                                                                    category: [],
                                                                    language: [],
                                                                    personalize: [],
                                                                    selectedContent: [],
                                                                    selectionType,
                                                                };

                                                                this.setState({ modules });



                                                            }}
                                                        />
                                                        Multiple Select
                                                    </label>
                                                </div>
                                            </Col>
                                        )}


                                        {module.moduleName === "CONTENT" && module?.selectionType == "single" && (
                                            <Col md={4}>
                                                <div className="form-group">
                                                    <label className="form-label">Content</label>
                                                    <Button
                                                        onClick={() => {
                                                            const modules = [...this.state.modules];
                                                            modules[index].showSearchContent = true;
                                                            this.setState({ modules });
                                                        }}
                                                        className="primary-btn mb-5 d-block"
                                                    >
                                                        Select Content
                                                    </Button>
                                                </div>
                                            </Col>
                                        )}

                                        {module.showSearchContent && (
                                            <SearchContent
                                                showModal={module.showSearchContent}
                                                onHide={(selectedContent) => {
                                                    const modules = [...this.state.modules];
                                                    modules[index].showSearchContent = false;
                                                    modules[index].selectedContent = selectedContent;
                                                    this.setState({ modules });
                                                }}
                                                selectedContent={module.selectedContent}
                                                isMultiselect={module.selectionType == "single" ? false : true}
                                            />
                                        )}

                                        {module.selectedContent?.length > 0 && (
                                            <div className="mb-3 d-flex flex-wrap gap-20 w-100 ml-3">
                                                {module.selectionType === "single" ? (
                                                    // Render 0th index for single selection
                                                    <div className="d-flex align-items-center gap-10">
                                                        <span className="inter-regular f-s-14 ellipsis lh-22">
                                                            <Image
                                                                height={60}
                                                                width={60}
                                                                src={
                                                                    module.selectedContent[0]?.amcCircleImagePath ||
                                                                    module.selectedContent[0]?.amcCircleImage ||
                                                                    module.selectedContent[0]?.content?.amc?.circleImagePath
                                                                }
                                                                alt="img"
                                                            />
                                                            {module.selectedContent[0]?.title || module.selectedContent[0]?.contentVariation?.title}
                                                        </span>
                                                        <Image
                                                            src={cancel}
                                                            onClick={() => {
                                                                const modules = [...this.state.modules];
                                                                modules[index].selectedContent = [];
                                                                this.setState({ modules });
                                                            }}
                                                            className="search-icons pointer"
                                                            alt="cancel"
                                                        />
                                                    </div>
                                                ) : (
                                                    // Map through selectedContent for multiple selection
                                                    module.selectedContent.map((content, idx) => (
                                                        <div key={idx} className="d-flex align-items-center gap-10">
                                                            <span className="inter-regular f-s-14 ellipsis lh-22">
                                                                <Image
                                                                    height={60}
                                                                    width={60}
                                                                    src={
                                                                        content?.amcCircleImagePath ||
                                                                        content?.amcCircleImage ||
                                                                        content?.content?.amc?.circleImagePath
                                                                    }
                                                                    alt="img"
                                                                />
                                                                {content?.title || content?.contentVariation?.title}
                                                            </span>
                                                            <Image
                                                                src={cancel}
                                                                onClick={() => {
                                                                    const modules = [...this.state.modules];
                                                                    modules[index].selectedContent = modules[index].selectedContent.filter(
                                                                        (_, i) => i !== idx
                                                                    );
                                                                    this.setState({ modules });
                                                                }}
                                                                className="search-icons pointer"
                                                                alt="cancel"
                                                            />
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        )}
                                        {module?.moduleName === "CAMPAIGN" && module?.selectionType == "single" && (

                                            <Col md={4}>
                                                <FormElement
                                                    valueLink={this.linkState(this, `modules.${index}.campaign`)}
                                                    label="Campaign"
                                                    required
                                                    validations={[
                                                        // {
                                                        //     validate: FormValidator.isRequired,
                                                        //     message: "Campaign cannot be empty",
                                                        // },
                                                    ]}
                                                    control={{
                                                        type: SelectControl,
                                                        settings: {
                                                            placeholder: "Select Campaign",
                                                            options: campaignOption,
                                                            multiple: module?.selectionType == "single" ? false : true,
                                                            searchable: true,
                                                            onChangeCallback: (onBlur) => {
                                                                onBlur(this.state.campaign);
                                                            },
                                                        },
                                                    }}
                                                />
                                                {this.state.modules?.[index]?.campaign?.length > 0 && this.state.modules?.[index]?.selectionType == "multiple" &&
                                                    <ClearButton
                                                        onClick={() => {
                                                            const updatedModules = [...this.state.modules]; // Create a shallow copy of the modules array
                                                            updatedModules[index] = {
                                                                ...updatedModules[index], // Copy the current module at index
                                                                campaign: [] // Clear the 'event' array
                                                            };
                                                            this.setState({ modules: updatedModules }); // Update the state with the new modules
                                                        }}
                                                    />
                                                }
                                            </Col>
                                        )}
                                        {module?.moduleName === "EVENT" && module?.selectionType == "single" && (

                                            <Col md={4}>
                                                <FormElement
                                                    valueLink={this.linkState(this, `modules.${index}.event`)}
                                                    label="Select Event"
                                                    required
                                                    // validations={[
                                                    //     {
                                                    //         validate: FormValidator.isRequired,
                                                    //         message: "Event type cannot be empty",
                                                    //     },
                                                    // ]}
                                                    control={{
                                                        type: SelectControl,
                                                        settings: {
                                                            placeholder: "Select Event",
                                                            options: eventOptions,
                                                            multiple: module?.selectionType == "single" ? false : true,
                                                            searchable: true,
                                                            onChangeCallback: (onBlur) => {
                                                                onBlur(this.state?.modules?.[index]?.event);
                                                            },
                                                        },
                                                    }}
                                                />
                                                {this.state.modules?.[index]?.event?.length > 0 && this.state.modules?.[index]?.selectionType == "multiple" &&
                                                    <ClearButton
                                                        onClick={() => {
                                                            const updatedModules = [...this.state.modules]; // Create a shallow copy of the modules array
                                                            updatedModules[index] = {
                                                                ...updatedModules[index], // Copy the current module at index
                                                                event: [] // Clear the 'event' array
                                                            };
                                                            this.setState({ modules: updatedModules }); // Update the state with the new modules
                                                        }}
                                                    />
                                                }
                                            </Col>
                                        )}
                                        {module?.moduleName === "TOOLS" && module?.selectionType && (

                                            <Col md={4}>
                                                <FormElement
                                                    valueLink={this.linkState(this, `modules.${index}.tools`)}
                                                    label="Select Tools"
                                                    required
                                                    validations={[
                                                        {
                                                            validate: FormValidator.isRequired,
                                                            message: "Tools cannot be empty",
                                                        },
                                                    ]}
                                                    control={{
                                                        type: SelectControl,
                                                        settings: {
                                                            placeholder: "Select Tools",
                                                            options: toolOptions,
                                                            multiple: module?.selectionType == "single" ? false : true,
                                                            searchable: true,
                                                            onChangeCallback: (onBlur) => {
                                                                onBlur(this.state?.modules?.[index]?.tools);
                                                            },
                                                        },
                                                    }}
                                                />
                                                {this.state.modules?.[index]?.tools?.length > 0 && this.state.modules?.[index]?.selectionType == "multiple" &&
                                                    <ClearButton
                                                        onClick={() => {
                                                            const updatedModules = [...this.state.modules]; // Create a shallow copy of the modules array
                                                            updatedModules[index] = {
                                                                ...updatedModules[index], // Copy the current module at index
                                                                tools: [] // Clear the 'event' array
                                                            };
                                                            this.setState({ modules: updatedModules }); // Update the state with the new modules
                                                        }}
                                                    />
                                                }
                                            </Col>
                                        )}
                                        {module?.moduleName === "TARGETED_CONTENT" && module?.selectionType && (

                                            <Col md={4}>
                                                <FormElement
                                                    valueLink={this.linkState(this, `modules.${index}.targetedContent`)}
                                                    label="Select Targeted Content"
                                                    required
                                                    validations={[
                                                        // {
                                                        //     validate: FormValidator.isRequired,
                                                        //     message: "Targeted Content cannot be empty",
                                                        // },
                                                    ]}
                                                    control={{
                                                        type: SelectControl,
                                                        settings: {
                                                            placeholder: "Select Targeted Content",
                                                            options: targetedContentOptions,
                                                            multiple: module?.selectionType == "single" ? false : true,
                                                            searchable: true,
                                                            onChangeCallback: (onBlur) => {
                                                                onBlur(this.state?.modules?.[index]?.targetedContent);
                                                            },
                                                        },
                                                    }}
                                                />
                                                {this.state.modules?.[index]?.targetedContent?.length > 0 && this.state.modules?.[index]?.selectionType == "multiple" &&
                                                    <ClearButton
                                                        onClick={() => {
                                                            const updatedModules = [...this.state.modules]; // Create a shallow copy of the modules array
                                                            updatedModules[index] = {
                                                                ...updatedModules[index], // Copy the current module at index
                                                                targetedContent: [] // Clear the 'event' array
                                                            };
                                                            this.setState({ modules: updatedModules }); // Update the state with the new modules
                                                        }}
                                                    />
                                                }
                                            </Col>
                                        )}
                                        {module?.moduleName === "CATEGORY" && module?.selectionType && (

                                            <Col md={4}>
                                                <FormElement
                                                    valueLink={this.linkState(this, `modules.${index}.category`)}
                                                    label="Select Category"
                                                    required
                                                    validations={[
                                                        // {
                                                        //     validate: FormValidator.isRequired,
                                                        //     message: "Category cannot be empty",
                                                        // },
                                                    ]}
                                                    control={{
                                                        type: SelectControl,
                                                        settings: {
                                                            placeholder: "Select Category",
                                                            options: categoryOptions,
                                                            multiple: module?.selectionType == "single" ? false : true,
                                                            searchable: true,
                                                            onChangeCallback: (onBlur) => {
                                                                onBlur(this.state?.modules?.[index]?.category);
                                                            },
                                                        },
                                                    }}
                                                />
                                                {this.state.modules?.[index]?.category?.length > 0 && this.state.modules?.[index]?.selectionType == "multiple" &&
                                                    <ClearButton
                                                        onClick={() => {
                                                            const updatedModules = [...this.state.modules]; // Create a shallow copy of the modules array
                                                            updatedModules[index] = {
                                                                ...updatedModules[index], // Copy the current module at index
                                                                category: [] // Clear the 'event' array
                                                            };
                                                            this.setState({ modules: updatedModules }); // Update the state with the new modules
                                                        }}
                                                    />
                                                }
                                            </Col>
                                        )}

                                        {module?.moduleName === "LANGUAGE" && module?.selectionType && (

                                            <Col md={4}>
                                                <FormElement
                                                    valueLink={this.linkState(this, `modules.${index}.language`)}
                                                    label="Select Language"
                                                    required
                                                    validations={[
                                                        // {
                                                        //     validate: FormValidator.isRequired,
                                                        //     message: "Language cannot be empty",
                                                        // },
                                                    ]}
                                                    control={{
                                                        type: SelectControl,
                                                        settings: {
                                                            placeholder: "Select Language",
                                                            options: languageOption,
                                                            multiple: module?.selectionType == "single" ? false : true,
                                                            searchable: true,
                                                            onChangeCallback: (onBlur) => {
                                                                onBlur(this.state?.modules?.[index]?.language);
                                                            },
                                                        },
                                                    }}
                                                />
                                                {this.state.modules?.[index]?.language?.length > 0 && this.state.modules?.[index]?.selectionType == "multiple" &&
                                                    <ClearButton
                                                        onClick={() => {
                                                            const updatedModules = [...this.state.modules]; // Create a shallow copy of the modules array
                                                            updatedModules[index] = {
                                                                ...updatedModules[index], // Copy the current module at index
                                                                language: [] // Clear the 'event' array
                                                            };
                                                            this.setState({ modules: updatedModules }); // Update the state with the new modules
                                                        }}
                                                    />
                                                }
                                            </Col>
                                        )}
                                        {module?.moduleName === "PERSONALIZE" && module?.selectionType && (

                                            <Col md={4}>
                                                <FormElement
                                                    valueLink={this.linkState(this, `modules.${index}.personalize`)}
                                                    label="Select Personalization"
                                                    required
                                                    validations={[
                                                        // {
                                                        //     validate: FormValidator.isRequired,
                                                        //     message: "Personalization cannot be empty",
                                                        // },
                                                    ]}
                                                    control={{
                                                        type: SelectControl,
                                                        settings: {
                                                            placeholder: "Select Personalization",
                                                            options: personalizationOptions,
                                                            multiple: module?.selectionType == "single" ? false : true,
                                                            searchable: true,
                                                            onChangeCallback: (onBlur) => {
                                                                onBlur(this.state?.modules?.[index]?.personalize);
                                                            },
                                                        },
                                                    }}
                                                />
                                                {this.state.modules?.[index]?.personalize?.length > 0 && this.state.modules?.[index]?.selectionType == "multiple" &&
                                                    <ClearButton
                                                        onClick={() => {
                                                            const updatedModules = [...this.state.modules]; // Create a shallow copy of the modules array
                                                            updatedModules[index] = {
                                                                ...updatedModules[index], // Copy the current module at index
                                                                personalize: [] // Clear the 'event' array
                                                            };
                                                            this.setState({ modules: updatedModules }); // Update the state with the new modules
                                                        }}
                                                    />
                                                }
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>

                                        {(module?.moduleName === "CAMPAIGN" || module?.moduleName === "EVENT" || module?.moduleName === "CONTENT") && this.state.modules?.[index]?.selectionType == "multiple" &&
                                            <Col md={4} style={{ position: "relative" }}>
                                                <FormElement
                                                    valueLink={this.linkState(this, `modules.${index}.creator`)} // Update the creator field in each module
                                                    label="Content Creator"
                                                    required
                                                    validations={[
                                                        {
                                                            validate: FormValidator.isRequired,
                                                            message: "Creator cannot be empty",
                                                        },
                                                    ]}
                                                    control={{
                                                        type: SelectControl,
                                                        settings: {
                                                            placeholder: "Select Creator",
                                                            options: this.state.creatorList,
                                                            multiple: true,
                                                            searchable: true,
                                                            onChangeCallback: (onBlur) => {
                                                                const selectedValues = this.state.modules[index].creator || []; // Access creator directly from the module state
                                                                const allOptionSelected = selectedValues.includes("all");

                                                                if (allOptionSelected) {
                                                                    // Select all values except "All" itself
                                                                    const allValues = this.state.creatorList
                                                                        .filter(option => option.value !== "all")
                                                                        .map(option => option.value);

                                                                    const modules = [...this.state.modules];
                                                                    modules[index].creator = allValues; // Update the creator field in the specific module
                                                                    modules[index].isAmcShowContent = allValues.some(
                                                                        value => this.state.creatorList.find(item => item.value === value)?.label === MUTUAL_FUND_COMPANY
                                                                    );
                                                                    modules[index].isRegistration = !allValues.some(
                                                                        value => this.state.creatorList.find(item => item.value === value)?.label === Thefinpedia
                                                                    );

                                                                    this.setState({ modules });
                                                                } else {
                                                                    // Normal selection, no "All" selected
                                                                    const modules = [...this.state.modules];
                                                                    modules[index].creator = selectedValues; // Update creator field in the specific module
                                                                    modules[index].isAmcShowContent = selectedValues.some(
                                                                        value => this.state.creatorList.find(item => item.value === value)?.label === MUTUAL_FUND_COMPANY
                                                                    );
                                                                    modules[index].isRegistration = !selectedValues.some(
                                                                        value => this.state.creatorList.find(item => item.value === value)?.label === Thefinpedia
                                                                    );

                                                                    this.setState({ modules });
                                                                }

                                                                onBlur(selectedValues); // Update the form control
                                                            },
                                                        },
                                                    }}
                                                />

                                                {module.creator?.length > 0 && (
                                                    <ClearButton
                                                        onClick={() => {
                                                            const modules = [...this.state.modules];
                                                            modules[index].creator = []; // Clear creator in the specific module
                                                            modules[index].isAmcShowContent = false;
                                                            modules[index].isRegistration = false;
                                                            this.setState({ modules });
                                                        }}
                                                    />
                                                )}


                                            </Col>}


                                        {
                                            (module?.isAmcShowContent || (userAmcUuids()?.length > 0)) && (
                                                <Col md={8} style={{ position: "relative" }}>
                                                    <FormElement
                                                        valueLink={this.linkState(this, `modules.${index}.amc`)} // Store AMC in the module object
                                                        label="AMC"
                                                        required
                                                        validations={[
                                                            {
                                                                validate: FormValidator.isRequired,
                                                                message: "AMC cannot be empty",
                                                            },
                                                        ]}
                                                        control={{
                                                            type: SelectControl,
                                                            settings: {
                                                                placeholder: "Enter AMC Name",
                                                                options: (userAmcUuids()?.length > 0)
                                                                    ? amcOption?.filter((ele) => userAmcUuids()?.some(item => item === ele.value))
                                                                    : amcOption,
                                                                multiple: true,
                                                                searchable: true,
                                                                onChangeCallback: (onBlur) => {
                                                                    const selectedValues = this.state.modules[index].amc || []; // Access AMC for the specific module
                                                                    const allOptionSelected = selectedValues.includes("all");
                                                                    if (allOptionSelected) {
                                                                        // Select all values except "All" itself
                                                                        const allValues = amcOption
                                                                            .filter(option => option.value !== "all")
                                                                            .map(option => option.value);

                                                                        // Update AMC in the specific module
                                                                        const modules = [...this.state.modules];
                                                                        modules[index].amc = allValues;
                                                                        this.setState({ modules });

                                                                        onBlur(allValues); // Update the form control with all selected options
                                                                    } else {
                                                                        // Update AMC normally if "All" is not selected
                                                                        const modules = [...this.state.modules];
                                                                        modules[index].amc = selectedValues; // Update AMC in the specific module
                                                                        this.setState({ modules });

                                                                        onBlur(selectedValues); // Update the form control with selected values
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    {this.state.modules[index].amc?.length > 0 && (
                                                        <ClearButton
                                                            onClick={() => {
                                                                // Clear AMC for the specific module
                                                                const modules = [...this.state.modules];
                                                                modules[index].amc = [];
                                                                this.setState({ modules });
                                                            }}
                                                        />
                                                    )}
                                                </Col>
                                            )
                                        }
                                    </Row>

                                    <Col sm={12}>


                                    </Col>

                                    <br />

                                </>
                            );
                        })
                    }

                    <Button onClick={this.addMore} className="primary-btn">Add More +</Button>

                </Form>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    masterCategoryState: state.MasterCategoryState,
    ssoState: state.SsoState
});

const mapDispatchToProps = {


    getAllSubscriptionsApi,
    getAllTypeApi,
    getAllSubdomainTypeApi,
    getAllAmcSsoApi,
    getAllSubscriptionsApi,
    getAllCampaignApi,
    getTargetedContentSubdomainApi,
    getAllCategoryApi,
    getLanguageNameApi,
    getAllCalendersDataApi,
    getAllToolsDataApi,
    getAllModulesDataApi



};


export default connect(mapStateToProps, mapDispatchToProps)(AddEditSubdomain);