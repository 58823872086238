import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CustomModal, ImageUploader } from "../common";
import { addBannerAdApi, updateBannerAdApi } from "./Api";

class AddEditAds extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;
    this.state = {
      bannerAdName: data?.bannerAdName || "",
      redirectionLink: data?.redirectionLink || "",
      keywords: data?.keywords.join(', ') || [],
      thumbnailPath: "",
      thumbnailFile: null,
      bannerPath: "",
      bannerfile: null,
      uploadImage: data?.thumbnailPath || null,
      uploadImage2: data?.bannerPath || null,
      startDate: "",
      endDate: "",
    };
  }

  componentDidMount() { }

  imageUploaser = (e, name) => {
    // console.log(typeof (e.target.files[0]))
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (name === "banner") {
      this.setState({ bannerPath: e.target.files[0].name, bannerfile: e.target.files[0], uploadImage2: imageUrl })

    } else {
      this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
    }
  }

  onValidSubmit = () => {
    let AdsData = {
      "data": {
        "bannerAdName": this.state.bannerAdName,
        "redirectionLink": this.state.redirectionLink,
        "keywords": this.state.keywords.split(', '),
        "position": 2,
        "startDate": "2023-10-10",
        "endDate": "2023-10-19",
        // "bannerPath" : this.state.bannerPath
      }
    };
    const data = new FormData();
    data.append("key", JSON.stringify(AdsData));
    data.append("file", this.state.bannerfile);
    if (this.props.editData) {
      updateBannerAdApi(data, this.props.this, this.props.onHide, this.props.editData.uuid)
    } else {
      addBannerAdApi(data, this.props.ctx, this.props.onHide)
    }
  }
  onHide = () => {
    this.props.getAllCampaignApi();
    this.props.onHide();
  }
  render() {
    const { bannerfile, uploadImage2 } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props.editData ? "Edit Ads" : "Add Ads"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "bannerAdName")}
                label="bannerAd Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Name",
                  },
                }}
              />
            </Col>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "redirectionLink")}
                label="Add redirection Link"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter redirection Link",
                  },
                }}
              />
            </Col>
            {/* <Col sm={6}>
              <ImageUploader  required={true} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e)=>this.imageUploaser(e,"thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} />
            </Col> */}
            <Col sm={6}>
              <ImageUploader required={true} id="Banner" label="Upload Banner" name={typeof (bannerfile) == 'object' ? uploadImage2 : bannerfile} onChange={(e) => this.imageUploaser(e, "banner")} onDelete={() => this.setState({ bannerPath: "" })} />
            </Col>

            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "keywords")}
                label="Add keywords "
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter keywords",
                  },
                }}
              />
            </Col>
          </Row>

          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              Save
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
  // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAds);
