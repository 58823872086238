// ClearButton.js
// ClearButton.js
import React, { Component } from 'react';

class ClearButton extends Component {
    render() {
        const { onClick } = this.props;
        return (
            <button
                type="button"
                className="clear-amc-btn"
                style={{
                    position: "absolute",
                    top: "-2px",
                    right: "10px",
                    color: "black",
                    fontWeight: "bold",
                    padding: "5px 10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "4px",
                    zIndex: 0,

                }}
                onClick={onClick}
            >
                Clear All
            </button>
        );
    }
}

export default ClearButton;
