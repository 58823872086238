import React from "react";
import { Image, Button } from "react-bootstrap";
import searchIcon from "../../assets/images/material-symbols_search.svg";
import closeIcon from "../../assets/images/close.png";
import leftBackArrow from "../../assets/images/left-back-arrow.png";
import {
  BaseReactComponent,
  CustomTextControl,
  Form,
  FormElement,
} from "../../utils/form";
import { withRouter } from "react-router-dom";
import Spinner from "./Spinner";

export class ComponentHeader extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: (props.searchValue && props.searchValue.search) || "",
    };
  }
  static defaultProps = {
    resLoading: false
  }
  delayTimer;
  onChangeMethod = (value) => {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.props.onChangeMethod(this.state);
    }, 1000);
  };


  clearSearch = () => {
    this.setState({ search: "" });
    this.props.clearSearch();
  };
  render() {
    const {
      title = "",
      handlePrimaryBtn,
      primaryBtn = "",
      isReturn = null,
      isBackArrow = false,
      isBack = null,
      isSearch,
      placeholder,
      onClose
    } = this.props;
    return (
      <div className="section-sub-header">
        {title && (
          <h3 className="inter-medium f-s-24 lh-29">
            {isBackArrow && isReturn && (
              <Image
                onClick={() => this.props.history.push(isReturn)}
                src={leftBackArrow}
                alt="icon"
                className="pointer"
              />
            )}
            {isBackArrow && isBack && (
              <Image
                onClick={() => this.props.history.goBack()}
                src={leftBackArrow}
                alt="icon"
                className="pointer"
              />
            )}
            {isBackArrow && onClose && (
              <Image
                onClick={() => this.props.onClose()}
                src={leftBackArrow}
                alt="icon"
                className="pointer"
              />
            )}
            {title}
          </h3>
        )}

        <div className="input-btn-wrapper">
          {/* <div className="search-frame">
             <Image src={search_iocn} alt='icon'/>

             <input onChange={onChange} className='search-input inter-medium  f-s-12 lh-15' type="text" name='search' value={value} placeholder='Search by contact' />



          </div> */}

          {isSearch && (
            <Form onValidSubmit={this.onChangeMethod}>
              <FormElement
                valueLink={this.linkState(this, "search", this.onChangeMethod)}
                // disabled={this.state.searchBy ? false : true}
                // validations={[]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: placeholder,
                  },
                }}
                classes={{
                  inputField: "search-input",
                  prefix: "search-prefix",
                  suffix: "search-suffix",
                }}
              />
              {this.state.search ? (
                <Image
                  src={closeIcon}
                  onClick={this.clearSearch}
                  className="search-icon clear-icon"
                />
              ) : (
                <Image src={searchIcon} className="search-icon" />
              )}
            </Form>
          )}

          {primaryBtn && (
            this.props?.resLoading ? (
              <Button
                onClick={() => { }}
                className="primary-btn inter-medium f-s-12 lh-15 disable-download-btn"
              >
                <Spinner />
                Saving...
              </Button>
            ) : (
              <Button
                onClick={handlePrimaryBtn}
                className="primary-btn inter-medium f-s-12 lh-15"
              >
                {primaryBtn}
              </Button>
            )
          )}

        </div>
      </div>
    );
  }
}

export default withRouter(ComponentHeader);
