import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  SelectControl,
} from "../../utils/form";
import { Row, Col, Button, Image } from "react-bootstrap";
import closeIcon from '../../assets/images/close.png';
import { getAllContentTypeApi, getAllPremiumPlanApi } from "../MasterCategory/Api";
import { COBRAND, DOWNLOAD, NON_PREMIUM, NumberOfDays, PREMIUM, subscriptionDuration, TYPE_OPTION, Validity } from "../../utils/Constant";
import { getAllUploadSectionApi, postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { ComponentHeader } from "../common";
import ReactDOM from "react-dom";

const htmlToEditerConvert = (data) => {
  const contentBlock = htmlToDraft(data || '');
  const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
}
class PremimumPlanForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.location.state?.editData || null;
    console.log(data)
    const orderedLabels = ["7 Days", "15 Days", "1 Month", "3 Months", "6 Months", "1 Year", "10 Years"];

    const editPlanData = data?.subPremiumPlanVariations?.map((item, i) => (
      {
        uuid: item?.uuid,
        htmlRichTitle: item?.title || '',
        title: data ? htmlToEditerConvert(item.title) : EditorState?.createEmpty(),
        discountedPrice: `${item?.discountedPrice}` || '',
        pack: item?.pack || "",
        price: `${item?.price}` || '',
        expiryInDays: `${item?.resetInDays}` || '',
        subTitle: '',
        editorState: data ? htmlToEditerConvert(item.description) : EditorState?.createEmpty(),
        htmlRichText: item?.description || null,
        features: item?.subPremiumPlanVariationSpecification?.map((ele) => ({
          uuid: ele.uuid || '',
          type: ele?.type || 'event',
          contentType: ele?.contentType?.split(',') || ["4d25bac3-5372-11ee-a6a5-b48c9dd517c6"],
          actionType: ele?.actionType?.split(',') || ["download"],
          premium: ele?.contentCategory?.split(',') || ["premium"],
          limit: ele?.limit == 0 ? "0" : ele?.limit,
          uploadSection: ele?.uploadSection?.split(',') || ["a20c98ef-ed60-4fe6-8210-56ace331e05d"],
          resetInDays: ele?.resetInDays || '',
        }))

      }))
    // console.log('Edite data checked ', data)
    // console.log(data?.contentTypeLimits)
    // const contentBlock = htmlToDraft(data?.subPremiumPlanVariation[0]?.description || '');
    // const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks);
    // const editorState = EditorState.createWithContent(contentState);

    this.state = {
      editData: data,
      isAddContent: true, //for content type dropdown
      contentTypeOptions: [],
      SelectControl: [],
      premiumPlanList: [],
      resetInDaysOptions: [],
      // description: data?.description || "",
      contentCode: '',
      typeLabel: '',
      resLoading: false,
      premiumPlanId: data?.premiumPlan?.uuid || '',
      durationInDays: data?.durationInDays || '',
      subPremiumPlanName: data?.name || '',

      isPostPlanner: data?.isPostPlanner || false,
      isAdsNotAllowed: data?.isAdsNotAllowed || false,
      isCustomUploadAllowed: data?.isCustomUploadAllowed || false,
      postPlannerDurationInDays: data?.postPlannerDurationInDays || '',
      level: data?.level || '',
      subscriptionOption: subscriptionDuration
        .sort((a, b) => orderedLabels.indexOf(a.label) - orderedLabels.indexOf(b.label))
        .map(option => ({
          value: option.value,
          label: option.label
        })),

      premiumPlans: data ? editPlanData :
        [{
          uuid: '',
          discountedPrice: '',
          price: '',
          expiryInDays: '',
          pack: "",

          subTitle: '',
          editorState: EditorState.createEmpty(),
          htmlRichText: null,
          htmlRichTitle: null,

          features: [
            { uuid: '', type: '', contentType: [], actionType: [], premium: [], limit: '', uploadSection: [], resetInDays: '' }
          ],
        }
        ],

      // premiumPlans: [
      // {
      // title: "",
      // discountedPrice: '',
      // price: '',
      // expiryInDays: '',
      // subTitle: '',
      // editorState: EditorState.createEmpty(),
      // htmlRichText: null,

      // features: [
      // { type: '', contentType: '', actionType: '', premium: '', limit: '', uploadSection: '', resetInDays: '' }
      // ],
      // }
      // ]
      isTopUp: data?.premiumPlan?.isTopup || false,

    };
  }

  componentDidMount() {
    getAllUploadSectionApi(this);
    getAllContentTypeApi(this)
    getAllPremiumPlanApi(this)

  }


  componentDidUpdate(prevProps, prevState) {


  }


  handleSetContentLimit = () => {
    Object.keys(this.state.editData.contentTypeLimits).forEach((item) => {
      // console.log(item + "=>" + this.state.editData.contentTypeLimits[item])
      this.setState({
        [item]: this.state.editData.contentTypeLimits[item]
      })
    })
  }


  // onHide = () => {
  // this.props.getAllSubscriptionApi();
  // this.props.onHide();
  // }

  // todo- Loop code START --------------

  handleAddFeature = (j) => {
    const newFeature = { uuid: '', type: '', contentType: '', actionType: '', premium: '', limit: '', uploadSection: '', resetInDays: '' };
    let updatedPremiumPlans = [...this.state.premiumPlans];
    updatedPremiumPlans[j]?.features.push(newFeature);
    this.setState({ premiumPlans: updatedPremiumPlans });
  };

  removeFeatures = (i, j) => {
    let updatedPremiumPlans = [...this.state.premiumPlans];
    updatedPremiumPlans[j]?.features.splice(i, 1);
    this.setState({ premiumPlans: updatedPremiumPlans });

  }

  renderFeature = (ele, id, j) => {
    const { features, isTopUp, contentTypeOptions, uploadSectionOptions, expiryInDays, premiumPlans, resetInDaysOptions, postPlannerDurationInDays } = this.state;


    return (
      <>
        {
          id > 0 &&
          <><hr /><br /></>
        }
        <p className="variation-srno f-s-14">Features -{id + 1} </p>
        <div className="d-flex justify-content-between align-items-center">

          <Row key={id} className="w-100">

            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, `premiumPlans.${j}.features.${id}.type`)}
                label="Type"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Type",
                    options: TYPE_OPTION,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.premiumPlans[j].features[id].type);

                    },
                  },
                }}
              />
            </Col>
            {premiumPlans[j].features[id]?.type === TYPE_OPTION[1].value && <>
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, `premiumPlans.${j}.features.${id}.uploadSection`)}
                  label="Upload Section"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Upload section cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Select Upload Section",
                      options: uploadSectionOptions,
                      multiple: true,
                      searchable: true,
                      // disabled: (userAmcUuids()?.length > 0),
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.premiumPlans[j].features[id].uploadSection);

                      },
                    },
                  }}
                />
              </Col>
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, `premiumPlans.${j}.features.${id}.contentType`)}
                  label="Content Type"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Select Content Type",
                      options: contentTypeOptions,
                      multiple: true,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.premiumPlans[j].features[id].contentType);

                      },
                    },
                  }}
                />
              </Col>

              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, `premiumPlans.${j}.features.${id}.actionType`)}
                  label="Action Type"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Select Action Type",
                      options: [
                        { label: 'Download', value: DOWNLOAD },
                        { label: 'Co-Brand / Whitelabeling', value: COBRAND },
                      ],
                      multiple: true,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.premiumPlans[j].features[id].actionType);

                      },
                    },
                  }}
                />
              </Col>

            </>
            }
            {premiumPlans[j].features[id]?.type === TYPE_OPTION[2].value &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, `premiumPlans.${j}.features.${id}.actionType`)}
                  label="Action Type"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Select Action Type",
                      options: [
                        { label: 'Download', value: DOWNLOAD },
                        { label: 'Co-Brand / Whitelabeling', value: COBRAND },
                      ],
                      multiple: true,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.premiumPlans[j].features[id].actionType);

                      },
                    },
                  }}
                />
              </Col>
            }
            {
              premiumPlans[j].features[id]?.type !== TYPE_OPTION[2].value && premiumPlans[j].features[id]?.type !== TYPE_OPTION[3].value &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, `premiumPlans.${j}.features.${id}.premium`)}
                  label="Premium / Free"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Select Premium / Free",
                      options: [
                        { label: 'Premium', value: PREMIUM },
                        { label: 'Free', value: NON_PREMIUM },
                      ],
                      multiple: true,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.premiumPlans[j].features[id].premium);

                      },
                    },
                  }}
                />
              </Col>
            }
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, `premiumPlans.${j}.features.${id}.limit`)}
                label="Limit"
                required
                validations={[
                  {
                    validate: FormValidator.isNum,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    // type: "",
                    placeholder: "Enter Limit",
                  },
                }}
              />
            </Col>
            {!isTopUp &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, `premiumPlans.${j}.features.${id}.resetInDays`)}
                  label="Replenishment In"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Select Replenishment In",
                      options: NumberOfDays?.filter(item => parseInt(premiumPlans[j]?.expiryInDays) >= parseInt(item.value)),
                      multiple: false,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state?.premiumPlans[j].features[id]?.resetInDays);
                        // const result = typeOptions.find(({ value }) => value === this.state.type);
                        // this.setState({ typeLabel: result.label, })


                      },
                    },
                  }}
                />
              </Col>
            }
          </Row>
          <div className="p-4">
            <Image src={closeIcon} className="remove-icon position-static" onClick={() => this.removeFeatures(id, j)} />
          </div>

        </div>

      </>
    );
  };


  // todo- Loop code END --------------

  handleAddPremiumPlan = () => {

    const newPremiumPlan = {
      uuid: '',
      highRichTitle: "",
      discountedPrice: '',
      price: '',
      expiryInDays: '',
      postPlannerDurationInDays: '',
      subTitle: '',
      editorState: EditorState.createEmpty(),
      htmlRichText: null,

      features: [
        { uuid: '', type: '', contentType: '', actionType: '', premium: '', limit: '', uploadSection: '', resetInDays: '' }
      ],
    }
    let updatedPremiumPlans = [...this.state.premiumPlans];
    updatedPremiumPlans.push(newPremiumPlan);
    this.setState({ premiumPlans: updatedPremiumPlans });
  }
  removePremiumPlan = (i) => {
    let updatedPremiumPlans = [...this.state.premiumPlans];
    updatedPremiumPlans.splice(i, 1);
    this.setState({ premiumPlans: updatedPremiumPlans });
  }



  // *================ SUBMIT HANDLER ======================
  onValidSubmit = async () => {
    this.setState({ resLoading: true });
    const { premiumPlans, subPremiumPlanName, premiumPlanId, durationInDays, postPlannerDurationInDays, isPostPlanner, isAdsNotAllowed, isCustomUploadAllowed, level } = this.state;
    const planPostData = {
      "data": {
        "premiumPlan": {
          "uuid": premiumPlanId
        },

        "name": subPremiumPlanName,
        "durationInDays": parseInt(durationInDays) || 0,
        "postPlannerDurationInDays": parseInt(postPlannerDurationInDays) || 0,
        "isPostPlanner": isPostPlanner,
        "isAdsNotAllowed": isAdsNotAllowed,
        "isCustomUploadAllowed": isCustomUploadAllowed,
        "level": parseInt(level),
        "subPremiumPlanVariation": premiumPlans?.map(
          (item) => {
            return (
              {
                ...(item?.uuid ? { 'uuid': item.uuid } : {}),
                "title": item?.htmlRichTitle,
                "description": item?.htmlRichText,
                "price": parseInt(item?.price),
                "pack": parseInt(item?.pack),
                "discountedPrice": parseInt(item?.discountedPrice),
                "resetInDays": parseInt(item?.expiryInDays),
                "isPostPlanner": isPostPlanner,


                "subPremiumPlanVariationSpecification": item?.features?.map((ele) => {
                  return (
                    {
                      "type": ele?.type,
                      "contentType": ele?.contentType,
                      "actionType": ele?.type === "Personalization" ? ["Cobrand"] : ele?.actionType,
                      "contentCategory": ele?.premium,
                      "limit": parseInt(ele?.limit),
                      "resetInDays": parseInt(ele?.resetInDays),
                      'uploadSection': ele?.uploadSection,
                      ...(ele?.uuid ? { 'uuid': ele.uuid } : {}),
                      ...(this.state.isTopUp ? { 'isTopup': this.state.isTopUp } : {}),
                    }
                  )
                }
                )
              }
            )
          })
      }
    }

    if (this.state.editData?.uuid) {
      const response = await putAuthCommonApi(`sub-premium-plan/${this.state.editData?.uuid}`, planPostData);
      if (response.status < 250 && response.status > 199) {
        // toast.success("Successfully Updated.")
        this.setState({ resLoading: false });
        this.props.history.push({ pathname: `/subscription-plan` });
      } else {
        this.setState({ resLoading: false });
      }

    } else {
      const response = await postAuthCommonApi('/sub-premium-plan', planPostData);
      // console.log(response);
      if (response.status < 250 && response.status > 199) {
        this.setState({ resLoading: false });
        this.props.history.push({ pathname: `/subscription-plan` });
        // toast.success("Successfully saved.")
      }
      else {
        // toast.success("Errer is get sucess in Post ai")
        this.setState({ resLoading: false });
      }

    }
  }
  handleSave = () => {
    ReactDOM.findDOMNode(this.form).dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };
  render() {
    const { contentTypeOptions, editData, resLoading, premiumPlanList, typeLabel, editorState, htmlRichText, premiumPlans, htmlRichTitle } = this.state;


    // ? ========= RICH TEXT EDITOR START ==================================
    // const toolbarOptions = {
    // inline: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
    // blockType: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
    // fontSize: [8, 9, 10, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    // fontFamily: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
    // list: ['unordered', 'ordered', 'indent', 'outdent'],
    // textAlign: ['left', 'center', 'right', 'justify'],
    // colorPicker: ['color', 'backgroundColor'],
    // link: ['link', 'unlink'],
    // embedded: ['embedded'],
    // emoji: ['emoji'],
    // image: ['image', 'uploadImage', 'altText'],
    // remove: ['remove'],
    // history: ['undo', 'redo']
    // };
    // ? ========= RICH TEXT EDITOR END ==================================
    return (
      <section className="add-section">
        <ComponentHeader
          // title={this.state.editData?.uuid ? "Edit Plan" : "Add Plan"}
          title={editData ? "Edit Subscription" : " Add Subscription"}
          primaryBtn="Save"
          // isSearch={true}
          isBackArrow={true}
          isReturn="/subscription-plan"
          handlePrimaryBtn={() => { this.handleSave() }}
        // handlePrimaryBtn={() => { this.props.history.push('/add-content') }}
        />
        <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "subPremiumPlanName")}
                label="Plan Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    // type: "",
                    placeholder: "Enter Plan Name",
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, `premiumPlanId`)}
                label="Membership Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Membership",
                    options: premiumPlanList,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.premiumPlanId);
                      const findTopUpObj = premiumPlanList.find((ele) => ele.value === this.state.premiumPlanId) || {};
                      const isTopUp = findTopUpObj?.isTopup ?? false
                      // console.log("Find ths TopUp logic =>",findTopUpObj,isTopUp);
                      this.setState({ isTopUp: isTopUp })



                    },
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              {!this.state.isTopUp &&
                <FormElement
                  valueLink={this.linkState(this, `durationInDays`)}
                  label="Duration"
                  required
                  validations={[
                    {
                      validate: FormValidator.isNum,
                      message: "Please enter numerical value",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    multiple: false,
                    settings: {
                      options: this.state.subscriptionOption,
                      placeholder: "Duration",
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.durationInDays);

                      }
                    },
                  }}
                />
              }
            </Col>

          </Row>
          <Row>
            <Col sm={4}>
              {!this.state.isTopUp &&
                <FormElement
                  valueLink={this.linkState(this, 'level')}
                  label="Level"
                  required
                  validations={[
                    {
                      validate: FormValidator.isNum,
                      message: "Please enter numerical value",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      // type: "",
                      placeholder: "Enter Level",
                    },
                  }}
                />
              }
            </Col>
            <Col sm={4}>
              <p className="inter-medium f-s-18 pt-5 pb-4 lh-22">
                Is Post Planner Available?
                <input type="checkbox" name="postPlanner" checked={this.state.isPostPlanner}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    console.log('checked?', checked);
                    let { isPostPlanner } = this.state;

                    isPostPlanner = checked;
                    this.setState({ isPostPlanner })
                  }}
                /> </p>
            </Col>


            <Col sm={4}>

              {
                this.state.isPostPlanner && !this.state.isTopUp &&
                <FormElement
                  valueLink={this.linkState(this, `postPlannerDurationInDays`)}
                  label="Post Planner Duration "
                  validations={[
                    {
                      validate: FormValidator.isNum,
                      message: "Please enter numerical value",
                    },
                  ]}
                  control={{
                    type: SelectControl,

                    multiple: false,
                    settings: {
                      options: this.state.subscriptionOption,
                      placeholder: "Post Planner Duration",
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.postPlannerDurationInDays);

                      }
                    },
                  }}
                />
              }
            </Col>
            <Col sm={4}>
              <p className="inter-medium f-s-18 pt-5 pb-4 lh-22">
                Is Ads Not Allowed?
                <input type="checkbox" name="adsAllowed" checked={this.state.isAdsNotAllowed}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    console.log('checked?', checked);
                    let { isAdsNotAllowed } = this.state;

                    isAdsNotAllowed = checked;
                    this.setState({ isAdsNotAllowed })
                  }}
                /> </p>
            </Col>
            {this.state.isPostPlanner &&
              <Col sm={4}>
                <p className="inter-medium f-s-18 pt-5 pb-4 lh-22">
                  Is Custom Upload Allowed?
                  <input type="checkbox" name="customUpload" checked={this.state.isCustomUploadAllowed}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      console.log('checked?', checked);
                      let { isCustomUploadAllowed } = this.state;

                      isCustomUploadAllowed = checked;
                      this.setState({ isCustomUploadAllowed })
                    }}
                  /> </p>
              </Col>
            }

            <Col sm={4}></Col>

          </Row>
          {
            premiumPlans?.map((ele, j) => {
              return (
                <div key={j} className="supPremiumPlan-form-wrapper" >
                  <h6 className="roboto-bold f-s-19 mb-5 grey-757 d-flex justify-content-between">
                    Plan Variation- {1 + j}
                    <Image src={closeIcon} onClick={() => this.removePremiumPlan(j)} className="remove-icon position-static" />
                  </h6>
                  <Row>


                    <Col md={6}>
                      <h3 className=" inter-bold f-s-16 black-757 mb-10">Offer Line</h3>
                      <Editor
                        editorStyle={{ height: '200px' }}
                        editorState={premiumPlans[j].title}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName bg-white inter-regular f-s-14 lh-15 grey-757"
                        onEditorStateChange={(editorState) => {
                          const htmlContent = draftToHtml(convertToRaw(editorState?.getCurrentContent()));
                          let updatedPremiumPlans = [...this.state.premiumPlans];
                          updatedPremiumPlans[j].title = editorState;
                          updatedPremiumPlans[j].htmlRichTitle = htmlContent;
                          this.setState({
                            premiumPlans: updatedPremiumPlans,
                          });


                        }}
                        toolbar={{
                          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'textAlign', 'emoji', 'colorPicker'],
                          inline: {
                            inDropdown: false,
                            options: ['bold', 'italic', 'underline', 'strikethrough']
                          },
                          fontSize: {
                            inDropdown: false,
                            className: 'd-none',
                            options: []
                          },
                          fontFamily: {
                            inDropdown: false,
                            options: [],
                            className: 'd-none',
                            component: undefined,
                            dropdownClassName: undefined,
                          },

                          blockType: {
                            inDropdown: false,
                            options: [],
                            className: 'd-none',
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          list: {
                            inDropdown: false,
                            options: []
                          },
                          link: {
                            inDropdown: true,
                            options: []
                          },
                          emoji: {
                            inDropdown: true,
                            popupClassName: 'popupEmojiClassName',
                            emojis: ['✔️', '❌']
                          },
                          embedded: {
                            inDropdown: true,
                            options: []
                          },
                          image: {
                            inDropdown: true,
                            options: []
                          },
                          history: {
                            inDropdown: true,
                            options: []
                          },
                          remove: {
                            visible: false
                          }
                        }}
                      />
                      <br></br>

                    </Col>
                    <Col md={6} style={{ zIndex: "0" }}>
                      <h3 className=" inter-bold f-s-16 black-757 mb-10">Description <span className=" text-danger">*</span> </h3>
                      <Editor
                        editorStyle={{ height: '200px' }}
                        editorState={premiumPlans[j].editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName bg-white inter-regular f-s-14 lh-15 grey-757"
                        onEditorStateChange={(editorState) => {
                          const htmlContent = draftToHtml(convertToRaw(editorState?.getCurrentContent()));
                          let updatedPremiumPlans = [...this.state.premiumPlans];
                          updatedPremiumPlans[j].editorState = editorState;
                          updatedPremiumPlans[j].htmlRichText = htmlContent;
                          this.setState({
                            premiumPlans: updatedPremiumPlans,
                          });


                        }}
                        toolbar={{
                          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'textAlign', 'emoji', 'colorPicker'],
                          inline: {
                            inDropdown: false,
                            options: ['bold', 'italic', 'underline', 'strikethrough']
                          },
                          fontSize: {
                            inDropdown: false,
                            className: 'd-none',
                            options: []
                          },
                          fontFamily: {
                            inDropdown: false,
                            options: [],
                            className: 'd-none',
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          // blockType: {
                          // inDropdown: true,
                          // options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
                          // },
                          blockType: {
                            inDropdown: false,
                            options: [],
                            className: 'd-none',
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          list: {
                            inDropdown: false,
                            options: []
                          },
                          link: {
                            inDropdown: true,
                            options: []
                          },
                          emoji: {
                            inDropdown: true,
                            popupClassName: 'popupEmojiClassName',
                            emojis: ['✔️', '❌']
                          },
                          embedded: {
                            inDropdown: true,
                            options: []
                          },
                          image: {
                            inDropdown: true,
                            options: []
                          },
                          history: {
                            inDropdown: true,
                            options: []
                          },
                          remove: {
                            visible: false
                          }
                        }}
                      />
                      <br></br>
                      {/* <textarea
 disabled
 value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
 /> */}
                    </Col>

                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, `premiumPlans.${j}.price`)}
                        label="Actual Price"

                        control={{
                          type: CustomTextControl,
                          settings: {
                            // type: "",
                            placeholder: "Actual Price",
                          },
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <FormElement
                        valueLink={this.linkState(this, `premiumPlans.${j}.discountedPrice`)}
                        label="Final Price"
                        required
                        validations={[
                          {
                            validate: FormValidator.isNum,
                            message: "Please enter numerical value",
                          },
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            // type: "",
                            placeholder: "Enter Final Price",
                          },
                        }}
                      />
                    </Col>



                    <Col md={4}>
                      <FormElement
                        valueLink={this.linkState(this, `premiumPlans.${j}.expiryInDays`)}
                        label={this.state.isTopUp ? "Preferred Validity" : "Billing Frequency"}
                        required={this.state.isTopUp ? false : true}
                        // validations={[
                        //   {
                        //     validate: FormValidator.isRequired,
                        //     message: "Field cannot be empty",
                        //   },
                        // ]}
                        control={{
                          type: SelectControl,
                          settings: {
                            placeholder: (this.state.isTopUp ? " Select Preferred Validity" : "Select Billing Frequency"),
                            options: this.state.isTopUp ? Validity : NumberOfDays,
                            multiple: false,
                            searchable: true,
                            onChangeCallback: (onBlur) => {
                              onBlur(this.state?.premiumPlans[j]?.expiryInDays);
                              // const result = typeOptions.find(({ value }) => value === this.state.type);
                              // this.setState({ resetInDaysOptions })


                            },
                          },
                        }}
                      />
                      {

                        this.state.isTopUp &&
                        <FormElement
                          valueLink={this.linkState(this, `premiumPlans.${j}.pack`)}
                          label="Pack"
                          // required
                          validations={[
                            {
                              validate: FormValidator.isNumber,
                              message: "Please enter numerical value",
                            },
                          ]}
                          control={{
                            type: CustomTextControl,
                            settings: {
                              // type: "",
                              placeholder: "Enter  Value",
                            },
                          }}
                        />

                      }



                    </Col>




                  </Row>
                  <br />
                  <h3 className="inter-semibold f-s-19 lh-24 section-title">Features / Benefits</h3>
                  <br />

                  {ele?.features?.map((item, i) => this.renderFeature(item, i, j))}
                  <div className=" d-flex justify-content-end">
                    <Button className='primary-btn' onClick={() => this.handleAddFeature(j)}>+ Add Features</Button>
                  </div>

                </div>
              )
            })
          }

          <div className=" d-flex ">
            <Button className='primary-btn' onClick={this.handleAddPremiumPlan}>+ Add Plan Variation</Button>
          </div>


        </Form>
      </section>
    );
  }
}



export default PremimumPlanForm;