import { combineReducers } from 'redux';
/* PLOP_INJECT_REDUCER_IMPORT */

import { RolesReducer } from '../app/roles';
import { DashboardReducer } from '../app/dashboard';
import { OnBoardingReducer } from '../app/onBoarding';
import { ContentCollectionsReducer } from '../app/contentCollections';
import { EventsReducer } from '../app/events';
import { UsersReducer } from '../app/users';
import { ProfileReducer } from '../app/profile';
import { LoginReducer } from '../app/login';
import { HomeReducer } from '../app/home';
import { MasterCategoryReducer } from '../app/MasterCategory';
import { ContentReducer } from '../app/content';
import { CustomersReducer } from '../app/customers';
import { AutoshareReducer } from '../app/autoshare';
import { SsoReducer } from '../app/sso';
import { SsoContentEventReducer } from '../app/ssoContentEvent';
import { SubDomainReducer } from '../app/subdomain';
import PersonalizationReducer from '../app/personalization/PersonalizationReducer';

export default combineReducers({
	/* PLOP_INJECT_REDUCER */

	RolesState: RolesReducer,
	DashboardState: DashboardReducer,
	CustomersState: CustomersReducer,
	ContentState: ContentReducer,
	MasterCategoryState: MasterCategoryReducer,
	OnBoardingState: OnBoardingReducer,
	ContentCollectionsState: ContentCollectionsReducer,
	EventsState: EventsReducer,
	AutoShareState: AutoshareReducer,
	UsersState: UsersReducer,
	ProfileState: ProfileReducer,
	LoginState: LoginReducer,
	HomeState: HomeReducer,
	SsoState: SsoReducer,
	SsoContentEventState: SsoContentEventReducer,
	SubdomainState: SubDomainReducer,
	PersonalizationState: PersonalizationReducer
});