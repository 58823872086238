import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CustomModal, ImageUploader, Spinner } from "../common";
import { addAmcApi, updateAmcApi } from "./Api";

class AmcForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;
    this.state = {
      amcName: data?.amcName || "",
      description: data?.description || "",
      thumbnailPath: "",
      thumbnailFile: null,
      bannerPath: "",
      bannerfile: null,
      circleImagePath: "",
      circleImageFile: null,
      uploadImage: data?.thumbnailPath || null,
      uploadImage2: data?.bannerPath || null,
      uploadImage3: data?.circleImagePath || null,
      resLoading: false,

    };
  }

  componentDidMount() { }

  imageUploaser = (e, name) => {

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (name === "banner") {
      this.setState({ bannerPath: e.target.files[0].name, bannerfile: e.target.files[0], uploadImage2: imageUrl })
    } else if (name === "circleImage") {
      this.setState({ circleImagePath: e.target.files[0].name, circleImageFile: e.target.files[0], uploadImage3: imageUrl })
    } else {
      this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
    }

  }

  onValidSubmit = () => {
    this.setState({ resLoading: true })
    const { thumbnailPath, bannerPath, editData, circleImagePath, amcName, description, amc, bannerfile, thumbnailFile, circleImageFile } = this.state;

    let AmcData = {
      "data": {
        ...(amcName ? { "amcName": amcName } : {}),
        ...(description ? { "description": description } : {}),
        "startDate": "2023-10-10",
        "endDate": "2023-10-19",
        ...(thumbnailPath ? { "thumbnailPath": thumbnailPath } : {}),
        ...(bannerPath ? { "bannerPath": bannerPath } : {}),
        ...(circleImagePath ? { "circleImagePath": circleImagePath } : {}),
      }

    };
    const data = new FormData();
    data.append("key", JSON.stringify(AmcData));
    thumbnailFile !== null && data.append("file", thumbnailFile);
    bannerfile !== null && data.append("file", bannerfile);
    circleImageFile !== null && data.append("file", circleImageFile);
    if (this.props.editData?.uuid) {
      updateAmcApi(data, this.props.this, this.props.onHide, this.props.editData.uuid, this)
    } else {
      addAmcApi(data, this.props.this, this.props.onHide, this)
    }
  }
  onHide = () => {
    this.props.getAllAmcApi();
    this.props.onHide();
  }
  render() {
    const { uploadImage, resLoading, thumbnailFile, bannerfile, uploadImage2, circleImageFile, uploadImage3 } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props.editData?.uuid ? "Edit AMC" : "Add AMC"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={6}>
              <FormElement
                valueLink={this.linkState(this, "amcName")}
                label="AMC Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter AMC Name",
                  },
                }}
              />
            </Col>
            <Col sm={6}>
              <ImageUploader required={true} id="circleImage" label="Upload Logo" name={typeof (circleImageFile) == 'object' ? uploadImage3 : circleImageFile} onChange={(e) => this.imageUploaser(e, "circleImage")} onDelete={() => this.setState({ circleImageFile: "" })} />
            </Col>
            <Col sm={6}>
              <ImageUploader required={true} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} />
            </Col>
            <Col sm={6}>
              <ImageUploader required={false} id="Banner" label="Upload Banner" name={typeof (bannerfile) == 'object' ? uploadImage2 : bannerfile} onChange={(e) => this.imageUploaser(e, "banner")} onDelete={() => this.setState({ bannerfile: "" })} />
            </Col>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "description")}
                label="Description"
                helpText="Description can't be more than 4000 character"

                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    as: "textarea",
                    placeholder: "Enter Description",
                  },
                }}
              />
            </Col>
          </Row>

          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              {resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
  // masterCategoryState: state.MasterCategoryState,
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AmcForm);
