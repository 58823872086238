import React, { } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";

import ComponentHeader from "../common/ComponentHeader";
import { AdminUsers, isModuleExist } from "../../utils/Constant";
class BulkCoBrand extends BaseReactComponent {
  constructor(props) {
    super(props);
    const AccesData = isModuleExist(AdminUsers, 'Users');

    this.state = {
      access: AccesData,

      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,
    };
  }


  onChangeMethod = (value) => {
    console.log(value);

  };

  clearSearch = () => { };



  showEditModal = (rowData = null) => {
    this.setState({
      showEdit: !this.state.showEdit,
      editData: rowData,
    });
  };


  render() {
    const { usersList, totalPages, currentPage } = this.props.usersState;
    const { showEdit, access } = this.state;

    return (
      <>
        <section className="request-section">
          <ComponentHeader
            title="Bulk Co-Brand Group"
            primaryBtn={access.createAccess ? "Add Bulk Co-Brand group" : ""}
            handlePrimaryBtn={() => {
              this.props.history.push("/bulk-cobrand-form");
            }}
          />

        </section>
      </>
    );
  }
}


const mapStateToProps = state => ({
  usersState: state.UsersState,
});
const mapDispatchToProps = {


};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BulkCoBrand));
