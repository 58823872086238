
import React from "react";
import { connect } from "react-redux";
import { Form, FormElement, FormValidator, CustomTextControl, BaseReactComponent, CustomRadio, SelectControl, DatePickerControl } from "../../utils/form";
import { Row, Col, Button, Image } from "react-bootstrap";
import { ImageUploader, ComponentHeader, SelectKeywords, ClearButton } from "../common";
import { ACCEPTEDFILETYPE, MAKER, FILETYPENAME, FULLVIDEO, MARKET_PLACE, MAX_LIMIT, NoBranding, SOCIALMEDIA, VerifyContent, YesNoOptions, isModuleExist, selectPosition, SUPER_ADMIN, IMAGEFILETYPE, CAROUSEL } from "../../utils/Constant";
import { getAllCategoryApi, getAllSubCategoryApi, getContentTypeApi, getLanguageNameApi, getAllColorApi, getKeywordNameApi, getAllAmcApi } from "../MasterCategory/Api";
import { getAllCampaignApi, getAllTargetedListApi, getFilterListTargeted } from '../contentCollections/Api';
import { getAllBrandingTypeApi, getAllContentCreatorApi, getAllDisplayPositionApi, getAllSocialMediaApi, getAllUploadSectionApi, getDetaisContentApi, postAuthCommonApi, putAuthCommonApi } from "./Api";
import ReactDOM from "react-dom";
import closeIcon from '../../assets/images/close.png';
import { toast } from "react-toastify";
import Content from "./Content";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-datepicker/dist/react-datepicker.css";
import { camelCaseTitle, isBtnShow, isFinpediaUser, isPDF, onlyUnique, userAmcUuids, userCreatorUuids, userRoleType } from "../../utils/ReusableFunctions";
import moment from "moment";
import CarouselFileUploader from "./CarouselFileUploader";

class AddEditContent extends BaseReactComponent {
  constructor(props) {

    const id = props.location?.state?.id || '';
    const editData = "";
    const AccesData = isModuleExist(Content, VerifyContent);
    const roleType = userRoleType() || SUPER_ADMIN;
    super(props);
    this.state = {
      id,
      roleType,
      accept: null,
      access: AccesData || {},
      uploadSection: (userAmcUuids()?.length > 0) ? [MARKET_PLACE] : [],
      isSocialMedia: false,
      uploadSectionOptions: [],
      contentType: "",
      uploadFileName: '',
      contentTypeOptions: [],

      socialMedia: [],
      socialMediaOption: [],

      defaultTitle: "",
      defaultDescription: "",
      defaultVariationUuid: '',

      keyword: [],
      keywordOption: [],
      selectedKeywords: [],

      thumbnailImage: null,
      uploadImage: null,
      urlLink: "",

      duration: "",
      source: "",
      defaultColor: "c076419a-f3d1-474c-8f83-c251b8da1b37",//default
      defaultLanguage: "b36f7712-5373-11ee-a6a5-b48c9dd517c6", //English

      date: "",

      categoryId: "",
      contentFile: '',
      uploadContentFile: null,

      contentPath: "",
      contentUrl: "",
      socialMediaId: "",

      branding: "",
      contenTypeId: "",
      keyWords: "",
      author: "",

      pageSize: MAX_LIMIT,
      isAddContent: true, //for category dropdown setstate
      category: "",
      categoryOptions: [],
      subcategoryId: "",

      subCategory: "",
      subCategoryOptions: [],

      displayPosition: [],
      displayPositionOptions: [],
      displayPositionsAllowed: [],

      creator: userCreatorUuids() || "",
      creatorList: [],

      branding: "",
      brandingTypeOption: [],



      language: "",
      languageOption: [],
      colorOption: [],


      campaign: "",
      campaignOption: [],
      displayPositionsNotAllowed: [],
      // colorOption: [],

      amc: (userAmcUuids()?.length > 0) ? userAmcUuids() : '',
      amcOption: [],

      targetedContent: "",
      targetedContentOption: [],

      isFeatured: "",
      isVerified: "",
      isPremium: "",

      likeCount: (roleType === MAKER) ? 0 : "",
      shareCount: (roleType === MAKER) ? 0 : "",
      savedCount: (roleType === MAKER) ? 0 : "",
      viewCount: (roleType === MAKER) ? 0 : "",
      downloadCount: (roleType === MAKER) ? 0 : "",
      allFilesImages: [],
      editorState: EditorState.createEmpty(),
      htmlRichText: null,
      // variatinsSubImage:{variationImage:null,variationImageUrl:""},
      variations: [],
      resLoading: false,
      isAmcShow: false,
      isPositionAllow: false,
      isNoBranding: false,

      targetedList: {},
      ageGroup: [],
      gender: [],
      employmentType: [],
      incomeGroup: [],
      maritalStatus: [],
      goal: [],
      riskAppetite: [],
      financialUnderstanding: [],

      publishedAt: null,
      isTextUrl: '',
      search: '',

      isUploadContentFile: '',
    };
  }

  componentDidMount() {
    getAllUploadSectionApi(this);
    this.props.getContentTypeApi(this)
    getAllDisplayPositionApi(this);
    getAllContentCreatorApi(this);
    this.props.getLanguageNameApi(this);
    getAllSocialMediaApi(this);
    getAllSubCategoryApi(this);
    getAllBrandingTypeApi(this);
    this.props.getAllCategoryApi(this);
    this.props.getAllColorApi(this);
    this.props.getKeywordNameApi(this);
    this.props.getAllCampaignApi(this);
    this.props.getAllAmcApi(this);
    this.props.getAllTargetedListApi(this);
    getFilterListTargeted(this);
    if (this?.state?.id) {
      getDetaisContentApi("/admin/content/allDetails", this, true, this.state);

    }

  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.creatorList !== prevState.creatorList) {
      if (userCreatorUuids()) {
        const isExest = this.state.creatorList?.find(item => userCreatorUuids().split(',')?.includes(item.value))
        console.log(userCreatorUuids().split(','), ' is selected thate this', this.state.creatorList, isExest)
        this.setState({ isAmcShow: isExest?.label?.includes('Mutual Fund Company') })
      }
    }
    if (this.state.search !== prevState.search) {

      this.props.getKeywordNameApi(this);
    }


  }

  setContentData = (data) => {
    console.log('data', data);

    if (data.contentType?.contentCode === "ARTICLES") {
      const contentBlock = htmlToDraft(data?.defaultVariation?.extUrlLink);

      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });
    }


    const parsedDate = moment(data.publishedAt, 'MM-DD-YYYY');
    parsedDate.set({ hour: 0, minute: 0, second: 0 });
    const utcDate = parsedDate.utc();
    const isoDateString = utcDate.toISOString();
    this.setState({
      uploadSection: data?.uploadSection?.map((item) => item?.uuid),
      socialMedia: data?.socialMedia?.map((item) => item?.uuid),
      contentType: data.contentType?.uuid || "",
      accept: data.contentType?.contentCode || "",
      defaultTitle: data?.defaultVariation?.title || "",
      defaultDescription: data?.defaultVariation?.description || "",
      selectedKeywords: data?.keywords?.split(','),
      uploadContentFile: (data.contentType.contentCode === CAROUSEL && data?.defaultVariation?.contentVariationimages?.length > 0) ? data?.defaultVariation?.contentVariationimages : data?.defaultVariation?.contentFilePath,
      isUploadContentFile: (data.contentType.contentCode === 'VIDEOS' || isBtnShow(data?.defaultVariation?.contentFilePath)) ? data?.defaultVariation?.contentFilePath : "",
      creator: data?.contentCreator?.uuid,
      defaultColor: data?.defaultVariation?.color?.uuid,
      defaultLanguage: data?.defaultVariation?.language?.uuid,
      defaultVariationUuid: data?.defaultVariation?.uuid,
      campaign: data?.campaign?.map((item) => item?.uuid) || [],
      amc: data?.amc?.uuid,
      targetedContent: data?.targetedContent?.map((item) => item?.uuid) || [],
      // urlLink: data?.contentType?.contentCode === "ARTICLES" ? data?.defaultVariation?.extUrlLink : '',
      urlLink: data?.defaultVariation?.extUrlLink,
      isTextUrl: data?.defaultVariation?.extUrlLink ? '2' : '1',
      htmlRichText: data?.contentType?.contentCode === "ARTICLES" ? data?.defaultVariation?.extUrlLink : "",
      uploadImage: data?.thumbnail,
      source: data?.source,
      duration: data?.duration,
      viewCount: data?.viewCount,
      shareCount: data?.shareCount,
      savedCount: data?.savedCount,
      likeCount: data?.likeCount,
      downloadCount: data?.downloadCount,
      branding: data?.branding?.uuid,
      gender: data?.gender ? data.gender.split(',').map(item => item.trim()).filter(item => item) : [],
      ageGroup: data?.ageGroup ? data.ageGroup.split(',').map(item => item.trim()).filter(item => item) : [],
      incomeGroup: data?.incomeGroup ? data.incomeGroup.split(',').map(item => item.trim()).filter(item => item) : [],
      employmentType: data?.employmentType ? data.employmentType.split(',').map(item => item.trim()).filter(item => item) : [],
      riskAppetite: data?.riskAppetide ? data.riskAppetide.split(',').map(item => item.trim()).filter(item => item) : [],
      financialUnderstanding: data?.financialUnderstanding ? data.financialUnderstanding.split(',').map(item => item.trim()).filter(item => item) : [],
      goal: data?.goal ? data.goal.split(',').map(item => item.trim()).filter(item => item) : [],
      maritalStatus: data?.maritalStatus ? data.maritalStatus.split(',').map(item => item.trim()).filter(item => item) : [],

      displayPosition: data?.displayPosition?.map((item) => item?.uuid),
      isPositionAllow: data?.displayPosition?.find((item) => item?.code?.includes("CORNER")) ? true : false,
      displayPositionsAllowed: (data?.displayPosition?.filter((item) => item.code?.includes("CORNER"))).map(x => x?.code),
      category: data?.category?.uuid,
      publishedAt: data.publishedAt || null,
      // publishedAt: isoDateString || null,
      subCategory: data?.subCategory?.uuid,
      isVerified: data?.isVerified ? 'true' : 'false',
      isPremium: data?.isPremium ? 'true' : 'false',


      // variations:data?.contentVariation
      uploadContentName: '',
      variations: data?.contentVariation?.map((item, i) => ({
        title: item?.title,
        description: item?.description,
        language: item?.language?.uuid,
        color: item?.color?.uuid,
        content: "",
        fileImage: (data.contentType.contentCode === CAROUSEL && item?.contentVariationimages?.length > 0) ? item?.contentVariationimages : item?.contentFilePath,
        fileImageUrl: (data.contentType.contentCode === 'VIDEOS' || isBtnShow(item?.contentFilePath)) ? item?.contentFilePath : "",
        isDefault: false,
        urlLink: item?.extUrlLink,
        variationsUuid: item?.uuid
      }))

    })


    const isExest = this.state?.creatorList?.find(item => data?.contentCreator?.uuid?.includes(item?.value))
    const isExest2 = this.state?.brandingTypeOption?.find(item => data?.branding?.uuid?.includes(item?.value))
    // console.log("Branding labeling:", isExest2);


    // console.log(data?.contentCreator?.uuid, ' is selected thate this', this.state.creatorList, isExest)
    this.setState({ isAmcShow: isExest?.label?.includes('Mutual Fund Company'), isNoBranding: isExest2?.label?.includes(NoBranding) })

  }

  handlePageSize = (pageSize) => {
    // console.log("Heyyy pageSize", pageSize);
  };

  imageUploader = (e, stateKey, imgUrlStateKey, type, thumb = '') => {
    console.log('type', type);
    // console.log(e.target.files[0])
    const fileSize = e.target.files[0].size;
    const fileSizeInMB = fileSize / (1024 * 1024);
    // console.log(fileSizeInMB)
    if (fileSizeInMB < 51) {
      const isTypeMathed = FILETYPENAME[type]?.split(',').some((ele) => e.target.files[0].name.includes(ele));
      const isFile = e.target.files[0].type.includes(FILETYPENAME[type])
      // console.log("Check-With:=", isTypeMathed, "With type :-", isFile)
      if (isFile || isTypeMathed) {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        this.setState({
          [stateKey]: e.target.files[0],
          [imgUrlStateKey]: imageUrl,
          uploadContentName: e.target.files[0],
        });
        if (!thumb) {
          this.setState({
            // uploadFileName: FILETYPENAME[type] === 'video' ? false : e.target.files[0]?.name || '',
            uploadFileName: e.target.files[0]?.name || '',
          })
        }

      } else {
        // console.log("Content Type mis-matched")
        toast.error("Content Type mis-matched ( Only " + FILETYPENAME[type] + ` Allowed. )`);
      }
    } else {
      toast.error("Uploaded content size is less_then 50MB");
    }

  };


  addMore = () => {
    let variations = this.state.variations ? this.state.variations.map(a => ({ ...a })) : [];
    variations.push({
      title: "",
      description: "",
      language: "",
      color: "",
      content: "",
      fileImage: "",
      fileImageUrl: "",
      isDefault: false,
      urlLink: '',
      variationsUuid: ''
    });
    this.setState({ variations });
  }
  removeVariation = (i) => {
    let variations = this.state.variations.map(a => ({ ...a }));
    variations.splice(i, 1);
    this.setState({ variations });
  }
  isError = () => {
    const {
      uploadSection,
      contentType,
      isPremium,
      category,
      publishedAt,
      isTextUrl,
      defaultTitle,
      defaultLanguage,
      defaultColor,
      creator,

      branding,
      displayPosition,
      isVerified,
      isNoBranding // added isNoBranding to the destructured state
    } = this.state;

    const requiredFields = [
      uploadSection,
      contentType,
      isPremium,
      category,
      publishedAt,
      defaultTitle,
      defaultLanguage,
      defaultColor,
      creator,
      branding
    ];

    // If isNoBranding is false, displayPosition is also required
    if (!isNoBranding) {
      requiredFields.push(displayPosition);
    }



    const emptyField = requiredFields.some(field =>
      field === "" || field == null || (Array.isArray(field) && field.length === 0)
    );

    if (emptyField) {
      toast.error("Please fill out all required fields.");
    }
  }

  handleSave = () => {
    this.isError()
    ReactDOM.findDOMNode(this.form).dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };
  onValidSubmit = () => {
    // console.log('hey submit');
  }
  // todo---------------------- POST API STATY ---------------------------------------
  onValidSubmit = async () => {

    this.setState({ resLoading: true });
    const { uploadSection, isAmcShow, subCategory, defaultVariationUuid, isPositionAllow, ageGroup, gender, employmentType, incomeGroup, maritalStatus, goal, riskAppetite, financialUnderstanding, displayPositionsNotAllowed, htmlRichText, id, amc, targetedContent, campaign, contentType, socialMedia, defaultTitle, defaultColor, defaultDescription, contentFile, defaultLanguage, selectedKeywords, thumbnailImage, creator, urlLink, source, duration, viewCount, likeCount, savedCount, shareCount, downloadCount, branding, category, isPremium, variations, isVerified, displayPositionOptions } = this.state;

    let { displayPosition, displayPositionsAllowed } = this.state;

    if (!isPositionAllow) {
      displayPositionsAllowed = []
    }
    displayPositionsAllowed.forEach(element => {
      displayPosition.push(displayPositionOptions?.find(x => x?.code === element)?.value)
    });
    displayPosition = displayPosition?.filter(onlyUnique) || [];
    const isFileNameOrArray = ((contentFile?.name ?? false) || ((contentFile?.length > 0)));

    const combinationSet = new Set();


    const defaultCombination = `${defaultLanguage}_${defaultColor}`;
    if (combinationSet.has(defaultCombination)) {
      toast.error("Duplicate color and language combination found.");
      this.setState({ resLoading: false });
      return;
    }
    combinationSet.add(defaultCombination);


    for (const variation of variations) {
      const combination = `${variation.language}_${variation.color}`;
      if (combinationSet.has(combination)) {
        toast.error("Duplicate color and language combination found.");
        this.setState({ resLoading: false });
        return;
      }
      combinationSet.add(combination);
    }


    let eventData =
    {
      data: {
        "uploadSection": uploadSection?.map((item, i) => { return ({ "uuid": item }) }),
        "contentType": {
          "uuid": contentType
        },
        "socialMedia": socialMedia?.map((item, i) => { return ({ "uuid": item }) }),
        "defaultVariation": {
          "title": defaultTitle,
          "description": defaultDescription,
          ...(isFileNameOrArray ? ({ "fileName": (Array.isArray(contentFile)) ? contentFile?.map((ele) => (ele?.file?.name || ele?.name)) : contentFile?.name }) : {}),
          ...((this.state.id) ? { "uuid": defaultVariationUuid } : {}),
          "language": {
            "uuid": defaultLanguage
          },
          "color": {
            "uuid": defaultColor
          },
          "isDefault": true,
          // "extUrlLink":this.state.isTextUrl === "1" ? htmlRichText : urlLink,

          "extUrlLink": urlLink,
        },
        "keywords": selectedKeywords?.join(','),
        ...((thumbnailImage?.name ?? false) ? { "thumbnailFileName": thumbnailImage.name } : {}),
        "contentCreator": {
          "uuid": creator
        },
        "defaultLanguage": {
          "uuid": defaultLanguage
        },

        "source": source,

        "duration": duration,
        "viewCount": viewCount,
        "shareCount": shareCount,
        "savedCount": savedCount,
        "likeCount": likeCount,
        "downloadCount": downloadCount,
        // "displayPositionsNotAllowed": displayPositionsNotAllowed?.join(','),
        "branding": {
          "uuid": branding
        },
        "displayPosition": displayPosition?.map((item, i) => { return ({ "uuid": item }) }),
        ...(this.state.amc && {
          "amc": {
            "uuid": this.state.amc
          }
        }),
        // "publishedAt": moment(this.state?.publishedAt.toISOString()).format('MM-DD-YYYY'),
        "publishedAt": this.state?.publishedAt,
        "category": {
          "uuid": category
        },
        ...(subCategory && {
          "subCategory": {
            "uuid": subCategory
          }
        }),
        ...(targetedContent.length > 0 ? { "targetedContent": targetedContent?.map((item, i) => { return ({ "uuid": item }) }) } : {}),
        ...(campaign.length > 0 ? { "campaign": campaign?.map((item, i) => { return ({ "uuid": item }) }) } : {}),
        "isPremium": isPremium === "true" ? true : false,
        "isVerified": isVerified === "true" ? true : false,
        "contentVariation": variations?.map((item, i) => {

          const isVariationFileNameOrArray = (
            Array?.isArray(item?.content) &&
            item.content?.filter((e) => e.file?.name)?.length > 0
          );

          return (
            {
              "title": item.title,
              "description": item.description,
              ...((item?.content ?? false) ? { "fileName": isVariationFileNameOrArray ? item?.content?.map((ele) => ele.file?.name) : item?.content } : {}),
              ...((this.state.id) ? { "uuid": item.variationsUuid } : {}),
              "language": {
                "uuid": item.language
              },
              "color": {
                "uuid": item.color
              },
              "isDefault": false,
              "extUrlLink": item.urlLink,
            }
          )
        }),

        "ageGroup": this.state?.ageGroup?.join(',') || "",
        "gender": this.state.gender?.join(','),
        "employmentType": this.state?.employmentType?.join(',') || "",
        "incomeGroup": this.state?.incomeGroup?.join(','),
        "maritalStatus": this.state?.maritalStatus?.join(',') || '',
        "goal": this.state?.goal?.join(',') || '',
        "riskAppetide": this.state?.riskAppetite?.join(',') || '',
        "financialUnderstanding": this.state?.financialUnderstanding?.join(',') || '',
      }
    }

    let variatiSubImages = variations.map((item, i) => { return (item.fileImage) });
    const data = new FormData();
    data.append("key", JSON.stringify(eventData));

    thumbnailImage && data.append("file", thumbnailImage);
    (contentFile || contentFile?.length > 0) && Array.isArray(contentFile) ? contentFile?.forEach((ele) => data.append("file", ele.file)) : data.append("file", contentFile);
    variatiSubImages && variatiSubImages.forEach((item, i) => {
      if ((item || item?.length > 0) && Array?.isArray(item)) {
        item?.forEach((ele) => data.append("file", ele))
      } else {
        item && data.append("file", item)
      }
      // console.log(item);

    })
    // const mainData={
    //   key:JSON.stringify(eventData),
    //   file:attachmentsFiles
    // }

    // console.log(data)
    // console.log(eventData)
    if (this.state.id) {
      const response = await putAuthCommonApi(`content/${id}`, data);
      if (response.status < 250 && response.status > 199) {
        // toast.success("Successfully Updated.")
        this.setState({ resLoading: false });
        this.props.history.push({ pathname: `/content` });
      } else {
        this.setState({ resLoading: false });
      }

    } else {
      const response = await postAuthCommonApi('/content', data);
      // console.log(response);
      if (response.status < 250 && response.status > 199) {
        this.setState({ resLoading: false });
        this.props.history.push({ pathname: `/content` });
        // toast.success("Successfully saved.")
      }
      else {
        // toast.success("Errer is get sucess in Post ai")
        this.setState({ resLoading: false });
      }

    }

    // addContentApi(data, this.props.ctx, this.props.onHide);


  }
  // todo---------------------- POST API END ---------------------------------------


  keywordsChangeHandler = (info) => {
    this.setState({ search: info })
  }
  render() {
    const { accept, isUploadContentFile, uploadContentFile, targetedList, subCategoryOptions, roleType, access, isSocialMedia, editorState, htmlRichText, resLoading, id, uploadFileName, contentTypeOptions, colorOption, selectedKeywords, categoryOptions, campaignOption, campaign, amcOption, amc, targetedContent, targetedContentOption, languageOption, brandingTypeOption, displayPositionOptions, socialMediaOption, uploadSectionOptions } = this.state;
    // console.log('accept', accept);
    return (
      <section className="add-section">

        <ComponentHeader
          title={this.state.id ? 'Edit Content' : "Add Content"}
          // primaryBtn={access.createAccess ? "Save" :''}
          primaryBtn={"Save"}
          isBackArrow={true}
          resLoading={resLoading}
          isReturn="/content"
          handlePrimaryBtn={() => this.handleSave()}
        />
        <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "uploadSection")}
                label="Upload Section"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Upload section cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Upload Section",
                    options: uploadSectionOptions,
                    multiple: true,
                    searchable: true,
                    disabled: (userAmcUuids()?.length > 0),
                    onChangeCallback: (onBlur) => {

                      onBlur(this.state.uploadSection);
                      const isExest = uploadSectionOptions?.find(item => this.state.uploadSection?.includes(item.value))
                      // console.log(this.state.uploadSection,' is selected thate this',uploadSectionOptions,isExest)
                      this.setState({ isSocialMedia: isExest?.code?.includes(SOCIALMEDIA) })

                    },
                  },
                }}
              />
              {this.state.uploadSection?.length > 0 &&
                <ClearButton onClick={() => this.setState({ uploadSection: [] })} />
              }
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "contentType")}
                label="Content Type"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Content type cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Content Type",
                    options: contentTypeOptions,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.contentType);
                      const result = contentTypeOptions.find(({ value }) => value === this.state.contentType);
                      // console.log(result)
                      this.setState({ accept: result.contentCode, contentFile: "", uploadContentFile: '' })
                    },
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "isPremium")}
                label="Is Premium"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Premium Field cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    options: YesNoOptions,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.isPremium);
                    },
                  },
                }}
              />
            </Col>
            {isSocialMedia &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "socialMedia")}
                  label="Social Media"

                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Select Social Media",
                      options: socialMediaOption,
                      multiple: true,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.socialMedia);
                      },
                    },
                  }}
                />
                {this.state.socialMedia?.length > 0 &&
                  <ClearButton onClick={() => this.setState({ socialMedia: [] })} />
                }
              </Col>
            }

            <Col md={4}>
              <SelectKeywords required={false} label="Keywords" placeholder={'Enter Keywords'} onchangeHandler={(data) => this.keywordsChangeHandler(data)} selectedKeyWords={this.state.selectedKeywords} options={this.state.keywordOption} getKeys={(data) => this.setState({ selectedKeywords: data })} />
            </Col>

            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "category")}
                label="Category"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Category Field cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    options: categoryOptions,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.category);
                    },
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "subCategory")}
                label="Sub Category"

                control={{
                  type: SelectControl,
                  settings: {
                    options: subCategoryOptions,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.subCategory);
                    },
                  },
                }}
              />
            </Col>

            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "campaign")}
                label="Campaign"

                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Campaign",
                    options: [
                      { value: "ALL", label: "All" },
                      ...campaignOption.map(item => ({
                        value: item.value,
                        label: item.label,
                      })),
                    ],
                    multiple: true,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      const selectedCampaign = this.state.campaign || [];

                      if (selectedCampaign.includes("ALL")) {

                        const allValues = campaignOption.map(item => item.value);
                        this.setState({
                          campaign: allValues,
                        });
                        onBlur(allValues);
                      } else {

                        onBlur(selectedCampaign);
                      }
                    },
                  },
                }}
              />
              {this.state.campaign?.length > 0 &&
                <ClearButton onClick={() => this.setState({ campaign: [] })} />
              }
            </Col>


            <Col md={4}>
              <ImageUploader

                id="ThumbnailImage"
                name={typeof (this.state.thumbnailImage) == 'object' ? this.state.uploadImage : this.state.thumbnailImage}
                onChange={(e) => this.imageUploader(e, "thumbnailImage", "uploadImage", "IMAGEFILE", 'thumb')}
                onDelete={() => this.setState({ thumbnailImage: "" })}
                label="Upload Thumbnail Image"
                size="373 x 187"
                acceptedFileType={IMAGEFILETYPE}
              />
            </Col>
            {/* {this.state.accept === "VIDEOS" && */}

            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "source")}
                label="Source"
                // required
                // validations={[
                //   {
                //     validate: FormValidator.isRequired,
                //     message: "Please enter source",
                //   },
                // ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "e.g. ' Youtube '",
                  },
                }}
              />
            </Col>
            <Col md={2}>

              <FormElement
                valueLink={this.linkState(this, "publishedAt")}
                label="Published At"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Please enter publish at in DD-MM-YYYY format",
                  },
                ]}
                control={{
                  type: DatePickerControl,

                  settings: {
                    placeholder: "DD-MM-YYYY",


                  },
                }}
              />
            </Col>
            {
              (this.state.accept === "VIDEOS" || this.state.accept === "PODCASTS" || this.state.accept === "REELS") &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "duration")}
                  label="Duration"
                  required
                  validations={[
                    {
                      validate: FormValidator.isDuration,
                      message: "Please enter duration in hh:mm:ss format",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "HH:MM:SS",
                    },
                  }}
                />
              </Col>
            }

          </Row>
          {
            this.state.accept === "ARTICLES" && <>

              <hr />
              <br />
              <h3 className="inter-semibold f-s-19 lh-24 grey-757">Articles/Blogs</h3>
              <br />
              <br />

              <Row>
                <Col md={6}>
                  <FormElement
                    valueLink={this.linkState(this, "isTextUrl")}
                    label="Add article Content/url"
                    required

                    control={{
                      type: CustomRadio,
                      settings: {
                        radioId: "ArticlesBlogs",
                        radioName: "ArticlesBlogs",
                        options: [
                          {
                            key: "1",
                            label: "Text Editor"
                          },
                          {
                            key: "2",
                            label: "Url Link (if it is a 3rd party article)"
                          }
                        ],
                        // isInline: true,
                      }
                    }}
                  />
                </Col>
                {this.state.isTextUrl === '1' &&
                  <Col md={12}>

                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName bg-white inter-regular f-s-14 lh-15 grey-757"
                      onEditorStateChange={(editorState) => {
                        const htmlContent = draftToHtml(convertToRaw(editorState?.getCurrentContent()));
                        // console.log('This  is content of TextEditer :--',htmlContent)
                        this.setState({
                          editorState,
                          htmlRichText: htmlContent,
                        });


                      }}
                    />
                    {/* <textarea
                  disabled
                  value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                /> */}
                  </Col>


                }
                {this.state.isTextUrl === '2' &&
                  <Col md={8}>
                    <FormElement
                      valueLink={this.linkState(this, "urlLink")}
                      label="URL Link "

                      control={{
                        type: CustomTextControl,
                        settings: {
                          placeholder: "Enter URL",
                        },
                      }}
                    />
                  </Col>
                }


              </Row>
            </>
          }
          <hr />
          <br />
          <h3 className="inter-semibold f-s-19 lh-24 grey-757 section-title">Default Variations</h3>
          <br />
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "defaultTitle")}
                label="Title"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Please enter Title",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter Title",
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "defaultDescription")}
                label="Description"
                helpText="Description can't be more than 4000 character"

                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Please enter description",
                    as: "textarea",
                    rows: 5,
                  },
                }}
                classes={{
                  inputField: "custom-input",
                  label: "custom-label",
                }}
              />
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "defaultLanguage")}
                label="Default Language"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Default language cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Default Language",
                    options: languageOption,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.defaultLanguage);
                    },
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, 'defaultColor')}
                label="Default Color"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Color cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Color",
                    options: colorOption,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.defaultColor);
                    },
                  },
                }}
              />
            </Col>
            {this.state.contentType && this.state.accept !== "ARTICLES" &&
              <Col md={4}>
                {CAROUSEL === this.state.accept ?
                  <CarouselFileUploader
                    id={'mainContent'}
                    isUploadContentFile={uploadContentFile || []}
                    isPdf={Array.isArray(uploadContentFile) ? false : true}
                    updateContentFile={(imgArr) => {
                      if (imgArr[0]?.file?.type === "application/pdf" || isPDF(imgArr[0]?.file)) {
                        this.setState({ contentFile: imgArr[0]?.file });
                      } else {
                        this.setState({ contentFile: imgArr });
                      }
                    }}
                  />
                  :
                  (isUploadContentFile ?
                    <div className="is-edit-content  h-100">
                      <label className="form-label">
                        Upload Content
                        {/* <span className="red-D43"> *</span> */}
                      </label>
                      <div className="d-flex gap-12 align-items-center">
                        <a href={isUploadContentFile} className="primary-btn mb-5 d-block" target="_blank" >
                          View content
                        </a>
                        <Image src={closeIcon} onClick={() => { this.setState({ isUploadContentFile: '', }) }} className="edit-cancel" />
                      </div>
                    </div>
                    :
                    <ImageUploader
                      id="contentImage"

                      name={((typeof (this.state.contentFile) === 'object') || (this.state.id && this.state.accept !== 'VIDEOS')) ? this.state.uploadContentFile : ''}
                      onChange={(e) => { this.imageUploader(e, "contentFile", "uploadContentFile", accept) }}

                      onDelete={() => this.setState({ contentFile: "", uploadContentFile: '', urlLink: "" })}
                      label={"Upload Content"}

                      acceptedFileType={ACCEPTEDFILETYPE[accept]}
                      uploadFileName={uploadFileName}
                      NameContentFile={this.state?.uploadContentName}
                    />)
                }
              </Col>
            }
            {this.state.contentType && this.state.accept !== "ARTICLES" &&

              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "urlLink")}
                  label="URL Link "

                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter URL",
                    },
                  }}
                />
              </Col>
            }
          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "creator")}
                label="Creator"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Creator cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Creator",
                    options: this.state.creatorList,
                    multiple: false,
                    searchable: true,
                    disabled: (userAmcUuids()?.length > 0),
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.creator);
                      const isExest = this.state.creatorList?.find(item => this.state.creator?.includes(item.value))
                      // console.log(this.state.creator,' is selected thate this',this.state.creatorList,isExest)
                      this.setState({ isAmcShow: isExest?.label?.includes('Mutual Fund Company') })
                    },
                  },
                }}
              />
            </Col>
            {((this.state.isAmcShow) || (userAmcUuids()?.length > 0)) &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "amc")}
                  label="AMC"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "AMC cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Enter AMC Name",
                      // options: (roleType === MAKER) ? amcOption?.filter((ele) => ["12741c18-4815-4904-999a-4ecdb1dd2670"].some(item => item === ele.value)) : amcOption,
                      options: (userAmcUuids()?.length > 0) ? amcOption?.filter((ele) => userAmcUuids()?.some(item => item === ele.value)) : amcOption,//if adil give data then use this
                      multiple: false,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.amc);
                      },
                    },
                  }}
                />
              </Col>
            }
          </Row>
          <hr />
          <h3 className="inter-semibold primary f-s-19 lh-24 section-title">Targeted Content</h3>
          <br />
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "targetedContent")}
                label="Playlist Name"
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Playlist Name",
                    options: [
                      { value: "ALL", label: "All" },
                      ...targetedContentOption.map(item => ({
                        value: item.value,
                        label: item.label,
                      })),
                    ],
                    multiple: true,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      const selectedContent = this.state.targetedContent || [];

                      if (selectedContent.includes("ALL")) {

                        const allValues = targetedContentOption.map(item => item.value);
                        this.setState({
                          targetedContent: allValues,
                        });
                        onBlur(allValues);
                      } else {

                        onBlur(selectedContent);
                      }
                    },

                  },
                }}
              />

              {this.state.targetedContent?.length > 0 && (
                <ClearButton onClick={() => this.setState({ targetedContent: [] })} />
              )}
            </Col>

            {targetedList?.list?.map((item, i) => {
              const title = camelCaseTitle(item?.title);

              const targetOption = [
                { label: "All", value: "ALL" },
                ...item.types?.map((ele) => ({ label: ele, value: ele })),
              ];

              return (
                <Col md={4} key={i}>
                  <FormElement
                    valueLink={this.linkState(this, `${title}`)}
                    label={item.title}
                    control={{
                      type: SelectControl,
                      settings: {
                        placeholder: "Select " + item.title,
                        options: targetOption,
                        multiple: true,
                        searchable: true,
                        onChangeCallback: (onBlur) => {
                          const selectedOptions = this.state[title] || [];

                          if (selectedOptions.includes("ALL")) {

                            const allValues = item.types.map((ele) => ele);
                            this.setState({ [title]: allValues });
                            onBlur(allValues);
                          } else {

                            onBlur(selectedOptions);
                          }
                        },
                      },
                    }}
                  />

                  {this.state[title]?.length > 0 && (
                    <ClearButton onClick={() => this.setState({ [title]: [] })} />
                  )}
                </Col>
              );
            })}



          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <FormElement
                valueLink={this.linkState(this, "branding")}
                label="Branding"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Branding cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select Branding",
                    options: brandingTypeOption,
                    multiple: false,
                    searchable: true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.branding);
                      const isExest = brandingTypeOption?.find(item => this.state.branding?.includes(item.value))
                      // console.log(this.state.branding,' is selected thate this',brandingTypeOption,isExest)
                      this.setState({ isNoBranding: isExest?.label?.includes(NoBranding), })
                      if (isExest?.label?.includes(NoBranding)) {
                        this.setState({ displayPosition: [], isPositionAllow: false, displayPositionsAllowed: [] })
                      }
                    },
                  },
                }}
              />
            </Col>
            {!this.state.isNoBranding &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "displayPosition")}
                  label="Display Position"
                  required
                  helpText="Note - For Start/End - If you select Start/End position branding will be done in the center of the page"
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Display position field cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Select Display Position",
                      options: [
                        { label: "All", value: "ALL" },
                        ...displayPositionOptions.slice(0, 3),
                      ],
                      multiple: true,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        const { displayPosition } = this.state;
                        const allOptionSelected = displayPosition?.includes("ALL");


                        if (allOptionSelected) {
                          const allValues = displayPositionOptions.slice(0, 3).map(item => item.value);
                          this.setState({ displayPosition: allValues, isPositionAllow: true });
                          onBlur(allValues);
                        } else {

                          onBlur(displayPosition);
                          const isExist = displayPositionOptions.find(item =>
                            displayPosition.includes(item.value)
                          );
                          this.setState({
                            isPositionAllow: isExist?.code?.includes(FULLVIDEO),
                          });
                        }
                      },
                    },
                  }}
                />
                {this.state.displayPosition?.length > 0 && (
                  <ClearButton onClick={() => this.setState({ displayPosition: [], isPositionAllow: false })} />
                )}
              </Col>

            }
            {
              this.state.isPositionAllow &&

              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "displayPositionsAllowed")}
                  label="Display Positions Allowed"
                  control={{
                    type: SelectControl,
                    settings: {
                      placeholder: "Select which positions allowed",
                      options: [
                        { value: "ALL", label: "All" },
                        ...selectPosition.map(item => ({ value: item.val, label: item.name })),
                      ],
                      multiple: true,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        const selectedPositions = this.state.displayPositionsAllowed || [];

                        if (selectedPositions.includes("ALL")) {

                          const allValues = selectPosition.map(item => item.val);
                          this.setState({
                            displayPositionsAllowed: allValues,
                          });
                          onBlur(allValues);
                        } else {

                          onBlur(selectedPositions);
                        }
                      },
                    },
                  }}
                />

                {this.state.displayPositionsAllowed?.length > 0 && (
                  <ClearButton onClick={() => this.setState({ displayPositionsAllowed: [] })} />
                )}
              </Col>

            }
          </Row>
          {(roleType !== MAKER || isFinpediaUser()) &&
            <>
              <hr />
              <br />
              <h3 className="inter-semibold f-s-19 lh-24 section-title">Stats</h3>
              <br />
              <Row>
                <Col md={4}>
                  <FormElement
                    valueLink={this.linkState(this, "viewCount")}
                    label="View Count"
                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter View Count",
                      },
                    }}
                  />
                </Col>
                <Col md={4}>
                  <FormElement
                    valueLink={this.linkState(this, "shareCount")}
                    label="Share Count"
                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter Share Count",
                      },
                    }}
                  />
                </Col>
                <Col md={4}>
                  <FormElement
                    valueLink={this.linkState(this, "savedCount")}
                    label="Saved Count"

                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter Saved Count",
                      },
                    }}
                  />
                </Col>

                <Col md={4}>
                  <FormElement
                    valueLink={this.linkState(this, "likeCount")}
                    label="Like Count"

                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter Like Count",
                      },
                    }}
                  />
                </Col>
                <Col md={4}>
                  <FormElement
                    valueLink={this.linkState(this, "downloadCount")}
                    label="Download Count"

                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Enter Download Count",
                      },
                    }}
                  />
                </Col>
              </Row>
            </>
          }
          <hr />
          <br />

          <br />
          <Row>
            {
              access.updateAccess &&
              <Col md={4}>
                <FormElement
                  valueLink={this.linkState(this, "isVerified")}
                  label="Is Verified"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      options: YesNoOptions,
                      multiple: false,
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.isVerified);
                      },
                    },
                  }}
                />
              </Col>
            }

          </Row>
          {/* <hr /> */}
          <br />
          <h3 className="inter-semibold f-s-19 lh-24 section-title">Variations</h3>
          <br />
          {
            this.state.variations.map((variation, index) => {
              return (
                <>
                  {
                    index > 0 &&
                    <><hr /><br /></>
                  }
                  <p className="variation-srno">Variation - {index + 1} </p>
                  <Row>
                    <Col md={4}>
                      <FormElement
                        valueLink={this.linkState(this, `variations.${index}.title`)}
                        label="Title"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "Please enter title",
                          },
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter Title",
                          },
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <FormElement
                        valueLink={this.linkState(this, `variations.${index}.description`)}
                        label="Description"
                        helpText="Description can't be more than 4000 character"

                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Please enter description",
                            as: "textarea",
                            rows: 4,
                          },
                        }}
                        classes={{
                          inputField: "custom-input",
                          label: "custom-label",
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <FormElement
                        valueLink={this.linkState(this, `variations.${index}.language`)}
                        label="Language"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "Language cannot be empty",
                          },
                        ]}
                        control={{
                          type: SelectControl,
                          settings: {
                            placeholder: "Select Language",
                            options: languageOption,
                            multiple: false,
                            searchable: true,
                            onChangeCallback: (onBlur) => {
                              onBlur(this.state.variations[index].language);
                            },
                          },
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <FormElement
                        valueLink={this.linkState(this, `variations.${index}.color`)}
                        label="Color"
                        required
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "Color cannot be empty",
                          },
                        ]}
                        control={{
                          type: SelectControl,
                          settings: {
                            placeholder: "Select Color",
                            options: colorOption,
                            multiple: false,
                            searchable: true,
                            onChangeCallback: (onBlur) => {
                              onBlur(this.state.variations[index].color);
                            },
                          },
                        }}
                      />
                    </Col>


                    <Col md={4}>
                      <FormElement
                        valueLink={this.linkState(this, `variations.${index}.urlLink`)}
                        label="URL Link"

                        control={{
                          type: CustomTextControl,
                          settings: {
                            placeholder: "Enter URL",
                          },
                        }}
                      />
                    </Col>

                    <Col md={3}>

                      {CAROUSEL === this.state.accept ?
                        <CarouselFileUploader
                          id={index}
                          isUploadContentFile={this.state.variations[index].fileImage || []}
                          isPdf={Array.isArray(this.state.variations[index].fileImage) ? false : true}
                          updateContentFile={(imgArr) => {
                            let variations = this.state.variations;
                            let currentVariations = variations[index];


                            if (imgArr[0]?.file?.type === "application/pdf") {
                              // this.setState({ contentFile: imgArr[0]?.file });
                              currentVariations = {
                                ...currentVariations,
                                content: imgArr[0]?.name,
                                fileImage: imgArr[0]?.file,
                                // fileImageUrl: URL.createObjectURL(fileData)
                              }
                              variations.splice(index, 1, currentVariations);
                              this.setState({ variations })
                            } else {
                              currentVariations = {
                                ...currentVariations,
                                content: imgArr?.map((ele) => ele?.name),
                                fileImage: imgArr?.map((ele) => ele?.file),
                                // fileImageUrl: URL.createObjectURL(fileData)
                              }
                              // this.setState({ contentFile: imgArr });
                              variations.splice(index, 1, currentVariations);
                              this.setState({ variations })
                            }

                          }}
                        />
                        : (
                          this.state.variations?.[index]?.fileImage ?
                            <div className="is-edit-content  h-100">
                              <label className="form-label">
                                Upload Content
                                {/* <span className="red-D43"> *</span> */}
                              </label>
                              <div className="d-flex gap-12 align-items-center">
                                <a href={this.state.variations?.[index]?.fileImage} className="primary-btn mb-5 d-block" target="_blank" >
                                  View content
                                </a>
                                <Image src={closeIcon} onClick={() => {
                                  let variations = this.state.variations;
                                  let currentVariations = variations[index];
                                  if (currentVariations) {
                                    currentVariations = {
                                      ...currentVariations,
                                      fileImageUrl: '',
                                    }
                                  }
                                  variations.splice(index, 1, currentVariations);
                                  // console.log(currentVariations)
                                  this.setState({ variations })
                                }}
                                  className="edit-cancel" />
                              </div>
                            </div>
                            :
                            <ImageUploader
                              // required={true}
                              id={`contentIamge${index}`}

                              name={((typeof (this.state.variations[index]?.fileImage) === 'object') || (this.state.id && this.state.accept !== 'VIDEOS')) ? this.state.variations[index]?.fileImageUrl : ''}

                              onChange={(fileData) => {
                                let variations = this.state.variations;
                                let currentVariations = variations[index];
                                const timestamp = Date.now();

                                currentVariations = {
                                  ...currentVariations,
                                  content: fileData.name,
                                  fileImage: fileData,
                                  fileImageUrl: URL.createObjectURL(fileData)
                                }
                                variations.splice(index, 1, currentVariations);
                                // console.log(currentVariations)
                                this.setState({ variations })
                              }}
                              onChangeMultiple={true}
                              onDelete={() => {
                                let variations = this.state.variations;
                                let currentVariations = variations[index];
                                currentVariations = {
                                  ...currentVariations,
                                  content: "",
                                  fileImage: "",
                                  fileImageUrl: "",
                                  urlLink: "",
                                }
                                variations.splice(index, 1, currentVariations);
                                // console.log(currentVariations)
                                this.setState({ variations })
                              }}
                              label={"Upload Content"}
                              acceptedFileType={ACCEPTEDFILETYPE[accept]}
                              // required={id ? false : true}
                              uploadFileName={this.state.variations[index].content || ""}
                            />
                        )
                      }


                    </Col>
                    <Col sm={1}>
                      {/* {
                index > 0 && */}
                      <Image src={closeIcon} className="remove-icon" onClick={() => this.removeVariation(index)} />
                      {/* } */}

                    </Col>
                  </Row>
                </>
              )
            })
          }
          <Button onClick={this.addMore} className="primary-btn">Add More +</Button>
        </Form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
  getAllCategoryApi,
  // getAllSubCategoryApi,
  getContentTypeApi,
  getLanguageNameApi,
  getAllColorApi,
  getKeywordNameApi,
  getAllCampaignApi,
  getAllAmcApi,
  getAllTargetedListApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditContent);



