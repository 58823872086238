import React from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import { API_LIMIT, START_PAGE, SUPER_ADMIN } from "../../utils/Constant";
import { ComponentHeader } from "../common";
import { getCustomerNameApi } from "./Api";
import { replaceHistory, userRoleType } from "../../utils/ReusableFunctions";
class Customers extends BaseReactComponent {
  constructor(props) {
    super(props);
    const roleType = userRoleType() || SUPER_ADMIN;
    this.state = {
      roleType,
      search: "",
      dummyData: [],
      searchResult: [],
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      delete: false,
      deleteData: {},
      editData: "",
      showEdit: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.push({
      pathname: this.props.location.pathname,
      search: `?p=${this.state.currentPage || START_PAGE}`,
    });
    this.props.getCustomerNameApi(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
    const prevSearch = prevParams.get("search") || "";

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const search = params.get("search") || "";

    if (prevPage !== page || prevSearch !== search) {
      this.setState({ currentPage: page, search }, () => {
        this.props.getCustomerNameApi(this);

      });
    }
  }


  clearSearch = () => {
    this.onChangeMethod({ search: "" })
  }

  onChangeMethod = (value) => {
    this.setState({ searchText: value.search, currentPage: START_PAGE }, () => {
      replaceHistory(this.props.history, START_PAGE, value.search)
    })
  }

  getDetailsUser = (id) => {

    this.props.history.push({ pathname: `/ads-customer-details/${id}`, state: { "id": id } });
  }

  getSsoPartnerDetails = (id) => {
    const type = sessionStorage.setItem("type", "admin-web-user")
    this.props.history.push({ pathname: `/sso/web-user/details/0/${id}`, state: { "id": id, "type": "admin-web-user" } });

  }



  render() {

    const { customerList, totalPages, currentPage } = this.props.customersState;

    return (
      <>

        <section className="request-section">
          <ComponentHeader
            title="Customers"
            isSearch={true}
            placeholder={"Search Customer"}
            onChangeMethod={this.onChangeMethod}
            clearSearch={() => this.clearSearch()}
          />

          <div className="commom-table-wrapper">
            <CustomTable
              tableData={customerList?.list || []}
              columnList={[

                {
                  coumnWidth: 250,
                  labelName: "Full Name",
                  dataKey: "firstName",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "firstName") {
                      const firstName = rowData.firstName ?? "";
                      const lastName = rowData.lastName ?? "";
                      return `${firstName} ${lastName}`.trim();
                    }
                  },
                },
                {
                  coumnWidth: 250,
                  labelName: "Email Id",
                  dataKey: "emailAddress",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "emailAddress") {
                      return rowData.emailAddress || 'Na';
                    }
                  },
                },

                {
                  coumnWidth: 250,
                  labelName: "Mobile Number",
                  dataKey: "mobileNumber",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "mobileNumber") {
                      return rowData.mobileNumber || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 250,
                  labelName: "Company Name",
                  dataKey: "companyName",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "companyName") {
                      return rowData.companyName || 'Na';
                    }
                  },
                },
                {
                  coumnWidth: 300,
                  labelName: "SSO Partner Name",
                  dataKey: "firstName",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "firstName") {
                      if (rowData?.sso_partner_id) {
                        const firstName = rowData.firstName ?? "";
                        const lastName = rowData.lastName ?? "";
                        return `${firstName} ${lastName}`.trim();
                      }
                      return "";
                    }
                  },
                },
                {
                  coumnWidth: 200,
                  labelName: "Is Premium",
                  dataKey: "isPremium",
                  className: "name",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "isPremium") {
                      return rowData?.isPremium ? "Yes" : "No";
                    }
                  },
                },
                {
                  coumnWidth: 100,
                  labelName: "Liked",
                  dataKey: "userActivities",
                  className: "activities",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "userActivities" && Array.isArray(rowData.userActivities.userActivities)) {
                      const likedCount = rowData.userActivities.userActivities.filter(activity => activity.type === "Liked").length;
                      return likedCount > 0 ? likedCount : '0';
                    }
                    return '0';
                  },
                },
                {
                  coumnWidth: 100,
                  labelName: "Shared",
                  dataKey: "userActivities",
                  className: "activities",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "userActivities" && Array.isArray(rowData.userActivities.userActivities)) {

                      const sharedCount = rowData.userActivities.userActivities.filter(activity => activity.type === "Shared").length;
                      return sharedCount > 0 ? sharedCount : '0';
                    }
                    return '0';
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Downloaded",
                  dataKey: "userActivities",
                  className: "activities",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "userActivities" && Array.isArray(rowData.userActivities.userActivities)) {

                      const downloadCount = rowData.userActivities.userActivities.filter(activity => activity.type === "Downloaded").length;
                      return downloadCount > 0 ? downloadCount : '0';
                    }
                    return '0';
                  },
                },
                {
                  coumnWidth: 150,
                  labelName: "Cobrand",
                  dataKey: "userActivities",
                  className: "activities",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "userActivities" && Array.isArray(rowData.userActivities.userActivities)) {

                      const cobrandCount = rowData.userActivities.userActivities.filter(activity => activity.type === "Cobrand").length;
                      return cobrandCount > 0 ? cobrandCount : '0';
                    }
                    return '0';
                  },
                },

                {
                  coumnWidth: 300,
                  labelName: "Action",
                  dataKey: "",
                  className: "",
                  isCell: true,
                  cell: (rowData, dataKey) => {
                    if (dataKey === "") {
                      return (
                        <>
                          <Button onClick={() => {
                            if (rowData?.sso_partner_id) {
                              this.getSsoPartnerDetails(rowData?.id);
                            } else {
                              this.getDetailsUser(rowData?.uuid);
                            }
                          }} className="primary-btn inter-medium f-s-14 lh-18">
                            View Detail
                          </Button>

                        </>

                      );
                    }
                  },
                },

              ]}
              message="No data found" // For Pagination
              history={this.props.history}
              location={this.props.location}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </div>
        </section>
      </>
    );
  }
}


const mapStateToProps = state => ({
  customersState: state.CustomersState,
});
const mapDispatchToProps = {
  getCustomerNameApi

};
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
