import React, { useEffect, useState } from "react";
import { Form, Image } from "react-bootstrap";
import close from '../../assets/images/greay-cancel-icon.svg';

export default function SelectLocation({
  label,
  onchangeHandler = () => { },
  placeholder,
  options = [],
  selectedKeyWords = [],
  required = true,
  getKeys = () => { console.log("default call Keys"); },
  validations = []
}) {
  const [keyword, setKeyword] = useState("");
  const [selectedOption, setSelectedOption] = useState(selectedKeyWords || []);
  const [failedValidation, setFailedValidation] = useState(null);

  useEffect(() => {
    getKeys(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    setSelectedOption(selectedKeyWords);
  }, [selectedKeyWords]);

  const validate = (value) => {
    const failedValidation = validations.find(
      (validation) => !validation.validate(value)
    );
    setFailedValidation(failedValidation ? failedValidation.message : null);
    return !failedValidation;
  };

  const keywordChangeHandler = (e) => {
    const value = e.target.value;
    setKeyword(value);
    onchangeHandler(value);
  };

  const handleBlur = () => {
    // Validate the current state (keyword or selected options)
    validate(keyword || selectedOption.length > 0 ? selectedOption : null);
  };

  const selectHandler = (data) => {
    setKeyword("");
    const updatedOptions = [...selectedOption, data];
    setSelectedOption(updatedOptions);
    validate(updatedOptions); // Validate after selecting
  };

  const removeKeyword = (data) => {
    const newArray = selectedOption.filter((item) => item !== data);
    setSelectedOption(newArray);
    validate(newArray); // Validate after removing
  };

  return (
    <div>
      <div className="img-form-group">
        <label className="form-label">
          {label}
          {required && <span> *</span>}
        </label>
        <div className="keywords-dropdown-frame">
          <div className="keywords-input-fields">
            <input
              type="text"
              placeholder={placeholder}
              style={{ border: failedValidation ? "1px solid red" : "" }}
              className={`input-box-keywords`}
              name="keyword"
              value={keyword}
              disabled={selectedOption.length === 1}
              onChange={keywordChangeHandler}
              onBlur={handleBlur} // Trigger validation on blur
            />
            {keyword && (
              <Image
                src={close}
                onClick={() => setKeyword("")}
                alt="cancel icon"
                className="cancel-field"
              />
            )}
          </div>
          {options.length > 0 && keyword && (
            <ul className="autocomplete-keywords-dropdown">
              {options.map((item, i) => (
                <li onClick={() => selectHandler(item.label)} key={i}>
                  {item.label}
                </li>
              ))}
            </ul>
          )}
          {failedValidation && (
            <Form.Text className={`${failedValidation ? "has-error" : ""}`}>
              {failedValidation}
            </Form.Text>
          )}
          <div className="show-selected-key-frame">
            {selectedOption.map((item, i) => (
              <span key={i}>
                {item}{" "}
                <Image
                  src={close}
                  onClick={() => removeKeyword(item)}
                  alt="cancel icon"
                  className="cancel-key"
                />
              </span>
            ))}

          </div>

        </div>
        {/* Display validation message if validation fails */}

      </div>
    </div>
  );
}
