import React from "react";

import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";

import { Row, Col } from "react-bootstrap";
import { CustomModal, Spinner } from "../common";
import { addKeywordNameApi, updateKeywordApi } from "./Api";

class KeywordForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;
    this.state = {
      keyword: data?.keyword || "",
      resLoading:false,
    };
  }
  componentDidMount() { }
  onValidSubmit = () => {
    this.setState({resLoading:true})
    let data = {
      "data": {
        "keyword": this.state.keyword
      }
    };
    if (this.props.editData?.uuid) {
      updateKeywordApi(JSON.stringify(data), this.props.this, this.props.onHide, this.props.editData.uuid,this)
    } else {
      addKeywordNameApi(JSON.stringify(data), this.props.this, this.props.onHide,this)
    }
  }
  render() {
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={(this.props.editData?.uuid)?'Edit keyword':"Add keyword"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "keyword")}
                label="Keyword"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Keyword",
                  },
                }}
              />
            </Col>
          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
            {this.state.resLoading?<span className="d-flex align-items-center gap-12"> <Spinner/> Saving...</span> :"Save"}
          </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

export default KeywordForm;
