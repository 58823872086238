import React, { Component } from 'react';
import { connect } from "react-redux";
import { getDashboardApi } from "./Api";
import { ComponentHeader } from '../common';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Row } from 'react-bootstrap';
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.getDashboardApi(this);
    }

    render() {
        const { dashboardList, totalPages, currentPage } = this.props.dashboardState;

        return (
            <section>
                <ComponentHeader
                    title="Dashboard"

                />
                <div className="dashboard-content-section">
                    <Row>
                        <Col sm={4} className='mb-5'>
                            <div className="dash-title">
                                <h5 className=' f-s-16 inter-medium lh-21'>Registered User Count </h5>
                                <p className='f-s-16 inter-regular lh-21'>{dashboardList?.registeredUserCount || 'Na'}</p>
                            </div>
                        </Col>
                        <Col sm={4} className='mb-5'>
                            <div className="dash-title">
                                <h5 className=' f-s-16 inter-medium lh-21'>Premium User Count </h5>
                                <p className='f-s-16 inter-regular lh-21'>{dashboardList?.premiumUserCount || 'Na'}</p>
                            </div>
                        </Col>
                        <Col sm={4} className='mb-5'>
                            <div className="dash-title">
                                <h5 className=' f-s-16 inter-medium lh-21'>Content Cobrand Count </h5>
                                <p className='f-s-16 inter-regular lh-21'>{dashboardList?.contentCobrandCount?.count || 'Na'}</p>
                            </div>
                        </Col>
                        <Col sm={4} className='mb-5'>
                            <div className="dash-title">
                                <h5 className=' f-s-16 inter-medium lh-21'>Amc Count </h5>
                                <p className='f-s-16 inter-regular lh-21'>{dashboardList?.amcCount || 'Na'}</p>
                            </div>
                        </Col>
                        <Col sm={4} className='mb-5'>
                            <div className="dash-title">
                                <h5 className=' f-s-16 inter-medium lh-21'>Content Download Count </h5>
                                <p className='f-s-16 inter-regular lh-21'>{dashboardList?.contentDownloadCount?.count || 'Na'}</p>
                            </div>
                        </Col>
                        <Col sm={4} className='mb-5'>
                            <div className="dash-title">
                                <h5 className=' f-s-16 inter-medium lh-21'>Non-Premium User Count </h5>
                                <p className='f-s-16 inter-regular lh-21'>{dashboardList?.nonPremiumUserCount || 'Na'}</p>
                            </div>
                        </Col>
                        <Col sm={4} className='mb-5'>
                            <div className="dash-title">
                                <h5 className=' f-s-16 inter-medium lh-21'>Targeted Content Count </h5>
                                <p className='f-s-16 inter-regular lh-21'>{dashboardList?.targetedContentCount || 'Na'}</p>
                            </div>
                        </Col>
                        <Col sm={4} className='mb-5'>
                            <div className="dash-title">
                                <h5 className=' f-s-16 inter-medium lh-21'>Language Count </h5>
                                <p className='f-s-16 inter-regular lh-21'>{dashboardList?.languageCount || 'Na'}</p>
                            </div>
                        </Col>
                        <Col sm={4} className='mb-5'>
                            <div className="dash-title">
                                <h5 className=' f-s-16 inter-medium lh-21'>Campaign Count </h5>
                                <p className='f-s-16 inter-regular lh-21'>{dashboardList?.campaignCount || 'Na'}</p>
                            </div>
                        </Col>


                    </Row>

                    <h3 className='inter-regular f-s-24 lh-29 mb-5 '>Content Types :-</h3>
                    <Row>
                        {Object.keys(dashboardList?.contentCountWithContentName || {}).map((item) => {
                            return (
                                <Col sm={4} className='mb-5'>
                                    <div className="dash-title">
                                        <h5 className=' f-s-16 inter-medium lh-21'>{item} </h5>
                                        <p className='f-s-16 inter-regular lh-21'>{dashboardList?.contentCountWithContentName[item] || 'Na'}</p>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>

                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    dashboardState: state.DashboardState
});
const mapDispatchToProps = {
    getDashboardApi
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));