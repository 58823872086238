import React, { Component } from "react";
import { Form, FormElement, FormValidator, CustomTextControl, BaseReactComponent, FormSubmitButton } from "../../utils/form";
import { Row, Col, Toast } from "react-bootstrap";
import { CustomModal, ImageUploader, Spinner } from "../common";
import { addLanguageNameApi, updateLanguageNameApi } from "./Api";
import { toast } from "react-toastify";

class LanguageForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;
    this.state = {
      languageName: data?.languageName || "",
      description: data?.description || "",
      thumbnailPath: "",
      thumbnailFile: null,
      bannerPath: "",
      bannerfile: null,
      circleImagePath: "",
      circleImageFile: null,
      uploadImage: data?.thumbnailPath || null,
      uploadImage2: data?.bannerPath || null,
      uploadImage3: data?.circleImagePath || null,
      resLoading: false,

    };
  }


  imageUploaser = (e, name) => {

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (name === "banner") {
      this.setState({ bannerPath: e.target.files[0].name, bannerfile: e.target.files[0], uploadImage2: imageUrl })
    } else if (name === "circleImage") {
      this.setState({ circleImagePath: e.target.files[0].name, circleImageFile: e.target.files[0], uploadImage3: imageUrl })
    } else {
      this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
    }
  };

  isBtnDisabled = () => {
    const { thumbnailPath, bannerPath, circleImagePath } = this.state;
    return !!(thumbnailPath || bannerPath || circleImagePath);
  };

  onValidSubmit = () => {


    this.setState({ resLoading: true })
    const { thumbnailPath, languageName, bannerPath, editData, circleImagePath, bannerfile, thumbnailFile, circleImageFile } = this.state;


    let LanguageData = {
      "data": {
        "languageName": languageName,
        "description": this.state.description,
        ...(thumbnailPath ? { "thumbnailPath": thumbnailPath } : {}),


      }
    };
    const data = new FormData();
    data.append("key", JSON.stringify(LanguageData));
    thumbnailFile !== null && data.append("file", thumbnailFile);


    if (this.props.editData?.uuid) {
      updateLanguageNameApi(data, this.props.this, this.props.onHide, this.props.editData.uuid, this)
    } else {
      addLanguageNameApi(data, this.props.this, this.props.onHide, this)
    }
  }

  render() {
    const { uploadImage, resLoading, thumbnailFile, bannerfile, uploadImage2, circleImageFile, uploadImage3, languageName } = this.state;

    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={(this.props.editData?.uuid) ? "Edit Language" : "Add Language"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={6}>
              <FormElement
                valueLink={this.linkState(this, "languageName")}
                label="Language Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Name",
                  },
                }}
              />
            </Col>

            <Col sm={6}>
              <ImageUploader required={true} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: null, uploadImage: null })} />
            </Col>

            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "description")}
                label="Description"
                helpText="Description can't be more than 4000 character"

                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    as: "textarea",
                    placeholder: "Enter Description",
                  },
                }}
              />
            </Col>
          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <button disabled={(!thumbnailFile && !uploadImage) || !languageName} className="primary-btn" style={{ padding: "0.5rem 2.4rem" }}
            >
              {resLoading ? <span className="d-flex align-items-center gap-12"> <Spinner /> Saving...</span> : "Save"}
            </button>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

export default LanguageForm;