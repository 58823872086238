import { DEFAULT_LIST, START_PAGE } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { GET_ALL_USER_DATA } from "./ActionTypes";

const INITIAL_STATE = {
    totalPages: null,
    currentPage: START_PAGE,
    usersList:DEFAULT_LIST
};
const UsersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_USER_DATA:
            return { 
                usersList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        default:
            return state
    }
};
export default UsersReducer