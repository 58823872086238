import React, { Component } from "react";
import { connect } from "react-redux";
import ComponentHeader from "../common/ComponentHeader";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Button, Col, Image, Row } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import editPencil from "../../assets/images/edit-icon.png";


import { getAllEventDataApi, getAllSsoEventDataApi } from "./Api";
import { API_LIMIT, START_PAGE, isModuleExist } from "../../utils/Constant";
import { InformationModal, VerifiedModal } from "../common";
import moment from "moment";
import { deleteAuthCommonApi } from "../content/Api";
import { toast } from "react-toastify";
import { EVENT_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";

class SsoEvents extends Component {
    constructor(props) {
        super(props);
        const AccesData = isModuleExist("Sso", 'Event');

        this.state = {
            access: AccesData,
            search: "",
            pageSize: API_LIMIT,
            currentPage: START_PAGE,
            delete: false,
            deleteData: {},
            editData: "",
            showEdit: false,


            isVerify: false,
            show: false,

            verified: '',
            verifyUrl: ''

        };
    }


    componentDidMount() {
        const { history } = this.props;
        history.push({
            pathname: this.props.location.pathname,
            search: `?p=${this.state.currentPage || START_PAGE}`,
        });
        this.props.getAllSsoEventDataApi(this);

    }

    componentDidUpdate(prevProps, prevState) {
        const prevParams = new URLSearchParams(prevProps.location.search);
        const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
        const prevVerified = prevParams.get("isVerified") || "";


        const params = new URLSearchParams(this.props.location.search);
        const page = parseInt(params.get("p"), 10) || START_PAGE;
        const verified = params.get("isVerified") || "";

        // const search = params.get("search") || "";

        if (prevPage !== page || prevVerified !== verified) {
            this.setState({ currentPage: page }, () => {
                this.props.getAllSsoEventDataApi(this);

            });
        }
    }

    getDetailsUser = (data) => {
        this.props.history.push({ pathname: `/sso/events-details/${data?.uuid}`, state: { "id": data?.uuid } });
    }
    // showEditModal = (rowData = null) => {
    //     this.props.history.push({ pathname: `/events/add-event`, state: { data: rowData } })
    //     this.setState({
    //         showEdit: !this.state.showEdit,
    //         editData: rowData,
    //     });
    // };
    // openCloseDeleteModal = (rowData) => {
    //     this.setState({
    //         delete: !this.state.delete,
    //         deleteData: rowData,
    //     });
    // };
    // // deleteHandle = (id) => {
    // //   deleteEventApi(id, this, this.openCloseDeleteModal)
    // // }
    // deleteHandle = async (id) => {
    //     const res = await deleteAuthCommonApi(`/events/${id}`);
    //     // console.log(res)
    //     if (res.status === 200) {
    //         this.props.getAllEventDataApi(this);
    //         this.setState({
    //             delete: !this.state.delete
    //         });

    //     } else {
    //         toast.error("Somthis wrong...")
    //     }
    // }
    render() {
        const { search, dummyData, access } = this.state;

        const { ssoEventData, totalPages, currentPage } = this.props.ssoEventsState;
        // console.log(eventData)
        return (
            <>
                {this.state.delete && (
                    <InformationModal
                        show={this.state.delete}
                        id={this.state.deleteData.uuid}
                        title={`Are you sure you want to delete ${this.state.deleteData.name}?`}
                        handleClose={this.openCloseDeleteModal}
                        handleDelete={this.deleteHandle}
                    />
                )}
                {/* <VerifiedModal
                    name={`'${this.state.editData?.name}'`}
                    show={this.state.show}
                    url={EVENT_VERIFY + `/${this.state?.editData?.uuid}`}
                    isVerified={this.state.isVerify}
                    handleCancel={() => { this.setState({ show: false }) }}
                    successRes={() => { this.props.getAllSsoEventDataApi(this) }}
                /> */}
                <section>
                    <ComponentHeader
                        title="Events"
                    // primaryBtn={access.createAccess ? "Add Event" : ""}
                    // // isSearch={true}
                    // // isBackArrow={true}
                    // // isReturn="/contentCollections"
                    // handlePrimaryBtn={() => { this.props.history.push('/events/add-event') }}
                    />
                    <Row>
                        <Col sm={2}>
                            <FilterVefiedDropDown ctx={this} />
                        </Col>
                    </Row>
                    <div className="commom-table-wrapper">
                        <CustomTable
                            tableData={ssoEventData?.list}
                            columnList={[
                                {
                                    coumnWidth: 200,
                                    labelName: "Name",
                                    dataKey: "name",
                                    className: "name",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "name") {
                                            return rowData.name;
                                        }
                                    },
                                },

                                // {
                                //   coumnWidth: 250,
                                //   labelName: "Description",
                                //   dataKey: "description",
                                //   className: "name",
                                //   isCell: true,
                                //   cell: (rowData, dataKey) => {
                                //     if (dataKey === "description") {
                                //       return rowData.description;
                                //     }
                                //   },
                                // },
                                {
                                    coumnWidth: 200,
                                    labelName: "Event Format",
                                    dataKey: "eventFormat",
                                    className: "name",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "eventFormat") {
                                            return rowData.eventFormat;
                                        }
                                    },
                                },

                                {
                                    coumnWidth: 200,
                                    labelName: "Event Date",
                                    dataKey: "date",
                                    className: "name",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "date") {
                                            return moment(rowData?.startDate).format('lll');
                                        }
                                    },
                                },
                                // {
                                //   coumnWidth: 250,
                                //   labelName: "Banner Image",
                                //   dataKey: "bannerPath",
                                //   className: "",
                                //   isCell: true,
                                //   cell: (rowData, dataKey) => {
                                //     if (dataKey === "bannerPath") {
                                //       return <Image src={rowData.bannerPath } className="table-img" alt="Photo" />;
                                //     }
                                //   },
                                // },
                                // {
                                //   coumnWidth: 250,
                                //   labelName: "Thumbnail Image",
                                //   dataKey: "thumbnailPath",
                                //   className: "",
                                //   isCell: true,
                                //   cell: (rowData, dataKey) => {
                                //     if (dataKey === "thumbnailPath") {
                                //       return <Image src={rowData.thumbnailPath} className="table-img" alt="Photo" />;
                                //     }
                                //   },
                                // },
                                // {
                                //   coumnWidth: 170,
                                //   labelName: "Action",
                                //   dataKey: "",
                                //   className: "",
                                //   isCell: true,
                                //   cell: (rowData, dataKey) => {
                                //     if (dataKey === "") {
                                //       return (
                                //         <Button onClick={() => this.getDetailsUser(rowData)} className=" primary-btn">
                                //           View Detail
                                //         </Button>
                                //       );
                                //     }
                                //   },
                                // },
                                {
                                    coumnWidth: 150,
                                    labelName: "Event Type",
                                    dataKey: "eventType",
                                    className: "type",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "eventType") {
                                            return rowData?.eventType || 'Na';;
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 150,
                                    labelName: "Total Seats",
                                    dataKey: "totalSeats",
                                    className: "total",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "totalSeats") {
                                            return rowData?.totalSeats || 'Na';
                                        }
                                    },
                                },
                                {

                                    coumnWidth: 250,
                                    labelName: "Action",
                                    dataKey: "",
                                    className: "",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "") {
                                            return (
                                                <>
                                                    <Button onClick={() => this.getDetailsUser(rowData)} className=" primary-btn mr-4">
                                                        View Detail
                                                    </Button>
                                                    {access.updateAccess && (
                                                        <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                                                    )}
                                                    {access.deleteAccess && (
                                                        <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                                                    )}
                                                </>
                                            );
                                        }
                                    },

                                }
                                // {
                                //   coumnWidth: 170,
                                //   labelName: "",
                                //   dataKey: "",
                                //   className: "",
                                //   isCell: true,
                                //   cell: (rowData, dataKey) => {
                                //     if (dataKey === "") {
                                //       return (
                                //         <>
                                //           <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                                //           <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                                //         </>
                                //       );
                                //     }
                                //   },
                                // },
                            ]}
                            message="No data found" // For Pagination
                            history={this.props.history}
                            location={this.props.location}
                            totalPages={totalPages}
                            currentPage={currentPage}
                        />
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    ssoEventsState: state.SsoContentEventState,
});
const mapDispatchToProps = {
    getAllSsoEventDataApi
};
SsoEvents.propTypes = {
    // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SsoEvents);
