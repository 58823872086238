import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ComponentHeader from "../common/ComponentHeader";
import { getAllAdsApi, getAllBannerApi } from './Api';
import { API_LIMIT, START_PAGE } from "../../utils/Constant";

import AddContent from "./ContentAds";
// import AddEditAds from "./AddEditAds";
import BannerTab from "./BannerTab";
// import BannerForm from "./BannerForm";
class Ads extends Component {
  constructor(props) {
    super(props);
    const { activeTab } = props.location.state || "";
    this.state = {

      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      dataList: [],
      totalPages: null,

      activeTab: {
        tabName: "Ads",
        eventKey: "Ads",
        primaryBtn: "Add Ads",
        searchPlaceholder: "Search Ads",
        api: this.props.getAllAdsApi,
        componentName: <AddContent {...this.props} />,
        showAddEdit: false,
        // addEditModal: (
        //   <AddEditAds show={true} onHide={this.handlePrimaryBtn} ctx={this} />
        // ),
      },
      tabArray: [
        {
          tabName: "Ads",
          eventKey: "Ads",
          primaryBtn: "Add Ads",
          searchPlaceholder: "Search Ads",
          api: this.props.getAllAdsApi,
          componentName: <AddContent {...this.props} />,
          showAddEdit: false,
          // addEditModal: (
          //   <AddEditAds show={true} onHide={this.handlePrimaryBtn} ctx={this} />
          // ),
        },
        {
          tabName: "Banner",
          eventKey: "Banner",
          primaryBtn: "Add Banner",
          searchPlaceholder: "Search  Banner",
          api: this.props.getAllBannerApi,
          componentName: <BannerTab {...this.props} />,
          showAddEdit: false,
          // addEditModal: (
          //   <BannerForm show={true} onHide={this.handlePrimaryBtn} ctx={this} />
          // ),
        },
        // {
        //   tabName: "Targeted List",
        //   eventKey: "Targeted",
        //   primaryBtn: "Add Targeted List",
        //   searchPlaceholder: "Search Targeted Name",
        //   api: this.props.getAllTargetedListApi,
        //   componentName: <TargatedTab {...this.props} />,
        //   showAddEdit: false,
        //   addEditModal: (
        //     <TargatedForm show={true} onHide={this.handlePrimaryBtn} ctx={this} />
        //   ),
        // },
      ]
    };
  }
  handlePrimaryBtn = () => {
    const { activeTab } = this.state;
    // console.log("Ads state", activeTab)
    if (activeTab.tabName === 'Ads') {
      this.props.history.push({ pathname: '/ads/content-ads/add', state: { activeTab: activeTab.eventKey } })
    } else {
      this.props.history.push({ pathname: '/ads/banner-ads/add', state: { activeTab: activeTab.eventKey } })
    }
    // ads/add-content-ads
    // ads/add-banner-ads
    // ads/edit-content-ads
    // ads/edit-banner-ads
    // this.setState({
    //   activeTab: {
    //     ...activeTab,
    //     showAddEdit: !activeTab.showAddEdit,
    //   },
    // });
  };
  componentDidMount() {
    const { history } = this.props;
    //   const params=new URLSearchParams(this.props.location.search)
    // console.log((params.get("tab")??false)? params.get('tab'):this.state.activeTab.eventKey)
    // console.log( `?tab=${(params.get("tab")??false)? params.get('tab'):this.state.activeTab.eventKey}&p=${START_PAGE}`)
    history.push({
      search: `?tab=${this.state.activeTab.eventKey}&p=${START_PAGE}`,
    });
    this.state.activeTab.api(this);
  }
  componentDidUpdate(prevProps, prevState) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("p"), 10) || START_PAGE;
    const search = params.get("search") || "";

    if (prevPage !== page) {
      this.setState({ currentPage: page }, () => {
        if (search) {
          this.state.activeTab.api(this);
        } else {
          this.state.activeTab.api(this);
        }
      });
    }
  }




  handleTabChange = (tab) => {
    let activeTab = this.state.tabArray.filter((item) => item.eventKey === tab);
    this.setState({ activeTab: activeTab[0] });
    this.props.history.push({
      search: `?tab=${activeTab[0].eventKey}&p=${START_PAGE}`,
    });
    activeTab[0].api(this);
  };

  render() {
    const { search, tabName, activeTab, tabArray } = this.state;

    return (
      <section className="contentCollections-main-frame">
        {activeTab.showAddEdit && activeTab.addEditModal}
        <ComponentHeader
          title="Ads and Banner"
          // primaryBtn={tabName}
          primaryBtn={activeTab.primaryBtn}
          // isSearch={true}
          // isBackArrow={true}
          // isReturn="/contentCollections"
          handlePrimaryBtn={this.handlePrimaryBtn}
        // handlePrimaryBtn={() => { this.props.history.push('/ads/ads-content') }}
        />
        <Tabs
          defaultActiveKey={activeTab.eventKey}
          onSelect={this.handleTabChange}
          className="tab-title"
          id="uncontrolled-tab-example"
        >
          {
            tabArray.map((tab) => {
              return (
                <Tab eventKey={tab.eventKey} title={tab.tabName}>
                  {tab.componentName}
                </Tab>
              )
            })
          }
        </Tabs>
      </section>
    );
  }
}


const mapStateToProps = (state) => ({
  contentCollectionsState: state.ContentCollectionsState,
});
const mapDispatchToProps = {
  getAllAdsApi,
  getAllBannerApi,

};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Ads));
