import { START_PAGE } from "../../utils/Constant";
import { GET_ALL_SSO_CONTENT, GET_ALL_SSO_EVENT, GET_ALL_TRACKING_DATA, GET_ALL_USER_DATA } from "./ActionTypes";

export const getAllSsoContent = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_SSO_CONTENT,
    payload,
    currentPage,
});
export const getAllSsoEventData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_SSO_EVENT,
    payload,
    currentPage,
});
export const getAllSsoUserData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_USER_DATA,
    payload,
    currentPage,
});
export const getAllTrackingData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_TRACKING_DATA,
    payload,
    currentPage,
});