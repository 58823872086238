import React from "react";
import { connect } from 'react-redux';
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
  SelectControl,
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import { CustomModal } from "../common";
import { addCouponApi, updateCouponApi } from "./Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCustomerNameApi } from "../customers/Api";
import { MAX_LIMIT, START_PAGE } from "../../utils/Constant";
class CouponForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;

    this.state = {
      pageSize: MAX_LIMIT,
      currentPage: START_PAGE,
      isDropdown: true,
      couponCode: data?.couponCode || "",
      isPercentage: data?.uuid ? (data?.isPercentage ? true : '0') : "",
      discountAmount: data?.discountAmount || null,
      discountPercentage: data?.discountPercentage || null,
      startDate: data?.startDate ? new Date(data?.startDate) : "",
      endDate: data?.endDate ? new Date(data?.endDate) : "",
      maxLimit: data?.maxLimit || null,
      userMaxTimeUse: data?.userMaxTimeUse || null,
      isUserSpecific: data?.uuid ? (data?.isUserSpecific ? true : '0') : "",
      customerId: data?.users?.length > 0 && data?.users.map(item => item.uuid) || [],
      customerList: [],
    };
  }

  componentDidMount() {

    if (this.props?.editData?.isUserSpecific) {
      this.props.getCustomerNameApi(this);

    }
  }
  imageUploaser = (e, name) => {

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (name === "banner") {
      this.setState({
        bannerPath: e.target.files[0].name,
        bannerfile: e.target.files[0],
        uploadImage2: imageUrl,
      });
    } else {
      this.setState({
        thumbnailPath: e.target.files[0].name,
        thumbnailFile: e.target.files[0],
        uploadImage: imageUrl,
      });
    }
  };

  onValidSubmit = () => {

    let CouponData = {
      data: {
        "couponCode": this.state.couponCode,
        "isPercentage": this.state.isPercentage === '0' ? false : true,
        ...(this.state.isPercentage !== '0'
          ? { "discountPercentage": parseInt(this.state.discountPercentage) }
          : { "discountAmount": parseInt(this.state.discountAmount) }),
        "startDate": this.state.startDate,
        "endDate": this.state.endDate,
        "maxLimit": parseInt(this.state.maxLimit),
        "userMaxTimeUse": parseInt(this.state.userMaxTimeUse),
        "isUserSpecific": this.state.isUserSpecific === '0' ? false : true, //true then pass users id
        ...(this.state.isUserSpecific !== '0' && {
          "users": this.state.customerId?.map((item, i) => ({ uuid: item })),
        }),
      },
    };

    if (this.props.editData?.uuid) {
      updateCouponApi(
        JSON.stringify(CouponData),
        this.props.this,
        this.props.onHide,
        this.props.editData?.uuid
      );
    } else {
      addCouponApi(
        JSON.stringify(CouponData),
        this.props.this,
        this.props.onHide
      );
    }
  };

  render() {
    const { isPercentage, isUserSpecific } = this.state;
    const { uploadImage, thumbnailFile, bannerfile, uploadImage2 } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props.editData?.uuid ? 'Edit Coupon' : "Add Coupon"}
        modalClass={"change-password event-ads-modal"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "couponCode")}
                label="Coupon Code"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Coupon code cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter coupon code",
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "maxLimit")}
                label="Maximum Limit"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Maximum limit cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter maximum limit",
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "userMaxTimeUse")}
                label="User Max Time Use"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter user max time use",
                  },
                }}
              />
            </Col>
            <Col sm={4}>

              <FormElement
                valueLink={this.linkState(this, "isPercentage")}
                label="Select Type"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select value",
                    options: [
                      {
                        value: true,
                        label: "Percentage",
                      },
                      {
                        value: '0',
                        label: "Flat",
                      }
                    ],
                    multiple: false,
                    searchable: true,
                    // disabled:true,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.isPercentage);

                    },
                  },
                }}
              />
              {/* } */}
            </Col>

            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(
                  this,
                  isPercentage !== '0' ? "discountPercentage" : "discountAmount"
                )}
                label={isPercentage !== '0' ? "Discount Percentage" : "Discount Amount"}
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "This field cannot be empty",
                  },
                  {
                    validate: isPercentage === 1 ? FormValidator.isWithinInt(0, 100) : FormValidator.isPositiveInt,
                    message: "Please enter a correct value",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    // as: "textarea",
                    placeholder: "Enter amount",
                  },
                }}
              />
            </Col>
            <Col md={4}>
              <div className="form-group custom-date-range-picker">
                <label htmlFor="" className="form-label">Start  Of Date <span className=" text-danger">*</span></label>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) => this.setState({ startDate: date, endDate: "" })}
                  // maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Start date"
                  className="form-control "
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group custom-date-range-picker">
                <label htmlFor="" className="form-label">End Date <span className=" text-danger">*</span></label>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) => this.setState({ endDate: date })}
                  minDate={this.state.startDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="End date"
                  className="form-control "
                  // showMonthDropdown
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                />
              </div>
            </Col>
            <Col sm={4}>
              <FormElement
                valueLink={this.linkState(this, "isUserSpecific")}
                label="Specific User"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: SelectControl,
                  settings: {
                    placeholder: "Select value",
                    options: [
                      {
                        value: true,
                        label: "Yes",
                      },
                      {
                        value: '0',
                        label: "No",
                      }
                    ],
                    multiple: false,
                    searchable: false,
                    onChangeCallback: (onBlur) => {
                      onBlur(this.state.isUserSpecific);
                      if (this.state.isUserSpecific !== '0') {

                        this.props.getCustomerNameApi(this);
                      }
                    },
                  },
                }}
              />
            </Col>
            {isUserSpecific === '0' ? false : true &&
              <Col sm={4}>
                <FormElement
                  valueLink={this.linkState(this, "customerId")}
                  label="Customer List"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty",
                    },
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      options: this.state.customerList,
                      multiple: true,
                      placeholder: "Select value",
                      searchable: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.customerId);
                      },
                    },
                  }}
                />
              </Col>
            }
          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              Save
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
  getCustomerNameApi
}
export default connect(mapStateToProps, mapDispatchToProps)(CouponForm);
