import { START_PAGE } from "../../utils/Constant";
import { GET_ALL_SSO_DATA, GET_ALL_TYPES_DATA, GET_SSO_DETAILS_DATA, GET_SSO_VIEW_USER_DATA } from "./ActionTypes";

export const getAllSsoData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_SSO_DATA,
    payload,
    currentPage,
});
export const getAllTypesData = (payload, currentPage = START_PAGE) => ({
    type: GET_ALL_TYPES_DATA,
    payload,
    currentPage,
});
export const getSsoDetails = (payload, currentPage = START_PAGE) => ({
    type: GET_SSO_DETAILS_DATA,
    payload,
    currentPage,
});
export const getAllSsoViewUserData = (payload, currentPage = START_PAGE) => ({
    type: GET_SSO_VIEW_USER_DATA,
    payload,
    currentPage,
});