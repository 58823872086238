import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse } from 'date-fns';

const DatePickerControl = (props) => {
  const {
    valueLink,
    placeholder,
    disabled = false,
    minDate,
    maxDate,
    failedValidation,
    showYearDropdown,
    yearDropdownItemNumber,
    scrollableYearDropdown,
    onBlur
  } = props;

  // Parse the date from 'dd-MM-yyyy' to Date object
  const parsedDate = valueLink.value
    ? parse(valueLink.value, 'dd-MM-yyyy', new Date())
    : null;

  const handleSelect = (date) => {
    // Format the selected date back to 'dd-MM-yyyy'
    const formattedDate = date ? format(date, 'dd-MM-yyyy') : '';
    valueLink.requestChange(formattedDate);
  };

  return (
    <>
      <div className="date-picker-wrapper">
        <DatePicker
          selected={parsedDate}
          onChange={handleSelect}
          placeholderText={placeholder}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          showYearDropdown={showYearDropdown}
          yearDropdownItemNumber={yearDropdownItemNumber}
          scrollableYearDropdown={scrollableYearDropdown}
          dateFormat="dd-MM-yyyy"
          onBlur={() => onBlur(valueLink.value)}

          customInput={
            <input
              type="text"
              readOnly
              className={`form-control date-picker-control  ${failedValidation && failedValidation.message ? "is-invalid" : ""}`}
              value={valueLink.value ? valueLink.value : ""}
            />
          }
        />
      </div>
    </>
  );
};

export default DatePickerControl;
