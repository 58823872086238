import { DEFAULT_LIST, START_PAGE } from "../../utils/Constant";
import { calculateTotalPageCount } from "../../utils/ReusableFunctions";
import { GET_ALL_TEMPLATES_DATA, GET_PERSONALIZATION_CATEGORY_DATA } from "./ActionTypes";

const INITIAL_STATE = {
    totalPages: null,
    currentPage: START_PAGE,
    templateList: DEFAULT_LIST
};
const PersonalizationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_ALL_TEMPLATES_DATA:
            return {
                ...state,
                templateList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };
        case GET_PERSONALIZATION_CATEGORY_DATA:
            return {
                ...state,
                categoryList: action.payload,
                totalPages: calculateTotalPageCount(action.payload.length),
                currentPage: action.currentPage
            };

        default:
            return state
    }
};
export default PersonalizationReducer