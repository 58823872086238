import React from "react";
import { connect } from 'react-redux';
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
  SelectControl,
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import { CustomModal, Spinner } from "../common";
import { addTrendingkeywordsApi, updateTrendingkeywordsApi } from "./Api";
import "react-datepicker/dist/react-datepicker.css";
import { getCustomerNameApi } from "../customers/Api";
import { MAX_LIMIT, START_PAGE } from "../../utils/Constant";
class TrendingKeywordsForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props?.editData || null;
    this.state = {
      editData:data,
      pageSize: MAX_LIMIT,
      currentPage: START_PAGE,
      isDropdown: true,
      trendingKeyword: data?.trendingKeyword || "",
      link: data?.link || "",
      position: data?.position || 0,
      resLoading:false,
    };
  }

  componentDidMount() {

  }

 isEditDataChanged=(key)=>{
   const {editData}=this.state;
    return editData[key]===this.state[key];
 }
  onValidSubmit = () => {
    this.setState({resLoading:true})
    const TrendingData = {
      "data": {
       "trendingKeyword":  this.state.trendingKeyword,
       "position":  parseInt(this.state.position),
       "link":  this.state.link
      }
    };

    if (this.props.editData?.uuid) {
    
      const TrendingData2 = {
        "data": {
         ...(this.isEditDataChanged("trendingKeyword")?{}:{ "trendingKeyword":  this.state.trendingKeyword}),
         ...(this.isEditDataChanged("position")?{}:{ "position":  parseInt(this.state.position)}),
         ...(this.isEditDataChanged("link")?{}:{ "link":  this.state.link})
        }
      };
      updateTrendingkeywordsApi(
        JSON.stringify(TrendingData2),
        this.props.this,
        this.props.onHide,
        this.props.editData.uuid,
        this
      );
    } else {
      addTrendingkeywordsApi(
        JSON.stringify(TrendingData),
        this.props.this,
        this.props.onHide,
        this
      );
    }
  };

  render() {
    const {resLoading}=this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={this.props.editData?.uuid ? "Edit Trending Keyword" : "Add Trending Keyword"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "trendingKeyword")}
                label="Trending Keywords Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field  cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter Trending Keywords Name",
                  },
                }}
              />
            </Col>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "link")}
                label="Link"
                // required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "https//google.com",
                  },
                }}
              />
            </Col>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "position")}
                label="Position"
                required
                validations={[
                  {
                    validate: FormValidator.isLimit,
                    message: "Position not be empty or between 1 to 10",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    placeholder: "Enter position between 1 to 10 ",
                  },
                }}
              />
            </Col>
          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
            {resLoading?<span className="d-flex align-items-center gap-12"> <Spinner/> Saving...</span> :"Save"}
           </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
  getCustomerNameApi
}
export default connect(mapStateToProps, mapDispatchToProps)(TrendingKeywordsForm);
