import React from "react";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Button, Col, Image, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { ALT_THUMBNAIL, API_LIMIT, START_PAGE, isModuleExist } from "../../utils/Constant";
import { InformationModal, ComponentHeader, Spinner } from "../common";
import { getAllSsoContentApi } from "./Api";
import { replaceHistory } from "../../utils/ReusableFunctions";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";
class SsoContent extends BaseReactComponent {
    constructor(props) {
        super(props);
        const AccesData = isModuleExist("Sso", 'Content');
        this.state = {
            access: AccesData,
            search: "",
            dummyData: [],
            searchResult: [],
            pageSize: API_LIMIT,
            currentPage: START_PAGE,
            delete: false,
            deleteData: {},
            editData: "",
            showEdit: false,
            isLoading: true,

            isVerify: false,
            show: false,

            verified: '',
            verifyUrl: ''
        };
    }

    componentDidMount() {
        const { history } = this.props;
        history.push({
            pathname: this.props.location.pathname,
            search: `?p=${this.state.currentPage || START_PAGE}`,
        });
        this.props.getAllSsoContentApi(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const prevParams = new URLSearchParams(prevProps.location.search);
        const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
        const prevSearch = prevParams.get("search") || "";
        const prevVerified = prevParams.get("isVerified") || "";

        const params = new URLSearchParams(this.props.location.search);
        const page = parseInt(params.get("p"), 10) || START_PAGE;
        const search = params.get("search") || "";
        const verified = params.get("isVerified") || "";

        if (prevPage !== page || prevSearch !== search || prevVerified !== verified) {
            this.setState({ currentPage: page, search }, () => {
                this.props.getAllSsoContentApi(this);
            });
        }
    }
    onChangeMethod = (value) => {
        this.setState({ searchText: value.search, currentPage: START_PAGE }, () => {
            replaceHistory(this.props.history, START_PAGE, value.search);
        })
    };
    clearSearch = () => {
        this.onChangeMethod({ search: "" });
    }

    getDetailsUser = (data) => {
        this.props.history.push({ pathname: `/sso/content-details/${data?.uuid}`, state: { "id": data?.uuid } });
    }
    render() {
        const { dummyData, search, serchResult, tabName, activeTab, access, isLoading } = this.state;
        const { ssoContentList, totalPages, currentPage } = this.props.ssoContentEventState;
        // console.log('from action reduction list', contentList)
        return (
            <>
                {this.state.delete && (
                    <InformationModal
                        show={this.state.delete}
                        id={this.state.deleteData.uuid}
                        title={`Are you sure you want to delete ${this.state.deleteData.title}?`}
                        handleClose={this.openCloseDeleteModal}
                        handleDelete={this.deleteHandle}
                    />
                )}

                <section className="request-section">
                    <ComponentHeader
                        title="Content"
                        isSearch={true}
                        placeholder={"Search Content"}
                        onChangeMethod={this.onChangeMethod}
                        clearSearch={() => this.clearSearch()}
                    // primaryBtn="Add Content"
                    // handlePrimaryBtn={() => {
                    //     this.props.history.push("/content/add-content");
                    // }}
                    />
                    <div className="">
                        <Row>

                            <Col sm={2}>
                                <FilterVefiedDropDown ctx={this} />
                            </Col>
                        </Row>
                    </div>
                    <div className="commom-table-wrapper">
                        <CustomTable
                            tableData={isLoading ? [] : ssoContentList?.list || []}
                            columnList={[

                                {
                                    coumnWidth: 150,
                                    labelName: "Title",
                                    dataKey: "title",
                                    className: "name",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "title") {
                                            return rowData?.title || '';
                                        }
                                    },
                                },

                                {
                                    coumnWidth: 150,
                                    labelName: "AMC",
                                    dataKey: "amc",
                                    className: "name",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        // console.log(rowData?.amc ?? "happy")
                                        if (dataKey === "amc") {
                                            return rowData?.amc?.amcName || ''
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 150,
                                    labelName: "Content Type",
                                    dataKey: "contentType",
                                    className: "name",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "contentType") {
                                            return rowData?.contentType?.contentTypeName || "";
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 150,
                                    labelName: "Thumbnail Image",
                                    dataKey: "thumbnail",
                                    className: "",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "thumbnail") {
                                            return (rowData.thumbnail ? <Image src={rowData.thumbnail} className="table-img" alt={ALT_THUMBNAIL} /> : "NA");
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 150,
                                    labelName: "Category",
                                    dataKey: "category",
                                    className: "name",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "category") {
                                            return rowData?.category?.categoryName || ''
                                        }
                                    },
                                },

                                {
                                    coumnWidth: 80,
                                    labelName: "Liked",
                                    dataKey: "Liked",
                                    className: "liked",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "Liked") {
                                            return rowData?.Liked;

                                        }

                                    },

                                },
                                {
                                    coumnWidth: 130,
                                    labelName: "Downloaded",
                                    dataKey: "Downloaded",
                                    className: "downloaded",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "Downloaded") {

                                            return rowData?.Downloaded;
                                        }

                                    },
                                },
                                {
                                    coumnWidth: 100,
                                    labelName: "Shared",
                                    dataKey: "Shared",
                                    className: "shared",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "shared") {

                                            return rowData?.Shared;
                                        }
                                        return '0';
                                    },
                                },
                                {
                                    coumnWidth: 80,
                                    labelName: "Cobrand",
                                    dataKey: "Cobrand",
                                    className: "cobrand",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "Cobrand") {
                                            return rowData?.Cobrand;

                                        }

                                    },
                                },
                                {
                                    coumnWidth: 120,
                                    labelName: "Saved",
                                    dataKey: "Saved",
                                    className: "saved",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "Saved") {
                                            return rowData?.Saved;

                                        }

                                    },
                                },
                                {
                                    coumnWidth: 300,
                                    labelName: "Action",
                                    dataKey: "",
                                    className: "",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "") {
                                            return (
                                                <>
                                                    <Button onClick={() => this.getDetailsUser(rowData)} className="primary-btn inter-medium f-s-14 lh-18 mr-5">
                                                        View Detail
                                                    </Button>
                                                    {access.updateAccess && <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />}
                                                    {access.deleteAccess && <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />}
                                                </>
                                            );
                                        }
                                    },
                                },
                            ]}
                            message="No data found" // For Pagination
                            history={this.props.history}
                            location={this.props.location}
                            totalPages={totalPages}
                            currentPage={currentPage}
                        />
                        {isLoading ?
                            <div className="inter-medium f-s-18 lh-22 text-center py-5 d-flex align-items-center justify-content-center gap-12"> <Spinner classes={'black-border'} /> Loading...</div>
                            : (ssoContentList.length === 0) ?
                                <div className="inter-medium f-s-18 lh-22 text-center py-5"> Result is not found.</div> : ''
                        }
                    </div>
                </section>
            </>
        );
    }
}


const mapStateToProps = (state) => ({
    ssoContentEventState: state.SsoContentEventState,
});
const mapDispatchToProps = {
    getAllSsoContentApi,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SsoContent));
