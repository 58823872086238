export const GET_ALL_SUBDOMAIN_DATA = 'GET_ALL_SUBDOMAIN_DATA';
export const GET_ALL_TYPES_DATA = 'GET_ALL_TYPES_DATA';
export const GET_ALL_SUBDOMAIN_TYPES_DATA = 'GET_ALL_SUBDOMAIN_TYPES_DATA';
export const GET_SUBDOMAIN_DETAILS_DATA = 'GET_ALL_SUBDOMAIN_DETAILS_DATA';
export const GET_SUBDOMAIN_VIEW_USER_DATA = 'GET_ALL_SUBDOMAIN_VIEW_USER_DATA';
export const GET_ALL_CALENDER_DATA = 'GET_ALL_CALENDER_DATA';
export const GET_ALL_CALENDER_FILTER_DATA = 'GET_ALL_CALENDER_FILTER_DATA';




