import { toast } from "react-toastify";
import { postLoginInstance } from "../../utils";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
import { getAllOccassionData, getAllPlatformData, getAllPlaylistData } from "./AutoshareAction";


export const getAllPlatformDataApi = (ctx = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {

    return postLoginInstance.get(`platform?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
      .then((res) => {

        // return ctx.setState({ dummyData: res.data.data ,});
        dispatch(getAllPlatformData(res.data.data, ctx?.state.currentPage || START_PAGE));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Something went wrong")
      })
  }

};

export const getAllOccassionDataApi = (ctx = null) => {
  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {

    return postLoginInstance.get(`playlist/occassion?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
      .then((res) => {



        // return ctx.setState({ dummyData: res.data.data ,});
        dispatch(getAllOccassionData(res?.data?.data, ctx?.state.currentPage || START_PAGE));

      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Something went wrong")
      })
  }

};


export const getAllPlaylistDataApi = (ctx = null) => {


  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance.get(`playlist?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
      .then((res) => {
        // return ctx.setState({ dummyData: res.data.data ,});
        dispatch(getAllPlaylistData(res.data.data, ctx?.state.currentPage || START_PAGE));

      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Something went wrong")
      })
  }

};







//   export const getAllCitesApi = (ctx) => {
//     return postLoginInstance.get(`website/location/allCities?pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}  ${ctx?.state.locationSearch && `&searchValue=${ctx?.state.locationSearch}`}`)
//       .then((res) => {
//         let option = [];

//         res.data.data.sort().map((item) =>
//           option.push({
//             label: item,
//             value: item
//           }))
//         ctx.setState({
//           allCities: option
//         })
//       }).catch((error)=>{
//         toast.error(error?.response?.data?.message || "Something went wrong")
//       })
//   }
//   export const getAmcApi = (ctx) => {
//     return postLoginInstance.get(`amc`)
//       .then((res) => {
//         let option = [];
//         res.data.data.list.map((item) =>
//           option.push({
//             label: item.amcName,
//             value: item.uuid
//           }))
//         ctx.setState({
//           amcOption: option
//         })
//       }).catch((error)=>{
//         toast.error(error?.response?.data?.message || "Something went wrong")
//       })
//   }
