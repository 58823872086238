import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Form,
} from "../../utils/form";
import { Row, Col, Dropdown } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { getAllModulesApi, putAuthCommonApi } from "./Api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { postAuthCommonApi } from "../content/Api";
import { CHECKER, CREATEACCESS, DELATEACCESS, MAKER, READEACCESS, SELECT_ROLE_TYPE, SUPER_ADMIN, UPDATEACCESS } from "../../utils/Constant";

const isAllCheck = (editData, type) => { return (editData?.roleModules?.length == 36 && editData?.roleModules?.every((item) => (item[type]))) };
const getColState = {
  "createAccess": 'createCol',
  "readAccess": 'readCol',
  "updateAccess": 'updateCol',
  "deleteAccess": "deleteCol",
  "verificationAccess": 'validateCol',
}
const AddEditRole = () => {
  const location = useLocation();
  const history = useHistory();
  const editData = location?.state?.editData || "";

  const [roleName, setRoleName] = useState(editData?.role?.roleName || "");
  const [roleModules, setRoleModules] = useState([]);
  const [colChecked, setColChecked] = useState({ createCol: (isAllCheck(editData, CREATEACCESS) && (editData?.roleModules?.length == 36)), readCol: isAllCheck(editData, READEACCESS), updateCol: isAllCheck(editData, UPDATEACCESS), deleteCol: isAllCheck(editData, DELATEACCESS), validateCol: isAllCheck(editData, 'verificationAccess') })
  const { createCol, readCol, updateCol, deleteCol, validateCol } = colChecked;

  const [moduleList, setModuleList] = useState([]);
  const [roleType, setRoleType] = useState(editData?.role?.roleType || SELECT_ROLE_TYPE)
  const [resLoading, setResLoading] = useState(false);
  useEffect(async () => {
    const modules = await getAllModulesApi();
    setModuleList(modules);



    let createRoleModules = modules.list?.map((item, i) => {
      let findData = (editData?.roleModules?.find((element, j) => (element.module.uuid === item.uuid)) ?? false)
      if (findData) {

        return (
          {
            "moduleName": findData.module.moduleName,
            "subModuleName": findData.module.subModuleName,
            "createAccess": findData.createAccess,
            "readAccess": findData.readAccess,
            "updateAccess": findData.updateAccess,
            "deleteAccess": findData.deleteAccess,
            "verificationAccess": findData.verificationAccess,
          }
        )
      } else {
        return (
          {
            "moduleName": item.moduleName,
            "subModuleName": item.subModuleName,
            "createAccess": false,
            "readAccess": false,
            "updateAccess": false,
            "deleteAccess": false,
            "verificationAccess": false,
          }
        )
      }



    })

    createRoleModules.sort((a, b) => a.moduleName.localeCompare(b.moduleName, undefined, { sensitivity: 'base' }));

    setRoleModules(createRoleModules)
  }, []);

  const handleCheckboxChange = (e, rowIndex, accessType) => {
    const updatedRoleModules = [...roleModules];
    updatedRoleModules[rowIndex][accessType] = e.target.checked;
    setRoleModules(updatedRoleModules);
    setColChecked({ ...colChecked, [getColState[accessType]]: updatedRoleModules?.every((item) => (item[accessType])) })
  };

  const colHandlerChecked = (e, accessType) => {
    console.log("type", accessType, e.target.checked)
    const checked = e.target.checked;
    setColChecked({ ...colChecked, [e.target.name]: checked })
    const updatedRoleModules = [...roleModules];
    moduleList?.list?.forEach((ele, i) => {
      updatedRoleModules[i][accessType] = checked;
    })


    setRoleModules(updatedRoleModules);

  }

  const handleSave = async () => {
    setResLoading(true)

    if (roleName === "" || roleType === SELECT_ROLE_TYPE) {
      toast.error("Field cannot be empty.")
      setResLoading(false)
    } else {

      const data = {
        "data": {
          "roleName": roleName,
          "roleModules": (roleModules.filter((item) => Object.values(item).includes(true))),
          'roleType': roleType === SELECT_ROLE_TYPE ? '' : roleType.toLowerCase()
        }
      }
      let res;
      if (editData) {
        res = await putAuthCommonApi('userRole/' + editData?.role?.uuid, data)

      } else {
        res = await postAuthCommonApi('userRole', data);
      }
      if (res.status < 250 && res.status > 199) {
        setResLoading(false)
        history.push('/roles')
      } else {
        setResLoading(false)
      }
    }
  };

  return (
    <section className="add-section">
      <ComponentHeader
        title={editData ? 'Edit Role' : "Add Role"}
        primaryBtn="Save"
        handlePrimaryBtn={handleSave}
        isBackArrow={true}
        resLoading={resLoading}
        isReturn="/roles"
      />
      <Form >
        <Row>
          <Col sm={4}>
            <div className="mb-5">
              <label htmlFor="rollName">Role Name <span className=" text-danger">*</span></label><br />
              <input type="text" id="rollName" value={roleName} placeholder="Enter Role Name" onChange={(e) => setRoleName(e.target.value)} className=" input-box " />
            </div>
          </Col>
          <Col sm={4}>
            <div className="mb-5">
              <label htmlFor="rollName">Role Type <span className=" text-danger">*</span></label><br />
              <Dropdown>
                <Dropdown.Toggle className="input-box role-type-dropdown " >
                  {roleType.toUpperCase()}
                </Dropdown.Toggle>

                <Dropdown.Menu className="custom-drop-down">
                  {
                    [SUPER_ADMIN, CHECKER, MAKER].map((item, i) => {
                      return (
                        <Dropdown.Item key={i} onClick={() => setRoleType(item)} >{item}</Dropdown.Item>
                      )
                    })
                  }

                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>

          <Col md={12}>
            <div className="w-100">
              <h2 className="inter-medium f-s-24 lh-29">Module List</h2>
              <table className=" w-100">
                <thead>
                  <tr>
                    <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-left">#</th>
                    <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-left">Module Name</th>
                    <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-center">
                      <input type="checkbox" name="createCol" checked={createCol}
                        onChange={(e) => { colHandlerChecked(e, 'createAccess') }}
                      /> Create Access
                    </th>
                    <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-center">
                      <input type="checkbox" name="readCol" checked={readCol}
                        onChange={(e) => { colHandlerChecked(e, 'readAccess') }}
                      /> Read Access</th>
                    <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-center">
                      <input type="checkbox" name="updateCol" checked={updateCol}
                        onChange={(e) => { colHandlerChecked(e, 'updateAccess') }}
                      />  Update Access</th>
                    <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-center">
                      <input type="checkbox" name="deleteCol" checked={deleteCol}
                        onChange={(e) => { colHandlerChecked(e, 'deleteAccess') }}
                      /> Delete Access</th>
                    {roleType !== MAKER.toUpperCase() &&
                      <th className="inter-medium f-s-18 pt-5 pb-4 lh-22 text-center">
                        <input type="checkbox" name="validateCol" checked={validateCol}
                          onChange={(e) => { colHandlerChecked(e, 'verificationAccess') }}
                        /> Validate Access</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {roleModules?.map((row, rowIndex) => (
                    <tr className="hover-gray" key={rowIndex}>
                      <td className="inter-normal f-s-16 py-4 lh-24 text-left">{rowIndex + 1}</td>
                      <td className="inter-normal f-s-16 py-4 lh-24 text-left">{row.moduleName} - {row.subModuleName} </td>
                      <td className="inter-normal f-s-16 py-4 lh-24 text-center">
                        <input
                          type="checkbox"
                          name="createAccess"
                          checked={row.createAccess}
                          onChange={(e) => handleCheckboxChange(e, rowIndex, "createAccess")}
                        />
                      </td>
                      <td className="inter-normal f-s-16 py-4 lh-24 text-center">
                        <input
                          type="checkbox"
                          name="readAccess"
                          checked={row.readAccess}
                          onChange={(e) => handleCheckboxChange(e, rowIndex, "readAccess")}
                        />
                      </td>
                      <td className="inter-normal f-s-16 py-4 lh-24 text-center">
                        <input
                          type="checkbox"
                          name="updateAccess"
                          checked={row.updateAccess}
                          onChange={(e) => handleCheckboxChange(e, rowIndex, "updateAccess")}
                        />
                      </td>
                      <td className="inter-normal f-s-16 py-4 lh-24 text-center">
                        <input
                          type="checkbox"
                          name="deleteAccess"
                          checked={row.deleteAccess}
                          onChange={(e) => handleCheckboxChange(e, rowIndex, "deleteAccess")}
                        />
                      </td>
                      {roleType !== MAKER.toUpperCase() &&
                        <td className="inter-normal f-s-16 py-4 lh-24 text-center">
                          <input
                            type="checkbox"
                            name="deleteAccess"
                            checked={row.verificationAccess}
                            onChange={(e) => handleCheckboxChange(e, rowIndex, "verificationAccess")}
                          />
                        </td>
                      }

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Form>
    </section>
  );
};

const mapStateToProps = (state) => ({
  rolesState: state.RolesState,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditRole);
