import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseReactComponent } from "../../utils/form";
import { Col, Row, Table } from "react-bootstrap";

import { Image } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import { durationObj } from "../../utils/Constant";
import { getSubdomainDetailsApi, getTemplateDetailsApi } from "./Api";
import { userAmcUuids, userCreatorUuids } from "../../utils/ReusableFunctions";

class ViewTemplateDetails extends BaseReactComponent {
    constructor(props) {
        super(props);
        const data = props?.location.state?.data || null;

        const { params } = props.match;
        this.state = {
            params,
            detailsData: data,
            templateUuid: data?.uuid || "",
            isUpdated: (props?.location.state ?? false) || false,
            templateName: data?.templateName || "",
            thumbnailPath: "",
            thumbnailFile: null,
            showSearchContent: false,
            search: '',
            locationSearch: '',
            resLoading: false,
            loading: false,


        };
    }

    componentDidMount() {
        getTemplateDetailsApi("/personalization/template", this);


    }
    render() {
        const { data } = this.state;
        console.log(data)


        return (
            <section className="user-section">
                {/* // ---------------------------------------View details -------------------------------- */}

                <div className="view-details-wrapper">

                    <ComponentHeader title="View Template " isBackArrow={true} isReturn="/templates" />
                    <div className="view-details d-flex">
                        <div className="view-details-content">
                            <div className="user-details d-flex">
                                <div className="eclipse">
                                    <Image src={data?.thumbnailImage} className="eclipse" />
                                </div>
                                <div className="name-details">
                                    <h3 className="inter-medium f-s-20">{data?.templateName || ""}</h3>

                                </div>
                                <div className="line"></div>
                                <div className="name-description">
                                    <h3 className="grey-2 ">About</h3>
                                    <p className="inter-medium f-s-14">
                                        {data?.description}
                                    </p>
                                </div>
                            </div>
                            <h3 className="inter-regular f-s-14 grey-2">
                                Template Details
                            </h3>
                            <div className="line2"></div>
                            <Table className="custom-table">
                                <Row>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Template Name</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.templateName}</h3>
                                        </div>
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Description</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.description}</h3>
                                        </div>
                                    </Col>

                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Category</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.personalizationCategory?.categoryName || ''}</h3>
                                        </div>
                                    </Col>

                                    <Col sm={3} xs={12}>
                                        <div className="detail">
                                            <h4 className="inter-semibold grey-2 ">Is Verified</h4>
                                            <h3 className="inter-semibold f-s-14 ">{data?.isVerified ? "Yes" : "No" || ''}</h3>
                                        </div>
                                    </Col>

                                </Row>

                            </Table>

                            {data?.personalizationTemplateVariations?.length > 0 &&
                                <div className="variation-wrapper">
                                    <h3 className="inter-regular f-s-14 grey-2">Variations</h3>
                                    <hr />
                                    {
                                        data?.personalizationTemplateVariations
                                            ?.map((item, i) => {
                                                return (
                                                    <div key={i}>
                                                        <Table>
                                                            <Row className="">
                                                                <Col sm={3} xs={12}>
                                                                    <div className="detail">
                                                                        <h4 className="inter-semibold grey-2 ">Language Name</h4>
                                                                        <h3 className="inter-semibold f-s-14 ">{item?.language?.languageName || "Na"}</h3>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Table>
                                                        {i < data?.personalizationTemplateVariations.length - 1 && <hr />}
                                                    </div>
                                                )
                                            })
                                    }


                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({ personalizationState: state.PersonalizationState });
const mapDispatchToProps = {
    // getPosts: fetchPosts
};
ViewTemplateDetails.propTypes = {
    // getPosts: PropTypes.func
    location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewTemplateDetails);

