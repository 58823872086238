const deleteToken = () => {
  localStorage.removeItem('finToken');
  localStorage.removeItem('finAdmin');
};

const getToken = () => {
  return localStorage.getItem('finToken');
};


export { getToken, deleteToken };