import React from "react";
import { connect } from "react-redux";

import {
    Form,
    FormElement,
    FormValidator,
    CustomTextControl,
    BaseReactComponent,
    SelectControl,

} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import ComponentHeader from "../common/ComponentHeader";
import "react-datepicker/dist/react-datepicker.css";
import { getAllCalenderApi, getAllCalenderFilterApi } from "./Api";
import { ClearButton, ImageUploader } from "../common";
import { postAuthCommonApi, putAuthCommonApi } from "../content/Api";
import ReactDOM from "react-dom";
import { MAX_LIMIT } from "../../utils/Constant";

class AddEditCalender extends BaseReactComponent {
    constructor(props) {
        super(props);
        const data = props?.location.state?.data || null;


        this.state = {
            editData: data,
            calenderUuid: data?.uuid || "",
            isUpdated: (props?.location.state ?? false) || false,
            calenderName: data?.calenderName || "",
            thumbnailFile: null,
            uploadImage: data?.calenderImage || null,
            resLoading: false,
            description: data?.description || "",
            filterOptions: [],
            pageSize: MAX_LIMIT,

            filtersUuid: data?.calenderFilters?.map((item) => item?.calenderFilter?.uuid) || [],
        };

    };


    componentDidMount() {

        this.props.getAllCalenderFilterApi(this);

    };
    componentDidUpdate(prevProps, prevState) {
        if (this.props.calenderFilterData !== prevProps.calenderFilterData) {
            const filterOptions = this.props.calenderFilterData?.list?.map(filter => ({
                value: filter?.uuid,
                label: filter?.filterName
            }));
            this.setState({ filterOptions });
        }
    };

    imageUploaser = (e, name) => {

        const imageUrl = URL.createObjectURL(e.target.files[0]);
        this.setState({ iconImagePath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })

    }


    handlePageSize = (pageSize) => {
        // console.log("Heyyy pageSize", pageSize);
    };
    handleSave = () => {
        ReactDOM.findDOMNode(this.form).dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    };
    onValidSubmit = async () => {
        this.setState({ resLoading: true });
        const { calenderName, description, filtersUuid } = this.state;
        const filters = filtersUuid?.join(',');


        const calenderData = {
            "data": {
                "calenderName": calenderName,
                "description": description,
                "filtersUuid": filters
            }
        };

        const data = new FormData();
        data.append("key", JSON.stringify(calenderData));
        data.append("file", this.state?.thumbnailFile);

        if (this.state.calenderUuid) {
            const response = await putAuthCommonApi(`calender/${this.state.calenderUuid}`, data);
            if (response.status === 200) {
                this.setState({ resLoading: false });
                this.props.history.push({ pathname: `/calender`, search: 'pageSize=10&pageNumber=1' });
            } else {
                this.setState({ resLoading: false });
            }
        } else {
            const response = await postAuthCommonApi('/calender', data);
            if (response.status === 200) {
                this.setState({ resLoading: false });
                this.props.history.push({ pathname: `/calender`, search: 'pageSize=10&pageNumber=1' });
            } else {
                this.setState({ resLoading: false });
            }
        }
    };


    render() {
        const {
            uploadImage,
            thumbnailFile,
            filterOptions

        } = this.state;
        // const {platformData}=this.props.autoShareState
        return (
            <section className="add-section">
                <ComponentHeader
                    title={this.state.calenderUuid ? "Update Calender" : "Add Calender"}
                    primaryBtn="Save"
                    isBackArrow={true}
                    resLoading={this.state.resLoading}
                    isReturn="/calender?pageSize=10&pageNumber=1"
                    handlePrimaryBtn={this.handleSave}
                />

                <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>
                    <Row>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "calenderName")}
                                label="Calender Name"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Name cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Enter Name",
                                    },
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "filtersUuid")}
                                label="Select Filter"
                                required
                                validations={[
                                    {
                                        validate: FormValidator.isRequired,
                                        message: "Filter cannot be empty",
                                    },
                                ]}
                                control={{
                                    type: SelectControl,
                                    settings: {
                                        placeholder: "Select Filter",
                                        options: filterOptions,
                                        multiple: true,
                                        searchable: true,
                                    },
                                }}
                            />
                            {this.state.filtersUuid?.length > 0 &&
                                <ClearButton onClick={() => this.setState({ filtersUuid: [] })} />
                            }
                        </Col>
                    </Row>
                    <Row>

                        <Col md={4}>
                            <FormElement
                                valueLink={this.linkState(this, "description")}
                                label="Description"
                                helpText="Description can't be more than 4000 character"
                                // required
                                // validations={[
                                //   {
                                //     validate: FormValidator.isRequired,
                                //     message: "Field cannot be empty",
                                //   },
                                // ]}
                                control={{
                                    type: CustomTextControl,
                                    settings: {
                                        placeholder: "Please enter description",
                                        as: "textarea",
                                        rows: 5,
                                    },
                                }}
                                classes={{
                                    inputField: "custom-input",
                                    label: "custom-label",
                                }}
                            />
                        </Col>

                        <Col sm={4}>
                            <ImageUploader required={true} id="Thumbnail" label="Upload Thumbnail" name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile} onChange={(e) => this.imageUploaser(e, "thumbnail")} onDelete={() => this.setState({ thumbnailFile: "" })} />
                        </Col>

                    </Row>


                </Form>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    calenderFilterData: state.SubdomainState.calenderFilterList,
});
const mapDispatchToProps = {


    getAllCalenderApi,
    getAllCalenderFilterApi

};
AddEditCalender.propTypes = {


};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCalender);