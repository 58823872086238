import React, { Component } from 'react';
import { connect } from "react-redux";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { ComponentHeader, InformationModal, VerifiedModal } from '../common';
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { Button, Col, Image, Row } from "react-bootstrap";
import { START_PAGE, isModuleExist } from "../../utils/Constant";
import editPencil from "../../assets/images/edit-icon.png";
import { TEMPLATE_VERIFY } from '../../utils/urls';
import FilterVefiedDropDown from '../common/FilterVefiedDropDown';
import { deleteAuthCommonApi } from "../content/Api";
import { toast } from 'react-toastify';
import { getAllTemplatesApi } from './Api';
import { replaceHistory } from '../../utils/ReusableFunctions';



class Templates extends Component {
    constructor(props) {
        super(props);
        const AccesData = isModuleExist("Personalization", 'Template');

        this.state = {
            access: AccesData,
            delete: false,
            deleteData: {},
            editData: "",
            showEdit: false,
            isVerify: false,
            show: false,
            verified: '',
            verifyUrl: '',
            search: ""
        }
    }

    componentDidMount() {
        const { history } = this.props;
        history.push({
            pathname: this.props.location.pathname,
            search: `?p=${this.state.currentPage || START_PAGE}`,
        });
        this.props.getAllTemplatesApi(this);
    }
    componentDidUpdate(prevProps, prevState) {
        const prevParams = new URLSearchParams(prevProps.location.search);
        const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
        const prevVerified = prevParams.get("isVerified") || "";
        const prevSearch = prevParams.get("search") || "";


        const params = new URLSearchParams(this.props.location.search);
        const page = parseInt(params.get("p"), 10) || START_PAGE;
        const search = params.get("search") || "";

        const verified = params.get("isVerified") || "";

        if (prevPage !== page || prevSearch !== search || prevVerified !== verified) {
            this.setState({ currentPage: page, search }, () => {
                this.props.getAllTemplatesApi(this);

            });
        }
    };
    getDetailsTemplate = (data) => {
        this.props.history.push({ pathname: `/template/details/${data?.uuid}`, state: { "data": data } });
    }


    showEditModal = (rowData = null) => {
        this.props.history.push({ pathname: `/personalization/edit-template`, state: { "data": rowData, "id": rowData.uuid } })
        this.setState({
            showEdit: !this.state.showEdit,
            editData: rowData
        });
    };
    openCloseDeleteModal = (rowData) => {
        this.setState({
            delete: !this.state.delete,
            deleteData: rowData,
        });
    };
    onChangeMethod = (value) => {
        this.setState({ searchText: value.search, currentPage: START_PAGE }, () => {
            replaceHistory(this.props.history, START_PAGE, value.search);
        })
    };
    clearSearch = () => {
        this.onChangeMethod({ search: "" });
    }
    deleteHandle = async (id) => {
        const res = await deleteAuthCommonApi(`/personalization/template/${id}`);
        // console.log(res)
        if (res?.status === 200) {
            this.props.getAllTemplatesApi(this);
            this.setState({
                delete: !this.state.delete
            });

        } else {
            toast.error("Somthis wrong...")
        }
    }

    render() {
        const { access } = this.state;

        const { templateList, totalPages, currentPage } = this.props.personalizationState;

        return (
            <>

                {this.state.delete && (
                    <InformationModal
                        show={this.state.delete}
                        id={this.state.deleteData?.uuid}
                        title={`Are you sure you want to delete ${this.state.deleteData?.templateName} ?`}
                        handleClose={this.openCloseDeleteModal}
                        handleDelete={this.deleteHandle}
                    />
                )}
                <VerifiedModal
                    name={`'${this.state.editData?.templateName}'`}
                    show={this.state.show}
                    url={TEMPLATE_VERIFY + `/${this.state?.editData?.uuid}`}
                    isVerified={this.state.isVerify}
                    handleCancel={() => { this.setState({ show: false }) }}
                    successRes={() => { this.props.getAllTemplatesApi(this) }}
                />
                <section className="request-section">
                    <ComponentHeader
                        title="Templates"
                        isSearch={true}
                        placeholder={"Search Template"}
                        onChangeMethod={this.onChangeMethod}
                        clearSearch={() => this.clearSearch()}
                        primaryBtn={access.createAccess ? "Add Template" : ""}
                        handlePrimaryBtn={() => {
                            this.props.history.push({ pathname: "/personalization/add-template", state: { templateList } });
                        }}
                    />
                    <Row>
                        <Col sm={2}>
                            <FilterVefiedDropDown ctx={this} />
                        </Col>
                    </Row>
                    <div className="commom-table-wrapper">
                        <CustomTable
                            tableData={templateList?.list || []}
                            columnList={[
                                {
                                    coumnWidth: 180,
                                    labelName: "Template Name",
                                    dataKey: "templateName",
                                    className: "name",
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "templateName") {
                                            return rowData?.templateName;
                                        }
                                    },
                                },
                                {
                                    coumnWidth: access.verificationAccess ? 110 : 0,
                                    labelName: "Verified",
                                    dataKey: "isVerified",
                                    className: "verify",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "isVerified") {
                                            return <div className=" d-flex align-items-center inter-medium">
                                                {(rowData?.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData?.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                                            </div>;
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 150,
                                    labelName: "Template Image",
                                    dataKey: "thumbnailImage",
                                    className: "logo",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "thumbnailImage") {
                                            return <Image className="table-img" src={rowData?.thumbnailImage} alt="Thumbnail Image" />;

                                        }
                                    },
                                },


                                {
                                    coumnWidth: 200,
                                    labelName: "Description",
                                    dataKey: "description",
                                    className: "description",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "description") {
                                            return rowData?.description || '';
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 350,
                                    coumnWidth: access.updateAccess || access.deleteAccess ? 350 : 0,
                                    labelName: "Action",
                                    dataKey: "",
                                    className: "",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "") {
                                            return (


                                                <>
                                                    <Button onClick={() => this.getDetailsTemplate(rowData)} className=" primary-btn mr-4">
                                                        View Detail
                                                    </Button>

                                                    {access.updateAccess && (
                                                        <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                                                    )}
                                                    {access.deleteAccess && (
                                                        <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                                                    )}
                                                </>

                                            );
                                        }
                                    },
                                },
                            ]}
                            message="No data found" // For Pagination
                            history={this.props.history}
                            location={this.props.location}
                            totalPages={totalPages}
                            currentPage={currentPage}
                        />
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => ({
    personalizationState: state.PersonalizationState
});
const mapDispatchToProps = {
    getAllTemplatesApi,
}
Templates.propTypes = {
    // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);