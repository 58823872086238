import React from "react";

import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import { CustomModal } from "../common";

class ContentTypeForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;
    this.state = {
      contentTypeName: data.uuid || "",
    };
  }

  componentDidMount() { }


  render() {
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={"Add Content Type"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "contentTypeName")}
                label="Content Type Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Content Type Name",
                  },
                }}
              />
            </Col>
          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              Save
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

export default ContentTypeForm;
