import React, { Component } from "react";
import { connect } from "react-redux";
import ComponentHeader from "../common/ComponentHeader";
import CustomTable from "../../utils/commonComponent/CustomTable";
import { Col, Image, Row } from "react-bootstrap";
import editIcon from '../../assets/images/edit-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { getAllOccassionDataApi } from "./Api";
import { API_LIMIT, START_PAGE, formatDate, isModuleExist } from "../../utils/Constant";
import { InformationModal, VerifiedModal } from "../common";
import { toast } from "react-toastify";
import { EVENT_VERIFY, OCCASSION_VERIFY } from "../../utils/urls";
import FilterVefiedDropDown from "../common/FilterVefiedDropDown";
import { deleteAuthCommonApi } from "../content/Api";
import editPencil from "../../assets/images/edit-icon.png";



class Occassion extends Component {
    constructor(props) {
        super(props);
        const AccesData = isModuleExist("Auto Share", 'Occassion');

        this.state = {
            access: AccesData,
            search: "",
            pageSize: API_LIMIT,
            currentPage: START_PAGE,
            delete: false,
            deleteData: {},
            editData: "",
            showEdit: false,


            isVerify: false,
            show: false,

            verified: '',
            verifyUrl: ''

        };
    }


    componentDidMount() {
        const { history } = this.props;
        history.push({
            pathname: this.props.location.pathname,
            search: `?p=${this.state.currentPage || START_PAGE}`,
        });
        this.props.getAllOccassionDataApi(this);

    }

    componentDidUpdate(prevProps, prevState) {
        const prevParams = new URLSearchParams(prevProps.location.search);
        const prevPage = parseInt(prevParams.get("p"), 10) || START_PAGE;
        const prevVerified = prevParams.get("isVerified") || "";


        const params = new URLSearchParams(this.props.location.search);
        const page = parseInt(params.get("p"), 10) || START_PAGE;
        const verified = params.get("isVerified") || "";

        // const search = params.get("search") || "";

        if (prevPage !== page || prevVerified !== verified) {
            this.setState({ currentPage: page }, () => {
                this.props.getAllOccassionDataApi(this);

            });
        }
    }

    // getDetailsUser = (data) => {
    //     this.props.history.push({ pathname: `/platform/details/${data?.uuid}`, state: { "id": data?.uuid } });
    // }

    showEditModal = (rowData = null) => {
        this.props.history.push({ pathname: `/occassion/add-occassion`, state: { data: rowData } })
        this.setState({
            showEdit: !this.state.showEdit,
            editData: rowData,
        });
    };

    openCloseDeleteModal = (rowData) => {
        this.setState({
            delete: !this.state.delete,
            deleteData: rowData,
        });
    };

    deleteHandle = async (id) => {
        const res = await deleteAuthCommonApi(`/playlist/content/${id}`);
        // console.log(res)
        if (res.status === 200) {
            this.props.getAllOccassionDataApi(this);
            this.setState({
                delete: !this.state.delete
            });

        } else {
            toast.error("Somthis wrong...")
        }
    }

    render() {
        const { access } = this.state;

        const { occassionData, totalPages, currentPage } = this.props.autoShareState;
        // console.log(eventData)
        return (
            <>

                {this.state.delete && (
                    <InformationModal
                        show={this.state.delete}
                        id={this.state.deleteData.uuid}
                        title={`Are you sure you want to delete ${this.state.deleteData.platformName}?`}
                        handleClose={this.openCloseDeleteModal}
                        handleDelete={this.deleteHandle}
                    />
                )}
                <VerifiedModal
                    name={`'${this.state.editData?.occasionName}'`}
                    show={this.state.show}
                    url={OCCASSION_VERIFY + `/${this.state?.editData?.uuid}`}
                    isVerified={this.state.isVerify}
                    handleCancel={() => { this.setState({ show: false }) }}
                    successRes={() => { this.props.getAllOccassionDataApi(this) }}
                />
                <section>
                    <ComponentHeader
                        title="Occassion"
                        primaryBtn={access.createAccess ? "Add Occassion" : ""}
                        isSearch={true}
                        // isBackArrow={true}
                        // isReturn="/contentCollections"
                        handlePrimaryBtn={() => { this.props.history.push('/occassion/add-occassion') }}
                    />
                    <Row>
                        <Col sm={2}>
                            <FilterVefiedDropDown ctx={this} />
                        </Col>
                    </Row>
                    <div className="commom-table-wrapper">
                        <CustomTable
                            tableData={occassionData?.list}
                            columnList={[
                                {
                                    coumnWidth: 200,
                                    labelName: "Occassion Name",
                                    dataKey: "occasionName",
                                    className: "occassionName",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "occasionName") {
                                            return rowData.occasionName;
                                        }
                                    },
                                },
                                {
                                    coumnWidth: access.verificationAccess ? 120 : 0,
                                    labelName: "Verified",
                                    dataKey: "isVerified",
                                    className: "name",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "isVerified") {
                                            return <div className=" d-flex align-items-center inter-medium">
                                                {(rowData?.isVerified) ? "Yes" : 'No'} <Image src={editPencil} onClick={() => this.setState({ show: true, isVerify: rowData.isVerified, editData: rowData })} className="edit-pencil-icon" alt="icon" />
                                            </div>;
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 200,
                                    labelName: "Date",
                                    dataKey: "date",
                                    className: "",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "date") {
                                            return formatDate(rowData?.date)
                                        }
                                    },
                                },
                                {
                                    coumnWidth: 350,
                                    labelName: "Content",
                                    dataKey: "contentVariation",
                                    className: "content",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "contentVariation") {
                                            return rowData?.contentVariation?.title;
                                        }
                                    },
                                },

                                {

                                    coumnWidth: access.updateAccess || access.deleteAccess ? 250 : 0,
                                    labelName: "Action",
                                    dataKey: "",
                                    className: "",
                                    isCell: true,
                                    cell: (rowData, dataKey) => {
                                        if (dataKey === "") {
                                            return (
                                                <>

                                                    {access.updateAccess && (
                                                        <Image src={editIcon} className="edit-icon" onClick={() => { this.showEditModal(rowData) }} />
                                                    )}
                                                    {access.deleteAccess && (
                                                        <Image src={deleteIcon} className="edit-icon" onClick={() => { this.openCloseDeleteModal(rowData) }} />
                                                    )}
                                                </>
                                            );
                                        }
                                    },

                                }

                            ]}
                            message="No data found" // For Pagination
                            history={this.props.history}
                            location={this.props.location}
                            totalPages={totalPages}
                            currentPage={currentPage}
                        />
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    autoShareState: state.AutoShareState,
});
const mapDispatchToProps = {
    getAllOccassionDataApi,
    deleteAuthCommonApi

};
Occassion.propTypes = {
    // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Occassion);
