import { toast } from "react-toastify";
import { postLoginInstance } from "../../utils";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
import { getAllEventData } from "./EventsAction";


export const getAllEventDataApi = (ctx = null) => {

  const { verifyUrl = false } = ctx?.state ?? {};
  return function (dispatch, getState) {
    return postLoginInstance.get(`events?${verifyUrl ? verifyUrl + '&' : ''}pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}`)
      .then((res) => {

        dispatch(getAllEventData(res.data.data, ctx?.state?.currentPage || START_PAGE));

      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Something went wrong")
      })
  }

};

export const addEventApi = (data, ctx, onHide) => {
  return postLoginInstance.post("events", data).then((res) => {
    ctx.props.getAllEventDataApi(null, onHide);
  })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "Something went wrong")
    })
};
export const deleteEventApi = (id, ctx, onHide) => {
  return postLoginInstance.delete(`events/${id}`).then((res) => {
    ctx.props.getAllEventDataApi(null, onHide);
  }).catch((error) => {
    toast.error(error?.response?.data?.message || "Something went wrong")
  })
};
export const updateEventApi = (data, ctx, onHide, id) => {
  return postLoginInstance.put(`events/${id}`, data).then((res) => {
    ctx.props.getAllEventDataApi(null, onHide);
  }).catch((error) => {
    toast.error(error?.response?.data?.message || "Something went wrong")
  })
};



export const getEventOrganiserApi = (ctx) => {
  return postLoginInstance.get(`eventOrganiser`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.eventOrganiserName,
          value: item.uuid
        }))
      ctx.setState({
        eventOrganiserOptions: option
      })
    }).catch((error) => {
      toast.error(error?.response?.data?.message || "Something went wrong")
    })
}
export const getEventCategoryApi = (ctx) => {
  return postLoginInstance.get(`eventCategory`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.eventCategoryName,
          value: item.uuid
        }))
      ctx.setState({
        eventCategoryOptions: option
      })
    })
}

export const getEventSpeakerApi = (ctx) => {
  return postLoginInstance.get(`eventSpeaker`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.speakerName,
          value: item.uuid
        }))
      ctx.setState({
        eventSpeakerOptions: option
      })
    }).catch((error) => {
      toast.error(error?.response?.data?.message || "Something went wrong")
    })
}
export const getAllEventsApi = (ctx) => {
  return postLoginInstance.get(`events?pageSize=10000&pageNumber=1`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.name,
          value: item.uuid
        }))
      ctx.setState({
        eventOptions: option
      })
    }).catch((error) => {
      toast.error(error?.response?.data?.message || "Something went wrong")
    })
}
export const getAllCitesApi = (ctx) => {
  return postLoginInstance.get(`website/location/allCities?pageSize=${ctx?.state.pageSize || API_LIMIT}&pageNumber=${ctx?.state.currentPage || START_PAGE}  ${ctx?.state.locationSearch && `&searchValue=${ctx?.state.locationSearch}`}`)
    .then((res) => {
      let option = [];

      res.data.data.sort().map((item) =>
        option.push({
          label: item,
          value: item
        }))
      ctx.setState({
        allCities: option
      })
    }).catch((error) => {
      toast.error(error?.response?.data?.message || "Something went wrong")
    })
}
export const getAmcApi = (ctx) => {
  return postLoginInstance.get(`amc`)
    .then((res) => {
      let option = [];
      res.data.data.list.map((item) =>
        option.push({
          label: item.amcName,
          value: item.uuid
        }))
      ctx.setState({
        amcOption: option
      })
    }).catch((error) => {
      toast.error(error?.response?.data?.message || "Something went wrong")
    })
};
