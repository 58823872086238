import React, { useEffect, useRef, useState } from "react"
import { fabric } from "fabric"
import { Dropdown, Overlay, Popover } from "react-bootstrap"
import { createPortal } from "react-dom"
import { ChromePicker, CirclePicker } from "react-color"
import "./canvas-history"
import "./canvas-placeholder"
import { PropTypes } from "prop-types"
import {
  widthIcon,
  heightIcon,
  textUploadIcon,
  imgUploadIcon,
  layerIcon,
  disabledLayerIcon,
  layerSendToBackIcon,
  layerBringToFrontIcon,
  undoIcon,
  disabledUndoIcon,
  redoIcon,
  disabledRedoIcon,
  plusIcon,
  minusIcon,
  textBoldIcon,
  textItalicIcon,
  textUnderlineIcon,
  textAlignLeftIcon,
  textAlignCenterIcon,
  textAlignRightIcon,
  textColor,
  textStrike,
  seperatorIcon,
  rotateSvg,
  duplicateIcon,
  deleteIcon,
  addIcon,
  eyeDropperIcon,
  zoomInIcon,
  zoomOutIcon,
  lockIconOpen,
  lockIconClose,
  circleIcon,
  squareIcon,
  placeholderIcon
} from "../../../assets/images/canvas"

const popularFonts = [
  "Roboto",
  "Lobster",
  "Arial",
  "Times New Roman",
  "Verdana",
  "Pacifico",
  "Poppins",
  "Montserrat",
  "Open Sans",
  "Oswald",
  "Raleway",
  "Dancing Script"
]

const CanvasCobrand = ({ canvas, setCanvas, screen, updateCanvasDimensions, height, width, setHeight, setWidth }) => {
  const [activeObject, setActiveObject] = useState(null)

  const [scale, setScale] = useState(100);
  const [canvasPages, setCanvasPages] = useState({});
  const [settings, setSettings] = useState({
    text: {
      ...initialTextSettings
    },
    image: {
      ...initialImageSettings
    }
  })
  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0,
    visible: false
  })
  const inputRef = useRef(null)
  const [activeObjectSettings, setActiveObjectSettings] = useState({})
  const [showLayersSettings, setShowLayersSettings] = useState(false)
  const [showPlaceholderSettings, setShowPlaceholderSettings] = useState(false)

  const layerIconRef = useRef(null)
  const layerMobileIconRef = useRef(null)
  const placeholderIconRef = useRef(null)

  const getHistoryRedoIcon = () => {
    return canvas?.ref?.canRedo() ? redoIcon : disabledRedoIcon
  }
  const getHistoryUndoIcon = () => {
    return canvas?.ref?.canUndo() ? undoIcon : disabledUndoIcon
  }

  const downloadHighResImage = () => {
    if (!canvas.ref) return
    const highResImage = canvas.ref.toDataURL({ format: "png", multiplier: 3 })
    const link = document.createElement("a")
    link.href = highResImage
    link.download = "cobrand-image.png"
    link.click()
  };

  fabric.Object.prototype.toObject = (function (toObject) {
    return function () {
      return fabric.util.object.extend(toObject.call(this), {
        lockMovementX: this.lockMovementX,
        lockMovementY: this.lockMovementY,

        selectable: this.selectable,
        hasControls: this.hasControls,
        editable: this.editable,
        disableHistory: this.disableHistory || true, // Include disableHistory with a default value

      });
    };
  })(fabric.Object.prototype.toObject);


  fabric.Object.prototype.toObject = (function (toObject) {
    return function () {
      const obj = toObject.call(this);

      if (this.type === "textbox") {
        obj.text = this.text || "";
      }

      return obj;
    };
  })(fabric.Object.prototype.toObject);


  fabric.Object.prototype.toObject = (function (toObject) {
    return function () {
      const obj = toObject.call(this);


      if (this.type === "textbox") {
        obj.fontSize = this.fontSize; // Ensure fontSize is serialized correctly
        obj.scaleX = this.scaleX || 1;
        obj.scaleY = this.scaleY || 1;
        obj.fontWeight = this.fontWeight || "normal";
        obj.fontStyle = this.fontStyle || "normal";
        obj.underline = this.underline || false;
        obj.fontFamily = this.fontFamily || "Arial";
        obj.linethrough = this.linethrough || false;
        obj.textAlign = this.textAlign || "left";
      }

      return obj;
    };
  })(fabric.Object.prototype.toObject);

  fabric.Object.prototype.toObject = (function (toObject) {
    return function () {
      const obj = toObject.call(this);

      if (this.type === "image") {
        obj.width = this.width;
        obj.height = this.height;
        obj.scaleX = this.scaleX || 1;
        obj.scaleY = this.scaleY || 1;
      }

      return obj;
    };
  })(fabric.Object.prototype.toObject);

  const addRectanglelaceholder = () => {
    if (canvas?.ref) {
      const rect = new fabric.Rect({
        left: 10,
        top: 20,
        width: 150,
        height: 100,
        fill: "transparent",
        stroke: "black",
        strokeWidth: 1,
      });
      rect.toPlaceholder();
      rect.attachPlaceholder(canvas.ref); // Attach placeholder behavior
      canvas.ref.add(rect);
      canvas.ref.renderAll();
    }
  };
  const addCirclePlaceholder = () => {
    if (canvas?.ref) {
      const circle = new fabric.Circle({
        left: 10,
        top: 20,
        radius: 60,
        fill: "transparent",
        stroke: "black",
        strokeWidth: 1,

      });
      canvas.ref.add(circle);
      canvas.ref.renderAll();

      circle.toPlaceholder();
      circle.attachPlaceholder(canvas.ref, canvas?.ref?._offset);
    }
  };

  useEffect(() => {
    const link = document.createElement("link")
    link.href = `https://fonts.googleapis.com/css2?${popularFonts
      .map((font) => `family=${font.replace(" ", "+")}`)
      .join("&")}&display=swap`
    link.rel = "stylesheet"
    document.head.appendChild(link)

    if (!fabric.Canvas.prototype.updateZIndices) {
      fabric.Canvas.prototype.updateZIndices = function () {
        const objects = this.getObjects()
        objects.forEach((obj, index) => {
          addIdToObject(obj)
          obj.zIndex = index
        })
      }
    }


    fabric.Object.prototype.cornerSize = 6


  }, []);

  const adjustTextboxWidth = (textbox) => {
    if (!textbox) return;
    const context = document.createElement("canvas").getContext("2d");
    context.font = `${textbox.fontWeight} ${textbox.fontStyle} ${textbox.fontSize}px ${textbox.fontFamily}`;
    const lines = textbox.text.split("\n");
    const maxWidth = lines.reduce((width, line) => {
      const lineWidth = context.measureText(line).width;
      return Math.max(width, lineWidth);
    }, 0);
    textbox.set({
      width: maxWidth + 10,
    });
    textbox.initDimensions();
  };

  useEffect(() => {
    if (!canvas.ref) return;

    const handleObjectMoving = (e) => {
      const obj = e.target;

      const canvasWidth = canvas.ref.getWidth();
      const canvasHeight = canvas.ref.getHeight();

      const objWidth = obj.getScaledWidth();
      const objHeight = obj.getScaledHeight();

      if (obj.left < 0) {
        obj.left = 0;
      }
      if (obj.top < 0) {
        obj.top = 0;
      }
      if (obj.left + objWidth > canvasWidth) {
        obj.left = canvasWidth - objWidth;
      }
      if (obj.top + objHeight > canvasHeight) {
        obj.top = canvasHeight - objHeight;
      }

      obj.setCoords();
    };

    const handleObjectScaling = (e) => {
      const obj = e.target;

      const canvasWidth = canvas.ref.getWidth();
      const canvasHeight = canvas.ref.getHeight();

      const objWidth = obj.getScaledWidth();
      const objHeight = obj.getScaledHeight();

      // Restrict scaling if the object's scaled dimensions exceed the canvas
      if (objWidth > canvasWidth || objHeight > canvasHeight) {
        const scaleX = canvasWidth / obj.width;
        const scaleY = canvasHeight / obj.height;
        const newScale = Math.min(scaleX, scaleY);

        obj.scaleX = Math.min(obj.scaleX, newScale);
        obj.scaleY = Math.min(obj.scaleY, newScale);
      }

      // Restrict scaling if the object is scaled below its original size
      const minScale = 0.1; // Example: set the minimum scale to 10% of the original size
      if (obj.scaleX < minScale) {
        obj.scaleX = minScale;
      }
      if (obj.scaleY < minScale) {
        obj.scaleY = minScale;
      }

      // Ensure the object remains within canvas boundaries during scaling
      const scaledWidth = obj.getScaledWidth();
      const scaledHeight = obj.getScaledHeight();

      if (obj.left < 0) {
        obj.left = 0;
      }
      if (obj.top < 0) {
        obj.top = 0;
      }
      if (obj.left + scaledWidth > canvasWidth) {
        obj.left = canvasWidth - scaledWidth;
      }
      if (obj.top + scaledHeight > canvasHeight) {
        obj.top = canvasHeight - scaledHeight;
      }

      obj.setCoords();
    };

    canvas.ref.on("object:moving", handleObjectMoving);
    canvas.ref.on("object:scaling", handleObjectScaling);

    return () => {
      canvas.ref.off("object:moving", handleObjectMoving);
      canvas.ref.off("object:scaling", handleObjectScaling);
    };
  }, [canvas.ref]);



  useEffect(() => {
    if (!canvas?.ref) return;

    const rotateIcon = new Image();
    rotateIcon.src = rotateSvg;

    rotateIcon.onload = function () {
      if (!canvas?.ref) return;
      const ctx = canvas.ref.getContext("2d");
      if (!ctx) return;

      const customRotateControl = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetY: -30,
        cursorStyle: "crosshair",
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        actionName: "rotate",
        render: renderIcon,
        cornerSize: 28
      });

      function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(rotateIcon, -size / 2, -size / 2, size, size);
        ctx.restore();
      }

      fabric.Textbox.prototype.controls.mtr = customRotateControl;
      fabric.Image.prototype.controls.mtr = customRotateControl;

      canvas?.ref?.getObjects().forEach((obj) => {
        obj.setControlsVisibility({
          mtr: true
        });
      });
      fabric.Object.prototype._fromObject = (function (_fromObject) {
        return function (object, callback) {
          // Restore custom properties
          this.set({
            lockMovementX: object.lockMovementX || false,
            lockMovementY: object.lockMovementY || false,
            lockScalingX: object.lockScalingX || false,
            lockScalingY: object.lockScalingY || false,
            lockRotation: object.lockRotation || false,
            selectable: object.selectable !== undefined ? object.selectable : true,
            hasControls: object.hasControls !== undefined ? object.hasControls : true,
            editable: object.editable !== undefined ? object.editable : true,
            disableHistory: object.disableHistory || true, // Restore disableHistory property
          });


          return _fromObject.call(this, object, callback);
        };
      })(fabric.Object.prototype._fromObject);

      fabric.Textbox.fromObject = (function (fromObject) {
        return function (object, callback) {
          object.text = object.text !== undefined ? object.text : "";
          return fromObject.call(this, object, callback);
        };
      })(fabric.Textbox.fromObject);

      fabric.Textbox.fromObject = (function (fromObject) {
        return function (object, callback) {
          const restoredObject = fromObject.call(this, object, callback);

          if (restoredObject) {
            // Restore scaling and ensure font size consistency
            restoredObject.set({
              fontSize: object.fontSize || 16, // Restore the original font size
              scaleX: object.scaleX || 1,
              scaleY: object.scaleY || 1,
              fontWeight: object.fontWeight || "normal",
              fontStyle: object.fontStyle || "normal",
              underline: object.underline || false,
              fontFamily: object.fontFamily || "Arial",
              linethrough: object.linethrough || false,
              textAlign: object.textAlign || "left",

            });
          }

          return restoredObject;
        };
      })(fabric.Textbox.fromObject);

      fabric.Image.fromObject = function (object, callback) {
        fabric.util.loadImage(object.src, (img) => {
          if (!img) return;

          const instance = new fabric.Image(img, {
            left: object.left || 0,
            top: object.top || 0,
            width: object.width,
            height: object.height,
            scaleX: object.scaleX || 1,
            scaleY: object.scaleY || 1,
            lockMovementX: object.lockMovementX || false, // Restore lock state
            lockMovementY: object.lockMovementY || false,
            selectable: object.selectable !== undefined ? object.selectable : true,
            hasControls: object.hasControls !== undefined ? object.hasControls : true,
            editable: object.editable !== undefined ? object.editable : true, // Restore editable state
          });

          if (callback) {
            callback(instance);
          }
        });
      };




      fabric.Textbox.prototype.initDimensions = (function (initDimensions) {
        return function () {
          // Ensure scaling is safely reset
          this.fontSize = this.fontSize / (this.scaleY || 1); // Normalize font size
          this.scaleX = 1;
          this.scaleY = 1;

          // Call the original initDimensions method
          initDimensions.call(this);
        };
      })(fabric.Textbox.prototype.initDimensions);







      canvas?.ref?.renderAll();
    };
  }, [canvas?.ref]);

  useEffect(() => {
    const canvasId = `screen-${screen}`;
    let fabricCanvas;

    if (canvasId) {

      const initCanvas = () => {
        fabricCanvas = new fabric.Canvas(canvasId, {
          width: canvas.canvasWidth || 800,
          height: canvas.canvasHeight || 450,
          // background: canvas.backgroundColor || "#ffffff",
        });
        fabricCanvas.setBackgroundColor(canvas.backgroundColor, () => {
          fabricCanvas.renderAll();
        });

        if (canvas.ref) {
          const canvasJSON = canvas.ref.toJSON();
          fabricCanvas.loadFromJSON(canvasJSON, () => {
            fabricCanvas.getObjects().forEach((obj) => {
              if (obj.type === "rect") {
                obj.toPlaceholder(); // Add placeholder behavior for rectangle
                obj.attachPlaceholder(fabricCanvas, fabricCanvas._offset); // Attach placeholder div
              } else if (obj.type === "circle") {
                obj.toPlaceholder(); // Add placeholder behavior for circle
                obj.attachPlaceholder(fabricCanvas, fabricCanvas._offset); // Attach placeholder div
              }
            });
            fabricCanvas.renderAll();
          });
        }
        else if (Array.isArray(canvas.layers) && canvas.layers.length > 0) {

          const jsonData = {
            version: "5.2.1",
            objects: [...canvas.layers],
            background: canvas.backgroundColor || "#ffffff",
          };
          fabricCanvas.loadFromJSON(jsonData, () => {
            fabricCanvas.getObjects().forEach((obj) => {
              if (obj.type === "rect") {
                obj.toPlaceholder(); // Add placeholder behavior for rectangle
                obj.attachPlaceholder(fabricCanvas, fabricCanvas._offset); // Attach placeholder div
              } else if (obj.type === "circle") {
                obj.toPlaceholder(); // Add placeholder behavior for circle
                obj.attachPlaceholder(fabricCanvas, fabricCanvas._offset); // Attach placeholder div
              }
            });
            fabricCanvas.renderAll();
          });
          fabricCanvas.setBackgroundColor(canvas.backgroundColor, () => {
            fabricCanvas.renderAll();
          });
        }

        setCanvas({
          ...canvas,
          ref: fabricCanvas,
        })

        return fabricCanvas;
      };

      fabricCanvas = initCanvas();
    }
    return () => {
      const placeholders = document.getElementsByClassName("placeholder");

      // Convert to a static array
      const placeholdersArray = Array.from(placeholders);

      // Remove each placeholder
      placeholdersArray.forEach((placeholder) => placeholder.remove());

      console.log("All placeholders removed:", placeholdersArray.length);
    };

  }, []);
  useEffect(() => {
    if (!canvas.ref) return;
    canvas.ref.setWidth(canvas.canvasWidth);
    canvas.ref.setHeight(canvas.canvasHeight);
    canvas.ref.setBackgroundColor(canvas.backgroundColor, () => {
      canvas.ref.renderAll();
    }
    );
  }, [canvas.backgroundColor, canvas.canvasWidth, canvas.canvasHeight]);

  useEffect(() => {
    if (!canvas.ref) {
      return
    };



    const handleSelection = (e) => {
      const selectedObject =
        e.selected && e.selected.length > 0 ? e.selected[0] : null
      setActiveObject(selectedObject)
      if (selectedObject) {
        if (selectedObject.type === "textbox") {
          setActiveObjectSettings({
            fontSize: selectedObject.fontSize,
            fill: selectedObject.fill,
            fontFamily: selectedObject.fontFamily,
            fontWeight: selectedObject.fontWeight,
            fontStyle: selectedObject.fontStyle,
            underline: selectedObject.underline,
            linethrough: selectedObject.linethrough,
            textAlign: selectedObject.textAlign,
          })
        } else if (selectedObject.type === "image") {
        }
        updateTooltipPosition(selectedObject);

      }
    }
    const handleObjectChange = () => {
      canvas?.ref?.updateZIndices()
    }

    const handleObjectMovingOrScaling = () => {
      const activeObject = canvas.ref.getActiveObject()
      if (activeObject) {
        updateTooltipPosition(activeObject)
      }
    }

    canvas.ref.on("object:moving", handleObjectMovingOrScaling)
    canvas.ref.on("object:scaling", handleObjectMovingOrScaling)
    canvas.ref.on("object:modified", handleObjectMovingOrScaling)
    canvas.ref.on("selection:created", handleSelection)
    canvas.ref.on("selection:updated", handleSelection)
    canvas.ref.on("selection:cleared", () => {
      setActiveObject(null)
    })
    canvas.ref.on("object:added", handleObjectChange)
    canvas.ref.on("object:modified", handleObjectChange)
    canvas.ref.on("object:removed", handleObjectChange)

    return () => {
      canvas.ref.off("selection:created", handleSelection)
      canvas.ref.off("selection:updated", handleSelection)
      canvas.ref.off("selection:cleared")
      canvas.ref.off("object:added", handleObjectChange)
      canvas.ref.off("object:modified", handleObjectChange)
      canvas.ref.off("object:removed", handleObjectChange)
      canvas.ref.off("object:moving", handleObjectMovingOrScaling)
      canvas.ref.off("object:scaling", handleObjectMovingOrScaling)
      canvas.ref.off("object:modified", handleObjectMovingOrScaling)
    }
  }, [canvas.ref])

  useEffect(() => {
  if (!canvas.ref || !canvas.ref.upperCanvasEl) return; // Ensure upperCanvasEl is defined

  const upperCanvas = canvas.ref.upperCanvasEl;
  upperCanvas.style.transform = `scale(${scale / 100})`;
  }, [scale, canvas.ref]);

  const updateTooltipPosition = (object) => {
    if (!object) return;

    const canvasRect = canvas.ref.getElement().getBoundingClientRect();
    const objectRect = object.getBoundingRect();

    const top = objectRect.top + canvasRect.top - 60 + window.scrollY;
    const left = objectRect.left + canvasRect.left + objectRect.width - 30 + window.scrollX;

    setTooltipPosition({ top, left, visible: true });
  };

  const loadFont = async (font) => {
    return await document.fonts.load(`16px ${font}`)
  }

  const undo = () => {
    canvas.ref.undo()
  }

  const redo = () => {
    canvas.ref.redo()
  }
  const moveSelectedObject = (direction) => {
    if (!activeObject) return

    const objects = canvas.ref.getObjects()
    const object = objects.find((obj) => obj.id === activeObject?.id)
    if (object) {
      const currentIndex = objects.indexOf(object)
      if (direction === "up" && currentIndex < objects.length - 1) {
        const current = objects[currentIndex]
        objects[currentIndex] = objects[currentIndex + 1]
        objects[currentIndex + 1] = current
      } else if (direction === "down" && currentIndex > 0) {
        const current = objects[currentIndex]
        objects[currentIndex] = objects[currentIndex - 1]
        objects[currentIndex - 1] = current
      }

      const backgroundColor = canvas.ref.backgroundColor
      canvas.ref.clear()
      objects.forEach((obj) => {
        if (obj.type === "rect" || obj.type === "circle") {
          obj.toPlaceholder();
          obj.attachPlaceholder(canvas.ref, canvas.ref._offset);
        }
        return canvas.ref.add(obj)
      })
      canvas.ref.backgroundColor = backgroundColor
      canvas.ref.renderAll()
      objects.forEach((obj, index) => {
        obj.zIndex = index
      })
      setActiveObject(object)
      canvas.ref.renderAll()
      canvas.ref.updateZIndices()
    }
  }
console.log("canvas", canvas?.ref?.toJSON())
  const addText = () => {
    if (!canvas.ref) return

    const text = new fabric.Textbox("New Text", {
      left: 100,
      top: 100,
      width: settings.text.width,
      fontSize: settings.text.fontSize,
      fill: settings.text.fill,
      fontFamily: settings.text.fontFamily,
      fontWeight: settings.text.fontWeight,
      fontStyle: settings.text.fontStyle,
      underline: settings.text.underline,
      editable: true
    });

    canvas.ref.add(text)
    canvas.ref.setActiveObject(text)
    canvas.ref.renderAll()
  }

  const addImage = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        fabric.Image.fromURL(reader.result, (img) => {
          img.scaleToWidth(100)
          img.set({
            left: 50, top: 50, lockMovementX: false,
            lockMovementY: false,
            selectable: true,
            hasControls: true,
            editable: true,
          })
          canvas.ref.add(img)
        })
      }
      reader.readAsDataURL(file)
    }
  };


  return (

    <section className="cobrand-canvas-container">
      <div className="main-container">
        <div className="d-flex justify-content-between my-3 align-items-end">
          <div>
            {!activeObject ? (

              <div className="canvas-dimensions">
                <p>Background:</p>
                <div className="d-flex align-items-center">
                  <img src={widthIcon} className="mx-2" alt="Width Icon" />
                  <span>Width:</span>
                  <div className="canvas-size-main ml-2">
                    <input
                      type="number"
                      id="canvas-size-input"
                      value={width}
                      onChange={(e) => {
                        const newWidth = e.target.value === "" ? "" : Number(e.target.value);
                        updateCanvasDimensions(newWidth, height);
                      }}
                      onBlur={() => {
                        if (width === "") setWidth(500);
                      }}
                    />
                    <img
                      src={minusIcon}
                      onClick={() => updateCanvasDimensions(width - 10, height)}
                      className="before"
                      alt="Decrease Width"
                    />
                    <img
                      src={plusIcon}
                      onClick={() => updateCanvasDimensions(width + 10, height)}
                      className="after"
                      alt="Increase Width"
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center mt-1">
                  <img src={heightIcon} className="mx-2" alt="Height Icon" />
                  <span>Height:</span>
                  <div className="canvas-size-main ml-2">
                    <input
                      type="number"
                      id="canvas-size-input"
                      value={height}
                      onChange={(e) => {
                        const newHeight = e.target.value === "" ? "" : Number(e.target.value);
                        updateCanvasDimensions(width, newHeight);
                      }}
                      onBlur={() => {
                        if (height === "") setHeight(300);
                      }}
                    />
                    <img
                      src={minusIcon}
                      onClick={() => updateCanvasDimensions(width, height - 10)}
                      className="before"
                      alt="Decrease Height"
                    />
                    <img
                      src={plusIcon}
                      onClick={() => updateCanvasDimensions(width, height + 10)}
                      className="after"
                      alt="Increase Height"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {activeObject && activeObject.type === "textbox" && (
                  <div className="canvas-dimensions">
                    <p>Text:</p>
                    <p className="d-flex align-items-center ">
                      <img src={widthIcon} className="mx-2" alt="" />
                      Width:{" "}
                      {(activeObject.width * activeObject.scaleX).toFixed(1)}
                    </p>
                    <p className="d-flex align-items-center ">
                      <img src={heightIcon} className="mx-2" alt="" />
                      Height:{" "}
                      {(activeObject.height * activeObject.scaleY).toFixed(1)}
                    </p>
                  </div>
                )}
                {activeObject && activeObject.type === "image" && (
                  <div className="canvas-dimensions">
                    <p>Image:</p>
                    <p className="d-flex align-items-center ">
                      <img src={widthIcon} className="mx-2" alt="" />
                      Width:
                      {(activeObject.width * activeObject.scaleX).toFixed(1)}
                    </p>
                    <p className="d-flex align-items-center ">
                      <img src={heightIcon} className="mx-2" alt="" />
                      Height:
                      {(activeObject.height * activeObject.scaleY).toFixed(1)}
                    </p>
                  </div>
                )}
                {activeObject && (activeObject.type === "rect" || activeObject.type === "circle") && (
                  <div className="canvas-dimensions">
                    <p>Placeholder:</p>
                    <p className="d-flex align-items-center ">
                      <img src={widthIcon} className="mx-2" alt="" />
                      Width:
                      {(activeObject.width * activeObject.scaleX).toFixed(1)}
                    </p>
                    <p className="d-flex align-items-center ">
                      <img src={heightIcon} className="mx-2" alt="" />
                      Height:
                      {(activeObject.height * activeObject.scaleY).toFixed(1)}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <div>
            <div className="canvas-zoom">
              <img
                src={zoomOutIcon}
                alt=""
                onClick={() => setScale((p) => p - 20)}
              />
              {scale}%
              <img
                src={zoomInIcon}
                alt=""
                onClick={() => setScale((p) => p + 20)}
              />
            </div>
          </div>
        </div>
        <div className="playground-wrapper">
          <div className="playground" onClick={(e) => {

            if (canvas?.ref) {
              const target = canvas.ref.findTarget(e.nativeEvent, false);
              if (!target) {
                canvas.ref.discardActiveObject();
                canvas.ref.renderAll();
                setActiveObject(null);
              }
            }
          }}>
            <div className="canvas-main">
              <canvas
                style={{ scale: `${scale / 100}` }}
                id={`screen-${screen}`}
              ></canvas>
            </div>
          </div>
          <div className="canvas-sidebar">
            <p title="Upload Image">
              <img
                src={imgUploadIcon}
                alt=""
                onClick={() => inputRef?.current?.click()}
              />
              <input
                type="file"
                className="d-none"
                accept="image/*"
                onChange={addImage}
                ref={inputRef}
              />
            </p>
            <p title="Add Placeholder" ref={placeholderIconRef}>
              <img
                src={placeholderIcon}
                alt=""
                onClick={() => setShowPlaceholderSettings(true)}
              />
              {placeholderIconRef.current && (
                <Overlay
                  show={showPlaceholderSettings}
                  onHide={() => setShowPlaceholderSettings(false)}
                  rootClose={true}
                  target={placeholderIconRef.current}
                  placement="right"
                >
                  <div className="layers-open">
                    <p onClick={() => addRectanglelaceholder()} title="Rectangle Placeholder">
                      <img src={squareIcon} alt="" />
                    </p>
                    <p onClick={() => addCirclePlaceholder()} title="Circle Placeholder">
                      <img src={circleIcon} alt="" />
                    </p>
                  </div>
                </Overlay>
              )}
            </p>
            <p onClick={addText} title="Add Text">
              <img src={textUploadIcon} alt="" />
            </p>

            <p ref={layerIconRef} title="Layer">
              <img
                src={activeObject ? layerIcon : disabledLayerIcon}
                alt=""
                onClick={() => {
                  if (activeObject) setShowLayersSettings(true)
                }}
              />
              {layerIconRef.current && (
                <Overlay
                  show={showLayersSettings}
                  onHide={() => setShowLayersSettings(false)}
                  rootClose={true}
                  target={layerIconRef.current}
                  placement="right"
                >
                  <div className="layers-open">
                    <p onClick={() => moveSelectedObject("up")} title="Bring to Front">
                      <img src={layerBringToFrontIcon} alt="" />
                    </p>
                    <p onClick={() => moveSelectedObject("down")} title="Send to Back">
                      <img src={layerSendToBackIcon} alt="" />
                    </p>
                  </div>
                </Overlay>
              )}
            </p>
            <p onClick={undo} title="Undo">
              <img src={getHistoryUndoIcon()} alt="" />
            </p>
            <p onClick={redo} title="Redo">
              <img src={getHistoryRedoIcon()} alt="" />
            </p>
          </div>
          <div className="canvas-bottombar">
            {!activeObject && (
              <div className="main d-flex gap-8">
                <CustomColorInput
                  onChange={(e) =>
                    setCanvas({
                      ...canvas,
                      backgroundColor: e.target.value
                    })
                  }
                  value={canvas.backgroundColor}
                />
                <img src={seperatorIcon} className="show-mobile" alt="" />
                <div className="canvas-sidebar show-mobile gap-8">
                  <p title="Upload Image">
                    <img
                      src={imgUploadIcon}
                      alt=""
                      onClick={() => inputRef?.current?.click()}
                    />
                    <input
                      type="file"
                      className="d-none"
                      accept="image/*"
                      onChange={addImage}
                      ref={inputRef}
                    />
                  </p>
                  <p onClick={addText} title="Add Text">
                    <img src={textUploadIcon} alt="" />
                  </p>

                  <p ref={layerMobileIconRef} title="Layer">
                    <img
                      src={activeObject ? layerIcon : disabledLayerIcon}
                      alt=""
                      onClick={() => {
                        if (activeObject) setShowLayersSettings(true)
                      }}
                    />
                    {layerMobileIconRef.current && (
                      <Overlay
                        show={showLayersSettings}
                        onHide={() => setShowLayersSettings(false)}
                        rootClose={true}
                        target={layerMobileIconRef.current}
                        placement="top"
                      >
                        <div className="layers-open">
                          <p onClick={() => moveSelectedObject("up")} title="Bring to Front">
                            <img src={layerBringToFrontIcon} alt="" />
                          </p>
                          <p onClick={() => moveSelectedObject("down")} title="Send to Back">
                            <img src={layerSendToBackIcon} alt="" />
                          </p>
                        </div>
                      </Overlay>
                    )}
                  </p>
                  <p onClick={undo} title="Undo">
                    <img src={getHistoryUndoIcon()} alt="" />
                  </p>
                  <p onClick={redo} title="Redo">
                    <img src={getHistoryRedoIcon()} alt="" />
                  </p>
                </div>
              </div>
            )}
            {activeObject && activeObject.type === "textbox" && activeObject?.editable && (
              <div className="main">
                <div className="d-flex align-items-center gap-8">
                  <div className="show-desktop">
                    <CustomColorInput
                      onChange={(e) =>
                        setCanvas({
                          ...canvas,
                          backgroundColor: e.target.value
                        })
                      }
                      value={canvas.backgroundColor}
                    />
                  </div>
                  <div className="canvas-sidebar show-mobile gap-8">
                    <p ref={layerMobileIconRef} title="Layer">
                      <img
                        src={activeObject ? layerIcon : disabledLayerIcon}
                        alt=""
                        onClick={() => {
                          if (activeObject) setShowLayersSettings(true)
                        }}
                      />
                      {layerMobileIconRef.current && (
                        <Overlay
                          show={showLayersSettings}
                          onHide={() => setShowLayersSettings(false)}
                          rootClose={true}
                          target={layerMobileIconRef.current}
                          placement="top"
                        >
                          <div className="layers-open">
                            <p onClick={() => moveSelectedObject("up")} title="Bring to Front">
                              <img src={layerBringToFrontIcon} alt="" />
                            </p>
                            <p onClick={() => moveSelectedObject("down")} title="Send to Back">
                              <img src={layerSendToBackIcon} alt="" />
                            </p>
                          </div>
                        </Overlay>
                      )}
                    </p>
                    <p onClick={undo} title="Undo">
                      <img src={getHistoryUndoIcon()} alt="" />
                    </p>
                    <p onClick={redo} title="Redo">
                      <img src={getHistoryRedoIcon()} alt="" />
                    </p>
                  </div>
                  <p>
                    <img src={seperatorIcon} />
                  </p>
                  <p>
                    <Dropdown drop="up">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {activeObjectSettings?.fontFamily}
                      </Dropdown.Toggle>

                      {createPortal(
                        <Dropdown.Menu
                          style={{ position: "absolute", zIndex: 1050 }}
                        >
                          {popularFonts.map((font) => (
                            <Dropdown.Item
                              key={font}
                              onClick={async () => {
                                setActiveObjectSettings((p) => ({
                                  ...p,
                                  fontFamily: font
                                }))
                                await loadFont(font)
                                activeObject.set({ fontFamily: font })
                                canvas?.ref?.renderAll()
                              }}
                            >
                              {font}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>,
                        document.body // Render to the body
                      )}
                    </Dropdown>
                  </p>
                  <p className="font-size-main">
                    <input
                      type="number"
                      id="font-size-input"
                      value={activeObjectSettings?.fontSize || 16} // Default to 16 if fontSize is undefined
                      onChange={(e) => {
                        if (!activeObject?.editable) return;
                        const newFontSize = Number(e.target.value)

                        if (newFontSize > 0) {
                          setActiveObjectSettings((prevSettings) => ({
                            ...prevSettings,
                            fontSize: newFontSize
                          }))
                          if (activeObject) {
                            activeObject.set({ fontSize: newFontSize })
                            adjustTextboxWidth(activeObject);// Safely update fontSize
                            canvas?.ref?.renderAll() // Re-render the canvas
                          }
                        }
                      }}
                    />
                    <img
                      src={minusIcon}
                      onClick={() => {
                        if (!activeObject) return;
                        const newFontSize = activeObjectSettings.fontSize - 1
                        if (newFontSize > 0) {
                          setActiveObjectSettings((prevSettings) => ({
                            ...prevSettings,
                            fontSize: newFontSize
                          }))
                          activeObject.set({ fontSize: newFontSize })
                          adjustTextboxWidth(activeObject);// Safely update fontSize

                          canvas?.ref?.renderAll()
                        }
                      }}
                      className="before"
                      alt=""
                    />
                    <img
                      src={plusIcon}
                      onClick={() => {
                        if (!activeObject?.editable) return;
                        const newFontSize = activeObjectSettings.fontSize + 1
                        setActiveObjectSettings((prevSettings) => ({
                          ...prevSettings,
                          fontSize: newFontSize
                        }))
                        activeObject.set({ fontSize: newFontSize })
                        adjustTextboxWidth(activeObject);// Safely update fontSize

                        canvas?.ref?.renderAll()
                      }}
                      className="after"
                      alt=""
                    />
                  </p>

                  <p>
                    <CustomColorInput
                      value={activeObjectSettings.fill}
                      onChange={(e) => {
                        const newColor = e.target.value
                        setActiveObjectSettings((prevSettings) => ({
                          ...prevSettings,
                          fill: newColor
                        }))
                        activeObject.set({ fill: newColor })
                        canvas?.ref?.renderAll()
                      }}
                    >
                      <img src={textColor} alt="" />
                    </CustomColorInput>
                  </p>
                  <p
                    className={
                      activeObjectSettings.fontWeight === "bold" ? "active" : ""
                    }
                    title="Bold"
                    onClick={() => {
                      if (!activeObject?.editable) return;
                      const newWetight =
                        activeObject.fontWeight === "bold" ? "normal" : "bold"
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        fontWeight: newWetight
                      }))
                      activeObject.set({ fontWeight: newWetight })
                      canvas?.ref?.renderAll()
                    }}
                  >
                    <img src={textBoldIcon} alt="" />
                  </p>
                  <p
                    className={
                      activeObjectSettings.fontStyle === "italic"
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      if (!activeObject?.editable) return;
                      const newStyle =
                        activeObject.fontStyle === "italic"
                          ? "normal"
                          : "italic"
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        fontStyle: newStyle
                      }))
                      activeObject.set({ fontStyle: newStyle })
                      canvas?.ref?.renderAll()
                    }}
                    title="Italic"
                  >
                    <img src={textItalicIcon} alt="" />
                  </p>
                  <p
                    className={activeObjectSettings.underline ? "active" : ""}
                    onClick={() => {
                      if (!activeObject?.editable) return;
                      const newUnderline = !activeObject.underline
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        underline: newUnderline
                      }))
                      activeObject.set({ underline: newUnderline })
                      canvas?.ref?.renderAll()
                    }}
                    title="Underline"
                  >
                    <img src={textUnderlineIcon} alt="" />
                  </p>
                  <p
                    className={activeObjectSettings.linethrough ? "active" : ""}
                    onClick={() => {
                      if (!activeObject?.editable) return;
                      const newlinethrough = !activeObject.linethrough
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        linethrough: newlinethrough
                      }))
                      activeObject.set({ linethrough: newlinethrough })
                      canvas?.ref?.renderAll()
                    }}
                  >
                    <img src={textStrike} alt="" />
                  </p>
                  <img src={seperatorIcon} alt="" />
                  <p
                    className={
                      activeObjectSettings.textAlign === "left" ? "active" : ""
                    }
                    onClick={() => {
                      if (!activeObject?.editable) return;
                      activeObject.set({ textAlign: "left" })
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        textAlign: "left"
                      }))
                      canvas?.ref?.renderAll()
                    }}
                    title="Align Left"
                  >
                    <img src={textAlignLeftIcon} alt="" />
                  </p>
                  <p
                    className={
                      activeObjectSettings.textAlign === "center"
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      activeObject.set({ textAlign: "center" })
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        textAlign: "center"
                      }))
                      canvas?.ref?.renderAll()
                    }}
                    title="Align Center"
                  >
                    <img src={textAlignCenterIcon} alt="" />
                  </p>
                  <p
                    className={
                      activeObjectSettings.textAlign === "right" ? "active" : ""
                    }
                    onClick={() => {
                      if (!activeObject?.editable) return;

                      if (!activeObject?.editable) return;
                      activeObject.set({ textAlign: "right" })
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        textAlign: "right"
                      }))
                      canvas?.ref?.renderAll()
                    }}
                    title="Align Right"
                  >
                    <img src={textAlignRightIcon} alt="" />
                  </p>
                </div>
              </div>
            )}
            {activeObject && activeObject.type === "image" && (
              <div className="main">
                <div className="d-flex align-items-center gap-8">
                  <div className="">
                    <CustomColorInput
                      onChange={(e) =>
                        setCanvas({
                          ...canvas,
                          backgroundColor: e.target.value
                        })
                      }
                      value={canvas.backgroundColor}
                    />
                  </div>{" "}
                  <p className="show-mobile">
                    <img src={seperatorIcon} />
                  </p>
                  <div className="canvas-sidebar show-mobile gap-8" title="Layer">
                    <p ref={layerMobileIconRef}>
                      <img
                        src={activeObject ? layerIcon : disabledLayerIcon}
                        alt=""
                        onClick={() => {
                          if (activeObject) setShowLayersSettings(true)
                        }}
                      />
                      {layerMobileIconRef.current && (
                        <Overlay
                          show={showLayersSettings}
                          onHide={() => setShowLayersSettings(false)}
                          rootClose={true}
                          target={layerMobileIconRef.current}
                          placement="top"
                        >
                          <div className="layers-open">
                            <p onClick={() => moveSelectedObject("up")} title="Bring to Front">
                              <img src={layerBringToFrontIcon} alt="" />
                            </p>
                            <p onClick={() => moveSelectedObject("down")} title="Send to Back">
                              <img src={layerSendToBackIcon} alt="" />
                            </p>
                          </div>
                        </Overlay>
                      )}
                    </p>
                    <p onClick={undo} title="Undo">
                      <img src={getHistoryUndoIcon()} alt="" />
                    </p>
                    <p onClick={redo} title="Redo">
                      <img src={getHistoryRedoIcon()} alt="" />
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {tooltipPosition.visible && activeObject && (
        <div
          className="canvas-tooltip"
          style={{
            position: "absolute",
            top: tooltipPosition.top,
            left: tooltipPosition.left,
          }}

        >
          {activeObject?.get("editable") ? (
            <>
              {/* Duplicate Icon */}
              {(activeObject?.type !=="rect" && activeObject?.type!=="circle") &&

              <img
                src={duplicateIcon}
                alt="Duplicate"
                title="Duplicate"
                onClick={() => {
                  if (!activeObject) return;

                  activeObject.clone((clonedObject) => {
                    if (clonedObject) {
                      clonedObject.set({
                        left: activeObject.left + 20,
                        top: activeObject.top + 20,
                        evented: true,
                        editable:true
                      });

                      canvas.ref.add(clonedObject);
                      canvas.ref.setActiveObject(clonedObject);
                      canvas.ref.renderAll();
                    }
                  });
                }}
              />
              }

              {/* Delete Icon */}
              <img
                src={deleteIcon}
                title="Delete"
                alt="Delete"
                onClick={() => {
                  canvas.ref.remove(activeObject);
                  canvas.ref.renderAll();
                }}
              />

              {/* Lock Icon (Open) */}
              <img
                src={lockIconOpen}
                alt="Lock"
                title="Lock"
                onClick={() => {
                  const activeObject = canvas?.ref?.getActiveObject();

                  if (activeObject) {
                    // Lock the object (but keep it selectable)
                    activeObject.set("editable", false);
                    activeObject.set({
                      lockMovementX: true,
                      lockMovementY: true,
                      selectable: true,
                      hasControls: false,
                    });

                    // Hide tooltip after locking
                    setTooltipPosition((prev) => ({ ...prev, visible: false }));
                    canvas.ref.renderAll();
                  }
                }}
              />
            </>
          ) : (
            <>
              {/* Lock Icon (Close) */}
              <img
                src={lockIconClose}
                alt="Unlock"
                title="Unlock"
                onClick={() => {
                  const activeObject = canvas?.ref?.getActiveObject();

                  if (activeObject) {
                    // Unlock the object
                    activeObject.set("editable", true);
                    activeObject.set({
                      lockMovementX: false,
                      lockMovementY: false,
                      selectable: true,
                      hasControls: true,
                    });

                    // Hide tooltip after unlocking
                    setTooltipPosition((prev) => ({ ...prev, visible: false }));
                    canvas.ref.renderAll();
                  }
                }}
              />
            </>
          )}
        </div>
      )}


    </section>
  )
}

// CanvasCobrand.propTypes = {
//   canvas: PropTypes.shape({
//     width: PropTypes.number,
//     height: PropTypes.number,
//     backgroundColor: PropTypes.string,
//     ref: PropTypes.object
//   }).isRequired,
//   setCanvas: PropTypes.func.isRequired
// }
export default CanvasCobrand

const addIdToObject = (object) => {
  if (!object?.id) {
    const timestamp = new Date().getTime()
    object.id = `${object?.type}_${timestamp}`
  }
}

const initialTextSettings = {
  fontSize: 16,
  fill: "#000000",
  fontFamily: "Arial",
  fontWeight: "normal",
  fontStyle: "normal",
  underline: false,
  editable: true,
  left: 50,
  top: 50,
  width: 100
}
const initialImageSettings = {
  left: 100,
  top: 100
}

const ColorPicker = ({ value, onChange }) => {
  const [activeTab, setActiveTab] = useState("circle")

  const handleEyePickerClick = async () => {
    if (!window.EyeDropper) {
      alert("Your browser does not support EyeDropper API.")
      return
    }

    try {
      const eyeDropper = new window.EyeDropper()
      const result = await eyeDropper.open()

      // setPickedColor(result.sRGBHex)
      onChange?.({ target: { value: result.sRGBHex } })
    } catch (e) {
      console.error("EyePicker failed:", e)
    }
  }

  const handleColorChange = (color) => {
    onChange?.({ target: { value: color.hex } })
  }

  return (
    <div
      style={{
        padding: "10px",
        borderRadius: "8px"
      }}
    >
      {activeTab === "circle" && (
        <>
          <CirclePicker
            color={value}
            onChangeComplete={handleColorChange}
            colors={[
              // Row 1 (Lightest shades of all colors)
              "#FFEBEE",
              "#E3F2FD",
              "#E8F5E9",
              "#FFFDE7",
              "#F3E5F5",
              "#FBE9E7",
              "#E0F2F1",
              // Row 2 (Slightly darker shades of all colors)
              "#FFCDD2",
              "#BBDEFB",
              "#C8E6C9",
              "#FFF9C4",
              "#E1BEE7",
              "#FFCCBC",
              "#B2DFDB",
              // Row 3 (Mid-tone shades of all colors)
              "#EF9A9A",
              "#90CAF9",
              "#A5D6A7",
              "#FFF59D",
              "#CE93D8",
              "#FFAB91",
              "#80CBC4",
              // Row 4 (Darker shades of all colors)
              "#E57373",
              "#64B5F6",
              "#81C784",
              "#FFF176",
              "#BA68C8",
              "#FF8A65",
              "#4DB6AC",
              "#000000",
              "#212121",
              "#424242",
              "#616161",
              "#9E9E9E",
              "#E0E0E0",
              "#FFFFFF",
              // Row 6 (Brown shades)
              "#3E2723",
              "#4E342E",
              "#5D4037",
              "#6D4C41",
              "#795548",
              "#A1887F",
              "#D7CCC8"
            ]}
          />
          <h4 className="f-s-12 my-4">Custom Color</h4>
          <div className="my-3 d-flex gap-6">
            <div
              onClick={handleEyePickerClick}
              style={{
                cursor: "pointer"
              }}
            >
              <img src={eyeDropperIcon} alt="" />
            </div>
            <div
              onClick={() => setActiveTab("sketch")}
              style={{
                cursor: "pointer"
              }}
            >
              <img src={addIcon} alt="" />
            </div>
          </div>
        </>
      )}

      {activeTab === "sketch" && (
        <ChromePicker color={value} disableAlpha onChange={handleColorChange} />
      )}
    </div>
  )
}
const CustomColorInput = ({ value, onChange, children = null }) => {
  const [showPicker, setShowPicker] = useState(false)
  const targetRef = React.useRef(null)

  return (
    <div ref={targetRef} style={{ display: "inline-block" }}>
      {/* Color preview (opens color picker) */}
      {children ? (
        <div onClick={() => setShowPicker(!showPicker)}>{children}</div>
      ) : (
        <div
          onClick={() => setShowPicker(!showPicker)}
          style={{
            width: "35px",
            height: "35px",
            backgroundColor: value,
            border: "1px solid #ccc",
            borderRadius: "50%",
            cursor: "pointer"
          }}
        ></div>
      )}

      {/* Overlay with ColorPicker */}
      <Overlay
        target={targetRef.current}
        show={showPicker}
        placement="top"
        rootClose
        onHide={() => setShowPicker(false)}
      >
        <Popover>
          <Popover.Content>
            <ColorPicker value={value} onChange={onChange} />
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  )
}