import React from "react";

import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  BaseReactComponent,
  FormSubmitButton,
} from "../../utils/form";
import { Row, Col } from "react-bootstrap";
import { CustomModal, ImageUploader } from "../common";
import { addCategoryApi, updateCategoryApi } from "./Api";
import { API_LIMIT, IMAGEFILETYPE, START_PAGE } from "../../utils/Constant";

class CategoryMasterForm extends BaseReactComponent {
  constructor(props) {
    super(props);
    const data = props.editData || null;

    this.state = {
      pageSize: API_LIMIT,
      currentPage: START_PAGE,
      categoryName: data?.categoryName || "",
      description: data?.description || "",
      thumbnailPath: "",
      thumbnailFile: null,
      bannerPath: "",
      bannerfile: null,
      circleImagePath: "",
      circleImageFile: null,
      uploadImage: data?.thumbnailPath || null,
      uploadImage2: data?.bannerPath || null,
      uploadImage3: data?.circleImagePath || null,

      attachments: [],
    };
  }

  componentDidMount() { }
  imageUploaser = (e, name) => {

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (name === "banner") {
      this.setState({ bannerPath: e.target.files[0].name, bannerfile: e.target.files[0], uploadImage2: imageUrl })
    } else if (name === "circleImage") {
      this.setState({ circleImagePath: e.target.files[0].name, circleImageFile: e.target.files[0], uploadImage3: imageUrl })
    } else {
      this.setState({ thumbnailPath: e.target.files[0].name, thumbnailFile: e.target.files[0], uploadImage: imageUrl })
    }

  }

  onValidSubmit = () => {

    let eventData = {
      "data": {
        "categoryName": this.state.categoryName,
        "description": this.state.description,
        ...(this.state.thumbnailPath ? { "thumbnailPath": this.state.thumbnailPath } : {}),
        ...(this.state.bannerPath ? { "bannerPath": this.state.bannerPath } : {}),
        ...(this.state.circleImagePath ? { "circleImagePath": this.state.circleImagePath } : {}),
      }
    }
    const data = new FormData();
    data.append("key", JSON.stringify(eventData));

    this.state.thumbnailFile !== null && data.append("file", this.state.thumbnailFile);


    if (this.props?.editData?.uuid) {
      updateCategoryApi(data, this.props.this, this.props.onHide, this.props.editData.uuid)
    } else {
      addCategoryApi(data, this.props.this, this.props.onHide);
    }
  };

  render() {
    const { uploadImage, thumbnailFile, bannerfile, uploadImage2, circleImageFile, uploadImage3 } = this.state;
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={(this.props.editData.uuid) ? "Edit Category Master" : "Add Category Master"}
        modalClass={"change-password"}
      >
        <Form onValidSubmit={this.onValidSubmit}>
          <Row>
            <Col sm={6}>
              <FormElement
                valueLink={this.linkState(this, "categoryName")}
                label="Name"
                required
                validations={[
                  {
                    validate: FormValidator.isRequired,
                    message: "Field cannot be empty",
                  },
                ]}
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    placeholder: "Enter Name",
                  },
                }}
              />
            </Col>

            <Col sm={6}>
              <ImageUploader required={false}
                id="Thumbnail"
                label="Upload Thumbnail"
                name={typeof (thumbnailFile) == 'object' ? uploadImage : thumbnailFile}
                onChange={(e) => this.imageUploaser(e, "thumbnail")}
                onDelete={() => this.setState({ thumbnailFile: "" })}
                acceptedFileType={IMAGEFILETYPE}
              />
            </Col>

            <Col sm={12}>
              <FormElement
                valueLink={this.linkState(this, "description")}
                label="Description"
                helpText="Description can't be more than 4000 character"
                control={{
                  type: CustomTextControl,
                  settings: {
                    //   type: "",
                    as: "textarea",
                    placeholder: "Enter Description",
                  },
                }}
              />
            </Col>
          </Row>
          <div className="submit-wrapper" style={{ justifyContent: "center" }}>
            <FormSubmitButton customClass={"primary-btn"}>
              Save
            </FormSubmitButton>
          </div>
        </Form>
      </CustomModal>
    );
  }
}

export default CategoryMasterForm;
